import React, { useState, useEffect, useRef } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Skeleton } from "@mui/material";
import { UserperDepartment } from "./DeptApiHelper";



// const CustomXAxisTick = ({ x, y, payload }) => {
//   return (
//     <text x={x} y={y} dy={16} textAnchor="middle" fill="#666">
//       {payload.value}
//     </text>
//   );
// };
const style = {
  top: '0',
  right: 0,
  transform: 'translate(0, -50%)',
  lineHeight: '24px',
};


const VerticalLineSkeleton = () => {

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end', height: '320px' }}>
      {/* Vertical Line Skeletons */}
      <Skeleton variant="rectangular" width={40} height="60%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
      <Skeleton variant="rectangular" width={40} height="70%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
      <Skeleton variant="rectangular" width={40} height="80%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
      <Skeleton variant="rectangular" width={40} height="50%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
      <Skeleton variant="rectangular" width={40} height="100%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />

      <Skeleton variant="rectangular" width={40} height="60%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
      <Skeleton variant="rectangular" width={40} height="70%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
      <Skeleton variant="rectangular" width={40} height="80%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
      <Skeleton variant="rectangular" width={40} height="50%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
      <Skeleton variant="rectangular" width={40} height="100%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
      <Skeleton variant="rectangular" width={40} height="90%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
      <Skeleton variant="rectangular" width={40} height="50%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
      <Skeleton variant="rectangular" width={40} height="80%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
      <Skeleton variant="rectangular" width={40} height="70%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
      <Skeleton variant="rectangular" width={40} height="60%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
      <Skeleton variant="rectangular" width={40} height="20%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
      <Skeleton variant="rectangular" width={40} height="40%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
      <Skeleton variant="rectangular" width={40} height="85%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
      <Skeleton variant="rectangular" width={40} height="50%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
      <Skeleton variant="rectangular" width={40} height="25%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
      <Skeleton variant="rectangular" width={40} height="65%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
      <Skeleton variant="rectangular" width={40} height="40%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
      <Skeleton variant="rectangular" width={40} height="15%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />

    </div>
  );
};
const GroupedBarChart = ({variable}) => {
  const departmentType = variable
  const [deptSearchResults, setDeptSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchData();

    return () => { };
  }, []);


  const fetchData = async () => {
    setLoading(true)
    try {
      const storedUserData = localStorage.getItem("userdata");

      if (!storedUserData) {
        console.error("User data not found in localStorage.");
        return;
      }

      const userDataObj = JSON.parse(storedUserData);
      if (!userDataObj || !userDataObj.token) {
        console.error(
          "User token not found in localStorage or component unmounted."
        );
        return;
      }

      const {
        token: userToken,
        user_id: deepuser_id,
        org_id: deeporg_id,
      } = userDataObj;


      const data = await UserperDepartment(
        deepuser_id,
        deeporg_id,
        departmentType,
        userToken
      );
      if (data.code == "200") {
        setLoading(false)

        setDeptSearchResults(data.data);


      } else {
        setLoading(false)
        console.error("Error fetching user data:", error);
      }
    } catch (error) {
      setLoading(false)
      console.error("Error fetching user data:", error);
    } finally {
      // Make sure to set isLoading to false whether the fetch was successful or not
      //setIsLoading(false);
    }
  };
  const CustomXAxisTick = ({ x, y, payload}) => {
    const truncatedName = payload.value.substring(0, 5) + (payload.value.length > 5 ? "..." : "");
  
    return (
      <g transform={`translate(${x},${y})`}>
        <title>{payload.value}</title>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-45)" fontSize={10}>
          <tspan x={0} dy="1.2em">{truncatedName}</tspan>
        </text>
      </g>
    );
  };
  

  return (
    <ResponsiveContainer height={350}>
      {loading ? (
        <VerticalLineSkeleton />
      ) : (
        <BarChart
          width={1000}
          height={350}
          data={deptSearchResults}
          margin={{ top: 0, right: 0, left: 0, bottom: 50 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis />
          <Tooltip />
          <Legend verticalAlign="top" wrapperStyle={style} />
          <Bar dataKey="users" fill="#2196F3" barSize={30} />

          <XAxis
 interval={0}
            dataKey="department_name"
            tick={<CustomXAxisTick />}
          />
        </BarChart>
      )}
    </ResponsiveContainer>
  );
};

export default GroupedBarChart;
