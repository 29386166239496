import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';

import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

export default function CheckboxList({ data,onSelectionChange }) {
  const [searchdepartment, setsearchdepartment] = useState('');
  const [filteredData, setFilteredData] = useState(data);

  const [checked, setChecked] = useState(() => {
    const storedState = JSON.parse(localStorage.getItem('checkboxState')) || [];
    return storedState;
  });

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    console.log('Updated Checked State:', newChecked);
    setChecked(newChecked);
    onSelectionChange(newChecked);
  };

  // Save state to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('checkboxState', JSON.stringify(checked));
  }, [checked]);
  const handleSearch = (query) => {
    setsearchdepartment(query);

    // Filter the data based on the search query
    const filteredData = data.filter((department) =>
      department.department_name.toLowerCase().includes(query.toLowerCase())
    );
    console.log('Filtered Data:', filteredData);
    setFilteredData(filteredData);
  };
  
  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <TextField
        className="rectangle-2 "
        placeholder="Search Department"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton onClick="">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={searchdepartment}
        onChange={(e) => handleSearch(e.target.value)}
      />
      <List>
        {filteredData.map((department) => (
          <ListItem key={department.department_id} disablePadding>
            <ListItemButton
              role={undefined}
              onClick={handleToggle(department.department_id)}
              dense
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(department.department_id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': `checkbox-list-label-${department.department_id}`,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={`checkbox-list-label-${department.department_id}`}
                primary={department.department_name}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </List>
  );
}
