import React, { useState, useEffect, useRef, useCallback } from "react";
import debounce from 'lodash.debounce';
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import DescriptionIcon from '@mui/icons-material/Description';
import CmdDateFilter from "../../AdminConsole/DocumentManager/CmdDateFilter";
import CmdDepartmentFilter from "../../AdminConsole/DocumentManager/CmdDepartmentFilter.jsx";
import Avatar from '@mui/material/Avatar';
import WorkIcon from '@mui/icons-material/Work';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import TaskIcon from '@mui/icons-material/Task';
import ShortTextIcon from '@mui/icons-material/ShortText';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ListIcon from '@mui/icons-material/List';
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ArticleIcon from '@mui/icons-material/Article';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Sidebar from "../Include/Sidebar";
import Tooltip from '@mui/material/Tooltip';
import { Button, TextField, FormControl, Card, CardContent, Select, Checkbox, MenuItem, Chip, Popover, Menu, DialogContentText, Autocomplete, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import Paper from '@mui/material/Paper';
import CheckIcon from '@mui/icons-material/Check';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InfiniteScroll from "react-infinite-scroll-component";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import NotesIcon from '@mui/icons-material/Notes';
import dayjs from 'dayjs';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import OutlinedInput from '@mui/material/OutlinedInput';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditIcon from '@mui/icons-material/Edit';
// import 'react-big-calendar/lib/sass/styles';
// import 'react-big-calendar/lib/addons/dragAndDrop/styles';
import {
    getCalenderMeeting,
    getAllAttendees,

    getMeetingRooms,
    AddMeetingEvent,
} from "../CmdApiHelper.js";
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import { getAllDepartment, deleteDepartment, addDepartment, fetchMoreDepartment } from "../AdminApiHelper";
import Skeleton from "@mui/material/Skeleton";
import { CircularProgress } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
// import AddDepartment from "./addDepartment";
// import ViewDepartment from "./viewDepartment";
import Appbar from "../Include/Header";
import { borderRadius } from "@mui/system";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteIcon from '@mui/icons-material/Delete';
import { getAllUser } from "../../AdminConsole/AdminApiHelper";
import { getAllDayToDayActivity,getMoreDayToDayActivity, getAllRedListbyid, RedListbydeleteid, RedListCreatetemPath, RedListDocument, RedListDocumentDeleteById } from "../Pages/CriticalActivitty/CriticalActivitiesApi.js";
import { CheckBox } from "@mui/icons-material";
import { getAllDepartment } from "../../AdminConsole/AdminApiHelper";
const drawerWidth = 200;
const theme = createTheme();
const localizer = momentLocalizer(moment);

function createData(name, calories, fat, carbs, protein, price) {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
        price,
        history: [
            {
                date: '2020-01-05',
                customerId: '11091700',
                amount: 3,
            },
            {
                date: '2020-01-02',
                customerId: 'Anonymous',
                amount: 1,
            },
        ],
    };
}



function DayToDay(props) {
    const { windows, id } = props;
    console.log('id', id)
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <Sidebar />
        </div>
    );

    const container =
        windows !== undefined ? () => window().document.body : undefined;

    const [departmentbutton, setDepartmentbutton] = React.useState(false);
    const handlebutton = () => {
        setDepartmentbutton(!departmentbutton);
    };

    const deptBtnStyle = {
        borderRadius: '200px',
        backgroundColor: 'var(--Secondary-blue, #003776)',
        textTransform: 'none'
    }
    const [isLoading, setIsLoading] = useState(false);
    const [spinnerLoading, SetspinnerLoading] = useState({});
    const [buttonLoading, setButtonLoading] = useState(false);
    const [usertoken, setusertoken] = useState("");
    const [index, setIndex] = useState(0);
    const [user_id, setuser_id] = useState("");
    const [userorg_id, setuserorg_id] = useState("");
    const [viewisLoading, setviewisLoading] = useState(false);
    const [showStaticContent, setShowStaticContent] = useState(false);
    const [userconversation_id, setuserconversation_id] = useState("");
    const [dept_id, setDept_id] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [totalrec, setTotalrec] = useState('');
    const [selectAsignee, setSelectAsignee] = useState(null);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [addDeptModalOpen, setAddDeptModalOpen] = useState(false)
    const openAddDeptModal = () => {
        setAddDeptModalOpen(true)
    };
    const closeAddDeptModal = () => {
        setAddDeptModalOpen(false)
    };
    const modalStyle = {
        borderRadius: '200px'
    }
    const [isDeleteModal, setIsDeleteModal] = useState(false)

    const openDeleteModal = (index, department_id) => {
        setIsDeleteModal(true);
        setDept_id(department_id);

    }
    const closeDeleteModal = () => {
        setIsDeleteModal(false);

    }
    const handleClickDelete = () => {

        setIsDeleteModal(false);


    }
    const [inputValue, setInputValue] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [Notificationto, setNotificationto] = useState(null);

    const handleMilestoneStatusChange = (index, isChecked) => {
        const updatedMilestones = [...selectedUserData.milestone];
        updatedMilestones[index].status = isChecked ? 'complete' : 'new';
        setSelectedUserData({
            ...selectedUserData,
            milestone: updatedMilestones
        });
    };
    const [selectedNotificationTo, setSelectedNotificationTo] = useState([]);

    const handleMultipleSelectChange = (event, newValue, name) => {
        if (name === 'assignTo') {
            setFormData({ ...formData, assignTo: newValue });
        } else if (name === 'notificationTo') {
            setFormData({ ...formData, notificationTo: newValue });
        }
    };
    const getOptionLabel = (option) => option.name;
    const [tempAtendees, setTempAttendees] = useState([]);
    const filterOptions = (options, { inputValue }) => {
        // Provide a default empty string value if inputValue is null or undefined
        const lowercaseInputValue = inputValue ? inputValue.toLowerCase() : '';

        return options.filter((option) => {
            // Ensure option.name, option.department, and option.designation are not null or undefined
            const name = option.name ? option.name.toLowerCase() : '';
            const department = option.dept_name ? option.dept_name.toLowerCase() : '';
            const designation = option.designation ? option.designation.toLowerCase() : '';
            const phone = option.mobile_no ? option.mobile_no : '';
            // Filter based on name, department, and designation
            const matchName = name.includes(lowercaseInputValue);
            const matchDepartment = department.includes(lowercaseInputValue);
            const matchDesignation = designation.includes(lowercaseInputValue);
            const matchPhone = phone.includes(lowercaseInputValue);
            return matchName || matchDepartment || matchDesignation || matchPhone;
        });
    };


    const renderOption = (props, option) => (
        <li {...props} style={{ display: "block" }}>
            <Box>
                <Box display="flex" style={{ fontSize: "0.8em" }}>
                    <Box>
                        <img src={option.photo} style={{ height: '35px', width: '35px', borderRadius: '50px', marginRight: '5px' }} />
                    </Box>
                    <Box fontWeight="bold" sx={{ marginTop: "5px" }}>
                        {option.name}
                        <Box display="flex" style={{ fontSize: "0.8em", color: "gray" }}>
                            <Box>

                                {option.email}{" "}
                            </Box>
                            <Box sx={{ margin: "0px 5px" }}> | </Box>
                            <Box>

                                {option.dept_name}
                            </Box>
                            <Box sx={{ margin: "0px 5px" }}> | </Box>
                            <Box>

                                {option.designation}
                            </Box>
                            <Box sx={{ margin: "0px 5px" }}> | </Box>
                            <Box>

                                {option.mobile_no}
                            </Box>
                        </Box>
                    </Box>

                </Box>

            </Box>
        </li>
    );



    const [RedListData, setRedListData] = useState([]);
    const [openAction, setOpenAction] = useState(Array(RedListData.length).fill(false));

    const [addNewRedList, setAddNewRedList] = useState(false);
    const [updateRedList, setUpdateRedList] = useState(false);
    const [viewRedList, setViewRedList] = useState(false);
    const [personName, setPersonName] = React.useState([]);
    const [deptSearchResults, setDeptSearchResults] = useState('');
    const [addMilestoneState, setAddMilestoneState] = useState(false);
    const [updatedMilestoneState, setUpdatedMilestoneState] = useState(false);
    const [userSearchResults, setUserSearchResults] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [uploadFiles, setUploadFiles] = useState([])
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [docToDelete, setDocToDelete] = useState(null);
    const [dateList, setDateList] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const dateRef = useRef();
    const deptRef = useRef();
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const clearSearchQuery = () => {
        setSearchText("");
    };
    const [deptId, setDeptId] = useState(id)
    const fetchUserData = async () => {
        // setIsLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
                super_admin: super_admin,
            } = userDataObj;

            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            // setSuperAdmin(super_admin)
            setIndex(0);
            const requestData = await getAllAttendees(deepuser_id, deeporg_id, 'all', '', userToken);
            if (requestData.code == "200") {
                setuserconversation_id(requestData.conversation_id);
                // const newResults = requestData.data;
                setUserSearchResults(requestData.data);
                // const userDetailToUpdate = requestData.data.find(item => item.user_id === tempUserId);
                setFilterData(requestData.data)
                // if (userDetailToUpdate) {
                // setUserDetails(userDetailToUpdate);
                // } else {
                // Handle case where no user detail is found with matching user_id
                // }

                // setTotal_rec(requestData.total_rec)
                // if (requestData.data.length >= 0 && requestData.data.length <15) {
                //     setHasMore(false)
                // }
                // else {
                //     setHasMore(true);

                // }

                // setIsLoading(false)
                setShowStaticContent(false);
            } else {
                setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            // setIsLoading(false);
        }
    };
    const fetchMoreActivity = async () => {
        // setIsLoading(true);
        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            setIndex(index+15);

            // Use the API helper function to fetch query history data
            const data = await getMoreDayToDayActivity(deepuser_id, deeporg_id, searchText, id, dateList, fromDate, toDate, userToken,index+15);

            if (data.code === "200") {
                setRedListData((prevData) => [...prevData, ...data.data]);
                setTotalrec(data.total_count)
                // setPdfHeadingForDownload(requestData.Meeting_title_pdf)
                console.log('requestData.total_rec', data.total_count)
                console.log('requestData.length', data.data.length)
                if (data.data.length >= 0 && data.data.length < 15) {
                    setHasMore(false)
                }
                else {
                    setHasMore(true);

                }
            } else {
                // setFullLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error, show a message or log it
            // seterrorSnackbar(true);
        }
    };
    useEffect(() => {

        console.log('doc_user')
        fetchUserData();

        return () => { };
    }, []);
    const useEffectFetch = async () => {
        setIsLoading(true);
        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            setIndex(0);

            // Use the API helper function to fetch query history data
            const data = await getAllDayToDayActivity(deepuser_id, deeporg_id, searchText, id, dateList, fromDate, toDate, userToken);

            if (data.code === "200") {
                setRedListData(data.data);
                setTotalrec(data.total_count)
                setIsLoading(false)
                // setPdfHeadingForDownload(requestData.Meeting_title_pdf)
                console.log('requestData.total_rec', data.total_count)
                console.log('requestData.length', data.data.length)
                if (data.data.length >= 0 && data.data.length < 15) {
                    setHasMore(false)
                }
                else {
                    setHasMore(true);

                }
            } else {
                // setFullLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error, show a message or log it
            // seterrorSnackbar(true);
        }
    };
    useEffect(() => {
        console.log('useEffect called'); // Verify if useEffect is called
        useEffectFetch(); // Call your fetch function here
    }, [id]);
    const handleApplydate = async (selectedValue) => {
        setDateList(selectedValue);
        // setfullLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");
            const userDataObj = JSON.parse(storedUserData);
            const { token: userToken } = userDataObj;

            setusertoken(userToken);

            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            setusertoken(userToken);
            setIndex(0);
            const data = await getAllDayToDayActivity(
                user_id,
                userorg_id,
                searchText,
                id,
                selectedValue,
                "",
                "",
                userToken,
                index
            );
            if (data.code == "200") {
                // setfullLoading(false);
                setRedListData(data.data);
                setTotalrec(data.total_count)
                // setPdfHeadingForDownload(requestData.Meeting_title_pdf)
                console.log('requestData.total_rec', data.total_count)
                console.log('requestData.length', data.data.length)
                if (data.data.length >= 0 && data.data.length < 15) {
                    setHasMore(false)
                }
                else {
                    setHasMore(true);

                }
            }
        } catch (error) {
            console.error("Error:", error);
        }
        // Handle the selected value in the parent page
    };
    const handleApplydatecustom = async ({ fromDateval, toDateval }) => {
        setToDate(toDateval);
        setFromDate(fromDateval);
        // setfullLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");
            const userDataObj = JSON.parse(storedUserData);
            const { token: userToken } = userDataObj;

            //setusertoken(userToken)

            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            // setusertoken(userToken);
            setIndex(0);
            const data = await getAllDayToDayActivity(
                user_id,
                userorg_id,
                searchText,
                id,
                [],
                fromDateval,
                toDateval,
                userToken,
                index
            );
            if (data.code == "200") {
                // setfullLoading(false);
                setRedListData(data.data);
                setTotalrec(data.total_count)
                // setPdfHeadingForDownload(requestData.Meeting_title_pdf)
                console.log('requestData.total_rec', data.total_count)
                console.log('requestData.length', data.data.length)
                if (data.data.length >= 0 && data.data.length < 15) {
                    setHasMore(false)
                }
                else {
                    setHasMore(true);

                }
            }
        } catch (error) {
            console.error("Error:", error);
        }
        // Handle the selected value in the parent page
    };

    const handleClearButtonClick = async () => {
        setDateList([]);
        setFromDate('');
        setToDate('');
        setSelectedDepartments('')
        clearSearchQuery()
        dateRef.current.handleResetClick();
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
                super_admin: super_admin,
            } = userDataObj;
            // setSuperAdmin(super_admin)
            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            setIndex(0);
            const requestData = await getAllDayToDayActivity(
                deepuser_id,
                deeporg_id,
                '', id, [], '', '',
                userToken,
                index
            );
            if (requestData.code == "200") {
                setuserconversation_id(requestData.conversation_id);
                // setTotalDoc(requestData.total_rec)
                const newResults = requestData.data;
                setRedListData(requestData.data);
                setTotalrec(data.total_count)
                // setPdfHeadingForDownload(requestData.Meeting_title_pdf)
                console.log('requestData.total_rec', data.total_count)
                console.log('requestData.length', data.data.length)
                if (data.data.length >= 0 && data.data.length < 15) {
                    setHasMore(false)
                }
                else {
                    setHasMore(true);

                }
                // setHasMore(requestData.data.length > 0);

                setIsLoading(false)
                setShowStaticContent(false);
            } else {
                setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            setIsLoading(false);
        }
    };
    const openAddRedListModal = () => {
        setAddNewRedList(true);
        setUploadFiles([])
        RedListSubmitTemp();

    }
    const [selectedUserData, setSelectedUserData] = useState([]);
    const closeAddRedListModal = () => {
        setAddNewRedList(false);
    }
    const openUpdateAddRedListModal = () => {
        setUpdateRedList(true);
        setUploadFiles([])
    }

    const handleEditClick = (item) => {
        setSelectedUserData(item);
        closeViewRedListModal();
        openUpdateAddRedListModal(); // Open the edit modal
    };

    const closeUpdateRedListModal = () => {
        setUpdateRedList(false);
    }

    const [userData, setUserData] = useState([]);
    const [Tempassign, setTempassign] = useState([]);
    const openViewAddRedListModal = async (redlistid) => {

        setviewisLoading(true);
        setViewRedList(true);
        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            // Use the API helper function to fetch query history data
            const data = await getAllRedListbyid(deepuser_id, deeporg_id, redlistid, userToken);

            if (data.code === "200") {
                setviewisLoading(false)
                setUserData(data.data);
                setRedList_id(data.redlist_id);
                SetDocpath(data.doc_path);
                if (data.data[0]?.notificationto) { // Check if notificationto is defined
                    setTempassign(data.data[0].notificationto.map(attendee => attendee));
                }
                console.log(data.data);
            } else {
                console.log(data.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error, show a message or log it
            // seterrorSnackbar(true);
        }



    };




    const [open, setOpen] = useState(false);
    const [RedListIdToDelete, setRedListIdToDelete] = useState('');
    const [Docpath, SetDocpath] = useState('');
    const handleOpen = (redlist_id) => {
        setOpen(true);
        setRedListIdToDelete(redlist_id);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleConfirmDelete = () => {
        RedListModaldelete(RedListIdToDelete); // Call the delete function
        setOpen(false); // Close the modal
    };

    const RedListSubmitTemp = async () => {


        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            // Use the API helper function to fetch query history data
            const data = await RedListCreatetemPath(deepuser_id, deeporg_id, userToken);

            if (data.code === "200") {
                // setUserData(data.data);
                SetDocpath(data.doc_path);
                console.log(data.data);
            } else {
                console.log(data.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error, show a message or log it
            // seterrorSnackbar(true);
        }



    };
    const [loadingStates, setLoadingStates] = useState([]);
    const [LoadingviewStates, setLoadingviewStates] = useState([]);
    const handleLoading = (index) => {
        setLoadingviewStates(prevStates => {
            const newState = [...prevStates];
            newState[index] = true;
            return newState;
        });
    };

    const RedListDocPreview = async (index, docIndex, docid, docname, docpath, redlistid) => {

        setLoadingStates(prevStates => {
            const newState = [...prevStates];
            if (!newState[docIndex]) newState[docIndex] = {};
            newState[docIndex][docid] = true; // Set loading state to false for the specific document
            return newState;
        });
        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            // Use the API helper function to fetch query history data
            const data = await RedListDocument(deepuser_id, deeporg_id, redlistid, docname, docid, docpath, userToken);

            if (data.code === "200") {


                const doclink = data.data[0].doclink;
                console.log(doclink);
                if (docname.endsWith('.pdf')) {
                    setPdfDialogOpen(true); // Open the PDF dialog
                }
                else {
                    console.log('doclink', doclink);
                    window.open(doclink, '_blank');
                }
                setPdfUrl(doclink); // Set the PDF URL
                setLoadingStates(prevStates => {
                    const newState = [...prevStates];
                    if (!newState[docIndex]) newState[docIndex] = {};
                    newState[docIndex][docid] = false; // Set loading state to false for the specific document
                    return newState;
                });
            } else {
                console.log(data.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error, show a message or log it
            // seterrorSnackbar(true);
        }



    };

    const RedListDocviewPreview = async (index, docid, docname, docpath, redlistid) => {
        handleLoading(index);

        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            // Use the API helper function to fetch query history data
            const data = await RedListDocument(deepuser_id, deeporg_id, redlistid, docname, docid, docpath, userToken);

            if (data.code === "200") {
                setLoadingviewStates(prevStates => {
                    const newState = [...prevStates];
                    newState[index] = false; // Set loading state to false after successful fetch
                    return newState;
                });

                const doclink = data.data[0].doclink;
                console.log(doclink);
                if (docname.endsWith('.pdf')) {
                    setPdfDialogOpen(true); // Open the PDF dialog
                }
                else {
                    console.log('doclink', doclink);
                    window.open(doclink, '_blank');
                }
                setPdfUrl(doclink); // Set the PDF URL

            } else {
                console.log(data.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error, show a message or log it
            // seterrorSnackbar(true);
        }



    };

    const RedListModaldelete = async (redlistid) => {
        setLoading(true);

        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            // Use the API helper function to fetch query history data
            const data = await RedListbydeleteid(deepuser_id, deeporg_id, redlistid, userToken);

            if (data.code === "200") {
                // setUserData(data.data);
                setLoading(false);
                useEffectFetch();
                closeViewRedListModal();
                console.log(data.data);
            } else {
                console.log(data.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error, show a message or log it
            // seterrorSnackbar(true);
        }



    };
    const [selectedDepartments, setSelectedDepartments] = useState('');
    const handleApplyButtonClick = (selectedCheckboxValues) => {
        setSelectedDepartments(selectedCheckboxValues);
        useEffectFetch(selectedCheckboxValues);
    };
    const closeViewRedListModal = () => {
        setViewRedList(false);
    }
    const handleRowClick = (index) => {
        const newOpenRows = [...openAction];
        newOpenRows[index] = !newOpenRows[index];
        setOpenAction(newOpenRows);
    };
    const [RedList_id, setRedList_id] = useState('');
    const [milestones, setMilestones] = useState([]);
    const [UpdateMilestones, setUpdateMilestones] = useState([]);
    const [milestoneName, setMilestoneName] = useState('');
    const [dueDate, setDueDate] = useState(null); // Use appropriate date state
    const [assign, setAssign] = useState('');
    const [loading, setLoading] = useState(false); // State to track loading
    const [deleteloading, setdeleteloading] = useState(false);
    const EditMilestone = () => {
        if (!dueDate) {
            // Handle the case when dueDate is null (not selected)
            // For example, you can display an error message or prevent the function from proceeding
            console.error("Due date is required");
            return;
        }
        const newMilestone = {
            milestone_description: milestoneName,
            due_date: dueDate.format('MM/DD/YYYY'), // Convert due date to string
            assign_to: selectAsignee,
            status: 'New' // Default status
        };

        // Update milestones array by appending the new milestone
        setUpdateMilestones([...milestones, newMilestone]);
        const updatedMilestoneData = [...(selectedUserData.milestone || []), newMilestone];

        // Update the selectedUserData state with the updated milestone data
        setSelectedUserData(prevState => ({
            ...prevState,
            milestone: updatedMilestoneData
        }));
        setSelectAsignee(null)
        // Clear input fields after adding milestone
        setMilestoneName('');
        setDueDate(null);
        setAssign('');
        setAddMilestoneState(false)
        setUpdatedMilestoneState(true)
    };

    const updateMilestone = () => {
        if (!dueDate) {
            // Handle the case when dueDate is null (not selected)
            // For example, you can display an error message or prevent the function from proceeding
            console.error("Due date is required");
            return;
        }
        const newMilestone = {
            milestone_description: milestoneName,
            due_date: dueDate.format('MM/DD/YYYY'), // Convert due date to string
            assign_to: selectAsignee,
            status: 'New' // Default status
        };

        // Update milestones array by appending the new milestone
        setMilestones([...milestones, newMilestone]);
        setSelectAsignee(null)

        setMilestoneName('');
        setDueDate(null);
        setAssign('');
        setAddMilestoneState(false)
        setUpdatedMilestoneState(true)
    };

    const cancelMilestone = (index) => {
        // Create a copy of the milestones array
        const updatedMilestones = [...milestones];
        // Remove the milestone at the specified index
        updatedMilestones.splice(index, 1);
        // Update the milestones array state
        setMilestones(updatedMilestones);
    };

    const canceleditMilestone = (indexToRemove) => {
        // Filter out the milestone with the specified indexToRemove
        const updatedMilestones = selectedUserData.milestone.filter((_, index) => index !== indexToRemove);

        // Update selectedUserData with the updated milestone array
        setSelectedUserData(prevUserData => ({
            ...prevUserData,
            milestone: updatedMilestones
        }));
    };

    const addMileStone = () => {
        setAddMilestoneState(true)
    }

    const cancelMileStone = () => {
        setAddMilestoneState(false)
    }
    const [value, setValue] = React.useState(null);
    function ButtonField(props) {
        const {
            setOpen,
            label,
            id,
            disabled,
            InputProps: { ref } = {},
            inputProps: { 'aria-label': ariaLabel } = {},
        } = props;
        console.log('value', value)
        return (
            <Button
                backgroundColor={dueDate !== null && 'grey'}
                sx={{ backgroundColor: dueDate !== null ? '#D8E2FF' : '' }}

                id={id}
                disabled={disabled}
                ref={ref}
                aria-label={ariaLabel}
                onClick={() => setOpen?.((prev) => !prev)} variant='none' style={{ borderColor: 'grey', color: 'grey', borderRadius: '8px', fontSize: '10px', textTransform: 'none', padding: '8px', fontWeight: 'bold' }} > <AccessTimeIcon sx={{ height: '20px', width: '20px' }}
                />
                Due Date</Button>
        );
    }

    function ButtonDatePicker(props) {
        const [open, setOpen] = React.useState(false);

        return (
            <DatePicker
                slots={{ field: ButtonField, ...props.slots }}
                slotProps={{ field: { setOpen } }}
                {...props}
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
            />
        );
    }
    const [assignee, setAssignee] = React.useState(null);
    const [UpdateAssignee, setUpdateAssignee] = React.useState(null);

    const openAssignee = Boolean(assignee);
    const handleClick = (event) => {
        setAssignee(event.currentTarget);
    };
    const handleUpdateClick = (event) => {
        setUpdateAssignee(event.currentTarget);
    };
    const handleInputChangeee = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleEditChange = (newValue, field) => {
        setSelectedUserData(prevUserData => ({
            ...prevUserData,
            [field]: newValue
        }));
    };


    const [assignees, setAssignees] = useState([]);
    const handleSelectAssignee = (name, photo) => { // Receive name directly instead of event
        setSelectAsignee(name);
        console.log('name:', photo);
        setAssignees([...assignees, photo]);
        setAssignee(null);
    };

    const handleAssigneeMenuClose = () => { // Receive name directly instead of event

        setAssignee(null);
    };
    const [filterData, setFilterData] = useState([]);
    const handleSearchMilestoneAssignee = (item) => {
        // Filter the data based on the search query
        const filteredData = userSearchResults.filter((name) =>
            name.name.toLowerCase().includes(item.toLowerCase())
        );
        setFilterData(filteredData);
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            useEffectFetch();
        }
    };
    const [formData, setFormData] = useState({
        user_id: '',
        org_id: '',
        projectName: '',
        agenda: '',
        description: '',
        assignTo: null,
        notificationTo: [],
        startDate: null,
        endDate: null,
        // status: '',
        documents: [],
        milestones: [],
    });
    const openConfirmModal = (docid, redlistid, docname, docpath) => {
        setDocToDelete({ docid, redlistid, docname, docpath });
        setConfirmOpen(true);
    };

    const closeConfirmModal = () => {
        setConfirmOpen(false);
        setDocToDelete(null);
    };

    const handleDocumentDelete = async (docid, redlistid, docname, docpath) => {
        // Call this function to open the confirm modal
        openConfirmModal(docid, redlistid, docname, docpath);

    };
    const confirmDelete = async () => {
        setdeleteloading(true);
        const { docid, redlistid, docname, docpath } = docToDelete;
        if (!docid) {
            setSelectedUserData(prevUserData => ({
                ...prevUserData,
                document: prevUserData.document.filter(doc => doc.doc_name !== docname) // Filter out the deleted document by doc_name
            }));
            setUploadFiles(prevFiles => prevFiles.filter(file => file.doc_name !== docname)); // Filter out the deleted document from upload files
            closeConfirmModal();
            setdeleteloading(false);
            return; // Exit the function
        }

        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            // Use the API helper function to fetch query history data
            const data = await RedListDocumentDeleteById(deepuser_id, deeporg_id, redlistid, docname, docid, docpath, userToken);

            if (data.code === "200") {
                setdeleteloading(false);
                setSelectedUserData(prevUserData => ({
                    ...prevUserData,
                    document: prevUserData.document.filter(doc => doc.doc_id !== docid) // Filter out the deleted document
                }));
                setUploadFiles(prevFiles => prevFiles.filter(file => file.doc_id !== docid)); // Filter out the deleted document from upload files

                // Call openEditModal with the updated redlistid
                //   openEditModal(redlistid);
                console.log(data.data);

            } else {
                console.log(data.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error, show a message or log it
            // seterrorSnackbar(true);
        }
        closeConfirmModal();
    };


    const handleAddRedList = async () => {
        console.log('tapan')
        event.preventDefault();
        setIsLoadingMore(true);
        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            let assignToUserId = '';

            // Check if assignTo is an array
            if (Array.isArray(formData.assignTo)) {
                // Assuming formData.assignTo is an array of users
                // Extract user_id from the first user object in the array
                if (formData.assignTo.length > 0) {
                    assignToUserId = formData.assignTo[0].attendees_id;
                }
            } else if (formData.assignTo && typeof formData.assignTo === 'object') {
                // If assignTo is a single user object
                assignToUserId = formData.assignTo.attendees_id;
            }

            console.log('Assign To User ID:', assignToUserId);

            // const milestoneArray = [];

            // // Loop through the milestones and push each milestone object to the milestone array
            // milestones.forEach(milestone => {
            //     milestoneArray.push({
            //         milestone_description: milestone.milestoneName,
            //         assign_to:assignToUserId,
            //         due_date: formData.endDate,
            //         status: 'new'
            //     });
            // });
            var myHeaders = new Headers();

            myHeaders.append("token", userToken);
            // const milestoneJSON = JSON.stringify(milestoneArray);
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', deepuser_id);
            formDataToSend.append('org_id', deeporg_id);
            formDataToSend.append('project_name', formData.projectName);
            formDataToSend.append('agenda', formData.agenda);
            formDataToSend.append('description', formData.description);
            formDataToSend.append('assignto', assignToUserId); // Append assignTo
            // formDataToSend.append('notificationto', notificationToUserIds); // Append notificationTo
            const notificationToUserIds = formData.notificationTo.map(user => user.attendees_id).join(',').split(',');
            notificationToUserIds.forEach(id => formDataToSend.append('notificationto[]', id)); // Append notificationTo
            formDataToSend.append('activity_type', 'daytoday');
            formDataToSend.append('dept_id', selectedOption);
            formDataToSend.append('start_datetime', formData.startDate.toISOString());
            formDataToSend.append('end_datetime', formData.endDate.toISOString());
            formDataToSend.append('docs', JSON.stringify(uploadFiles));
            formDataToSend.append('doc_path', Docpath);

            formDataToSend.append('milestone', JSON.stringify(milestones));

            // Add selected user from Autocomplete


            // Make API call
            const response = await fetch(`${window.constants.api_url}/cmdadmin/createnewredlistactivity`, {
                method: 'POST',
                headers: myHeaders,
                body: formDataToSend,
                redirect: 'follow'
            });
            console.log(response);

            const responseData = await response.json();
            if (responseData.code === "200") {
                closeAddRedListModal();
                setIsLoadingMore(false);
                setUploadFiles([])
                useEffectFetch();
                setFormData({
                    // Reset formData state to initial values
                    user_id: '',
                    org_id: '',
                    projectName: '',
                    agenda: '',
                    description: '',
                    assignTo: null,
                    notificationTo: [],
                    startDate: null,
                    endDate: null,
                    documents: [],
                    milestones: []
                });
                setMilestones([]);
            } else {
                setIsLoadingMore(false);
                console.error(responseData.message);
            }
        } catch (error) {
            setIsLoadingMore(false);
            console.error('Error:', error);
        }
    };
    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handleEditOptionChange = (event) => {
        const newValue = event.target.value;
        setSelectedUserData(prevUserData => ({
            ...prevUserData,
            dept_id: newValue
        }));
    };
    const [selectedFile, setSelectedFile] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const LoadingTable = () => {
        // You can customize the skeleton based on your design
        return (
            <TableRow>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>

            </TableRow>
        );
    };
    const LoadingSkeleton = () => {
        // You can customize the skeleton based on your design
        return (
            <Stack spacing={2} sx={{ padding: '30px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box display='flex'>
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                        <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={80} height={30} />
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    </Box>
                    <Box display='flex'>
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    </Box>
                </Box>
                <Box display='flex'>
                    <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={500} height={30} />
                </Box>
                <Box display='flex'>
                    <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={500} height={90} />
                </Box>
                <Box display='flex'>
                    <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={500} height={30} />
                </Box>
                <Box display='flex'>
                    <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={500} height={30} />
                </Box>
                <Box display='flex'>
                    <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={500} height={30} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box display='flex'>
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                        <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                        <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    </Box>
                    <Box display='flex'>
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                        <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                        <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    </Box>
                </Box>
                <Box display='flex'>
                    <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={500} height={30} />
                </Box>


            </Stack>
        );
    };
    const handleFileChange = async (event) => {
        const uploadedFiles = Array.from(event.target.files);

        const newFiles = uploadedFiles.map(file => ({
            doc_type: getFileType(file.name),
            doc_name: file.name,
            doc_size: file.size.toString(),
            doc_path: Docpath,
            uploadstatus: 'new', // Initially set upload status to 'loading'
            percentage: '0', // Initial value for percentage
            message: ''
        }));

        setUploadFiles(prevFiles => [...prevFiles, ...newFiles]);

        await Promise.all(uploadedFiles.map(async (file) => {
            const fileType = getFileType(file.name);

            // Make a request to your backend to get the presigned URL for S3 upload
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            // get presigned url
            const presignedUrlResponse = await fetch('https://54.236.17.116/deep-probe-mcl-lumen-api-v2/cmdadmin/initiate-redlist-upload', {
                method: 'POST',
                body: JSON.stringify({
                    "user_id": deepuser_id,
                    "org_id": deeporg_id,
                    "activity_id": "0",
                    "activity_type": "daytoday",
                    "doc_path": Docpath,
                    "docs": [file.name]
                }
                ),
                headers: {
                    'Content-Type': 'application/json',
                    'token': userToken
                },
            });

            if (!presignedUrlResponse.ok) {
                throw new Error('Failed to get presigned URL from the server');
            }

            const presignedUrlData = await presignedUrlResponse.json();

            if (!presignedUrlData.data[0].presignedUrl) {
                return {
                    doc_type: fileType,
                    doc_name: file.name,
                    doc_size: file.size.toString(),
                    doc_path: Docpath,
                    uploadstatus: 'failed', // Update upload status to 'error'
                    percentage: '0', // Initial value for percentage
                    message: presignedUrlData.data[0].message,
                };
            }

            // Upload the file to S3 using the presigned URL
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', presignedUrlData.data[0].presignedUrl);
            xhr.upload.onprogress = (event) => {
                const percentage = (event.loaded / event.total) * 100;
                // Update the percentage and upload status for this file
                setUploadFiles(prevFiles => {
                    const updatedFiles = prevFiles.map(prevFile => {
                        if (prevFile.doc_name === file.name) {
                            return {
                                ...prevFile,
                                percentage: percentage.toFixed(2),
                                uploadstatus: percentage === 100 ? 'complete' : 'processing',
                            };
                        }
                        return prevFile;
                    });
                    return updatedFiles;
                });
            };

            xhr.send(file);
        }));
    };

    // get file size
    const formatBytes = (bytes) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };


    // get file type
    function getFileType(fileName) {
        const extension = fileName.split('.').pop().toLowerCase();
        if (extension === 'pdf') {
            return 'pdf';
        } else if (extension === 'doc' || extension === 'docx') {
            return 'doc';
        } else if (extension === 'xls' || extension === 'xlsx') {
            return 'excel';
        } else {
            return 'other';
        }
    }

    const handleDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        const updatedDocuments = [...selectedFiles];
        for (let i = 0; i < files.length; i++) {
            updatedDocuments.push(files[i]);
        }
        setSelectedFiles(updatedDocuments);
        if (files.length > 0) {
            setSelectedFile(true);
        } else {
            setSelectedFile(false);
        }
        // Update form data with the updated documents
        setFormData({ ...formData, documents: updatedDocuments });
    };
    const handleUpdateDrop = (event) => {
        event.preventDefault(); // Prevent default behavior of dropping files (e.g., opening them in the browser)

        const files = event.dataTransfer.files; // Get the dropped files
        handleFiles(files); // Handle the dropped files
    };

    const handleFiles = (files) => {
        let documentNames = selectedUserData.document || ''; // Initialize with existing document names or an empty string
        for (let i = 0; i < files.length; i++) {
            documentNames += (documentNames ? ',' : '') + files[i].name; // Append file name with comma separator
        }
        setSelectedUserData(prevState => ({
            ...prevState,
            document: documentNames
        }));
        if (files.length > 0) {
            setSelectedFile(true);
        } else {
            setSelectedFile(false);
        }
    };

    // const handleUpdateFileChange = (event) => {
    //     const files = event.target.files;
    //     handleFiles(files); // Handle the selected files
    // };
    const handleUpdateFileChange = async (redlistid, docpath) => {
        try {
            const uploadedFiles = Array.from(event.target.files);

            // Create an array of new files with initial upload status
            const newFiles = uploadedFiles.map(file => ({
                doc_type: getFileType(file.name),
                doc_name: file.name,
                doc_size: file.size.toString(),
                doc_path: docpath,
                uploadstatus: 'new', // Initially set upload status to 'loading'
                percentage: '0', // Initial value for percentage
                message: ''
            }));
            setUploadFiles(prevFiles => [...prevFiles, ...newFiles]);
            // Append the new files to the selectedUserData.document state
            setSelectedUserData(prevUserData => ({
                ...prevUserData,
                document: [...prevUserData.document, ...newFiles]
            }));
            // console.log(electedUserData.document)
            await Promise.all(uploadedFiles.map(async (file) => {
                const fileType = getFileType(file.name);

                // Make a request to your backend to get the presigned URL for S3 upload
                const userData = await localStorage.getItem("userdata");
                const userDataObj = JSON.parse(userData);
                if (!userDataObj || !userDataObj.token) {
                    console.error("User token not found in AsyncStorage.");
                    return;
                }
                const userToken = userDataObj.token;
                const deepuser_id = userDataObj.user_id;
                const deeporg_id = userDataObj.org_id;

                // get presigned url
                const presignedUrlResponse = await fetch('https://54.236.17.116/deep-probe-mcl-lumen-api-v2/cmdadmin/initiate-redlist-upload', {
                    method: 'POST',
                    body: JSON.stringify({
                        "user_id": deepuser_id,
                        "org_id": deeporg_id,
                        "activity_id": redlistid,
                        "activity_type": "daytoday",
                        "doc_path": docpath,
                        "docs": [file.name]
                    }
                    ),
                    headers: {
                        'Content-Type': 'application/json',
                        'token': userToken
                    },
                });

                if (!presignedUrlResponse.ok) {
                    throw new Error('Failed to get presigned URL from the server');
                }

                const presignedUrlData = await presignedUrlResponse.json();

                if (!presignedUrlData.data[0].presignedUrl) {
                    console.log(presignedUrlData.data[0].presignedUrl)
                    const failedFile = {
                        doc_type: fileType,
                        doc_name: file.name,
                        doc_size: file.size.toString(),
                        doc_path: docpath,
                        uploadstatus: 'failed', // Update upload status to 'failed'
                        percentage: '0', // Initial value for percentage
                        message: presignedUrlData.data[0].message,
                    };
                    // Update selectedUserData.document with the failed file
                    setSelectedUserData(prevUserData => ({
                        ...prevUserData,
                        document: [...prevUserData.document, failedFile]
                    }));
                    return; // Exit the function execution for this file
                }

                // Upload the file to S3 using the presigned URL
                const xhr = new XMLHttpRequest();
                xhr.open('PUT', presignedUrlData.data[0].presignedUrl);
                xhr.upload.onprogress = (event) => {
                    const percentage = (event.loaded / event.total) * 100;
                    // Update the percentage and upload status for this file
                    setSelectedUserData(prevUserData => ({
                        ...prevUserData,
                        document: prevUserData.document.map(prevFile => {
                            if (prevFile.doc_name === file.name) {
                                return {
                                    ...prevFile,
                                    percentage: percentage.toFixed(2),
                                    uploadstatus: percentage === 100 ? 'complete' : 'processing',
                                };
                            }
                            return prevFile;
                        })
                    }));
                };

                xhr.send(file);
            }));
        } catch (error) {
            console.error("Error handling file upload:", error);
            // Handle error, show a message or log it
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };
    const handleDragupdateOver = (event) => {
        event.preventDefault();
    };
    const handleStartDateChange = (date) => {
        setFormData({ ...formData, startDate: date });
    };

    const handleEndDateChange = (date) => {
        setFormData({ ...formData, endDate: date });
    };

    useEffect(() => {


        fetchData();

        return () => { };
    }, []);
    const fetchData = async () => {
        // setIsLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
            } = userDataObj;

            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            setIndex(0);
            const data = await getAllDepartment(deepuser_id, deeporg_id, userToken);
            if (data.code == "200") {
                setuserconversation_id(data.conversation_id);
                const newResults = data.data;
                setDeptSearchResults(data.data);
                setTotalrec(data.total_rec)
                // if (data.data.length >= 0 && data.data.length < 15) {
                //     setHasMore(false)
                // }
                // else {
                //     setHasMore(true);

                // }
                // setIsLoading(false)
                setDept_id(data.department_id)
                setShowStaticContent(false);
            } else {
                setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            // setIsLoading(false);
        }
    };
    const [tempMeetingDate, setTempMeetingDate] = React.useState(null);
    const [startDate, setstartDate] = React.useState(null);
    // Parse the date string before setting it as the initial value
    React.useEffect(() => {
        if (selectedUserData.end_datetime) {
            setTempMeetingDate(dayjs(selectedUserData.end_datetime));
        }
    }, [selectedUserData.end_datetime]);
    React.useEffect(() => {
        if (selectedUserData.start_datetime) {
            setstartDate(dayjs(selectedUserData.start_datetime));
        }
    }, [selectedUserData.end_datetime]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const handleUserSelection = (event, newValue) => {
        setSelectedUsers(newValue); // Update selected users
        console.log(newValue);
    };
    const handleUpdateRedList = async (event) => {
        event.preventDefault();

        setButtonLoading(true);
        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            let assignToUserId = '';

            // Check if assignTo is an array
            if (Array.isArray(selectedUserData.assignto)) {
                // Assuming formData.assignTo is an array of users
                // Extract user_id from the first user object in the array
                if (selectedUserData.assignto.length > 0) {
                    assignToUserId = selectedUserData.assignto[0].attendees_id;
                }
            } else if (selectedUserData.assignto && typeof selectedUserData.assignto === 'object') {
                // If assignTo is a single user object
                assignToUserId = selectedUserData.assignto.attendees_id;
            }

            console.log('Assign To User ID:', selectedUserData.assignto);

            var myHeaders = new Headers();
            const userIds = selectedUsers.map(user => user.attendees_id);
            myHeaders.append("token", userToken);

            const formDataToSend = new FormData();
            formDataToSend.append('user_id', deepuser_id);
            formDataToSend.append('org_id', deeporg_id);
            formDataToSend.append('project_name', selectedUserData.project_name);
            formDataToSend.append('agenda', selectedUserData.agenda);
            formDataToSend.append('dept_id', selectedUserData.dept_id);
            formDataToSend.append('redlist_id', selectedUserData.redlist_id);
            formDataToSend.append('description', selectedUserData.description);
            formDataToSend.append('assignto', selectedUserData.assignto);
            formDataToSend.append('status', selectedUserData.status);

            //  const notificationToUserIds = selectedUserData.notificationto.map(user => user.user_id).join(',').split(',');
            //      notificationToUserIds.forEach(id => formDataToSend.append('notificationto[]',id));

            // selectedUserData.notificationto.forEach(id => formDataToSend.append('notificationto[]', id));
            formDataToSend.append('notificationto[]', userIds);
            formDataToSend.append('start_datetime', startDate.toISOString());
            formDataToSend.append('end_datetime', tempMeetingDate.toISOString());
            formDataToSend.append('milestone', JSON.stringify(selectedUserData.milestone));
            formDataToSend.append('docs', JSON.stringify(uploadFiles));
            //   formDataToSend.append('docs[]',);
            const response = await fetch(`${window.constants.api_url}/cmdadmin/editredlistactivity`, {
                method: 'POST',
                headers: myHeaders,
                body: formDataToSend,
                redirect: 'follow'
            });

            console.log(response);

            const responseData = await response.json();
            if (responseData.code === "200") {
                setButtonLoading(false);
                closeUpdateRedListModal();
                closeViewRedListModal();
                setUploadFiles([])
                useEffectFetch();
                setSelectedUserData({
                    // Reset selectedUserData state to initial values
                    projectName: '',
                    agenda: '',
                    description: '',
                    assignto: null,
                    notificationto: [],
                    startDate: null,
                    endDate: null,
                    document: [],
                    milestone: []
                });
            } else {
                setButtonLoading(false);
                console.error(responseData.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const [pdfDialogOpen, setPdfDialogOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');

    const handleClosePdfDialog = () => {
        setPdfDialogOpen(false);
        setPdfUrl('');
    };
    const tableContainerRef = useRef(null);
    const isLoadingRef = useRef(false);

    const debouncedHandleScroll = useCallback(debounce(() => {
        const tableContainer = tableContainerRef.current;
        if (
            tableContainer &&
            tableContainer.scrollTop + tableContainer.clientHeight >= tableContainer.scrollHeight &&
            !isLoadingRef.current
        ) {
            isLoadingRef.current = true;
            fetchMoreActivity().finally(() => {
                isLoadingRef.current = false;
            });
        }
    }, 200), [fetchMoreActivity]);

    useEffect(() => {
        const tableContainer = tableContainerRef.current;

        if (tableContainer) {
            tableContainer.addEventListener('scroll', debouncedHandleScroll);

            return () => {
                tableContainer.removeEventListener('scroll', debouncedHandleScroll);
                debouncedHandleScroll.cancel();
            };
        }
    }, [debouncedHandleScroll]);
    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    backgroundColor: "white",
                }}
            >
                <Appbar />
            </AppBar>
            {/* <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                            borderRight: '0px',
                        },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: "none", sm: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                            borderRight: '0px',
                        },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box> */}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    background: "#EFF1F3",
                    minHeight: "58vh",
                }}
            >
                <Box >

                    <Box component={Paper} >
                        <Box>
                            <Box sx={{ display: 'flex', padding: '10px' }}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box sx={{ marginRight: '10px' }}><Typography sx={{ color: 'black', fontSize: '18px', fontWeight: 'bold' }}>
                                        Day To Day Activity at MCL
                                    </Typography>
                                    </Box>
                                    <Box sx={{ marginRight: '10px' }}><Typography sx={{ color: '#2F80ED', fontSize: '12px', backgroundColor: '#D8E2FF', fontWeight: 'bold', borderRadius: '50px', paddingLeft: '15px', paddingRight: '15px', marginTop: '5px' }}>
                                        {totalrec} activities
                                    </Typography>
                                    </Box>
                                </Box>

                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '10px' }}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box sx={{ marginRight: '10px' }}>
                                        <TextField
                                            onKeyPress={handleKeyPress}
                                            value={searchText}
                                            onChange={(e) => setSearchText(e.target.value)}
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    height: '8px',
                                                    // border:'1px solid grey',
                                                    borderRadius: '8px',
                                                }, marginRight: '10px',
                                                // border: '1px solid grey',
                                                borderRadius: '8px',
                                                height: '50px',
                                                width: '100%'
                                            }}
                                            placeholder='Search activities'
                                            InputProps={{

                                                startAdornment: (
                                                    <IconButton onClick={useEffectFetch}>
                                                        <SearchIcon sx={{ top: '-3px' }} />
                                                    </IconButton>
                                                ),
                                                endAdornment: (null),
                                            }}
                                        />
                                    </Box>

                                    <Box><CmdDateFilter
                                        ref={dateRef}
                                        onApplycustomdate={handleApplydatecustom}
                                        onApply={handleApplydate}
                                    /></Box>
                                    <Box sx={{ margin: '0px 10px' }}>
                                        <Button variant='outlined' style={{ borderColor: '#D0D5DD', color: '#344054', borderRadius: '8px', fontSize: '14px', textTransform: 'none', padding: '7px', fontWeight: 'bold' }} onClick={handleClearButtonClick} >
                                            Clear All
                                        </Button>
                                    </Box>
                                    {/* <Box sx={{ marginRight: '10px' }}>
                                        <Button variant='outlined' style={{ borderColor: 'grey', color: 'grey', borderRadius: '8px', fontSize: '10px', textTransform: 'none', padding: '8px', fontWeight: 'bold' }} >Filter by date<CalendarTodayIcon sx={{ height: '20px', width: '20px' }} />
                                        </Button>
                                    </Box> */}
                                </Box>

                            </Box>
                        </Box>
                        <Box sx={{ position: 'relative' }}>

                        <TableContainer ref={tableContainerRef} component={Paper} sx={{ height: '48.8vh', overflow: 'auto' }}>
                                <Table aria-label="collapsible table" size='small' stickyHeader>
                                    <TableHead sx={{ color: '#eff1f3' }}>

                                        <TableRow>
                                            {/* <TableCell sx={{ backgroundColor: '#F9FAFB' }}>#</TableCell> */}
                                            <TableCell sx={{ backgroundColor: '#F9FAFB', color: '#475467', fontWeight: 'bold' }}>Agenda</TableCell>
                                            {/* <TableCell sx={{ backgroundColor: '#F9FAFB' }} align="left">Project Name</TableCell> */}
                                            <TableCell sx={{ backgroundColor: '#F9FAFB', color: '#475467', fontWeight: 'bold' }} align="left">Due Date</TableCell>
                                            <TableCell sx={{ backgroundColor: '#F9FAFB', color: '#475467', fontWeight: 'bold' }} align="left">Assign To</TableCell>
                                            <TableCell sx={{ backgroundColor: '#F9FAFB', color: '#475467', fontWeight: 'bold' }} align="left">Status</TableCell>
                                            <TableCell sx={{ backgroundColor: '#F9FAFB', color: '#475467', fontWeight: 'bold' }} align="left">Action</TableCell>

                                        </TableRow>

                                    </TableHead>
                                    {isLoading ? (
                                        <LoadingTable
                                        />) : (
                                        <TableBody sx={{ fontWeight: 'bold' }}>
                                            {RedListData.map((dummyRedListData, index) => (
                                                <React.Fragment key={index}>
                                                    <TableRow >
                                                        {/* <TableCell align="left">{dummyRedListData.id}</TableCell> */}
                                                        <TableCell sx={{ maxWidth: '360px', }} align="left" onClick={() => openViewAddRedListModal(dummyRedListData.redlist_id)}><Typography cursor="pointer" sx={{
                                                            color: '#101828', cursor: 'pointer', fontSize: '14px', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                                                            '&:hover': {
                                                                textDecoration: 'underline',// Set the hover color
                                                                color: '#2196F3'
                                                            },
                                                        }}>{dummyRedListData.agenda}</Typography></TableCell>
                                                        {/* <TableCell align="left">{dummyRedListData.project_name}</TableCell> */}
                                                        <TableCell align="left">{dummyRedListData.end_datetime}</TableCell>
                                                        <TableCell align="left">{dummyRedListData.assignto}</TableCell>
                                                        <TableCell align="left">
                                                            <Box sx={{ display: 'flex' }}>
                                                                {dummyRedListData.status == 'new' &&
                                                                    <Chip label='New' size="small" color="primary" sx={{ width: '90px' }} />

                                                                }
                                                                {dummyRedListData.status == 'inprogress' &&
                                                                    <Chip label='In Progress' size="small" color="warning" sx={{ width: '90px' }} />
                                                                }
                                                                {dummyRedListData.status == 'complete' &&
                                                                    <Chip label='Complete' size="small" color="success" sx={{ width: '90px' }} />
                                                                }
                                                                {dummyRedListData.status == 'hold' &&
                                                                    <Chip label='Hold' size="small" color="error" sx={{ width: '90px' }} />
                                                                }
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            <IconButton
                                                                aria-label="expand row"
                                                                size="small"
                                                                onClick={() => handleRowClick(index)}
                                                            >
                                                                {openAction[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow key={index}>
                                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                            <Collapse in={openAction[index]} timeout="auto" unmountOnExit>
                                                                <Box sx={{ margin: 1 }}>
                                                                    <Typography gutterBottom component="div">
                                                                        {dummyRedListData.description}
                                                                    </Typography>


                                                                    {dummyRedListData.milestone && (
                                                                        <>
                                                                            <Box sx={{ display: 'flex' }}>

                                                                                {parseInt(dummyRedListData.milestone.new) + parseInt(dummyRedListData.milestone.complete) > 0 && (

                                                                                    <>
                                                                                        <Typography sx={{ fontWeight: 'bold', fontSize: '14px', marginTop: '3px' }}>Milestones :</Typography>
                                                                                        <Chip label={`${dummyRedListData.milestone.new} new`} size="small" color="primary" variant="outlined" sx={{ marginLeft: '10px', width: '100px' }} />

                                                                                        <Chip label={`${dummyRedListData.milestone.complete} Completed`} size="small" color="success" variant="outlined" sx={{ marginLeft: '10px', width: '100px' }} />


                                                                                    </>
                                                                                )}
                                                                            </Box>
                                                                        </>
                                                                    )}


                                                                    <Box sx={{ display: 'flex', marginTop: '10px', flexWrap: 'wrap' }}>
                                                                        {dummyRedListData.document && dummyRedListData.document.map((filename, docIndex) => {
                                                                            let fileType;
                                                                            if (filename.doc_name.endsWith('.pdf')) {
                                                                                fileType = 'pdf';
                                                                            } else if (filename.doc_name.endsWith('.doc') || filename.doc_name.endsWith('.docx')) {
                                                                                fileType = 'doc';
                                                                            } else if (filename.doc_name.endsWith('.xls') || filename.doc_name.endsWith('.xlsx')) {
                                                                                fileType = 'xls';
                                                                            }

                                                                            return (
                                                                                <Box key={docIndex} sx={{ display: 'flex', flex: '0 0 calc(33.33% - 80px)', marginRight: '5px', marginBottom: '5px' }}>

                                                                                    <Box>
                                                                                        {filename.doc_name.endsWith('.pdf') && <DescriptionIcon sx={{ color: 'red', margin: '0 5px 0 0' }} />}
                                                                                        {filename.doc_name.endsWith('.xls') && <DescriptionIcon sx={{ color: 'green', margin: '0 5px 0 0' }} />}
                                                                                        {filename.doc_name.endsWith('.xlsx') && <DescriptionIcon sx={{ color: 'green', margin: '0 5px 0 0' }} />}
                                                                                        {filename.doc_name.endsWith('.doc') && <DescriptionIcon sx={{ color: 'blue', margin: '0 5px 0 0' }} />}
                                                                                        {filename.doc_name.endsWith('.docx') && <DescriptionIcon sx={{ color: 'blue', margin: '0 5px 0 0' }} />}
                                                                                        {filename.doc_name.endsWith('.ppt') && <DescriptionIcon sx={{ color: '#FFA500', margin: '0 5px 0 0' }} />}
                                                                                        {filename.doc_name.endsWith('.pptx') && <DescriptionIcon sx={{ color: '#FFA500', margin: '0 5px 0 0' }} />}
                                                                                    </Box>
                                                                                    <Box sx={{ maxWidth: '200px', }}>
                                                                                        <Typography sx={{ margin: '5px 5px 5px 5px', color: '#1976d2', textDecoration: "underline", cursor: 'pointer', fontSize: '14px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', }} onClick={() => {

                                                                                            RedListDocPreview(index, docIndex, filename.doc_id, filename.doc_name, filename.doc_path, dummyRedListData.redlist_id, dummyRedListData.activity_type);
                                                                                        }}>{filename.doc_name}  </Typography>
                                                                                    </Box>
                                                                                    {loadingStates[docIndex] && loadingStates[docIndex][filename.doc_id] && <CircularProgress size={18} sx={{ marginTop: '6px' }} />}


                                                                                </Box>
                                                                            );
                                                                        })}
                                                                    </Box>

                                                                </Box>
                                                            </Collapse>
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            ))}
                                        </TableBody>
                                    )}
                                </Table>
                                {/* {isLoading == false && totalrec === 0 && <Typography sx={{ textAlign: 'center', justifyContent: 'center' }}>No activities to display</Typography>} */}
                                <InfiniteScroll
                                    dataLength={totalrec}
                                    next={fetchMoreActivity}
                                    hasMore={hasMore}
                                    loader={
                                        <CircularProgress disableShrink style={{ margin: "20px" }} />
                                    }
                                    endMessage={
                                        <div style={{ textAlign: "center", marginTop: "10px" }}>
                                            {RedListData.length == 0 ? (
                                                <div
                                                    style={{
                                                        color: "grey",
                                                        fontSize: "13px",
                                                        marginTop: "10px", marginBottom: '10px'
                                                    }}
                                                >
                                                    No activity to display
                                                </div>
                                            ) : (
                                                <>
                                                    <div style={{
                                                        color: "grey",
                                                        fontSize: "13px",
                                                        marginTop: "10px", marginBottom: '10px'
                                                    }}>No more activities</div>

                                                </>
                                            )}
                                        </div>
                                    }
                                ></InfiniteScroll>
                            </TableContainer>
                        </Box>

                    </Box>

                </Box>
                {/* <Toolbar /> */}

            </Box>




            <Dialog fullWidth maxWidth="sm" open={viewRedList} onClose={closeViewRedListModal}>

                {viewisLoading ? (

                    <DialogContent >
                        <LoadingSkeleton />
                    </DialogContent>
                ) : (
                    userData.map((item, index) => (
                        <div key={index}>
                            <DialogTitle sx={{ padding: '0px 5px ' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '15px 15px 0px 15px' }}>
                                    <Box sx={{ display: 'flex', margin: '10px ' }}>
                                        <Box>
                                            <AddCardOutlinedIcon sx={{}} />
                                        </Box>
                                        <Typography sx={{ fontWeight: 'bold', marginLeft: '10px' }}>View Day To Day Activity</Typography>
                                    </Box>
                                    <Box>

                                        <IconButton onClick={closeViewRedListModal}> <CloseIcon /></IconButton>

                                    </Box>
                                </Box>
                            </DialogTitle>

                            <DialogContent sx={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto', }}>

                                <Box >
                                    <Box sx={{ display: 'flex', marginBottom: "20px", marginTop: '20px' }}>
                                        <MeetingRoomIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Department:</Typography></Box>
                                        <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.dept_name}</Typography></Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <WorkIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Project Name:</Typography></Box>
                                        <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.project_name}</Typography></Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <TaskIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Agenda:</Typography></Box>
                                        <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.agenda}</Typography></Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <ShortTextIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Description: </Typography></Box>
                                        <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.description}</Typography></Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <PersonAddIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Assign To:</Typography></Box>

                                        <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}> {item.assignto_name}</Typography>

                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <NotificationsIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>{"Notification To:"}</Typography></Box>
                                        <List sx={{ marginLeft: '10px', padding: '0px' }}>
                                            {item.notificationto.map((item, index) => (
                                                <ListItem key={item.attendees_id} sx={{ padding: '0px' }}> {/* Make sure to include a unique key for each item */}
                                                    <ListItemAvatar sx={{ minWidth: '30px', paddingTop: '5px' }}>
                                                        <img src={item.photo} style={{ width: '20px', height: '20px', borderRadius: '50px' }} />
                                                    </ListItemAvatar>
                                                    <Typography sx={{ fontSize: '14px' }}>
                                                        {item.name}
                                                    </Typography>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Box>

                                    <Box sx={{ marginBottom: "20px", display: 'flex' }}>
                                        <CalendarTodayIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>Activity Start:</Typography></Box>
                                        <Box>
                                            <Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.start_datetime}</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ marginBottom: "20px", display: 'flex' }}>
                                        <CalendarTodayIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Activity End:</Typography></Box>
                                        <Box>
                                            <Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.end_datetime}</Typography>
                                        </Box>
                                    </Box>


                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <HourglassTopIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box>
                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Status: </Typography>

                                        </Box>
                                        <Box sx={{ display: 'flex', marginLeft: '10px' }}>
                                            {item.status == 'new' &&
                                                <Chip label='New' size="small" color="primary" sx={{ width: '90px' }} />
                                            }
                                            {item.status == 'inprogress' &&
                                                <Chip label='In Progress' size="small" color="warning" sx={{ width: '90px' }} />
                                            }
                                            {item.status == 'complete' &&
                                                <Chip label='Complete' size="small" color="success" sx={{ width: '90px' }} />
                                            }
                                            {item.status == 'hold' &&
                                                <Chip label='Hold' size="small" color="error" sx={{ width: '90px' }} />
                                            }
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <ShowChartIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box>
                                            <Box>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Milestones: </Typography>

                                            </Box>
                                            <Box sx={{ display: 'flex', fontSize: '14px', marginTop: '10px' }}>
                                                <Box sx={{ display: 'flex' }}>
                                                    {item.milestone && item.milestone.length > 0 ? (

                                                        <TableContainer >

                                                            <Table size="small" aria-label="a dense table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell sx={{ backgroundColor: 'rgba(0, 0, 0, 0.00)', fontWeight: 'bold' }} > Description</TableCell>
                                                                        <TableCell sx={{ backgroundColor: 'rgba(0, 0, 0, 0.00)', fontWeight: 'bold' }}>Due Date</TableCell>
                                                                        <TableCell sx={{ backgroundColor: 'rgba(0, 0, 0, 0.00)', fontWeight: 'bold' }} >Assign To</TableCell>
                                                                        {/* <th style={{ border: '1px solid #dddddd', padding: '8px' }}>photo</th> */}
                                                                        <TableCell sx={{ backgroundColor: 'rgba(0, 0, 0, 0.00)', fontWeight: 'bold' }}>Status</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {item.milestone.map((milestone, index) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell >{milestone.milestone_description}</TableCell>
                                                                            <TableCell >{milestone.due_date}</TableCell>

                                                                            <TableCell >
                                                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>

                                                                                    <Avatar src={milestone.assign_to_photo} alt={milestone.assign_to_name} sx={{ height: 25, width: 25, right: 5 }} />
                                                                                    <Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{milestone.assign_to_name}</Typography>
                                                                                </Box>
                                                                            </TableCell>
                                                                            {/* <td style={{ border: '1px solid #dddddd', padding: '8px' }}>  </td> */}
                                                                            <TableCell >{milestone.status}</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>

                                                    ) : (

                                                        <Typography sx={{ textAlign: 'center' }} variant="body1">N/A</Typography>

                                                    )}
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <FilePresentIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box>
                                            <Box>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>Document: </Typography>

                                            </Box>
                                            <List>
                                                {item.document.map((file, docindex) => (
                                                    <Box key={docindex} sx={{
                                                        backgroundColor: '', '&:hover': {
                                                            textDecoration: 'underline',// Set the hover color
                                                            color: '#2196F3'
                                                        }, cursor: 'pointer', margin: '0px 0px 10px 0px ', overflow: 'auto'
                                                    }}>

                                                        <Box display='flex' justifyContent='space-between' alignItems='center' padding='10px'>
                                                            <Box display='flex' alignItems='center'>
                                                                <Box>
                                                                    <Box>
                                                                        {file.doc_name.endsWith('.pdf') && <DescriptionIcon sx={{ color: 'red', margin: '0 10px 0 0' }} />}
                                                                        {file.doc_name.endsWith('.xls') && <DescriptionIcon sx={{ color: 'green', margin: '0 10px 0 0' }} />}
                                                                        {file.doc_name.endsWith('.xlsx') && <DescriptionIcon sx={{ color: 'green', margin: '0 10px 0 0' }} />}
                                                                        {file.doc_name.endsWith('.doc') && <DescriptionIcon sx={{ color: 'blue', margin: '0 10px 0 0' }} />}
                                                                        {file.doc_name.endsWith('.docx') && <DescriptionIcon sx={{ color: 'blue', margin: '0 10px 0 0' }} />}
                                                                        {file.doc_name.endsWith('.ppt') && <DescriptionIcon sx={{ color: '#FFA500', margin: '0 10px 0 0' }} />}
                                                                        {file.doc_name.endsWith('.pptx') && <DescriptionIcon sx={{ color: '#FFA500', margin: '0 10px 0 0' }} />}
                                                                    </Box>
                                                                </Box>
                                                                <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px', fontWeight: 'bold' }} onClick={() => RedListDocviewPreview(docindex, file.doc_id, file.doc_name, file.doc_path, item.redlist_id)}>{file.doc_name} {LoadingviewStates[docindex] && <CircularProgress size={18} sx={{ marginRight: '12px' }} />}</Box>
                                                            </Box>

                                                            <Box sx={{ fontSize: '12px' }}>
                                                                <Typography sx={{ fontSize: '13px', marginLeft: '10px' }}>{file.doc_size}</Typography>
                                                            </Box>
                                                        </Box>

                                                    </Box>
                                                ))}
                                            </List>
                                        </Box>
                                    </Box>
                                    {/* <Box>
                            <Box>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey' }}>Document : </Typography>

                                </Box> 

                             <Box>
    {item.document.includes(',') ? (
        // If item.document contains a comma, split it and map over the array
        item.document.split(',').map((document, index) => (
            <Box key={index} sx={{ backgroundColor: '#f5f3f7', border: '1px solid #74777f', borderRadius: '10px', margin: '0px 0px ', maxHeight: '60px' }}>
                <Box display='flex' flexGrow={1} sx={{ justifyContent: 'space-between' }}>
                    <Box display='flex'>
                        <Box><PictureAsPdfIcon sx={{ color: "red", margin: '10px', height: '35px', width: '35px' }} /></Box>
                        <Box sx={{ marginTop: '10px' }}>
                            <Box sx={{ fontSize: '14px', fontWeight: 'bold', maxWidth: '120px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={document}>{document}</Box>
                            
                            <Box sx={{ fontSize: '12px' }}>
                                <Typography sx={{ fontSize: '13px' }}>{'100'}kb</Typography>
                            </Box>
                        </Box>
                    </Box>
                    
                    <Box sx={{ margin: '18px 10px 10px 10px' }}>
                    </Box>
                </Box>
            </Box>
        ))
    ) : (
        // If item.document does not contain a comma, render a single Box
        <Box sx={{ backgroundColor: '#f5f3f7', border: '1px solid #74777f', borderRadius: '10px', margin: '0px 0px ', maxHeight: '60px' }}>
            <Box display='flex' flexGrow={1} sx={{ justifyContent: 'space-between' }}>
                <Box display='flex'>
                    <Box><PictureAsPdfIcon sx={{ color: "red", margin: '10px', height: '35px', width: '35px' }} /></Box>
                    <Box sx={{ marginTop: '10px' }}>
                        <Box sx={{ fontSize: '14px', fontWeight: 'bold', maxWidth: '120px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={item.document}>{item.document}</Box>
                        
                        <Box sx={{ fontSize: '12px' }}>
                            <Typography sx={{ fontSize: '13px' }}>{'100'}kb</Typography>
                        </Box>
                    </Box>
                </Box>
                
                <Box sx={{ margin: '18px 10px 10px 10px' }}>
                </Box>
            </Box>
        </Box>
    )}
</Box> 



                            </Box> */}
                                </Box>

                            </DialogContent>
                            {/* <DialogActions>
                    <Box sx={{ display: 'flex', width: '100%' }}>
                        <Box sx={{ width: '50%', marginRight: '5px' }}>
                            <Button variant="outlined" sx={{ textTransform: 'none', width: '100%',fontWeight:'bold' }} onClick={closeViewRedListModal}>Cancel</Button>
                        </Box>
                        <Box sx={{ width: '50%', marginLeft: '5px' }}>
                            <Button variant="contained" sx={{ textTransform: 'none', width: '100%',fontWeight:'bold' }}>Update Red List</Button>
                        </Box>
                    </Box>
                </DialogActions> */}
                        </div>
                    ))
                )}
            </Dialog>
            <Dialog
                open={confirmOpen}
                onClose={closeConfirmModal}
                aria-labelledby="confirm-modal"
                aria-describedby="confirm-modal-description"
            >
                <DialogTitle id="confirm-modal">Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-modal-description">
                        Are you sure you want to delete this item? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={closeConfirmModal} color="primary">
                        Cancel
                    </Button>

                    <Button onClick={confirmDelete} backgroundColor={deleteloading && '#616161'} disabled={deleteloading}>
                        {deleteloading && <CircularProgress size={16} sx={{ marginRight: '10px' }} />} Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this item?
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>

                    <Button onClick={handleConfirmDelete} backgroundColor={loading && '#616161'} disabled={loading}>
                        {loading ? (
                            <>
                                <CircularProgress size={24} style={{ marginRight: '8px' }} />
                                Confirming...
                            </>
                        ) : (
                            "Confirm"
                        )}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={pdfDialogOpen} onClose={handleClosePdfDialog} fullScreen >
                <DialogTitle>
                    <Button variant="none" sx={{ textTransform: "none", fontWeight: "bold", marginLeft: '30px' }} onClick={handleClosePdfDialog} size="small">
                        <ArrowBackIcon /> Go back
                    </Button>
                </DialogTitle>
                <DialogContent>
                    <iframe src={pdfUrl} title="PDF Preview" width="100%" height="100%"></iframe>
                </DialogContent>
                {/* <DialogActions>
                    <Button variant="outlined" sx={{ textTransform: "none", fontWeight: "bold", marginRight: '30px' }} onClick={handleClosePdfDialog} size="small">
                        Close
                    </Button>

                </DialogActions> */}
            </Dialog>
            <Menu
                id="basic-menu"
                anchorEl={assignee}
                open={openAssignee}
                onClose={handleAssigneeMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem>
                    <TextField sx={{ width: '100%' }}
                        placeholder="Search User"
                        onChange={(e) => handleSearchMilestoneAssignee(e.target.value)}
                    />
                </MenuItem>
                {filterData.map((item, index) => (
                    <MenuItem
                        key={item.user_id}
                        value={index} // Change the value to index or a unique identifier
                        onClick={() => handleSelectAssignee(item.attendees_id, item.photo)} // Pass name directly to the handler
                    >
                        <Box sx={{ display: 'flex' }}>
                            <Box>
                                <img src={item.photo} style={{ height: '30px', width: '30px', borderRadius: '50px', marginRight: '10px' }} />
                            </Box>
                            <Box>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>{item.name}</Typography>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: 'grey' }}>Email:{" "}{item.email}{"  "}</Typography>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: 'grey' }}>{" | "}Department:{" "}{item.department}</Typography>
                                </Box>
                            </Box>
                        </Box>

                    </MenuItem>
                ))}
            </Menu>
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: 'white',
                    top: "auto",
                    bottom: 0,
                    width: `calc(100% - ${drawerWidth}px)`,
                    ml: `${drawerWidth}px`,
                }}
            >


                <Typography component="div"
                    //   sx={{ flexGrow: 1 ,textAlign:'right',color:'grey',padding:'5px 10px'}}
                    className="cmd_footer"
                >
                    Powered by <span style={{ fontWeight: 'bold' }}>ZeonAI Labs</span>
                </Typography>

            </AppBar>
        </Box>
    );
}

export default DayToDay;