import React, { useState, useEffect, useRef,Suspense } from "react";
import AppBar from "@mui/material/AppBar";
import Footer from "../../Include/footer";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Grid, List, ListItem } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Sidebar from "../../Include/Sidebar";
import BarChartIcon from "@mui/icons-material/BarChart";
import {
  Button,
  Divider,
  TextField,
  Card,
  CardContent,
  ListItemText,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { useLocation } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer } from 'recharts';
// import { getAllDepartment, deleteDepartment, addDepartment, fetchMoreDepartment } from "../AdminApiHelper";
import Skeleton from "@mui/material/Skeleton";
import { CircularProgress } from "@mui/material";
import Appbar from "../../Include/Header";
import "./DepartmentCmd.css";

import PieChartComponent from "./PieChartComponent"; // Adjust the path accordingly
import DepartmentWizard from "./DepartmentWizard"; // Adjust the path accordingly
import GroupedBarChart from "./BarCharts"; // Adjust the path accordingly
import SingleLineChart from "./SingleLineChart"; // Adjust the path accordingly
import Activities from "./Activities"; // Adjust the path accordingly
import GroupsIcon from "@mui/icons-material/Groups";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useInView } from 'react-intersection-observer';
const drawerWidth = 200;
const theme = createTheme();

const LazyActivities = React.lazy(() => import('./Activities'));
const LazyDepartmentWizard = React.lazy(() => import('./DepartmentWizard'));
const LazyPieChartComponent = React.lazy(() => import('./PieChartComponent'));
const LazyDepartmentDoc = React.lazy(() => import('./DepartmentDoc'));
const LazySingleLineChart = React.lazy(() => import('./SingleLineChart'));
const LazyBarChart = React.lazy(() => import('./BarCharts'));

function AreaLanding(props) {
  const { windows } = props;
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Sidebar />
    </div>
  );

  const container =
    windows !== undefined ? () => window().document.body : undefined;

  const [departmentbutton, setDepartmentbutton] = React.useState(false);
  const handlebutton = () => {
    setDepartmentbutton(!departmentbutton);
  };

  const deptBtnStyle = {
    borderRadius: "200px",
    backgroundColor: "var(--Secondary-blue, #003776)",
    textTransform: "none",
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const cardStyle = {
    display: "flex",
   
    height: "400px",
    padding: "16px",
    flexDirection: "column",
    alignItems: "flex-start",

    borderRadius: "8px",
    border: "1px solid rgba(0, 0, 0, 0.50)", // Adjust this line if you need to use custom variables
  };

  const LoadingSkeleton = () => {
    // You can customize the skeleton based on your design
    return (
      <TableRow>
        <TableCell>
          <Skeleton width={150} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton width={150} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton width={150} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton width={150} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton width={150} height={20} />
        </TableCell>
      </TableRow>
    );
  };
  const LazyLoadedComponent = ({ component: Component , ...rest}) => {
    const { ref, inView } = useInView({
      triggerOnce: true,
    });

    return (
      <div ref={ref}>
        {inView ? (
          <Suspense fallback={<CircularProgress />}>
            <Component {...rest}/>
          </Suspense>
        ) : (
          <div style={{ height: "400px" }}> {/* Placeholder for loading height */}
          </div>
        )}
      </div>
    );
  };
  const variable = 'area'
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "white",
        }}
      >
        <Appbar />
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: "0px",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: "0px",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflowY: "hidden",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          background: "#FAF9FD",
          minHeight: "100vh",
        }}
      >
        <Box
          component={Paper}
          sx={{ marginBottom: "20px" }}
          className="department_card"
        >
          <Grid sx={{ marginTop: "20px" }} container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Suspense fallback={<CircularProgress />}>
              <LazyLoadedComponent component={LazyActivities} variable={variable} />
              
              </Suspense>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Suspense fallback={<CircularProgress />}>
              <LazyLoadedComponent component={LazyDepartmentWizard} variable={variable}/>
              </Suspense>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Box style={cardStyle}>
                <Box display="flex" alignItems="center">
                  <IconButton
                    size="small"
                    sx={{
                      borderRadius: "100px",
                      background: "var(--blueGrey-50, #ECEFF1)",
                      marginBottom: '20px',
                    }}
                  >
                    <GroupsIcon color="primary" />
                  </IconButton>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
                      // fontFamily: "Roboto",
                      fontSize: "22px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "133.4%",
                      marginLeft: "10px",
                      marginBottom: '20px',
                    }}
                  >
                    Users per Area
                  </Typography>
                </Box>
                <ResponsiveContainer>
                  <Suspense fallback={<CircularProgress />}>
             
                    <LazyLoadedComponent component={LazyPieChartComponent} variable={variable}/>
                  </Suspense>
                </ResponsiveContainer>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Box style={cardStyle}>
                <Box display="flex" alignItems="center">
                  <IconButton
                    size="small"
                    sx={{
                      borderRadius: "100px",
                      background: "var(--blueGrey-50, #ECEFF1)",
                      marginBottom: '20px',
                    }}
                  >
                    <FileCopyIcon color="primary" />
                  </IconButton>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
                      // fontFamily: "Roboto",
                      fontSize: "22px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "133.4%",
                      marginLeft: "10px",
                      marginBottom: '20px',
                    }}
                  >
                    Documents per Area
                  </Typography>
                </Box>
                <ResponsiveContainer>
                  <Suspense fallback={<CircularProgress />}>
                 
                    <LazyLoadedComponent component={LazyDepartmentDoc} variable={variable}/>
                  </Suspense>
                </ResponsiveContainer>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box style={cardStyle}>
                <Box display="flex" alignItems="center">
                  <IconButton
                    size="small"
                    sx={{
                      borderRadius: "100px",
                      background: "var(--blueGrey-50, #ECEFF1)",
                    }}
                  >
                    <SsidChartIcon color="primary" />
                  </IconButton>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
                      // fontFamily: "Roboto",
                      fontSize: "22px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "133.4%",
                      marginLeft: "10px",
                    }}
                  >
                    Production Data
                  </Typography>
                </Box>
                <ResponsiveContainer>
                  <Suspense fallback={<CircularProgress />}>
                
                    <LazyLoadedComponent component={LazySingleLineChart} />
                  </Suspense>
                </ResponsiveContainer>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box style={cardStyle}>
                <Box display="flex" alignItems="center">
                  <IconButton
                    size="small"
                    sx={{
                      borderRadius: "100px",
                      background: "var(--blueGrey-50, #ECEFF1)",
                    }}
                  >
                    <BarChartIcon color="primary" />
                  </IconButton>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
                      // fontFamily: "Roboto",
                      fontSize: "22px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "133.4%",
                      marginLeft: "10px",
                    }}
                  >
                    Production Goal
                  </Typography>
                </Box>
                <ResponsiveContainer>
                  <Suspense fallback={<CircularProgress />}>
                  <LazyLoadedComponent component={LazyBarChart} />
                   
                  </Suspense>
                </ResponsiveContainer>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Footer />
      </Box>
    </Box>
  );
}

export default AreaLanding;
