import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import Skeleton from "@mui/material/Skeleton";
import {
    CircularProgress, ListItemIcon, Box, Typography,ListItemAvatar,ListItem,List,Grid,
    AppBar, CssBaseline,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import DescriptionIcon from '@mui/icons-material/Description';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TaskIcon from '@mui/icons-material/Task';
import ShortTextIcon from '@mui/icons-material/ShortText';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ListIcon from '@mui/icons-material/List';
import logo from '../../../../soochna kosh-black.png'
import { validateShortLinkToken ,getShortLinkDoc} from "../../CmdApiHelper";
function ShortlinkLanding(props) {

    const [paramPresent, setParamPresent] = useState(false)
    const [paramPresentLoading, setParamPresentLoading] = useState(false)
    const [paramValue, setParamValue] = useState('')
    const [tokenData, setTokenData] = useState([])
    const [LoadingviewStates, setLoadingviewStates] = useState([]);
    const [pdfUrl, setPdfUrl] = useState("");
    const navigate = useNavigate();
    useEffect(() => {
        const queryParam = new URLSearchParams(window.location.search);
        console.log('queryParam:', queryParam);

        if (!queryParam.has('token')) {
            console.log('QueryParam not present');
            setParamPresent(true)
        }
        const token = queryParam.get('token');
        if (token) {
            console.log('Token:', token);
            setParamValue(token)
            fetchTokenForValidate(token)
        } else {
            console.log('Token not present');
            setParamPresent(true)
        }
    }, []);

    const fetchTokenForValidate = async (token) => {
        setParamPresentLoading(true)
        try {
            const data = await validateShortLinkToken(token);
            if (data.code == "200") {
                setParamPresentLoading(false)
                const result = data.data
                setTokenData(data.data)
                console.log('result', result)
                // localStorage.setItem('userdata', JSON.stringify(data.data));
                // navigate(`/cmd-dashboard/review-meetings?meeting_id=${data.metting_id}`);
                return;
            } else {
                setParamPresent(true)
                console.error("Error fetching user data:", error);
            }
        } catch (error) {
            setParamPresent(true)
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            //setIsLoading(false);
        }
    }
    const handleLoading = (index) => {
        setLoadingviewStates((prevStates) => {
            const newState = [...prevStates];
            newState[index] = true;
            return newState;
        });
    };
    const RedListDocviewPreview = async (
        index,
        docid,
        docname,
        docpath,
    ) => {
        handleLoading(index);

        try {
            

            // Use the API helper function to fetch query history data
            const data = await getShortLinkDoc(
                
                docname,
                docid,
                docpath,
                paramValue
            );

            if (data.code === "200") {
                setLoadingviewStates((prevStates) => {
                    const newState = [...prevStates];
                    newState[index] = false; // Set loading state to false after successful fetch
                    return newState;
                });

                const doclink = data.data[0].doclink;
                console.log(doclink);
                
                    console.log('doclink', doclink);
                    window.open(doclink, '_blank');
             
                setPdfUrl(doclink); // Set the PDF URL
            } else {
                console.log(data.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error, show a message or log it
            // seterrorSnackbar(true);
        }
    };

    const LandingSkeleton = () => {
        // You can customize the skeleton based on your design
        return (
            <Box >
                <Box display='flex'>
                    <Skeleton sx={{ margin: '10px' }} variant="circular" width={30} height={30} />
                    <Skeleton sx={{ margin: '10px', borderRadius: '8px' }} variant="rectangular" width={300} height={30} />
                </Box>
                <Box display='flex'>
                    <Skeleton sx={{ margin: '10px', borderRadius: '8px' }} variant="rectangular" width={350} height='76vh' />
                </Box>
            </Box>
        );
    };

    return (
        <Box>
            <CssBaseline />
            <AppBar
                sx={{
                    width: '100%',
                    // ml: { sm: `${drawerWidth}px` },
                    backgroundColor: "white",
                    // height:'10vh',
                    color: 'black'
                }}
            >
                <img alt="" src={logo} style={{ width: '130px', margin: '10px 0px 10px 10px' }} />
            </AppBar>
            <Box
                component="main"
                sx={{
                    // flexGrow: 1,
                    // width: { sm: `calc(100% - ${drawerWidth}px)` },
                    background: "#EFF1F3",
                    minHeight: "100vh",

                }}
            >
                {paramPresent  ?
                 <Box sx={{ textAlign: 'center', paddingTop: '10vh', justifyContent: 'center' }}>
                 <InfoOutlinedIcon />
                 <Typography variant="h6" > Page Expired. </Typography>
             </Box>
                    
                    :
                    <Box>
                        { paramPresentLoading ?
                            <Box
                            sx={{
                                paddingTop: '10vh',
                            }}
                        >
                            <LandingSkeleton />
                        </Box>
                        :
<Box
                        sx={{
                            paddingTop: '10vh',
                        }}
                    >
                       
                        <Box component={Paper} sx={{
                                '@media (min-width: 500px)': {
                                    // maxHeight: 'calc(100vh - 200px)', overflowY: 'auto'
                                },
                                padding:'10px'
                            }}>
                                {tokenData.map((item, index) => (
                                    <Box >
                                        <Box display='flex' sx={{ marginBottom: "20px" }}>
                                            <EventNoteOutlinedIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Event Type:</Typography>
                                            <Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.meeting_type}</Typography>
                                        </Box>
                                        <Box display='flex' sx={{ marginBottom: "20px" }}>
                                            <TaskIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Agenda:</Typography>
                                            <Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.agenda}</Typography>
                                        </Box>
                                        <Box display='flex' sx={{ marginBottom: "20px" }}>
                                            <ShortTextIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                            <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Description: </Typography></Box>
                                            <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.description}</Typography></Box>
                                        </Box>

                                        <Box display='flex' sx={{ marginBottom: "20px" }}>
                                            <PersonAddIcon sx={{ color: '#0000008A', marginRight: '20px', marginTop: '5px' }} />
                                            <Box sx={{ marginBottom: "20px" }}>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', marginTop: '5px' }}>Attendees:</Typography>
                                            </Box>
                                            <Box>
                                                <List sx={{ marginLeft: '10px', padding: '0px' }}>
                                                    {item.attendees?.map((item) => (
                                                        <ListItem key={item.attendees_id} sx={{ padding: '0px' }}> {/* Make sure to include a unique key for each item */}
                                                            <ListItemAvatar sx={{ minWidth: '30px', paddingTop: '5px' }}>
                                                                <img src={item.photo} style={{ width: '20px', height: '20px', borderRadius: '50px' }} />
                                                            </ListItemAvatar>
                                                            <Typography sx={{ fontSize: '14px' }}>
                                                                {item.name}{item.within_org == 'no' && " * "}
                                                            </Typography>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Box>
                                        </Box>
                                        <Box display='flex' sx={{ marginBottom: "20px" }}>
                                            <WhatsAppIcon sx={{ color: 'white', backgroundColor: 'green', borderRadius: '50px', marginRight: '20px', marginTop: '5px' }} />
                                            <Box sx={{ marginBottom: "20px" }}>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', marginTop: '5px' }}>Notification To:</Typography>
                                            </Box>
                                            <Box>
                                                <List sx={{ marginLeft: '10px', padding: '0px' }}>
                                                    {item.whatsapp_notification_to?.map((item) => (
                                                        <ListItem key={item.attendees_id} sx={{ padding: '0px' }}> {/* Make sure to include a unique key for each item */}
                                                            <ListItemAvatar sx={{ minWidth: '30px', paddingTop: '5px' }}>
                                                                <img src={item.photo} style={{ width: '20px', height: '20px', borderRadius: '50px' }} />
                                                            </ListItemAvatar>
                                                            <Typography sx={{ fontSize: '14px' }}>
                                                                {item.name}{item.within_org == 'no' && " * "}
                                                            </Typography>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Box>
                                        </Box>
                                        <Box display='flex' sx={{ marginBottom: "20px" }}>
                                            <MeetingRoomIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Meeting Room:</Typography>
                                            <Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.meeting_room} </Typography>
                                        </Box>
                                        <Box display='flex' sx={{ marginBottom: "20px" }}>
                                            <CalendarViewDayIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Virtual Conference Link :</Typography>
                                            <Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>
                                                {item.virtual_meeting_status === 'no' ?
                                                    'No' :
                                                    item.virtual_meeting_link
                                                }
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                            <CalendarTodayIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                            <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Meeting Date:</Typography></Box>
                                            <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}> {item.meeting_date} </Typography></Box>

                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: "20px" }}>
                                            <Box sx={{ width: '100%' }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>

                                                        <Box sx={{ display: 'flex' }}>
                                                            <AccessTimeIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                                            <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Start Time:</Typography></Box>
                                                            <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}> {item.start_time} </Typography></Box>

                                                        </Box>

                                                    </Grid>
                                                    <Grid item xs={6}>

                                                        <Box sx={{ display: 'flex' }}>
                                                            <AccessTimeIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                                            <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>End Time:</Typography></Box>
                                                            <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.end_time} </Typography></Box>

                                                        </Box>

                                                    </Grid>
                                                </Grid>
                                            </Box>



                                        </Box>
                                        <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                            <ListIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                            <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Reference to Red List Activity: </Typography></Box>
                                            <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px', }}  > {item.redlist_agenda ? <Typography onClick={(e) => openViewAddRedListModal(item.redlist_id)} sx={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '14px' }}> {item.redlist_agenda} </Typography> : 'N/A'} </Typography></Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                            <FilePresentIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                            <Box>
                                                <Box>
                                                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>Document: </Typography>

                                                </Box>
                                                <List>
                                                    {item.document.map((file, docindex) => (
                                                        <Box key={docindex} sx={{
                                                            backgroundColor: '', cursor: 'pointer', '&:hover': {
                                                                textDecoration: 'underline',// Set the hover color
                                                                color: '#2196F3'
                                                            }, margin: '0px 0px 10px 0px ', overflow: 'auto'
                                                        }}>

                                                            <Box display='flex' justifyContent='space-between' alignItems='center' padding='10px'>
                                                                <Box display='flex' alignItems='center'>
                                                                    <Box>   <Box> {file.doc_name.endsWith('.pdf') && <DescriptionIcon sx={{ color: 'red', margin: '0 10px 0 0' }} />}
                                                                        {file.doc_name.endsWith('.xls') && <DescriptionIcon sx={{ color: 'green', margin: '0 10px 0 0' }} />}
                                                                        {file.doc_name.endsWith('.xlsx') && <DescriptionIcon sx={{ color: 'green', margin: '0 10px 0 0' }} />}
                                                                        {file.doc_name.endsWith('.doc') && <DescriptionIcon sx={{ color: 'blue', margin: '0 10px 0 0' }} />}
                                                                        {file.doc_name.endsWith('.docx') && <DescriptionIcon sx={{ color: 'blue', margin: '0 10px 0 0' }} />}
                                                                        {file.doc_name.endsWith('.ppt') && <DescriptionIcon sx={{ color: '#FFA500', margin: '0 10px 0 0' }} />}
                                                                        {file.doc_name.endsWith('.pptx') && <DescriptionIcon sx={{ color: '#FFA500', margin: '0 10px 0 0' }} />}
                                                                    </Box>

                                                                    </Box>
                                                                    <Box sx={{width:'150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px', fontWeight: 'bold' }} 
                                                                    onClick={() => RedListDocviewPreview(docindex, file.doc_id, file.doc_name, file.doc_path)}
                                                                    >{file.doc_name}
                                                                     {LoadingviewStates[docindex] && <CircularProgress size={18} sx={{ marginRight: '12px' }} />}
                                                                     </Box>
                                                                </Box>

                                                                <Box sx={{ fontSize: '12px' }}>
                                                                    <Typography sx={{ fontSize: '13px', marginLeft: '10px' }}>{file.doc_size}</Typography>
                                                                </Box>
                                                            </Box>

                                                        </Box>
                                                    ))}
                                                </List>
                                            </Box>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                    </Box>
                        }
                    
                    </Box>
                }
            </Box>
        </Box>
    )
}
export default ShortlinkLanding;