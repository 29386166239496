import React, { useState, useEffect, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Footer from "../../Include/footer";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Grid } from '@mui/material';
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Sidebar from "../../Include/Sidebar";
import { Button, Divider, TextField } from "@mui/material";
import Paper from '@mui/material/Paper';
import { useLocation } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InfiniteScroll from "react-infinite-scroll-component";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import NotesIcon from '@mui/icons-material/Notes';
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import { getAllDepartment, deleteDepartment, addDepartment, fetchMoreDepartment } from "../AdminApiHelper";
import Skeleton from "@mui/material/Skeleton";
import { CircularProgress } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import FindInPageRoundedIcon from '@mui/icons-material/FindInPageRounded';
// import AddDepartment from "./addDepartment";
// import ViewDepartment from "./viewDepartment";
import cmd1 from '../../../../cmd_1.jpeg'
import cmd2 from '../../../../cmd_2.jpg'
import Appbar from "../../Include/Header";
import './DepartmentCmd.css';
import { borderRadius, fontWeight } from "@mui/system";
import GeneralInfo from "../../Component/GeneralInfo";
import DayToDay from "../../Component/DayToDay";
import Critical from "../../Component/CriticalActivity";
import Query from "../../../Query/CmdSearchQuery";
import DocumentManager from "../../../AdminConsole/DocumentManager/CmdDocuments";
const drawerWidth = 200;
const theme = createTheme();
function CmdDepartment(props) {
    const { windows } = props;
    const location = useLocation()
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <Sidebar />
        </div>
    );
    const cardStyle = {
        backgroundColor: 'white',
        borderRadius: '6px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        padding: '1rem',
        marginTop: '5px',
        color: '#606060',
        fontSize: '14px'
    };
    const container =
        windows !== undefined ? () => window().document.body : undefined;

    const [departmentbutton, setDepartmentbutton] = React.useState(false);
    const handlebutton = () => {
        setDepartmentbutton(!departmentbutton);
    };
   
    const deptBtnStyle = {
        borderRadius: '200px',
        backgroundColor: 'var(--Secondary-blue, #003776)',
        textTransform: 'none'
    }
    const [isLoading, setIsLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [usertoken, setusertoken] = useState("");
    const [index, setIndex] = useState(0);
    const [user_id, setuser_id] = useState("");
    const [userorg_id, setuserorg_id] = useState("");
    const [deptSearchResults, setDeptSearchResults] = useState([]);
    const [showStaticContent, setShowStaticContent] = useState(false);
    const [userconversation_id, setuserconversation_id] = useState("");
    const [dept_id, setDept_id] = useState([]);
    const [dept_name, setDept_name] = useState('');
    const [dept_desc, setDept_desc] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [toastmessage, settoastmessage] = useState('');
    const [hasMore, setHasMore] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [totalrec, setTotalrec] = useState('');
    
   
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const LoadingSkeleton = () => {
        // You can customize the skeleton based on your design
        return (
            <TableRow>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>

            </TableRow>
        );
    };
    console.log('location.pathname', location)
    const parts = location.pathname.split("/");

    // Extract the last part which contains the ID
    const id = parts[parts.length - 1];
    const departmentName = location.state
    console.log('id of dept', id);
    const dept_btn_name = ['General Information', 'Search', 'Documents', 'Activities', 'Comments']
    const [btn_status, setBtn_status] = useState(false)
    const [btn_status_search, setBtn_status_search] = useState(false)
    const [btn_status_activity_day, setBtn_status_activity_day] = useState(false)
    const [btn_status_activity_critical, setBtn_status_activity_critical] = useState(true)
    const [btn_status_document, setBtn_status_document] = useState(false)
    const [btn_status_comment, setBtn_status_comment] = useState(false)
    const btn_click = () => {
        setBtn_status(true);
        setBtn_status_search(false);
        setBtn_status_activity_day(false);
        setBtn_status_document(false);
        setBtn_status_comment(false);
        setBtn_status_activity_critical(false)
    }
    const btn_click_search = () => {
        setBtn_status_search(true);
        setBtn_status(false);
        setBtn_status_activity_day(false);
        setBtn_status_document(false);
        setBtn_status_comment(false);
        setBtn_status_activity_critical(false)
    }
    const btn_click_activity_day = () => {
        setBtn_status_search(false);
        setBtn_status(false);
        setBtn_status_activity_day(true);
        setBtn_status_document(false);
        setBtn_status_comment(false);
        setBtn_status_activity_critical(false)
    }
    const btn_click_activity_critical = () => {
        setBtn_status_search(false);
        setBtn_status(false);
        setBtn_status_activity_day(false);
        setBtn_status_activity_critical(true)
        setBtn_status_document(false);
        setBtn_status_comment(false);
    }
    const btn_click_documment = () => {
        setBtn_status_search(false);
        setBtn_status(false);
        setBtn_status_activity_day(false);
        setBtn_status_document(true);
        setBtn_status_comment(false);
        setBtn_status_activity_critical(false)
    }
    const btn_click_comment = () => {
        setBtn_status_search(false);
        setBtn_status(false);
        setBtn_status_activity_day(false);
        setBtn_status_document(false);
        setBtn_status_comment(true);
        setBtn_status_activity_critical(false)
    }
    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    backgroundColor: "white",
                }}
            >
                <Appbar />
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                            borderRight: '0px',
                        },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: "none", sm: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                            borderRight: '0px',
                        },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,

                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    background: "#EFF1F3",
                    minHeight: "100vh",
                }}
            >


                <div >
                    <Box className='department_card'>
                        <Box>
                            <Typography sx={{ textAlign: 'left', color: '#005AC1', fontWeight: 'bold' }}>{departmentName} </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', margin: '10px 0px 0px 0px' }}>
                            {/* {dept_btn_name.map((item)=>{ */}



                            <Button
                                onClick={btn_click_activity_critical}
                                sx={{
                                    textDecoration: 'none',
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    marginRight: '10px',
                                    backgroundColor: btn_status_activity_critical ? '#D8E2FF' : '#eff1f3',
                                    borderRadius: btn_status_activity_critical ? "10px 10px 0px 0px" : "",
                                    borderBottom: btn_status_activity_critical ? "2px solid #005AC1" : "",
                                    color: btn_status_activity_critical ? "#005AC1" : "grey",
                                }}
                            >Critical Activities </Button>
                            <Button
                                onClick={btn_click_activity_day}
                                sx={{
                                    textDecoration: 'none',
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    marginRight: '10px',
                                    backgroundColor: btn_status_activity_day ? '#D8E2FF' : '#eff1f3',
                                    borderRadius: btn_status_activity_day ? "10px 10px 0px 0px" : "",
                                    borderBottom: btn_status_activity_day ? "2px solid #005AC1" : "",
                                    color: btn_status_activity_day ? "#005AC1" : "grey",
                                }}
                            >Day To Day Activities </Button>
                            <Button onClick={btn_click}
                                sx={{
                                    textDecoration: 'none',
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    marginRight: '10px',
                                    backgroundColor: btn_status ? '#D8E2FF' : '#eff1f3',
                                    borderRadius: btn_status ? "10px 10px 0px 0px" : "",
                                    borderBottom: btn_status ? "2px solid #005AC1" : "",
                                    color: btn_status ? "#005AC1" : "grey",
                                }}>
                                General Information
                            </Button>
                            <Button
                                onClick={btn_click_comment}
                                sx={{
                                    textDecoration: 'none',
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    marginRight: '10px',
                                    backgroundColor: btn_status_comment ? '#D8E2FF' : '#eff1f3',
                                    borderRadius: btn_status_comment ? "10px 10px 0px 0px" : "",
                                    borderBottom: btn_status_comment ? "2px solid #005AC1" : "",
                                    color: btn_status_comment ? "#005AC1" : "grey",
                                }}
                            >Search and Comments </Button>
                           
                            <Button onClick={btn_click_documment}
                                sx={{
                                    textDecoration: 'none',
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    marginRight: '10px',
                                    backgroundColor: btn_status_document ? '#D8E2FF' : '#eff1f3',
                                    borderRadius: btn_status_document ? "10px 10px 0px 0px" : "",
                                    borderBottom: btn_status_document ? "2px solid #005AC1" : "",
                                    color: btn_status_document ? "#005AC1" : "grey",
                                }}
                            >Documents </Button>
                            {/* })} */}

                        </Box>
                        <Divider orientation="horizontal" />
                        <Box sx={{ marginTop: '20px' }}>
                            {btn_status && <GeneralInfo  id={id}/>}
                            {btn_status_activity_critical && <Critical id={id}/>}
                            {btn_status_activity_day && <DayToDay  id={id} />}
                            {btn_status_comment && <Query  id={id}/>}
                            {btn_status_document && <DocumentManager  id={id} />}
                        </Box>

                    </Box>
                    {/* {btn_status_document == false && <Toolbar />} */}

                </div>
            </Box>
            {/* <AppBar
                position="fixed"
                sx={{
                    backgroundColor: 'white',
                    top: "auto",
                    bottom: 0,
                    width: `calc(100% - ${drawerWidth}px)`,
                    ml: `${drawerWidth}px`,
                }}
            >


                <Typography component="div" className="cmd_footer">
                    Powered by <span style={{ fontWeight: 'bold' }}>ZeonAI Labs</span>
                </Typography>

            </AppBar> */}
            <Footer/>
        </Box>
    );
}

export default CmdDepartment;
