import { Box } from '@mui/system';
import * as React from 'react'
import { Link } from 'react-router-dom';
import image1 from '../../image 1.png';
const Error = () =>{


    return(
        <>
        <Box>
        <Box sx={{textAlign:'left'}}> <img src={image1}/> </Box>
        <Box>
        <Box><h1>Page not found</h1></Box>
        <Box sx={{textDecoration:'none'}}>Click here to <Link to='/query' >Home Page</Link></Box>
        </Box>
        </Box>
        
        </>
    )
}
export default Error;