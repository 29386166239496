import React, { useState,useEffect } from "react";
import { Grid, Box, Typography, Button, Dialog, DialogActions, DialogContent, Autocomplete, TextField } from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import FindInPageRoundedIcon from '@mui/icons-material/FindInPageRounded';
import MapsUgcRoundedIcon from '@mui/icons-material/MapsUgcRounded';
import { getGeneralInfo ,getAllAttendees,commentToNodalOfficer } from "../CmdApiHelper";
import { CircularProgress } from '@mui/material';
const GeneralInfo = (props) => {


    const { windows ,id} = props;
    console.log('id of general info',id)
    const [isLoading, setIsLoading] = useState(false);
    const [spinnerLoading, SetspinnerLoading] = useState({});
    const [buttonLoading, setButtonLoading] = useState(false);
    const [usertoken, setusertoken] = useState("");
    const [index, setIndex] = useState(0);
    const [user_id, setuser_id] = useState("");
    const [userorg_id, setuserorg_id] = useState("");
    const [viewisLoading, setviewisLoading] = useState(false);
    const [userSearchResults, setUserSearchResults] = useState([]);
    const [showStaticContent, setShowStaticContent] = useState(false);
    const [userconversation_id, setuserconversation_id] = useState("");
    const [dept_id, setDept_id] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [totalDoc, setTotalDoc] = useState('');
    const [totalUser, setTotalUser] = useState('');
    const [totalSearchQuery, setTotalSearchQuery] = useState('');
    const [content, setContent] = useState(null);
    useEffect(() => {


        fetchData();

        return () => { };
    }, [id]);
    const fetchData = async () => {
        setIsLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
            } = userDataObj;

            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            setIndex(0);
            const data = await getGeneralInfo(deepuser_id, deeporg_id, id,userToken);
            if (data.code == "200") {
                setuserconversation_id(data.conversation_id);
                const newResults = data.data;
                console.log('data',data.data)
                setTotalDoc(data.document_count)
                setTotalUser(data.user_count)
                setTotalSearchQuery(data.searchquery_count)
                setContent(data.content)
                // if (data.data.length >= 0 && data.data.length < 15) {
                //     setHasMore(false)
                // }
                // else {
                //     setHasMore(true);

                // }
                setIsLoading(false)
                setShowStaticContent(false);
            } else {
                setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            setIsLoading(false);
        }
    };
    const fetchUserData = async () => {
        setIsLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
                super_admin: super_admin,
            } = userDataObj;

            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            // setSuperAdmin(super_admin)
            setIndex(0);
            const requestData = await getAllAttendees(deepuser_id, deeporg_id, 'nodal', id, userToken);
            if (requestData.code == "200") {
                setuserconversation_id(requestData.conversation_id);
                // const newResults = requestData.data;
                setUserSearchResults(requestData.data);
                // const userDetailToUpdate = requestData.data.find(item => item.user_id === tempUserId);
                // setFilterData(requestData.data)
                // if (userDetailToUpdate) {
                // setUserDetails(userDetailToUpdate);
                // } else {
                // Handle case where no user detail is found with matching user_id
                // }

                // setTotal_rec(requestData.total_rec)
                // if (requestData.data.length >= 0 && requestData.data.length <15) {
                //     setHasMore(false)
                // }
                // else {
                //     setHasMore(true);

                // }

                setIsLoading(false)
                setShowStaticContent(false);
            } else {
                setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            setIsLoading(false);
        }
    };


    const fetchCommentToNodalOfficer = async () => {
        setButtonLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
                super_admin: super_admin,
            } = userDataObj;

            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            // setSuperAdmin(super_admin)
            setIndex(0);
            const requestData = await commentToNodalOfficer(deepuser_id, deeporg_id, id,nodalOfficers,doc_desc, userToken);
            if (requestData.code == "200") {
                // setuserconversation_id(requestData.conversation_id);
                // const newResults = requestData.data;
                // setUserSearchResults(requestData.data);
                setDoc_desc('')
                setIsCommentModal(false);
                // const userDetailToUpdate = requestData.data.find(item => item.user_id === tempUserId);
                // setFilterData(requestData.data)
                // if (userDetailToUpdate) {
                // setUserDetails(userDetailToUpdate);
                // } else {
                // Handle case where no user detail is found with matching user_id
                // }

                // setTotal_rec(requestData.total_rec)
                // if (requestData.data.length >= 0 && requestData.data.length <15) {
                //     setHasMore(false)
                // }
                // else {
                //     setHasMore(true);

                // }

                setButtonLoading(false)
                setShowStaticContent(false);
            } else {
                setShowStaticContent(true);
                setButtonLoading(false)
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            setButtonLoading(false)
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            setIsLoading(false);
        }
    };

    const cardStyle = {
        backgroundColor: 'white',
        borderRadius: '6px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        padding: '1rem',
        marginTop: '5px',
        color: '#606060',
        fontSize: '14px'
    };

    const [isCommentModal, setIsCommentModal] = useState(false)

    const openCommentModal = () => {
        setIsCommentModal(true);
        fetchUserData()

    }
    const closeCommentModal = () => {
        setIsCommentModal(false);

    }
    const handleClickComment = () => {
        fetchCommentToNodalOfficer()
        

    }
    const getOptionLabel = (option) => option.name;
    const [tempAtendees, setTempAttendees] = useState([]);
    const filterOptions = (options, { inputValue }) => {
        // Provide a default empty string value if inputValue is null or undefined
        const lowercaseInputValue = inputValue ? inputValue.toLowerCase() : '';

        return options.filter((option) => {
            // Ensure option.name, option.department, and option.designation are not null or undefined
            const name = option.name ? option.name.toLowerCase() : '';
            const department = option.dept_name ? option.dept_name.toLowerCase() : '';
            const designation = option.designation ? option.designation.toLowerCase() : '';
            const phone = option.mobile_no ? option.mobile_no : '';
            // Filter based on name, department, and designation
            const matchName = name.includes(lowercaseInputValue);
            const matchDepartment = department.includes(lowercaseInputValue);
            const matchDesignation = designation.includes(lowercaseInputValue);
            const matchPhone = phone.includes(lowercaseInputValue);
            return matchName || matchDepartment || matchDesignation || matchPhone;
        });
    };


    const renderOption = (props, option) => (
        <li {...props} style={{ display: "block" }}>
            <Box>
                <Box display="flex" style={{ fontSize: "0.8em" }}>
                    <Box>
                        <img src={option.photo} style={{ height: '35px', width: '35px', borderRadius: '50px', marginRight: '5px' }} />
                    </Box>
                    <Box fontWeight="bold" sx={{ marginTop: "5px" }}>
                        {option.name}
                        <Box display="flex" style={{ fontSize: "0.8em", color: "gray" }}>
                            <Box>

                                {option.email}{" "}
                            </Box>
                            <Box sx={{ margin: "0px 5px" }}> | </Box>
                            <Box>

                                {option.dept_name}
                            </Box>
                            <Box sx={{ margin: "0px 5px" }}> | </Box>
                            <Box>

                                {option.designation}
                            </Box>
                            <Box sx={{ margin: "0px 5px" }}> | </Box>
                            <Box>

                                {option.mobile_no}
                            </Box>
                        </Box>
                    </Box>

                </Box>

            </Box>
        </li>
    );
    const dummyName = ['Amrit', 'Biswa', 'Sukanta', 'Himadri', 'Tapan', 'Pradipta', 'Putul']
    const [nodalOfficers, setNodalOfficer] = useState([]);
    const handleSetNodalOfficer = (value) =>{
        setNodalOfficer(value.map(nodalOfficer => nodalOfficer.attendees_id));
    }
    const [doc_desc, setDoc_desc] = useState('')
    return (
        <>
            <Box>
                <Box sx={{ margin: '15px 0px' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} sx={{ gridAutoFlow: "dense", }} >

                        <Grid item xs={12} md={4}
                        // className='mobile_view_1'
                        >

                            <div className='' style={cardStyle}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box
                                    //  className="card_icon_size"
                                    >
                                        <PeopleAltIcon 
                                        sx={{color:'#005AC1',backgroundColor:'#D8E2FF',padding:'10px',height:'50px',width:'50px',borderRadius:'50px'}}
                                        // className="card_icon"
                                        />
                                    </Box>
                                    <Box><Typography variant="h6" className="card_text"sx={{margin:'10px 0px 0px 10px'}}>Users</Typography> </Box>
                                </Box>
                                <Box><Typography variant="h4" className="card_bottom_number" sx={{margin:'5px 0px 0px 10px'}} >{totalUser ? totalUser :'0'}</Typography></Box>
                            </div>

                        </Grid>
                        <Grid item xs={12} md={4}>

                            <div className='' style={cardStyle}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box 

                                    // className="card_icon_size"
                                    >
                                        <TextSnippetIcon 
                                         sx={{color:'#005AC1',backgroundColor:'#D8E2FF',padding:'10px',height:'50px',width:'50px',borderRadius:'50px'}}
                                        // className="card_icon"
                                        />
                                    </Box>
                                    <Box><Typography variant="h6" className="card_text" sx={{margin:'10px 0px 0px 10px'}}>Documents</Typography></Box>
                                </Box>
                                <Box><Typography variant="h4" className="card_bottom_number" sx={{margin:'5px 0px 0px 10px'}} >{totalDoc ? totalDoc : '0'}</Typography></Box>
                            </div>

                        </Grid>
                        <Grid item xs={12} md={4}>

                            <div className='' style={cardStyle}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box 
                                    // className="card_icon_size"
                                    >
                                        <FindInPageRoundedIcon 
                                         sx={{color:'#005AC1',backgroundColor:'#D8E2FF',padding:'10px',height:'50px',width:'50px',borderRadius:'50px'}}
                                        // className="card_icon"
                                           
                                        />
                                    </Box>
                                    <Box><Typography variant="h6" className="card_text" sx={{margin:'10px 0px 0px 10px'}}>Searched Query</Typography></Box>
                                </Box>
                                <Box><Typography variant="h4" className="card_bottom_number" sx={{margin:'5px 0px 0px 10px'}} >{totalSearchQuery ? totalSearchQuery : '0'}</Typography></Box>
                            </div>

                        </Grid>
                    </Grid>
                </Box>
                <Box >
                    <div className='general_info_bottom_card' style={cardStyle}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant='outlined' style={{ borderColor: 'grey', color: 'grey', borderRadius: '8px', fontSize: '10px', textTransform: 'none', padding: '8px', fontWeight: 'bold' }} onClick={openCommentModal} startIcon={<MapsUgcRoundedIcon />} >Comment to Nodal Officers</Button></Box>

                        <p>
                        {content ?<div dangerouslySetInnerHTML={{ __html: content.replace(/<br>/g, '<br/>') }} />  : "General info is not added by the Department Nodal officer" }
                            </p>
                        
                        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                            {/* <img src={cmd1} style={{height:'100px',width:'300px'}}/>
                                        <img src={cmd2} style={{height:'100px',width:'300px'}}/> */}
                        </Box>
                    </div>
                </Box>
                <Dialog open={isCommentModal} onClose={closeCommentModal}
                >

                    <DialogContent >
                        <Box>
                            <Box sx={{ marginBottom: '20px ' }}>Select Nodal Officers </Box>
                            <Box>
                                {/* {shareTo && ( */}
                                <Autocomplete
                                    multiple
                                    id="tags-outlined"
                                    options={userSearchResults}
                                    getOptionLabel={getOptionLabel}
                                    renderOption={renderOption}
                                    filterOptions={filterOptions}
                                    onChange={(event, newValue) => handleSetNodalOfficer(newValue)}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Nodal Officers"
                                            placeholder="Add.."
                                            sx={{minWidth:'400px'}}
                                        />
                                    )}
                                />
                                {/* )} */}
                            </Box>
                            
                            <Box sx={{ margin: '20px 0px' }}>Description </Box>
                            <Box>
                                <TextField
                                    id="outlined-multiline-static"
                                    sx={{width:'100%'}}
                                    multiline
                                    rows={3}
                                    value={doc_desc}
                                    placeholder="Enter a description"
                                    onChange={(e) => setDoc_desc(e.target.value)}
                                />
                            </Box>
                        
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ padding: '10px 23px 15px 23px' }}>
                        <Box sx={{ display: 'flex', width: '100%' }}>
                            <Box sx={{ width: '50%', marginRight: '5px' }}>
                            <Button variant='outlined' sx={{fontWeight:'bold',textTransform:'none',width: '100%'}} onClick={closeCommentModal}>Cancel</Button>
                            </Box>
                            <Box sx={{ width: '50%', marginLeft: '5px' }}>
                            <Button color='primary' variant='contained' onClick={handleClickComment} 
    sx={{ fontWeight:'bold',textTransform:'none',width: '100%'}}
    disabled={buttonLoading}>
        {buttonLoading && <CircularProgress size={16} sx={{marginRight:'10px'}}/>}
        Submit
    </Button>
                            </Box>
                        </Box>
                       
    
</DialogActions>
                </Dialog>
            </Box>
        </>
    )
}
export default GeneralInfo;