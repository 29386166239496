import React, { useState, useEffect, useRef } from "react";
import { PieChart, Pie, Cell, Legend } from "recharts";
import Box from "@mui/material/Box";
import { useNavigate } from 'react-router-dom'
import { Grid, List, ListItem, Typography, TextField,ListItemIcon,Skeleton,ListItemText } from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import Drawer from "@mui/material/Drawer";
import GroupsIcon from "@mui/icons-material/Groups";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { getAllWizardDepartment } from "../../../AdminConsole/AdminApiHelper";
import { width } from "@mui/system";
const DepartmentWizard = ({variable}) => {
    const departmentType = variable
    const [usertoken, setusertoken] = useState("");
    const [index, setIndex] = useState(0);
    const [user_id, setuser_id] = useState("");
    const [userorg_id, setuserorg_id] = useState("");
    const [deptSearchResults, setDeptSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);

    const DepartmentSkeleton = () => {
        return (
          <>
            {[...Array(5)].map((_, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: "space-between",
                  marginBottom: "8px", // Adjust spacing between rows as needed
                }}
              >
                <ListItem style={{ flexBasis: "45%" }}> {/* Adjust flex basis to control width of each skeleton */}
                  <ListItemIcon>
                    <Skeleton variant="circular" width={32} height={32} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Skeleton width={170} height={20} /> 
                    }
                  />
                </ListItem>
                <ListItem style={{ flexBasis: "45%" }}> 
                  <ListItemIcon>
                    <Skeleton variant="circular" width={32} height={32} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Skeleton width={170} height={20} /> 
                    }
                  />
                </ListItem>
              </div>
            ))}
          </>
        );
      };

  const [filter, setFilter] = useState("");



  const cardStyle = {
    display: "flex",
   
    height: "400px",
    padding: "16px",
    flexDirection: "column",
    alignItems: "flex-start",

    borderRadius: "8px",
    border: "1px solid rgba(0, 0, 0, 0.50)", // Adjust this line if you need to use custom variables
  };
  useEffect(() => {
    fetchData();

    return () => {};
  }, []);
  const navigate = useNavigate()
  const handleNavigateToDepartment = (id, department_name) => {
    navigate(`/cmd-dashboard/department/${id}`, { state:  department_name });
}
  const fetchData = async () => {
    setLoading(true)
    try {
      const storedUserData = localStorage.getItem("userdata");

      if (!storedUserData) {
        console.error("User data not found in localStorage.");
        return;
      }

      const userDataObj = JSON.parse(storedUserData);
      if (!userDataObj || !userDataObj.token) {
        console.error(
          "User token not found in localStorage or component unmounted."
        );
        return;
      }

      const {
        token: userToken,
        user_id: deepuser_id,
        org_id: deeporg_id,
      } = userDataObj;

      setusertoken(userToken);
      setuser_id(deepuser_id);
      setuserorg_id(deeporg_id);
      setIndex(0);
      const data = await getAllWizardDepartment(
        deepuser_id,
        deeporg_id,
        departmentType,
        userToken
      );
      if (data.code == "200") {
        setLoading(false)
        const newResults = data.data;
        setDeptSearchResults(data.data);
     
      
      } else {
        setLoading(false)
        console.error("Error fetching user data:", error);
      }
    } catch (error) {
        setLoading(false)
      console.error("Error fetching user data:", error);
    } finally {
      // Make sure to set isLoading to false whether the fetch was successful or not
      //setIsLoading(false);
    }
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value); // Update filter value
  };

  // Filter department data based on the filter value
  const filteredDeptResults = deptSearchResults.filter((item) =>
    item.department_name.toLowerCase().includes(filter.toLowerCase())
  );
  return (
    <Box style={cardStyle}>
      <Box display="flex" justifyContent="space-between">
        <IconButton
          size="small"
          sx={{
            borderRadius: "100px",
            background: "var(--blueGrey-50, #ECEFF1)",
          }}
        >
          <CorporateFareIcon color="primary" />
        </IconButton>
        <Typography
          variant="h5"
          component="div"
          sx={{
            color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
            // fontFamily: "Roboto",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "133.4%",
            marginLeft: "10px",
          }}
        >
         {departmentType == 'department' ? "Departments" : "Areas"}
        </Typography>
      </Box>
      <TextField
        label= {departmentType == 'department' ? " Search Departments" : " Search Areas"}
        value={filter}
        onChange={handleFilterChange}
        variant="outlined"
        margin="normal"
        fullWidth
        InputProps={{
          startAdornment: <SearchIcon />,
        }}
        size="small"
      />
         {loading ? (
        <DepartmentSkeleton />
      ) : (
        <Box style={{ overflowY: "auto",width:'100%' }}>
        <List>
          <Grid container spacing={2}>
            {filteredDeptResults.map((item, index) => (
              <Grid item xs={6} md={6} sx={{ padding: 0 }} key={index}> {/* Adjust xs value as needed for responsiveness */}
               <ListItem>
  <IconButton
    size="small"
    onClick={() => handleNavigateToDepartment(item.department_id,item.department_name)}
    sx={{
      borderRadius: "100px",
      background: "var(--blueGrey-50, #ECEFF1)",
      marginRight: "8px",
    }}
  >
    <ListIcon
      color="primary"
      icon={item.icon}
      style={{ fontSize: "small" }}
    />
  </IconButton>
  <div onClick={() => handleNavigateToDepartment(item.department_id,item.department_name)}>
    <Typography
      sx={{
        whiteSpace: "nowrap",
        fontSize: "14px",
        fontWeight: 400,
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'underline',// Set the hover color
          color: '#2196F3'
        },
      }}
    >
      {item.department_name}
    </Typography>
  </div>
</ListItem>

              </Grid>
            ))}
          </Grid>
        </List>
      </Box>
      )}
    </Box>
  );
};

export default DepartmentWizard;
