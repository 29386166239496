export const getAllRedList = async (user_id, userorg_id,searchText,dept_id,dateList,fromDate,toDate,token) => {
    try {
      const apiEndpoint = "cmdadmin/getallredlist";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
        serach_text:searchText,
        dept_id:dept_id,
        date: {
          type: "1",
          value: dateList,
        },
     
        activity_type:'critical',
        start_index: 0,
        limit: 15,
        
      };
      if (fromDate !== "" && toDate !== "") {
        requestData.date = {
          type: "2",
          value: `${fromDate},${toDate}`,
        };
      }
    
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };

  export const getAllDayToDayActivity = async (user_id, userorg_id,searchText,dept_id,dateList,fromDate,toDate,token) => {
    try {
      const apiEndpoint = "cmdadmin/getallredlist";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
        serach_text:searchText,
        dept_id:dept_id,
        date: {
          type: "1",
          value: dateList,
        },
     
        activity_type:'daytoday',
        start_index: 0,
        limit: 15,
        
      };
      if (fromDate !== "" && toDate !== "") {
        requestData.date = {
          type: "2",
          value: `${fromDate},${toDate}`,
        };
      }
    
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };

  export const getAllRedListbyid = async (user_id, userorg_id,redlist_id,token) => {
    try {
      const apiEndpoint = "cmdadmin/getredlistbyid";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
        redlist_id:redlist_id,

      };
    
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };

  export const RedListbydeleteid = async (user_id, userorg_id,redlist_id,token) => {
    try {
      const apiEndpoint = "cmdadmin/deleteredlist";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
        redlist_id:redlist_id,

      };
    
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };

  export const RedListCreatetemPath = async (user_id, userorg_id,token) => {
    try {
      const apiEndpoint = "cmdadmin/createtempdocpath";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
      

      };
    
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };

  export const RedListDocument = async (user_id, userorg_id,redlistid,docname,docid,docpath,token) => {
    try {
      const apiEndpoint = "cmdadmin/downloadactivitydoucment";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
        activity_id:redlistid,
        doc_name:docname,
        document_id:docid,
         doc_path:docpath,
         activity_type: "critical",
      

      };
    
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };

  export const RedListDocumentDeleteById = async (user_id, userorg_id,redlistid,docname,docid,docpath,token) => {
    try {
      const apiEndpoint = "cmdadmin/deleteactivitydocument";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
        activity_id:redlistid,
        doc_name:docname,
        document_id:docid,
         doc_path:docpath,
         activity_type: "critical",
      

      };
    
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };


  export const InstantDocumentDelete = async (deepuser_id, userorg_id,docpath,docname,token) => {
    try {
      const apiEndpoint = "cmdadmin/delete_instant_upload_file";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id:deepuser_id,
        doc_name:docname,
         doc_path:docpath,
         activity_type: "critical",
      

      };
    
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };

  export const getMoreRedList = async (user_id, userorg_id,searchText,dept_id,dateList,fromDate,toDate,token,index) => {
    try {
      const apiEndpoint = "cmdadmin/getallredlist";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
        serach_text:searchText,
        dept_id:dept_id,
        date: {
          type: "1",
          value: dateList,
        },
     
        activity_type:'critical',
        start_index: index,
        limit: 15,
        
      };
      if (fromDate !== "" && toDate !== "") {
        requestData.date = {
          type: "2",
          value: `${fromDate},${toDate}`,
        };
      }
    
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };

  export const getMoreDayToDayActivity = async (user_id, userorg_id,searchText,dept_id,dateList,fromDate,toDate,token,index) => {
    try {
      const apiEndpoint = "cmdadmin/getallredlist";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
        serach_text:searchText,
        dept_id:dept_id,
        date: {
          type: "1",
          value: dateList,
        },
     
        activity_type:'daytoday',
        start_index: index,
        limit: 15,
        
      };
      if (fromDate !== "" && toDate !== "") {
        requestData.date = {
          type: "2",
          value: `${fromDate},${toDate}`,
        };
      }
    
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };