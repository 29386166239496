import React, { useState, useEffect, useRef } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer } from 'recharts';
import { Skeleton } from '@mui/material';
const data = [
  { name: 0, uv: 4000, Series : 2400, amt: 2400 },
  { name: 20, uv: 3000, Series : 1398, amt: 2210 },
  { name: 40, uv: 2000, Series : 9800, amt: 2290 },
  { name: 60, uv: 2780, Series : 3908, amt: 2000 },
  { name:80, uv: 1890, Series : 4800, amt: 2181 },
  { name: 100, uv: 2390, Series : 3800, amt: 2500 },
  { name: 200, uv: 3490, Series : 4300, amt: 2100 },
 
];
const style = {
  top: '0',
  right: 20,
  transform: 'translate(0, -50%)',
  lineHeight: '24px',
};
const SingleLineChartSkeleton = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'flex-end', height: '320px' }}>
    {/* Vertical Line Skeletons */}
    <Skeleton variant="rectangular" width={20} height="60%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="70%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={10} height="80%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="50%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="100%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
   
    <Skeleton variant="rectangular" width={20} height="60%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="70%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="80%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="50%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="100%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="90%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="50%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="80%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="70%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="60%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="20%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="40%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="85%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="50%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="25%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
   
  </div>
  );
};
const SingleLineChart = () => {
  const [loading, setLoading] = useState(false);
  return (
    <ResponsiveContainer  height={350}>
       
         {loading ? (
        <SingleLineChartSkeleton />
      ) : (
    <LineChart
      width={500}
      height={350}
      data={data}
      margin={{ top: 15, right: 30, left: 0, bottom: 30 }}
    >
   
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend verticalAlign="top"  wrapperStyle={style}/>
       <Line type="monotone" dataKey="Series" stroke="#D500F9" strokeWidth={4} dot={{ strokeWidth: 4 }} />
    </LineChart>
    )}
  </ResponsiveContainer>
      
  );
};

export default SingleLineChart;
