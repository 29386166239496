import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { TextField, List, Autocomplete, ListItem, Divider } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TodayIcon from '@mui/icons-material/Today';
import InputAdornment from '@mui/material/InputAdornment';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CloseIcon from '@mui/icons-material/Close';

function CmdDateFilter(props, ref) {
  const { onApply, onApplycustomdate } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsShowMore(false);
  };

  const [isShowMore, setIsShowMore] = useState(false);
  const [buttonText, setButtonText] = useState('Filter by date');
  const [selectedValue, setSelectedValue] = useState('');



  const showMore = () => {
    setIsShowMore(!isShowMore);
    // Reset the button text
  };

  const handleCustomDateApply = () => {
    // Apply custom date range logic here
    setButtonText('Custom Date'); // Change the button text to 'Custom Date' when applied
    setIsShowMore(false);
    setAnchorEl(null);
    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(toDate);
    onApplycustomdate({ fromDateval: formattedFromDate, toDateval: formattedToDate })
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const modifyDateText = [
    { label: 'Today', value: 'today' },
    { label: 'Tomorrow', value: 'tomorrow' },
    { label: 'Last 7 Days', value: '7days' },
    { label: 'Next 7 Days', value: 'next7days' },
  ];
  const handleListItemClick = (item, value) => {
    setButtonText(item);
    setSelectedValue(value);
    onApply(value);
    setAnchorEl(null);
  };
  const formatDate = (dateString) => {
    if (!dateString) {
      return "Date not selected"; // or any other default message
    }

    const timeZone = "India Standard Time"; // Add your desired time zone here

    // Create a new Date object from the input string
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return "Invalid date";
    }

    // Set time to 00:00:00
    date.setHours(0, 0, 0, 0);

    // Format the date string manually in the required format
    const formattedDate = date.toDateString(); // Sat Nov 25 2023
    const formattedTime = date.toTimeString().substring(0, 8); // 00:00:00
    const gmtOffset = date.getTimezoneOffset(); // Offset in minutes
    const offsetHours = Math.floor(Math.abs(gmtOffset) / 60);
    const offsetMinutes = Math.abs(gmtOffset) % 60;
    const offsetSign = gmtOffset >= 0 ? '+' : '-';

    const formattedTimeZone = `GMT${offsetSign}${offsetHours}${offsetMinutes > 0 ? `:${offsetMinutes}` : ''}`; // GMT+0530

    return `${formattedDate} ${formattedTime} ${formattedTimeZone} (${timeZone})`;
  };

  // Example usage
  const formattedResult = formatDate("Tue, 31 Oct 2023 18:30:00 GMT"); // Output: Sat Nov 25 2023 00:00:00 GMT+0530 (India Standard Time)
  const handleResetClick = () => {
    setFromDate(null);
    setToDate(null);
    setSelectedValue(null);
    setButtonText('Filter by date');
  };

  useEffect(() => {
    // Use the ref to expose the handleResetClick function
    if (ref) {
      ref.current = {
        handleResetClick,
      };
    }
  }, [ref]);
  const DateApplyRevert = () => {
    setButtonText('Filter by date');
    setAnchorEl(null);
    setAnchorEl(false);
    setIsShowMore(false);
  }
  return (
    <div>
      <Button
        onClick={handleClick}
        sx={buttonText != 'Filter by date' ? {
          '&:hover': {
            backgroundColor: '#DBE2F9', // Set the hover color
          },
          fontWeight: 'bold',
          color: '#2B74E2',
          backgroundColor: '#DBE2F9',
          textTransform: 'none',
          borderRadius: '8px',
          border: '1px solid #DBE2F9',
          // marginRight: '15px',
          // padding:'7.5px 20px',
          height: '40px',
          minWidth: '100px'
        } : {
          '&:hover': {
            backgroundColor: '', // Set the hover color
            border: '1px solod black'
          },
          fontWeight: 'bold',
          backgroundColor: '',
          color: '#344054',
          textTransform: 'none',
          borderRadius: '8px',
          border: '1px solid #D0D5DD',
          // marginRight: '15px',
          // padding:'7.5px 20px',
          height: '40px',
          minWidth: '100px'
        }}
      >

        {buttonText}{' '}
        {buttonText === 'Filter by date' ? (null) : (
          ''
          //  <IconButton onClick={DateApplyRevert}style={{padding:'0px 0px',}}><CloseIcon sx={{ height: '15px', width: '15px', color: '#2B74E2' }} /></IconButton> 
        )}
        <CalendarTodayIcon sx={{ width: '18px', height: '18px', marginRight: '5px', marginLeft: '5px' }} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography>
          <Card
            variant="outlined"
            style={{
              display: 'flex',
            }}
          >
            <div>
              <div style={{ display: 'flex' }}>
                <CardContent style={{ display: 'flex' }}>
                  <List>
                    {modifyDateText.map((item) => (
                      <ListItem sx={{ margin: '0px', padding: '0px' }}>
                        <Button
                          sx={{ width: '100%', justifyContent: 'left' }}
                          onClick={() => handleListItemClick(item.label, item.value)}
                        >
                          {item.label}
                        </Button>
                      </ListItem>
                    ))}
                    <ListItem sx={{ margin: '0px', padding: '0px' }}>
                      <Button sx={{ width: 'max-content' }} onClick={showMore}>
                        Custom date range
                      </Button>
                    </ListItem>
                  </List>
                  <Divider />
                </CardContent>
                <Divider orientation="vertical" flexItem />
                {isShowMore && (
                  <div>
                    <Divider orientation="vertical" flexItem />
                    <CardContent style={{ flex: 1 }}>
                      <div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DatePicker']}>
                            <Box sx={{ minWidth: '100px' }}>
                              <DatePicker placeholder="From Date"
                                sx={{ marginTop: '20px' }}
                                label='From'
                                slotProps={{ textField: { size: 'small', format: 'DD/MM/YYYY' } }}
                                value={fromDate}
                                onChange={(date) => setFromDate(date)}
                              />
                            </Box>
                          </DemoContainer>
                        </LocalizationProvider>

                      </div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DatePicker']}>
                            <Box sx={{ minWidth: '100px' }}>
                              <DatePicker placeholder="To Date"
                                sx={{ marginTop: '20px' }}
                                label='To'
                                slotProps={{ textField: { size: 'small', format: 'DD/MM/YYYY' } }}
                                value={toDate}
                                onChange={(date) => setToDate(date)}
                              />
                            </Box>
                          </DemoContainer>
                        </LocalizationProvider>
                    </CardContent>
                  </div>
                )}
              </div>
              {isShowMore && (
                <div>
                  <Divider orientation="horizontal" sx={{ marginBottom: '20px' }} flexItem />
                  <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                      variant="outlined"
                      sx={{ margin: '10px' }}
                      onClick={() => {
                        setButtonText('Filter by date');
                        setAnchorEl(null);
                        setIsShowMore(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button variant="contained" sx={{ margin: '10px' }} onClick={handleCustomDateApply}>
                      Apply
                    </Button>
                  </Box>
                </div>
              )}
            </div>
          </Card>
        </Typography>
      </Popover>
    </div>
  );
}

export default React.forwardRef(CmdDateFilter);