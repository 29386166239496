import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect, useRef,useCallback } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Toolbar, Drawer, AppBar, } from '@mui/material';
import Sidebar from '../../Includes/Menu';
import Appbar from '../../Includes/Appbar';
import Paper from '@mui/material/Paper';
import ClearIcon from "@mui/icons-material/Clear";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { format } from 'date-fns';
import { Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import BasicMenu from './MenuBtn';
import { TextField,InputAdornment } from '@mui/material';
import TodayIcon from '@mui/icons-material/Today';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress } from "@mui/material";
import debounce from 'lodash.debounce';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  fetchQueryHistory,
  deleteQueryTitle,
  editQueryTitle,
  fetchMoreQueryHistory
} from "../ApiHelper/QuerySearchApi.js"
import HistoryDateFilter from './HistoryDateFilter.jsx';
const ITEM_HEIGHT = 48;
export default function QueryHistory(props, { items }) {
  const navigate = useNavigate();
  const [totalsearch, settotalsearch] = useState("");
   const [index, setIndex] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [usertoken, setusertoken] = useState("");
 
  const [errorSnacknar, seterrorSnacknar] = useState(false);
  const [user_id, setuser_id] = useState("");
  const [userorg_id, setuserorg_id] = useState("");
  
  const [menuVisibilities, setMenuVisibilities] = useState({});
  const [fullloading, setfullLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [queryHistoryData, setQueryHistoryData] = useState([]);
  const [querySearch, setQuerySearch] = useState('');
  const [datelist, setDateList] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [todate, settodate] = useState('');
  const date = new Date();
  const formattedDate = format(date, 'MMMM dd, yyyy');
  const [isFocused, setIsFocused] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const options1 = [''];
  useEffect(() => {

    useEffectFetch();
  }, []);
  const handleNewQueryClick = () => {
    console.log('New Query Clicked');
   
  };
  const fetchData = async () => {
    try {
      setfullLoading(false);
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }
      const userToken = userDataObj.token;
      const user_id = userDataObj.user_id;
      const userorg_id = userDataObj.org_id;

      setusertoken(userToken);
      setuser_id(user_id);
      setuserorg_id(userorg_id);
      setIndex(0);

      // Use the API helper function to fetch query history data
      const data = await fetchQueryHistory(user_id, userorg_id, userToken, datelist, fromDate, todate, querySearch, index);

      if (data.code === "200") {
        setQueryHistoryData(data.data);
        settotalsearch(data.total_rec);
        console.log(data.data);
        const initialMenuVisibilities = {};
        data.data.forEach((item, index) => {
          initialMenuVisibilities[index] = false;
        });
        // setHasMore(data.data.length > 0);
        setMenuVisibilities(initialMenuVisibilities);
        setfullLoading(false);
        if (data.data.length >= 0 && data.data.length <15) {
          setHasMore(false);
        } else {
          setHasMore(true);
          setfullLoading(false);
        }
      } else {
        setfullLoading(false);
      }
    } catch (error) {
      seterrorSnacknar(true);
    }
  };
  const useEffectWithoutQuery = async () => {
    try {
      setfullLoading(true);
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }
      const userToken = userDataObj.token;
      const deepuser_id = userDataObj.user_id;
      const deeporg_id = userDataObj.org_id;

      setusertoken(userToken);
      setuser_id(deepuser_id);
      setuserorg_id(deeporg_id);
      setIndex(0);

      // Use the API helper function to fetch query history data
      const data = await fetchQueryHistory(deepuser_id, deeporg_id, '', datelist, fromDate, '', userToken, index);

      if (data.code === "200") {
        setQueryHistoryData(data.data);
        // console.log(data.data);
        settotalsearch(data.total_rec);
        // setHasMore(data.data.length > 0);
        const initialMenuVisibilities = {};
        data.data.forEach((item, index) => {
          initialMenuVisibilities[index] = false;
        });
        setMenuVisibilities(initialMenuVisibilities);
        setfullLoading(false);
        if (data.data.length >= 0 && data.data.length <15) {
          setHasMore(false);
        } else {
          setHasMore(true);
          setfullLoading(false);
        }
      } else {
        setfullLoading(false);
      }
    } catch (error) {
      seterrorSnacknar(true);
    }
  };



  const useEffectFetch = async () => {
    
    try {
      setfullLoading(true);
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }
      const userToken = userDataObj.token;
      const deepuser_id = userDataObj.user_id;
      const deeporg_id = userDataObj.org_id;

      setusertoken(userToken);
      setuser_id(deepuser_id);
      setuserorg_id(deeporg_id);
      setIndex(0);

      // Use the API helper function to fetch query history data
      const data = await fetchQueryHistory(deepuser_id, deeporg_id, querySearch, datelist, fromDate, '', userToken, index);

      if (data.code === "200") {
        setQueryHistoryData(data.data);
        // console.log(data.data);
        settotalsearch(data.total_rec);
        // setHasMore(data.data.length > 0);
        const initialMenuVisibilities = {};
        data.data.forEach((item, index) => {
          initialMenuVisibilities[index] = false;
        });
        setMenuVisibilities(initialMenuVisibilities);
        setfullLoading(false);
        if (data.data.length >= 0 && data.data.length <15) {
          setHasMore(false);
        } else {
          setHasMore(true);
          setfullLoading(false);
        }
      } else {
        setfullLoading(false);
      }
    } catch (error) {
      seterrorSnacknar(true);
    }
  };
  const dateRef = useRef();
  const clearQuery = () => {
    setQuerySearch('');
  }
  const ClearAll = async () => {
    clearQuery()
    setfullLoading(true)
    setDateList('');
    settodate('');
    setFromDate('');
    setQuerySearch('');
    setDateList('');

    dateRef.current.handleResetClick();
    try {
      setfullLoading(true);
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }
      const userToken = userDataObj.token;
      const deepuser_id = userDataObj.user_id;
      const deeporg_id = userDataObj.org_id;

      setusertoken(userToken);
      setuser_id(deepuser_id);
      setuserorg_id(deeporg_id);
      const index = 0;

      // Use the API helper function to fetch query history data
      const data = await fetchQueryHistory(deepuser_id, deeporg_id, '', '', '', '', userToken, index);

      if (data.code === "200") {
        setQueryHistoryData(data.data);
        // console.log(data.data);
        settotalsearch(data.total_rec);
        // setHasMore(data.data.length > 0);
        const initialMenuVisibilities = {};
        data.data.forEach((item, index) => {
          initialMenuVisibilities[index] = false;
        });
        setMenuVisibilities(initialMenuVisibilities);
        setfullLoading(false);
        if (data.data.length >= 0 && data.data.length <15) {
          setHasMore(false);
        } else {
          setHasMore(true);
          setfullLoading(false);
        }
      } else {
        setfullLoading(false);
      }
    } catch (error) {
      seterrorSnacknar(true);
    }
  };
  //   const querySearchResult = async (values) => {
  //     // Handle form submission
  //     console.log(values);
  //     // return false;
  //     // setSubmitting(false);
  //     // setLoading(true);
  //     setfullLoading(true)
  //     let BaseURL = `${window.constants.api_url}/queryhistory`;
  //     const storedUserData = localStorage.getItem('userdata');
  //     const userDataObj = JSON.parse(storedUserData);
  //     const { token: userToken, user_id: deepuser_id, org_id: deeporg_id } = userDataObj;

  //     if (!userDataObj || !userDataObj.token) {
  //       console.error("User token not found in AsyncStorage.");
  //       return;
  //     }

  //     const requestData = {
  //       "user_id": deepuser_id,
  //       "org_id": deeporg_id,
  //       "searchstring": querySearch,
  //       "date":{
  //         "type":'',
  //         "value":'',
  //       },
  //       "start_index": 0,
  //       "limit": 15
  //     };

  //     try {
  //       const response = await fetch(BaseURL, {
  //         method: 'POST',
  //         body: JSON.stringify(requestData),
  //         headers: {
  //           token: userToken,
  //           'Content-Type': 'application/json'
  //         }
  //       });

  //       const res = await response.json();
  // console.log(res.data);
  //       if (res.code === "200") {
  //         console.log(res.data);
  //         setQueryHistoryData(res.data);
  //         setfullLoading(false);
  //         settotalsearch(res.total_rec);

  //       } else {
  //         setfullLoading(false);
  //       }
  //     } catch (error) {
  //       console.error("Error:", error);
  //     }
  //   };
  const handleApplydate = async (selectedValue) => {
    setDateList(selectedValue)
    setIndex(0)
    setfullLoading(true)
    try {
      const storedUserData = localStorage.getItem('userdata');
      const userDataObj = JSON.parse(storedUserData);
      const { token: userToken } = userDataObj

      setusertoken(userToken)

      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }
      setusertoken(userToken);
      setIndex(0);
      console.log(index)
      const data = await fetchQueryHistory(
        user_id,
        userorg_id,
        querySearch,
        selectedValue,
        '',
        '',
        userToken,
        index
      );
      console.log('+inside')
      if (data.code == "200") {
        console.log(' ++inside')
        setfullLoading(false);
        setQueryHistoryData(() => [...data.data,]);
        settotalsearch(data.total_rec);
        // setHasMore(data.data.length > 0);
        console.log(data.total_rec)
        console.log(queryHistoryData)
        setShowStaticContent(false);
        if (data.data.length >= 0 && data.data.length <15) {
          setHasMore(false);
        } else {
          setfullLoading(false);
          setHasMore(true);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
    // Handle the selected value in the parent page
  };
  const handleApplydatecustom = async ({ fromDateval, toDateval }) => {
    console.log('himadri')
    settodate(toDateval);
    setFromDate(fromDateval);
    console.log('Received toDateval:', toDateval);
    setfullLoading(true)
    try {
      const storedUserData = localStorage.getItem('userdata');
      const userDataObj = JSON.parse(storedUserData);
      const { token: userToken } = userDataObj

      //setusertoken(userToken)

      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }
      // setusertoken(userToken);
      setIndex(0);
      const data = await fetchQueryHistory(
        user_id, userorg_id, querySearch, '', fromDateval, toDateval, userToken, index
      );
      console.log('hima', data)
      if (data.code == "200") {
        console.log(' ++inside')
        setfullLoading(false);
        setQueryHistoryData(data.data);
        settotalsearch(data.total_rec);
        // setHasMore(data.data.length > 0);
        console.log(queryHistoryData)
        setShowStaticContent(false);
        if (data.data.length >= 0 && data.data.length <15) {
          setHasMore(false);
        } else {
          setfullLoading(false);
          setHasMore(true);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
    // Handle the selected value in the parent page
  };
  // const dateRef = useRef();
  const handleClearButtonClick = () => {
    // Access the handleResetClick function from the date component using the ref
    // dateRef.current.handleResetClick();
    // Access the handleResetClick function from the department component using the ref

    // fetchpreData();
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && querySearch.length >= 3) {
      // Handle the submit action with the current input value
      querySearchResult();
    }
  };

  const querySearchResult = async () => {
    try {
      setfullLoading(true);
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }
      const userToken = userDataObj.token;
      const deepuser_id = userDataObj.user_id;
      const deeporg_id = userDataObj.org_id;

      setusertoken(userToken);
      setuser_id(deepuser_id);
      setuserorg_id(deeporg_id);
      setIndex(0);

      // Use the API helper function to fetch query history data
      const data = await fetchQueryHistory(deepuser_id, deeporg_id, querySearch, datelist, fromDate, '', userToken, index);

      if (data.code === "200") {
        setQueryHistoryData(data.data);
        // console.log(data.data);
        settotalsearch(data.total_rec);
        // setHasMore(data.data.length > 0);
        const initialMenuVisibilities = {};
        data.data.forEach((item, index) => {
          initialMenuVisibilities[index] = false;
        });
        setMenuVisibilities(initialMenuVisibilities);
        setfullLoading(false);
        if (data.data.length >= 0 && data.data.length <15) {
          setHasMore(false);
        } else {
          setHasMore(true);
          setfullLoading(false);
        }
      } else {
        setfullLoading(false);
      }
    } catch (error) {
      seterrorSnacknar(true);
    }
  };
  const LoadingSkeleton = () => {
    // You can customize the skeleton based on your design
    return (
      <TableRow>
        <TableCell>
          <Skeleton width={400} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton width={200} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton width={200} height={20} />
        </TableCell>
      </TableRow>
    );
  };
  const handleCardPress = async (index, userconversation_id) => {
    try {

      navigate('/query', {
        state: {
          conversation_id: userconversation_id,
          random_no: Math.random(),
        },
      });

    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleLoadMore = async () => {
    setIsLoadingMore(true);
    try {
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }
      const userToken = userDataObj.token;
      const deepuser_id = userDataObj.user_id;
      const deeporg_id = userDataObj.org_id;
      setusertoken(userToken);
      setuser_id(deepuser_id);
      setuserorg_id(deeporg_id);
      setIndex(index + 15);


      const data = await fetchMoreQueryHistory(
        deepuser_id, deeporg_id, querySearch, datelist, fromDate, '', userToken,
        index + 15,

      );

      if (data.code === "200") {
        setQueryHistoryData((prevData) => [...prevData, ...data.data]);
        setIsLoadingMore(false);
        // setHasMore(data.data.length > 0);
        const initialMenuVisibilities = {};
        data.data.forEach((item, index) => {
          initialMenuVisibilities[index] = false;
        });
        setMenuVisibilities(initialMenuVisibilities);
        setfullLoading(false);
        if (data.data.length >= 0 && data.data.length <15) {
          setHasMore(false);
        } else {
          setfullLoading(false);
          setHasMore(true);
        }
      } else {
        console.error("Error:", data.message);
        setIsLoadingMore(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const [editData, setEditData] = useState({
    index: null,
    query: '',
    userconversation_id: '',
  });

  const showEditDialog = (index, query, userconversation_id) => {
    setEditData({
      index,
      query,
      userconversation_id,
    });
  };
  const { windows } = props;
  const drawerWidth = 240;
  const drawer = (
    <div>
   <Sidebar onNewQueryClick={handleNewQueryClick} />
    </div>
  );
  const container =
    windows !== undefined ? () => window().document.body : undefined;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [dataCount, setDataCount] = useState(0); // Track the number of data items loaded
  const tableContainerRef = useRef(null);
  const isLoadingRef = useRef(false);

  const debouncedHandleScroll = useCallback(debounce(() => {
    const tableContainer = tableContainerRef.current;
    if (
      tableContainer &&
      tableContainer.scrollTop + tableContainer.clientHeight >= tableContainer.scrollHeight &&
      !isLoadingRef.current
    ) {
      isLoadingRef.current = true;
      handleLoadMore().finally(() => {
        isLoadingRef.current = false;
      });
    }
  }, 200), [handleLoadMore]);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    if (tableContainer) {
      tableContainer.addEventListener('scroll', debouncedHandleScroll);

      return () => {
        tableContainer.removeEventListener('scroll', debouncedHandleScroll);
        debouncedHandleScroll.cancel();
      };
    }
  }, [debouncedHandleScroll]);

  const handleSearch = () => {
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.scrollTop = 0; // Reset scroll position to top
    }
    querySearchResult().then((newDataCount) => {
      setDataCount(newDataCount); // Update data count with the result of the search
      // Optionally check if additional data needs to be loaded if not enough data is displayed
      if (newDataCount < 15) {
        handleLoadMore();
      }
    });
  };
  const handleClearAndFetch = () => {
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.scrollTop = 0; // Reset scroll position to top
    }
    setQuerySearch('');
    useEffectWithoutQuery();
  };

  return (
    <>
      <Box sx={{ display: 'flex' }} >
        <CssBaseline />
        <AppBar
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            backgroundColor: "white",
          }}
        >
          <Appbar />
        </AppBar>

        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            // onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 10, bgcolor: '#eff1f3', textDecoration: 'none', "& fieldset": { border: 'none' }, minHeight: '100vh' }}
        >
          <Box sx={{padding: '20px 20px 0px 20px', height:'37vh',paddingTop:'15vh' }}>
         
          <TextField
        fullWidth
       
        onKeyDown={handleKeyDown}
        sx={{
        
          borderRadius: '25px',
          backgroundColor: isFocused ? 'white' : '#D0E2FB',
          '.MuiOutlinedInput-root': {
            borderRadius: '25px',
            paddingRight: 2,
           
            fontSize: '0.8rem', // Adjust font size to reduce height
            lineHeight: '1.2', // Adjust line height if needed
            minHeight: '30px', // Set minimum height for the input
    
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
           '& .MuiInputBase-input::placeholder': {
                color: 'black', // Set placeholder color to black
                opacity: 1, // Ensure the placeholder is fully opaque
            },
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={(e) => setQuerySearch(e.target.value)}
        value={querySearch}
        placeholder="Search for query title"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={querySearchResult} size="small">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <Box display="flex">
              <Box display="flex" alignItems="center">
                {querySearch && (
                  <IconButton onClick={handleClearAndFetch} size="small">
                    <ClearIcon />
                  </IconButton>
                )}
              </Box>
            </Box>
          ),
        }}
      />
          
          <Box sx={{ display: 'flex', textAlign: 'left', padding:'15px 0px'}}>
            <HistoryDateFilter ref={dateRef} onApplycustomdate={handleApplydatecustom} onApply={handleApplydate} />
            <Box sx={{ marginLeft: '10px' }}>
              <Button variant="outlined" onClick={ClearAll}>Clear</Button>
            </Box>
          </Box>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>


            <span style={{ fontSize: '13px', color: 'grey' }}>Total {totalsearch ? totalsearch : 0}{" "} search titles</span>
          </div>
          </Box>
          <Paper sx={{ width: '100%', overflow: 'hidden', }}>

            <TableContainer  ref={tableContainerRef}
                                sx={{ height: '62.8vh', overflow: 'auto' }}
                                >
              
                <Table stickyHeader size="small" aria-label="a dense table">
                  <TableHead sx={{ backgroundColor: '#eff1f3', color: 'pink' }}>
                    <TableRow >

                      <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white', borderLeft: '4px solid white', borderRadius: '5px', width: '600px' }}>
                        Search Title
                      </TableCell>
                      <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white' }}>
                        Last Updated
                      </TableCell>
                      <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white', borderRight: '4px solid white', borderRadius: '5px' }}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {fullloading ? (
                // Render loading skeletons while data is being fetched
                Array.from({ length: 10 }).map((_, index) => (
                  <LoadingSkeleton key={index} />
                ))
              ) : (
                  <TableBody>
                    {queryHistoryData.map((item, index) => (
                      <TableRow key={index}
                        sx={{ padding: '0px', height: '20px' }}>
                        <TableCell sx={{ padding: '0px 0px 0px 20px' }}>
                          <div style={{ display: 'flex', padding: '0px', margin: '0px' }}>
                            {/* <ChatBubbleOutlineIcon sx={{ marginTop: '10px', marginRight: '5px',color: 'grey'  }} /> */}

                            <p
                              onClick={() => handleCardPress(index, item.conversation_id)}
                              style={{ textDecoration: 'underline', cursor: 'pointer', color: 'grey' }}
                            >
                              {item.query}
                            </p>

                          </div>
                        </TableCell>
                        <TableCell sx={{ color: 'grey' }}>
                          {item.last_updated}
                        </TableCell>
                        <TableCell sx={{ color: 'grey' }}>
                          <BasicMenu fetchData={useEffectFetch} items={item} index={index} showEditDialog={showEditDialog} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                    )}
                </Table>
                <InfiniteScroll
              dataLength={queryHistoryData.length}
              next={handleLoadMore}
              hasMore={hasMore}
              loader={
                <CircularProgress disableShrink style={{ margin: "20px" }} />
              }
              endMessage={
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  {queryHistoryData.length == 0 ? (
                    <div
                      style={{
                        color: "grey",
                        fontSize: "13px",
                        marginTop: "20px",
                      }}
                    >
                      No record found
                    </div>
                  ) : (
                    <>
                      <div>No More Records</div>
                     
                    </>
                  )}
                </div>
              }
            ></InfiniteScroll>
            </TableContainer>
          </Paper>
          <Box>

            


            {/* {totalsearch > queryHistoryData.length &&
            <Button onClick={handleLoadMore} style={{ marginTop: '15px', color: '#2F80ED', textTransform: 'none' }} variant="secondary" color="primary"> {isLoadingMore ? "Loading..." : "Load More"}</Button>
          }
          {queryHistoryData.length == 0 && <div style={{color:'grey', fontSize:'13px' ,marginTop:'20px'}}>No record found</div> }
           */}

          </Box>
        </Box>
      </Box>
    </>
  )
};
// export default QueryHistory;