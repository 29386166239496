import React, { useState, useEffect, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import { convertFromRaw } from 'draft-js';

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CmdDateFilter from "../DocumentManager/CmdDateFilter.jsx";
import BusinessIcon from '@mui/icons-material/Business';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import WorkIcon from '@mui/icons-material/Work';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import TaskIcon from '@mui/icons-material/Task';
import ShortTextIcon from '@mui/icons-material/ShortText';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ArticleIcon from '@mui/icons-material/Article';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'; // Import InsertDriveFileIcon


import Sidebar from "../Includes/Sidebar.jsx";
import Tooltip from '@mui/material/Tooltip';
import {
    Button, TextField, FormControl, Card, CardContent, Select,
    Checkbox, MenuItem, Chip, Popover, Menu, DialogContentText, Autocomplete,
    List, ListItem, ListItemAvatar, ListItemText, Divider
} from "@mui/material";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InfiniteScroll from "react-infinite-scroll-component";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import NotesIcon from '@mui/icons-material/Notes';
import dayjs from 'dayjs';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import OutlinedInput from '@mui/material/OutlinedInput';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import { getAllDepartment, deleteDepartment, addDepartment, fetchMoreDepartment } from "../AdminApiHelper";
import Skeleton from "@mui/material/Skeleton";
import { CircularProgress, Modal } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
// import AddDepartment from "./addDepartment";
// import ViewDepartment from "./viewDepartment";
import Appbar from "../Includes/Header.jsx";
import { borderRadius } from "@mui/system";
import Badge from '@mui/material/Badge';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
// import { getAllUser } from "../../../AdminConsole/AdminApiHelper";
import { getAllRedList, AddGeneralInfo, GetNotificationCount, getAllRedListbyid, getGeneralInfoTab, RedListbydeleteid, GetallnotificationCmdLink, RedListDocumentDeleteById, getAllAttendees, UpdateNotification, RedListDocument, RedListCreatetemPath } from "./CmdLinkApi.js";
import { CheckBox } from "@mui/icons-material";
import { userMasterData } from "../AdminApiHelper.js";

const drawerWidth = 140;
const theme = createTheme();
const localizer = momentLocalizer(moment);
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}





function AdminCmdLink(props) {
    const { windows } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [notificationSearchText, setnotificationSearchText] = useState('');
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <Sidebar />
        </div>
    );

    const container =
        windows !== undefined ? () => window().document.body : undefined;

    const [departmentbutton, setDepartmentbutton] = React.useState(false);
    const handlebutton = () => {
        setDepartmentbutton(!departmentbutton);
    };

    const deptBtnStyle = {
        borderRadius: '200px',
        backgroundColor: 'var(--Secondary-blue, #003776)',
        textTransform: 'none'
    }
    const [isLoadingnotification, setisLoadingnotification] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [deleteloading, setdeleteloading] = useState(false);

    const [usertoken, setusertoken] = useState("");
    const [index, setIndex] = useState(0);
    const [user_id, setuser_id] = useState("");
    const [userorg_id, setuserorg_id] = useState("");
    const [viewisLoading, setviewisLoading] = useState(false);
    const [showStaticContent, setShowStaticContent] = useState(false);
    const [userconversation_id, setuserconversation_id] = useState("");
    const [dept_id, setDept_id] = useState([]);
    const [dept_name, setDept_name] = useState('');
    const [departmentSearchResults, setDepartmentSearchResults] = useState('');
    const [dept_desc, setDept_desc] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [totalrec, setTotalrec] = useState('');
    const [selectAsignee, setSelectAsignee] = useState(null);
    const [Docpath, SetDocpath] = useState('');
    const [pdfDialogOpen, setPdfDialogOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState("");
    const [isModified, setIsModified] = useState(false)

    const handleRemoveFile = (indexToRemove) => {
        setUploadFiles(prevFiles => prevFiles.filter((file, index) => index !== indexToRemove));
    };
    const handleClosePdfDialog = () => {
        setPdfDialogOpen(false);
        setPdfUrl("");
    };
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [docToDelete, setDocToDelete] = useState(null);

    const openConfirmModal = (docid, redlistid, docname, docpath, activityType) => {
        setDocToDelete({ docid, redlistid, docname, docpath, activityType });
        setConfirmOpen(true);
    };

    const closeConfirmModal = () => {
        setConfirmOpen(false);
        setDocToDelete(null);
    };

    const handleDocumentDelete = async (docid, redlistid, docname, docpath, activityType) => {
        // Call this function to open the confirm modal
        openConfirmModal(docid, redlistid, docname, docpath, activityType);
        setIsModified(true)
    };

    const confirmDelete = async () => {
        setdeleteloading(true);
        const { docid, redlistid, docname, docpath, activityType } = docToDelete;
        if (!docid) {
            setSelectedUserData(prevUserData => ({
                ...prevUserData,
                document: prevUserData.document.filter(doc => doc.doc_name !== docname) // Filter out the deleted document by doc_name
            }));
            setUploadFiles(prevFiles => prevFiles.filter(file => file.doc_name !== docname)); // Filter out the deleted document from upload files
            closeConfirmModal();
            setdeleteloading(false);
            return; // Exit the function
        }

        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            // Use the API helper function to fetch query history data
            const data = await RedListDocumentDeleteById(deepuser_id, deeporg_id, redlistid, docname, docid, docpath, activityType, userToken);

            if (data.code === "200") {
                setdeleteloading(false);
                setSelectedUserData(prevUserData => ({
                    ...prevUserData,
                    document: prevUserData.document.filter(doc => doc.doc_id !== docid) // Filter out the deleted document
                }));
                setUploadFiles(prevFiles => prevFiles.filter(file => file.doc_id !== docid)); // Filter out the deleted document from upload files

                // Call openEditModal with the updated redlistid
                //   openEditModal(redlistid);
                console.log(data.data);

            } else {
                console.log(data.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error, show a message or log it
            // seterrorSnackbar(true);
        }
        closeConfirmModal();
    };
    useEffect(() => {
        const fetchMasterData = async () => {
            // setIsLoading(true);
            try {
                const storedUserData = localStorage.getItem("userdata");

                if (!storedUserData) {
                    console.error("User data not found in localStorage.");
                    return;
                }

                const userDataObj = JSON.parse(storedUserData);
                console.log(userDataObj);
                if (!userDataObj || !userDataObj.token) {
                    console.error(
                        "User token not found in localStorage or component unmounted."
                    );
                    return;
                }

                const {
                    token: userToken,
                    user_id: deepuser_id,
                    org_id: deeporg_id,
                } = userDataObj;

                setusertoken(userToken);
                setuser_id(deepuser_id);
                setuserorg_id(deeporg_id);
                setIndex(0);
                const requestData = await userMasterData(deepuser_id, deeporg_id, userToken);
                if (requestData.code == "200") {
                    setuserconversation_id(requestData.conversation_id);
                    setDepartmentSearchResults(requestData.department);
                    // setIsLoading(false)
                    setShowStaticContent(false);
                } else {
                    setShowStaticContent(true);
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            } finally {
                // Make sure to set isLoading to false whether the fetch was successful or not
                // setIsLoading(false);
            }
        };

        fetchMasterData();

        return () => { };
    }, []);
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };
    const fetchDeptName = (event) => {
        setDept_name(event.target.value)
    }
    const fetchDeptDesc = (event) => {
        setDept_desc(event.target.value)
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [addDeptModalOpen, setAddDeptModalOpen] = useState(false)
    const openAddDeptModal = () => {
        setAddDeptModalOpen(true)
    };
    const closeAddDeptModal = () => {
        setAddDeptModalOpen(false)
    };
    const modalStyle = {
        borderRadius: '200px'
    }
    const [isDeleteModal, setIsDeleteModal] = useState(false)

    const openDeleteModal = (index, department_id) => {
        setIsDeleteModal(true);
        setDept_id(department_id);

    }
    const closeDeleteModal = () => {
        setIsDeleteModal(false);

    }
    const handleClickDelete = () => {

        setIsDeleteModal(false);


    }
    const [inputValue, setInputValue] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [Notificationto, setNotificationto] = useState(null);

    const handleMilestoneStatusChange = (index, isChecked) => {
        const updatedMilestones = [...selectedUserData.milestone];
        updatedMilestones[index].status = isChecked ? 'complete' : 'new';
        setSelectedUserData({
            ...selectedUserData,
            milestone: updatedMilestones
        });
    };
    const [selectedNotificationTo, setSelectedNotificationTo] = useState([]);

    const handleMultipleSelectChange = (event, newValue, name) => {
        if (name === 'assignTo') {
            setFormData({ ...formData, assignTo: newValue });
        } else if (name === 'notificationTo') {
            setFormData({ ...formData, notificationTo: newValue });
        }
    };
    const getOptionLabel = (option) => option.name;
    const [tempAtendees, setTempAttendees] = useState([]);
    const filterOptions = (options, { inputValue }) => {
        // Provide a default empty string value if inputValue is null or undefined
        const lowercaseInputValue = inputValue ? inputValue.toLowerCase() : '';

        return options.filter((option) => {
            // Ensure option.name, option.department, and option.designation are not null or undefined
            const name = option.name ? option.name.toLowerCase() : '';
            const department = option.dept_name ? option.dept_name.toLowerCase() : '';
            const designation = option.designation ? option.designation.toLowerCase() : '';
            const phone = option.mobile_no ? option.mobile_no : '';
            // Filter based on name, department, and designation
            const matchName = name.includes(lowercaseInputValue);
            const matchDepartment = department.includes(lowercaseInputValue);
            const matchDesignation = designation.includes(lowercaseInputValue);
            const matchPhone = phone.includes(lowercaseInputValue);
            return matchName || matchDepartment || matchDesignation || matchPhone;
        });
    };
    const RedListSubmitTemp = async () => {


        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            // Use the API helper function to fetch query history data
            const data = await RedListCreatetemPath(deepuser_id, deeporg_id, userToken);

            if (data.code === "200") {

                SetDocpath(data.doc_path);
                console.log(data.data);
            } else {
                console.log(data.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error, show a message or log it
            // seterrorSnackbar(true);
        }



    };


    const [loadingStates, setLoadingStates] = useState([]);



    const renderOption = (props, option) => (
        <li {...props} style={{ display: "block" }}>
            <Box>
                <Box display="flex" style={{ fontSize: "0.8em" }}>
                    <Box>
                        <img src={option.photo} style={{ height: '35px', width: '35px', borderRadius: '50px', marginRight: '5px' }} />
                    </Box>
                    <Box fontWeight="bold" sx={{ marginTop: "5px" }}>
                        {option.name}
                        <Box display="flex" style={{ fontSize: "0.8em", color: "gray" }}>
                            <Box>

                                {option.email}{" "}
                            </Box>
                            <Box sx={{ margin: "0px 5px" }}> | </Box>
                            <Box>

                                {option.dept_name}
                            </Box>
                            <Box sx={{ margin: "0px 5px" }}> | </Box>
                            <Box>

                                {option.designation}
                            </Box>
                            <Box sx={{ margin: "0px 5px" }}> | </Box>
                            <Box>

                                {option.mobile_no}
                            </Box>
                        </Box>
                    </Box>

                </Box>

            </Box>
        </li>
    );



    const [RedListData, setRedListData] = useState([]);
    const [openAction, setOpenAction] = useState(Array(RedListData.length).fill(false));

    const [addNewRedList, setAddNewRedList] = useState(false);
    const [updateRedList, setUpdateRedList] = useState(false);
    const [viewRedList, setViewRedList] = useState(false);
    const [personName, setPersonName] = React.useState([]);
    const [addMilestoneState, setAddMilestoneState] = useState(false);
    const [updatedMilestoneState, setUpdatedMilestoneState] = useState(false);
    const [userSearchResults, setUserSearchResults] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [dateList, setDateList] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const dateRef = useRef();

    const clearSearchQuery = () => {
        setSearchText("");
    };
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const getActivityTypeChip = (activityType) => {
        switch (activityType) {
            case 'redlist':
                return <Chip label='Red List' color="warning" size="small" sx={{ width: '110px' }} />;
            case 'daytoday':
                return <Chip label='Day To Day' color="success" size="small" sx={{ width: '110px' }} />;
            case 'critical':
                return <Chip label='Critical' style={{ backgroundColor: 'red', color: 'white' }} size="small" sx={{ width: '110px' }} />;
            default:
                return null;
        }
    };
    const fetchUserData = async () => {
        // setIsLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
                super_admin: super_admin,
            } = userDataObj;

            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            // setSuperAdmin(super_admin)
            setIndex(0);
            const requestData = await getAllAttendees(deepuser_id, deeporg_id, 'all', '', userToken);
            if (requestData.code == "200") {
                setuserconversation_id(requestData.conversation_id);
                // const newResults = requestData.data;
                setUserSearchResults(requestData.data);
                // const userDetailToUpdate = requestData.data.find(item => item.user_id === tempUserId);
                setFilterData(requestData.data)
                // if (userDetailToUpdate) {
                // setUserDetails(userDetailToUpdate);
                // } else {
                // Handle case where no user detail is found with matching user_id
                // }

                // setTotal_rec(requestData.total_rec)
                // if (requestData.data.length >= 0 && requestData.data.length <15) {
                //     setHasMore(false)
                // }
                // else {
                //     setHasMore(true);

                // }

                // setIsLoading(false)
                setShowStaticContent(false);
            } else {
                setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            // setIsLoading(false);
        }
    };

    useEffect(() => {

        console.log('doc_user')
        fetchUserData();

        return () => { };
    }, []);
    const useEffectFetch = async () => {
        setIsLoading(true);
        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            setIndex(0);

            // Use the API helper function to fetch query history data
            const data = await getAllRedList(deepuser_id, deeporg_id, searchText, catagoryType, catagoryStatus, dateList, fromDate, toDate, userToken);

            if (data.code === "200") {
                setRedListData(data.data);
                setIsLoading(false);
                setTotalrec(data.total_count)
                console.log(data.data);
            } else {
                setFullLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error, show a message or log it
            // seterrorSnackbar(true);
        }
    };
    useEffect(() => {


        useEffectFetch();
        // GetTotalnotificationCount();// Call the function inside useEffect directly
    }, []); // Empty dependency array since useEffectFetch doesn't depend on any prop or state



    const handleApplydate = async (selectedValue) => {
        setDateList(selectedValue);
        // setfullLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");
            const userDataObj = JSON.parse(storedUserData);
            const { token: userToken } = userDataObj;

            setusertoken(userToken);

            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            setusertoken(userToken);
            setIndex(0);
            const data = await getAllRedList(
                user_id,
                userorg_id,
                searchText, catagoryType, catagoryStatus,
                selectedValue,
                "",
                "",
                userToken,
                index
            );
            if (data.code == "200") {
                // setfullLoading(false);
                setRedListData(data.data);
                // setTotalDoc(data.total_rec);
                // setShowStaticContent(false);
                // if (data.total_rec == data.data.length) {
                //     setHasMore(false)
                // }
                // else {
                //     setHasMore(data.data.length > 0);

                // }
            }
        } catch (error) {
            console.error("Error:", error);
        }
        // Handle the selected value in the parent page
    };
    const handleApplydatecustom = async ({ fromDateval, toDateval }) => {
        setToDate(toDateval);
        setFromDate(fromDateval);
        // setfullLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");
            const userDataObj = JSON.parse(storedUserData);
            const { token: userToken } = userDataObj;

            //setusertoken(userToken)

            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            // setusertoken(userToken);
            setIndex(0);
            const data = await getAllRedList(
                user_id,
                userorg_id,
                searchText, catagoryType, catagoryStatus,
                [],
                fromDateval,
                toDateval,
                userToken,
                index
            );
            if (data.code == "200") {
                // setfullLoading(false);
                setRedListData(data.data);
                // setTotalDoc(data.total_rec);
                setShowStaticContent(false);
                // if (data.total_rec == data.data.length) {
                //     setHasMore(false)
                // }
                // else {
                //     setHasMore(data.data.length > 0);

                // }
            }
        } catch (error) {
            console.error("Error:", error);
        }
        // Handle the selected value in the parent page
    };

    const handleClearButtonClick = async () => {
        setDateList([]);
        setFromDate('');
        setToDate('');
        setStatusButtonText('Status');
        setTypeButtonText('Type');
        setCatagoryStatus('');
        setCatagoryType('')
        clearSearchQuery()
        dateRef.current.handleResetClick();
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
                super_admin: super_admin,
            } = userDataObj;
            // setSuperAdmin(super_admin)
            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            setIndex(0);
            const requestData = await getAllRedList(
                deepuser_id,
                deeporg_id,
                '', '', '', [], '', '',
                userToken,
                index
            );
            if (requestData.code == "200") {
                setuserconversation_id(requestData.conversation_id);
                // setTotalDoc(requestData.total_rec)
                const newResults = requestData.data;
                setRedListData(requestData.data);
                console.log(newResults)
                console.log('requestData.total_rec', requestData.total_rec)
                console.log('requestData.length', requestData.data.length)
                // if (requestData.total_rec == requestData.data.length) {
                //     setHasMore(false)
                // }
                // else {
                //     setHasMore(requestData.data.length > 0);

                // }
                // setHasMore(requestData.data.length > 0);

                setIsLoading(false)
                setShowStaticContent(false);
            } else {
                setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            setIsLoading(false);
        }
    };

    const handleApplynoificationdate = async (selectedValue) => {
        setDateList(selectedValue);
        setisLoadingnotification(true)
        try {
            const storedUserData = localStorage.getItem("userdata");
            const userDataObj = JSON.parse(storedUserData);
            const { token: userToken } = userDataObj;

            setusertoken(userToken);

            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            setusertoken(userToken);
            setIndex(0);
            const data = await GetallnotificationCmdLink(
                user_id,
                userorg_id,
                searchText,
                selectedValue,
                "",
                "",
                userToken,
                index
            );
            if (data.code == "200") {
                setisLoadingnotification(false)
                // setfullLoading(false);
                Setnotificationdata(data.data);
                // setTotalDoc(data.total_rec);
                // setShowStaticContent(false);
                // if (data.total_rec == data.data.length) {
                //     setHasMore(false)
                // }
                // else {
                //     setHasMore(data.data.length > 0);
                setcountnotification(data.total_count);
                // }
            }
        } catch (error) {
            console.error("Error:", error);
        }
        // Handle the selected value in the parent page
    };
    const handleApplydatenotificationcustom = async ({ fromDateval, toDateval }) => {
        setToDate(toDateval);
        setFromDate(fromDateval);
        setisLoadingnotification(true)
        try {
            const storedUserData = localStorage.getItem("userdata");
            const userDataObj = JSON.parse(storedUserData);
            const { token: userToken } = userDataObj;

            //setusertoken(userToken)

            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            // setusertoken(userToken);
            setIndex(0);
            const data = await GetallnotificationCmdLink(
                user_id,
                userorg_id,
                searchText,
                [],
                fromDateval,
                toDateval,
                userToken,
                index
            );
            if (data.code == "200") {
                setisLoadingnotification(false)
                setcountnotification(data.total_count);   // setfullLoading(false);
                Setnotificationdata(data.data);
                setcountnotification(data.total_count);
                setShowStaticContent(false);
                // if (data.total_rec == data.data.length) {
                //     setHasMore(false)
                // }
                // else {
                //     setHasMore(data.data.length > 0);

                // }
            }
        } catch (error) {
            console.error("Error:", error);
        }
        // Handle the selected value in the parent page
    };

    const handleClearnotificationButtonClick = async () => {
        setisLoadingnotification(true)
        setDateList([]);
        setFromDate('');
        setToDate('');
        setnotificationSearchText('');
        // clearSearchQuery()
        dateRef.current.handleResetClick();
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
                super_admin: super_admin,
            } = userDataObj;
            // setSuperAdmin(super_admin)
            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            setIndex(0);
            const requestData = await GetallnotificationCmdLink(
                deepuser_id,
                deeporg_id,
                '', [], '', '',
                userToken,
                index
            );
            if (requestData.code == "200") {
                setisLoadingnotification(false)
                setcountnotification(requestData.total_count);
                const newResults = requestData.data;
                Setnotificationdata(requestData.data);

                setIsLoading(false)
                setShowStaticContent(false);
            } else {
                setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {

            setIsLoading(false);
        }
    };


    const LoadingTable = () => {

        return (
            <TableRow>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>

            </TableRow>
        );
    };
    const LoadingSkeleton = () => {
        // You can customize the skeleton based on your design
        return (
            <Stack spacing={2} sx={{ padding: '30px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box display='flex'>
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                        <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={80} height={30} />
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    </Box>
                    <Box display='flex'>
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    </Box>
                </Box>
                <Box display='flex'>
                    <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={500} height={30} />
                </Box>
                <Box display='flex'>
                    <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={500} height={90} />
                </Box>
                <Box display='flex'>
                    <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={500} height={30} />
                </Box>
                <Box display='flex'>
                    <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={500} height={30} />
                </Box>
                <Box display='flex'>
                    <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={500} height={30} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box display='flex'>
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                        <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                        <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    </Box>
                    <Box display='flex'>
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                        <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                        <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    </Box>
                </Box>
                <Box display='flex'>
                    <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={500} height={30} />
                </Box>


            </Stack>
        );
    };
    const openAddRedListModal = () => {
        setAddNewRedList(true);
        setUploadFiles([])
        RedListSubmitTemp();
        // fetchUserData()

    }
    const [selectedUserData, setSelectedUserData] = useState([]);
    const closeAddRedListModal = () => {
        // setAddNewRedList(false);
        // openCloseConfirm()
        if (isModified) {

            openCloseConfirm()
        }
        else {
            setAddNewRedList(false);

        }
    }
    const openUpdateAddRedListModal = () => {
        setUpdateRedList(true);
        setUploadFiles([]);

    }

    const handleEditClick = (item) => {
        setSelectedUserData(item);
        closeViewRedListModal();
        openUpdateAddRedListModal(); // Open the edit modal
    };

    const closeUpdateRedListModal = () => {
        // setUpdateRedList(false);
        // openCloseConfirm()
        if (isModified) {

            openCloseConfirm()
        }
        else {
            setUpdateRedList(false);

        }
    }

    const [userData, setUserData] = useState([]);
    const [Tempassign, setTempassign] = useState([]);
    const openViewAddRedListModal = async (redlistid) => {

        setviewisLoading(true);
        setViewRedList(true);
        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            // Use the API helper function to fetch query history data
            const data = await getAllRedListbyid(deepuser_id, deeporg_id, redlistid, userToken);

            if (data.code === "200") {
                setviewisLoading(false)
                setUserData(data.data);

                if (data.data[0]?.notificationto) { // Check if notificationto is defined
                    setTempassign(data.data[0].notificationto.map(attendee => attendee));
                }
                console.log(data.data);
            } else {
                console.log(data.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error, show a message or log it
            // seterrorSnackbar(true);
        }



    };
    const [open, setOpen] = useState(false);
    const [RedListIdToDelete, setRedListIdToDelete] = useState('');
    const handleOpen = (redlist_id) => {
        setOpen(true);
        setRedListIdToDelete(redlist_id);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleConfirmDelete = () => {
        RedListModaldelete(RedListIdToDelete); // Call the delete function
        setOpen(false); // Close the modal
    };

    const RedListModaldelete = async (redlistid) => {
        setLoading(true);

        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            // Use the API helper function to fetch query history data
            const data = await RedListbydeleteid(deepuser_id, deeporg_id, redlistid, userToken);

            if (data.code === "200") {
                // setUserData(data.data);
                setLoading(false);
                useEffectFetch();
                closeViewRedListModal();
                console.log(data.data);
            } else {
                console.log(data.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error, show a message or log it
            // seterrorSnackbar(true);
        }



    };
    const closeViewRedListModal = () => {
        setViewRedList(false);
    }
    const handleRowClick = (index) => {
        const newOpenRows = [...openAction];
        newOpenRows[index] = !newOpenRows[index];
        setOpenAction(newOpenRows);
    };
    const [milestones, setMilestones] = useState([]);
    const [UpdateMilestones, setUpdateMilestones] = useState([]);
    const [milestoneName, setMilestoneName] = useState('');
    const [dueDate, setDueDate] = useState(null); // Use appropriate date state
    const [assign, setAssign] = useState('');
    const [loading, setLoading] = useState(false); // State to track loading
    const EditMilestone = () => {
        if (!dueDate) {
            // Handle the case when dueDate is null (not selected)
            // For example, you can display an error message or prevent the function from proceeding
            console.error("Due date is required");
            return;
        }
        const newMilestone = {
            milestone_description: milestoneName,
            due_date: dueDate.format('MM/DD/YYYY'), // Convert due date to string
            assign_to: selectAsignee,
            assign_to_pic: assignees,
            assign_to_Name: assigneeName,
            status: 'New' // Default status
        };

        // Update milestones array by appending the new milestone
        setUpdateMilestones([...milestones, newMilestone]);
        const updatedMilestoneData = [...(selectedUserData.milestone || []), newMilestone];

        // Update the selectedUserData state with the updated milestone data
        setSelectedUserData(prevState => ({
            ...prevState,
            milestone: updatedMilestoneData
        }));
        setSelectAsignee(null)
        // Clear input fields after adding milestone
        setMilestoneName('');
        setDueDate(null);
        setAssign('');
        setAddMilestoneState(false)
        setUpdatedMilestoneState(true)
    };

    const updateMilestone = () => {
        if (!dueDate) {
            // Handle the case when dueDate is null (not selected)
            // For example, you can display an error message or prevent the function from proceeding
            console.error("Due date is required");
            return;
        }
        const newMilestone = {
            milestone_description: milestoneName,
            due_date: dueDate.format('MM/DD/YYYY'), // Convert due date to string
            assign_to: selectAsignee,
            assign_to_pic: assignees,
            assign_to_Name: assigneeName,
            status: 'New' // Default status
        };

        // Update milestones array by appending the new milestone
        setMilestones([...milestones, newMilestone]);
        setSelectAsignee(null)

        setMilestoneName('');
        setDueDate(null);
        setAssign('');
        setAddMilestoneState(false)
        setUpdatedMilestoneState(true)
    };

    const cancelMilestone = (index) => {
        // Create a copy of the milestones array
        const updatedMilestones = [...milestones];
        // Remove the milestone at the specified index
        updatedMilestones.splice(index, 1);
        // Update the milestones array state
        setMilestones(updatedMilestones);
    };

    const canceleditMilestone = (indexToRemove) => {
        // Filter out the milestone with the specified indexToRemove
        const updatedMilestones = selectedUserData.milestone.filter((_, index) => index !== indexToRemove);

        // Update selectedUserData with the updated milestone array
        setSelectedUserData(prevUserData => ({
            ...prevUserData,
            milestone: updatedMilestones
        }));
    };

    const addMileStone = () => {
        setAddMilestoneState(true)
    }

    const cancelMileStone = () => {
        setAddMilestoneState(false)
    }
    const [value, setValue] = React.useState(null);
    function ButtonField(props) {
        const {
            setOpen,
            label,
            id,
            disabled,
            InputProps: { ref } = {},
            inputProps: { 'aria-label': ariaLabel } = {},
        } = props;
        console.log('value', value)
        return (
            <Box sx={{ display: 'flex' }}>
                <IconButton
                    backgroundColor={dueDate !== null && 'grey'}
                    sx={{ border: dueDate == null ? '1px dotted grey' : '', fontSize: '14px', }}

                    id={id}
                    disabled={disabled}
                    ref={ref}
                    aria-label={ariaLabel}
                    onClick={() => setOpen?.((prev) => !prev)} variant='none'
                // style={{ borderColor: 'grey', color: 'grey', borderRadius: '8px', fontSize: '10px', textTransform: 'none', padding: '8px', fontWeight: 'bold' }} 
                >

                    <CalendarTodayIcon sx={{ height: '20px', width: '20px', }}
                    />

                </IconButton>
                <Box sx={{ marginTop: '8px', fontSize: '14px' }}>
                    {dueDate !== null ?
                        dueDate.format('MM/DD/YYYY') : ''
                    }
                </Box>
            </Box>
        );
    }

    function ButtonDatePicker(props) {
        const [open, setOpen] = React.useState(false);

        return (
            <DatePicker
                slots={{ field: ButtonField, ...props.slots }}
                slotProps={{ field: { setOpen } }}
                {...props}
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
            />
        );
    }
    const [assignee, setAssignee] = React.useState(null);
    const [UpdateAssignee, setUpdateAssignee] = React.useState(null);

    const openAssignee = Boolean(assignee);
    const handleClick = (event) => {
        setAssignee(event.currentTarget);
        setIsModified(true)
    };
    const handleUpdateClick = (event) => {
        setUpdateAssignee(event.currentTarget);
    };
    const handleInputChangeee = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        setIsModified(true)
    };
    const handleEditChange = (newValue, field) => {
        setSelectedUserData(prevUserData => ({
            ...prevUserData,
            [field]: newValue
        }));
        setIsModified(true)
    };


    const [assigneeName, setAssigneeName] = useState('');
    const [assignees, setAssignees] = useState();
    const handleSelectAssignee = (id, photo, name) => { // Receive name directly instead of event
        setSelectAsignee(id);
        console.log('name:', photo);
        setAssigneeName(name)
        setAssignees(photo);
        setAssignee(null);
    };

    const handleAssigneeMenuClose = () => { // Receive name directly instead of event

        setAssignee(null);
    };
    const [filterData, setFilterData] = useState([]);
    const handleSearchMilestoneAssignee = (item) => {
        // Filter the data based on the search query
        const filteredData = userSearchResults.filter((name) =>
            name.name.toLowerCase().includes(item.toLowerCase())
        );
        setFilterData(filteredData);
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            useEffectFetch();
        }
    };
    const handleKeyPressnotification = (event) => {
        if (event.key === 'Enter') {
            GetallNotifications();
        }
    };
    const [formData, setFormData] = useState({
        user_id: '',
        org_id: '',
        projectName: '',
        agenda: '',
        description: '',
        assignTo: null,
        notificationTo: [],
        startDate: null,
        endDate: null,
        // status: '',
        documents: [],
        milestones: [],
    });

    const [errorStartDate, setErrorStartDate] = useState(false)
    const [errorEndDate, setErrorEndDate] = useState(false)
    const handleAddRedList = async () => {
        console.log('formData.startDate', formData.startDate)
        console.log('formData.enddate', formData.endDate)
        // if (formData.startDate == null) {
        //     setErrorStartDate(true)
        // }
        if (formData.endDate == null) {
            setErrorEndDate(true)
        }
        event.preventDefault();
        setIsLoadingMore(true);
        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            let assignToUserId = '';

            // Check if assignTo is an array
            if (Array.isArray(formData.assignTo)) {
                // Assuming formData.assignTo is an array of users
                // Extract user_id from the first user object in the array
                if (formData.assignTo.length > 0) {
                    assignToUserId = formData.assignTo[0].attendees_id;
                }
            } else if (formData.assignTo && typeof formData.assignTo === 'object') {
                // If assignTo is a single user object
                assignToUserId = formData.assignTo.attendees_id;
            }

            console.log('Assign To User ID:', assignToUserId);


            var myHeaders = new Headers();

            myHeaders.append("token", userToken);
            // const milestoneJSON = JSON.stringify(milestoneArray);
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', deepuser_id);
            formDataToSend.append('org_id', deeporg_id);
            formDataToSend.append('project_name', formData.projectName);
            formDataToSend.append('agenda', formData.agenda);
            formDataToSend.append('description', formData.description);
            formDataToSend.append('assignto', assignToUserId); // Append assignTo
            // formDataToSend.append('notificationto', notificationToUserIds); // Append notificationTo
            const notificationToUserIds = formData.notificationTo.map(user => user.attendees_id).join(',').split(',');
            notificationToUserIds.forEach(id => formDataToSend.append('notificationto[]', id)); // Append notificationTo
            formDataToSend.append('activity_type', selectedOption);
            formDataToSend.append('dept_id', selectedDepartment);
            formDataToSend.append('start_datetime', formData.startDate ? formData.startDate.toISOString() : '');
            formDataToSend.append('end_datetime', formData.endDate.toISOString());
            ;
            formData.documents.forEach(file => formDataToSend.append('docs[]', file));
            formDataToSend.append('milestone', JSON.stringify(milestones));
            formDataToSend.append('docs', JSON.stringify(uploadFiles));
            formDataToSend.append('doc_path', Docpath);
            // Add selected user from Autocomplete


            // Make API call
            const response = await fetch(`${window.constants.api_url}/cmdadmin/createnewredlistactivity`, {
                method: 'POST',
                headers: myHeaders,
                body: formDataToSend,
                redirect: 'follow'
            });
            console.log(response);

            const responseData = await response.json();
            if (responseData.code === "200") {
                setIsModified(false)
                setAddNewRedList(false);
                setIsLoadingMore(false);
                useEffectFetch();
                setSelectedDepartment('')
                setUploadFiles([])
                setFormData({
                    // Reset formData state to initial values
                    user_id: '',
                    org_id: '',
                    projectName: '',
                    agenda: '',
                    description: '',
                    assignTo: null,
                    notificationTo: [],
                    startDate: null,
                    endDate: null,
                    documents: [],
                    milestones: []
                });
                setMilestones([]);
            } else {
                setIsLoadingMore(false);
                console.error(responseData.message);
            }
        } catch (error) {
            console.error('Error:', error);
            setIsLoadingMore(false);
        }
    };
    const [selectedFile, setSelectedFile] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadFiles, setUploadFiles] = useState([]);


    const handleFileChange = async (event) => {
        const uploadedFiles = Array.from(event.target.files);

        const newFiles = uploadedFiles.map(file => ({
            doc_type: getFileType(file.name),
            doc_name: file.name,
            doc_size: file.size.toString(),
            doc_path: Docpath,
            uploadstatus: 'new', // Initially set upload status to 'loading'
            percentage: '0', // Initial value for percentage
            message: ''
        }));

        setUploadFiles(prevFiles => [...prevFiles, ...newFiles]);

        await Promise.all(uploadedFiles.map(async (file) => {
            const fileType = getFileType(file.name);

            // Make a request to your backend to get the presigned URL for S3 upload
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            // get presigned url
            const presignedUrlResponse = await fetch(`${window.constants.api_url}/cmdadmin/initiate-redlist-upload`, {
                method: 'POST',
                body: JSON.stringify({
                    "user_id": deepuser_id,
                    "org_id": deeporg_id,
                    "activity_id": "0",
                    "activity_type": selectedOption,
                    "doc_path": Docpath,
                    "docs": [file.name]
                }
                ),
                headers: {
                    'Content-Type': 'application/json',
                    'token': userToken
                },
            });

            if (!presignedUrlResponse.ok) {
                throw new Error('Failed to get presigned URL from the server');
            }

            const presignedUrlData = await presignedUrlResponse.json();

            if (!presignedUrlData.data[0].presignedUrl) {
                return {
                    doc_type: fileType,
                    doc_name: file.name,
                    doc_size: file.size.toString(),
                    doc_path: Docpath,
                    uploadstatus: 'failed', // Update upload status to 'error'
                    percentage: '0', // Initial value for percentage
                    message: presignedUrlData.data[0].message,
                };
            }

            // Upload the file to S3 using the presigned URL
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', presignedUrlData.data[0].presignedUrl);
            xhr.upload.onprogress = (event) => {
                const percentage = (event.loaded / event.total) * 100;
                // Update the percentage and upload status for this file
                setUploadFiles(prevFiles => {
                    const updatedFiles = prevFiles.map(prevFile => {
                        if (prevFile.doc_name === file.name) {
                            return {
                                ...prevFile,
                                percentage: percentage.toFixed(2),
                                uploadstatus: percentage === 100 ? 'complete' : 'processing',
                            };
                        }
                        return prevFile;
                    });
                    return updatedFiles;
                });
            };

            xhr.send(file);
        }));
        setIsModified(true)
    };

    // get file size
    const formatBytes = (bytes) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };


    // get file type
    function getFileType(fileName) {
        const extension = fileName.split('.').pop().toLowerCase();
        if (extension === 'pdf') {
            return 'pdf';
        } else if (extension === 'doc' || extension === 'docx') {
            return 'doc';
        } else if (extension === 'xls' || extension === 'xlsx') {
            return 'excel';
        } else {
            return 'other';
        }
    }
    const handleDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        const updatedDocuments = [...selectedFiles];
        for (let i = 0; i < files.length; i++) {
            updatedDocuments.push(files[i]);
        }
        setSelectedFiles(updatedDocuments);
        if (files.length > 0) {
            setSelectedFile(true);
        } else {
            setSelectedFile(false);
        }
        // Update form data with the updated documents
        setFormData({ ...formData, documents: updatedDocuments });
    };
    const handleUpdateDrop = (event) => {
        event.preventDefault(); // Prevent default behavior of dropping files (e.g., opening them in the browser)

        const files = event.dataTransfer.files; // Get the dropped files
        handleFiles(files); // Handle the dropped files
    };

    const handleFiles = (files) => {
        let documentNames = selectedUserData.document || ''; // Initialize with existing document names or an empty string
        for (let i = 0; i < files.length; i++) {
            documentNames += (documentNames ? ',' : '') + files[i].name; // Append file name with comma separator
        }
        setSelectedUserData(prevState => ({
            ...prevState,
            document: documentNames
        }));
        if (files.length > 0) {
            setSelectedFile(true);
        } else {
            setSelectedFile(false);
        }
    };

    const handleUpdateFileChange = async (redlistid, docpath, activityType) => {
        try {
            const uploadedFiles = Array.from(event.target.files);

            // Create an array of new files with initial upload status
            const newFiles = uploadedFiles.map(file => ({
                doc_type: getFileType(file.name),
                doc_name: file.name,
                doc_size: file.size.toString(),
                doc_path: docpath,
                uploadstatus: 'new', // Initially set upload status to 'loading'
                percentage: '0', // Initial value for percentage
                message: ''
            }));
            setUploadFiles(prevFiles => [...prevFiles, ...newFiles]);
            // Append the new files to the selectedUserData.document state
            setSelectedUserData(prevUserData => ({
                ...prevUserData,
                document: [...prevUserData.document, ...newFiles]
            }));
            // console.log(electedUserData.document)
            await Promise.all(uploadedFiles.map(async (file) => {
                const fileType = getFileType(file.name);

                // Make a request to your backend to get the presigned URL for S3 upload
                const userData = await localStorage.getItem("userdata");
                const userDataObj = JSON.parse(userData);
                if (!userDataObj || !userDataObj.token) {
                    console.error("User token not found in AsyncStorage.");
                    return;
                }
                const userToken = userDataObj.token;
                const deepuser_id = userDataObj.user_id;
                const deeporg_id = userDataObj.org_id;

                // get presigned url
                const presignedUrlResponse = await fetch(`${window.constants.api_url}/cmdadmin/initiate-redlist-upload`, {
                    method: 'POST',
                    body: JSON.stringify({
                        "user_id": deepuser_id,
                        "org_id": deeporg_id,
                        "activity_id": redlistid,
                        // "department": department,
                        "activity_type": activityType,
                        "doc_path": docpath,
                        "docs": [file.name]
                    }
                    ),
                    headers: {
                        'Content-Type': 'application/json',
                        'token': userToken
                    },
                });

                if (!presignedUrlResponse.ok) {
                    throw new Error('Failed to get presigned URL from the server');
                }

                const presignedUrlData = await presignedUrlResponse.json();

                if (!presignedUrlData.data[0].presignedUrl) {
                    console.log(presignedUrlData.data[0].presignedUrl)
                    const failedFile = {
                        doc_type: fileType,
                        doc_name: file.name,
                        doc_size: file.size.toString(),
                        doc_path: docpath,
                        uploadstatus: 'failed', // Update upload status to 'failed'
                        percentage: '0', // Initial value for percentage
                        message: presignedUrlData.data[0].message,
                    };
                    // Update selectedUserData.document with the failed file
                    setSelectedUserData(prevUserData => ({
                        ...prevUserData,
                        document: [...prevUserData.document, failedFile]
                    }));
                    return; // Exit the function execution for this file
                }

                // Upload the file to S3 using the presigned URL
                const xhr = new XMLHttpRequest();
                xhr.open('PUT', presignedUrlData.data[0].presignedUrl);
                xhr.upload.onprogress = (event) => {
                    const percentage = (event.loaded / event.total) * 100;
                    // Update the percentage and upload status for this file
                    setSelectedUserData(prevUserData => ({
                        ...prevUserData,
                        document: prevUserData.document.map(prevFile => {
                            if (prevFile.doc_name === file.name) {
                                return {
                                    ...prevFile,
                                    percentage: percentage.toFixed(2),
                                    uploadstatus: percentage === 100 ? 'complete' : 'processing',
                                };
                            }
                            return prevFile;
                        })
                    }));
                };

                xhr.send(file);
            }));
        } catch (error) {
            console.error("Error handling file upload:", error);
            // Handle error, show a message or log it
        }
        setIsModified(true)
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };
    const handleDragupdateOver = (event) => {
        event.preventDefault();
    };
    const handleStartDateChange = (date) => {
        setFormData({ ...formData, startDate: date });
        // setErrorStartDate(false)
        setIsModified(true)
    };

    const handleEndDateChange = (date) => {
        setFormData({ ...formData, endDate: date });
        setErrorEndDate(false)
        setIsModified(true)
    };


    const [tempMeetingDate, setTempMeetingDate] = React.useState(null);
    const [startDate, setstartDate] = React.useState(null);
    // Parse the date string before setting it as the initial value
    React.useEffect(() => {
        if (selectedUserData.end_datetime) {
            setTempMeetingDate(dayjs(selectedUserData.end_datetime));
        }
    }, [selectedUserData.end_datetime]);
    React.useEffect(() => {
        if (selectedUserData.start_datetime) {
            setstartDate(dayjs(selectedUserData.start_datetime));
        }
    }, [selectedUserData.end_datetime]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const handleUserSelection = (event, newValue) => {
        setSelectedUsers(newValue); // Update selected users
        console.log(newValue);
        setIsModified(true)
    };
    const handleUpdateRedList = async (event) => {
        event.preventDefault();

        setButtonLoading(true);
        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            let assignToUserId = '';

            // Check if assignTo is an array
            if (Array.isArray(selectedUserData.assignto)) {
                // Assuming formData.assignTo is an array of users
                // Extract user_id from the first user object in the array
                if (selectedUserData.assignto.length > 0) {
                    assignToUserId = selectedUserData.assignto[0].attendees_id;
                }
            } else if (selectedUserData.assignto && typeof selectedUserData.assignto === 'object') {
                // If assignTo is a single user object
                assignToUserId = selectedUserData.assignto.attendees_id;
            }

            console.log('Assign To User ID:', selectedUserData.assignto);

            var myHeaders = new Headers();
            const userIds = selectedUsers.map(user => user.attendees_id);
            myHeaders.append("token", userToken);

            const formDataToSend = new FormData();
            formDataToSend.append('user_id', deepuser_id);
            formDataToSend.append('org_id', deeporg_id);
            formDataToSend.append('project_name', selectedUserData.project_name);
            formDataToSend.append('agenda', selectedUserData.agenda);

            formDataToSend.append('redlist_id', selectedUserData.redlist_id);
            formDataToSend.append('activity_type', selectedUserData.activity_type);
            formDataToSend.append('dept_id', selectedUserData.dept_id);
            formDataToSend.append('description', selectedUserData.description);
            formDataToSend.append('assignto', selectedUserData.assignto);
            formDataToSend.append('status', selectedUserData.status);

            formDataToSend.append('notificationto[]', userIds);
            formDataToSend.append('start_datetime', startDate ? startDate.toISOString() : '');
            formDataToSend.append('end_datetime', tempMeetingDate.toISOString());
            formDataToSend.append('milestone', JSON.stringify(selectedUserData.milestone));
            formDataToSend.append('docs', JSON.stringify(uploadFiles));

            //   formDataToSend.append('docs[]',);
            const response = await fetch(`${window.constants.api_url}/cmdadmin/editredlistactivity`, {
                method: 'POST',
                headers: myHeaders,
                body: formDataToSend,
                redirect: 'follow'
            });

            console.log(response);

            const responseData = await response.json();
            if (responseData.code === "200") {
                setIsModified(false)
                setButtonLoading(false);
                setUpdateRedList(false);
                closeViewRedListModal();
                setUploadFiles([])
                useEffectFetch();
                setSelectedUserData({
                    // Reset selectedUserData state to initial values
                    projectName: '',
                    agenda: '',
                    description: '',
                    assignto: null,
                    notificationto: [],
                    startDate: null,
                    endDate: null,
                    document: [],
                    milestone: []
                });
            } else {
                setButtonLoading(false);
                console.error(responseData.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        console.log(newValue)
    };
    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handleEditOptionChange = (event) => {
        const newValue = event.target.value;
        setSelectedUserData(prevUserData => ({
            ...prevUserData,
            activity_type: newValue
        }));
        setIsModified(true)
    };
    const [selectedDepartment, setSelectedDepartment] = useState('');

    const handleDepartmentChange = (event) => {
        setSelectedDepartment(event.target.value);
    };
    const handleEditDepartmentChange = (event) => {
        const newValue = event.target.value;
        setSelectedUserData(prevUserData => ({
            ...prevUserData,
            department: newValue
        }));
        setIsModified(true)
    };
    const [LoadingviewStates, setLoadingviewStates] = useState([]);
    const handleLoading = (docindex) => {
        setLoadingviewStates(prevStates => {
            const newState = [...prevStates];
            newState[docindex] = true;
            return newState;
        });
    };
    const RedListDocviewPreview = async (docindex, docid, docname, docpath, redlistid, activitytype) => {
        handleLoading(docindex);

        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            // Use the API helper function to fetch query history data
            const data = await RedListDocument(deepuser_id, deeporg_id, redlistid, docname, docid, docpath, activitytype, userToken);

            if (data.code === "200") {
                setLoadingviewStates(prevStates => {
                    const newState = [...prevStates];
                    newState[docindex] = false; // Set loading state to false after successful fetch
                    return newState;
                });
                console.log('docname');
                const doclink = data.data[0].doclink;
                console.log(doclink);
                console.log('docname', docname); // Make sure docname is defined somewhere
                if (docname && docname.endsWith('.pdf')) {
                    setPdfDialogOpen(true); // Open the PDF dialog
                } else {
                    console.log('doclink', doclink);
                    window.open(doclink, '_blank'); // Open the PDF in a new window
                }

                setPdfUrl(doclink); // Set the PDF URL

            } else {
                console.log(data.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error, show a message or log it
            // seterrorSnackbar(true);
        }



    };
    const RedListDocPreview = async (docIndex, docid, docname, docpath, redlistid, activitytype) => {

        setLoadingStates(prevStates => {
            const newState = [...prevStates];
            if (!newState[docIndex]) newState[docIndex] = {};
            newState[docIndex][docid] = true; // Set loading state to false for the specific document
            return newState;
        });

        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            // Use the API helper function to fetch query history data
            const data = await RedListDocument(deepuser_id, deeporg_id, redlistid, docname, docid, docpath, activitytype, userToken);

            if (data.code === "200") {

                setLoadingStates(prevStates => {
                    const newState = [...prevStates];
                    if (!newState[docIndex]) newState[docIndex] = {};
                    newState[docIndex][docid] = false; // Set loading state to false for the specific document
                    return newState;
                });
                const doclink = data.data[0].doclink;

                if (docname.endsWith('.pdf')) {
                    setPdfDialogOpen(true); // Open the PDF dialog
                }
                else {
                    console.log('doclink', doclink);
                    window.open(doclink, '_blank');
                }
                setPdfUrl(doclink); // Set the PDF URL

            } else {
                console.log(data.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error, show a message or log it
            // seterrorSnackbar(true);
        }



    };

    const [notificationdata, Setnotificationdata] = useState([]);
    const [generalInfo, SetgeneralInfo] = useState([]);
    const [countnotification, setcountnotification] = useState(0);
    const [apiCalled, setApiCalled] = useState(false);
    const [editorContent, setEditorContent] = useState('');
    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setEditorContent(data);
    };

    const GetallNotifications = async () => {

        setisLoadingnotification(true)
        event.preventDefault();
        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            // Use the API helper function to fetch query history data
            const data = await GetallnotificationCmdLink(deepuser_id, deeporg_id, notificationSearchText, dateList, fromDate, toDate, userToken);

            if (data.code === "200") {

                setisLoadingnotification(false)
                // console.log(data.data);
                setcountnotification(data.total_count);
                Setnotificationdata
                    (data.data);

            } else {
                console.log(data.data);

                console.log(data.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error, show a message or log it
            // seterrorSnackbar(true);
        }



    };
    const GetallGeneralInfo
        = async () => {



            try {
                const userData = await localStorage.getItem("userdata");
                const userDataObj = JSON.parse(userData);
                if (!userDataObj || !userDataObj.token) {
                    console.error("User token not found in AsyncStorage.");
                    return;
                }
                const userToken = userDataObj.token;
                const deepuser_id = userDataObj.user_id;
                const deeporg_id = userDataObj.org_id;
                const dept_id = userDataObj.dept_id;
                // Use the API helper function to fetch query history data
                const data = await getGeneralInfoTab(deepuser_id, deeporg_id, dept_id, userToken);

                if (data.code === "200") {

                    console.log(data.data);

                    SetgeneralInfo(data.content);
                } else {
                    console.log(data.data);

                    console.log(data.data);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                // Handle error, show a message or log it
                // seterrorSnackbar(true);
            }



        };
    const [editerLoader, setediterLoader] = useState(false);
    const AddGeneralInfoediter
        = async () => {

            setediterLoader(true)

            try {
                const userData = await localStorage.getItem("userdata");
                const userDataObj = JSON.parse(userData);
                if (!userDataObj || !userDataObj.token) {
                    console.error("User token not found in AsyncStorage.");
                    return;
                }
                const userToken = userDataObj.token;
                const deepuser_id = userDataObj.user_id;
                const deeporg_id = userDataObj.org_id;
                const dept_id = userDataObj.dept_id;
                // Use the API helper function to fetch query history data
                const data = await AddGeneralInfo(deepuser_id, deeporg_id, dept_id, editorContent, userToken);

                if (data.code === "200") {
                    setediterLoader(false)
                    console.log(data.data);
                    GetallGeneralInfo();

                } else {
                    console.log(data.data);

                    console.log(data.data);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                // Handle error, show a message or log it
                // seterrorSnackbar(true);
            }



        };
    const [totalpopupcount, settotalpopupcount] = useState(0);

    // const GetTotalnotificationCount = async () => {
    //     try {
    //         const userData = await localStorage.getItem("userdata");
    //         const userDataObj = JSON.parse(userData);
    //         if (!userDataObj || !userDataObj.token) {
    //             console.error("User token not found in AsyncStorage.");
    //             return;
    //         }
    //         const userToken = userDataObj.token;
    //         const deepuser_id = userDataObj.user_id;
    //         const deeporg_id = userDataObj.org_id;
    //         const dept_id = userDataObj.dept_id;
    //         // Use the API helper function to fetch query history data
    //         const data = await GetNotificationCount(deepuser_id, deeporg_id, userToken);

    //         if (data.code === "200") {

    //             console.log(data.notification_count);
    //             settotalpopupcount(data.notification_count);

    //         } else {
    //             console.log(data.data);

    //             console.log(data.data);
    //         }
    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //         // Handle error, show a message or log it
    //         // seterrorSnackbar(true);
    //     }



    // };

    const GetChangestatusNotification = async () => {
        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;
            const dept_id = userDataObj.dept_id;
            // Use the API helper function to fetch query history data
            const data = await UpdateNotification(deepuser_id, deeporg_id, userToken);

            if (data.code === "200") {

                console.log(data.notification_count);
                settotalpopupcount(data.notification_count);

            } else {
                console.log(data.data);

                console.log(data.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error, show a message or log it
            // seterrorSnackbar(true);
        }



    };
    useEffect(() => {
        if (tabValue === 0) {
            GetallGeneralInfo();
        }
    }, [tabValue]);
    const updateNotifications = async () => {

        await GetChangestatusNotification();
    };
    // useEffect(() => {


    //     GetTotalnotificationCount();// Call the function inside useEffect directly
    // }, []);
    const tabStyle = (isActive) => ({
        textDecoration: 'none',
        textTransform: 'none',
        fontWeight: 'bold',
        marginRight: '10px',
        backgroundColor: isActive ? '#D8E2FF' : 'inherit',
        borderRadius: "10px 10px 0px 0px",
        borderBottom: isActive ? "2px solid #005AC1" : 'none',
        color: isActive ? "#005AC1" : 'inherit',
    });

    const [anchorStatus, setAnchorStatus] = React.useState(null);
    const [statusbuttonText, setStatusButtonText] = useState('Status');
    const [catagoryType, setCatagoryType] = useState('');
    const [catagoryStatus, setCatagoryStatus] = useState('');
    const handleStatusClick = (event) => {
        setAnchorStatus(event.currentTarget);
    };
    const handleStatusSelect = async (selectedValue, value) => {
        console.log('selectedValue', value)
        setCatagoryStatus(value)
        setAnchorStatus(null);
        if (selectedValue) {
            setStatusButtonText(selectedValue);
            // You can set the selected value in a state here if needed
        }
        setIsLoading(true);
        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            setIndex(0);

            // Use the API helper function to fetch query history data
            const data = await getAllRedList(deepuser_id, deeporg_id, searchText, catagoryType, value, dateList, fromDate, toDate, userToken);

            if (data.code === "200") {
                setRedListData(data.data);
                setIsLoading(false);
                setTotalrec(data.total_count)
                console.log(data.data);
            } else {
                setFullLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error, show a message or log it
            // seterrorSnackbar(true);
        }
    }
    const handleStatusClose = () => {
        setAnchorStatus(null);
    };

    const openStatus = Boolean(anchorStatus);
    const idStatus = openStatus ? 'simple-popover' : undefined;

    const [typebuttonText, setTypeButtonText] = useState('Type');
    const [anchorType, setAnchorType] = React.useState(null);

    const handleTypeClick = (event) => {
        setAnchorType(event.currentTarget);
    };
    const handleTypeSelect = async (selectedValue, value) => {
        console.log('selectedValue', value)
        setCatagoryType(value)
        setAnchorType(null);
        if (selectedValue) {
            setTypeButtonText(selectedValue);
            // You can set the selected value in a state here if needed
        }
        setIsLoading(true);
        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            setIndex(0);

            // Use the API helper function to fetch query history data
            const data = await getAllRedList(deepuser_id, deeporg_id, searchText, value, catagoryStatus, dateList, fromDate, toDate, userToken);

            if (data.code === "200") {
                setRedListData(data.data);
                setIsLoading(false);
                setTotalrec(data.total_count)
                console.log(data.data);
            } else {
                setFullLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error, show a message or log it
            // seterrorSnackbar(true);
        }
    }
    const handleTypeClose = () => {

        setAnchorType(null);

    };

    const openType = Boolean(anchorType);
    const idType = openType ? 'simple-popover' : undefined;

    const [closeConfirmation, setCloseConfirmation] = useState(false);

    const openCloseConfirm = () => {
        setCloseConfirmation(true)
    }
    const closeCloseConfirm = () => {
        setCloseConfirmation(false)
    }
    const handleCloseConfirm = () => {
        setAddNewRedList(false);
        setUpdateRedList(false);
        setCloseConfirmation(false)
        setIsModified(false)
    }

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    backgroundColor: "white",
                }}
            >

                <Appbar />

            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                            borderRight: '0px',
                        },
                    }}
                >
                    <Sidebar updateNotifications={updateNotifications} />
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: "none", sm: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                            borderRight: '0px',
                        },
                    }}
                    open
                >
                    <Sidebar updateNotifications={updateNotifications} />
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,

                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    background: "#EFF1F3",
                    minHeight: "100vh",
                }}
            >

                <Box sx={{ marginTop: '12vh' }}>
                    <Box className="department_text" sx={{ marginLeft: '3vw', marginBottom: '2vh' }} display='flex' flexGrow={1}>
                        General Info
                    </Box>


                    <TabPanel value={1} index={1}>
                        <Box >
                            <CKEditor
                                
                                editor={ClassicEditor}
                                data={generalInfo}
                                config={{
                                    fontFamily: {
                                        options: [
                                            'default', // Default font
                                            'Arial, Helvetica, sans-serif', // Additional fonts
                                            'Courier New, Courier, monospace',
                                            'Georgia, serif',
                                            'Lucida Sans Unicode, Lucida Grande, sans-serif',
                                            'Tahoma, Geneva, sans-serif',
                                            'Times New Roman, Times, serif',
                                            'Trebuchet MS, Helvetica, sans-serif',
                                            'Verdana, Geneva, sans-serif'
                                        ]
                                    },
                                    minHeight:'250px',
                                    toolbar: {
                                        items: [
                                            'heading',
                                            '|',
                                            'bold',
                                            'italic',
                                            // 'underline',
                                            'link',
                                            '|',
                                            'bulletedList',
                                            'numberedList',
                                            '|',
                                            'outdent', // Retaining decrease indent option
                                            'indent', // Retaining increase indent option
                                            '|',
                                            'blockQuote', // Retaining block quote option
                                            '|',
                                            'insertTable', // Keeping the table builder option
                                            '|',
                                            'mediaEmbed', // Keeping the video link option
                                            '|',
                                            'undo',
                                            'redo'
                                        ]
                                    },

                                }}
                                onChange={handleEditorChange}

                            />
                        </Box>
                        <Box style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '6px', }}>
                            <Button
                                onClick={AddGeneralInfoediter}
                                disabled={editerLoader}
                                style={{
                                    borderColor: '#D0D5DD',
                                    borderRadius: '8px',
                                    fontSize: '14px',
                                    textTransform: 'none',
                                    padding: '7px',
                                    fontWeight: 'bold',
                                    position: 'relative'
                                }}
                                variant="contained"
                                color="primary"
                            >
                                {editerLoader && <CircularProgress size={20} style={{ marginRight: '8px' }} />}
                                Submit
                            </Button>
                        </Box>
                    </TabPanel>



                </Box>
                <Toolbar />

            </Box>

            <Dialog open={addNewRedList} onClose={closeAddRedListModal}

                sx={{
                    "& .MuiDialog-paper": {
                        borderRadius: "10px",
                    },
                }}
            >
                <form onSubmit={handleAddRedList}>
                    <DialogTitle sx={{ padding: '0px 5px ' }}>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '15px 15px 0px 15px' }}>
                            <Box sx={{ display: 'flex', margin: '10px 0px 0px 10px' }}>
                                <Box>
                                    <AddCardOutlinedIcon sx={{}} />
                                </Box>
                                <Typography sx={{ fontWeight: 'bold', marginLeft: '10px' }}>Create New Activity</Typography>
                            </Box>
                            <Box>
                                <IconButton onClick={closeAddRedListModal}> <CloseIcon /></IconButton>

                            </Box>
                        </Box>

                    </DialogTitle>
                    <DialogContent sx={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                        <Box >
                            <Box sx={{ marginTop: '20px' }}>

                                {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Activity Type   <span style={{ color: 'red' }}>*</span></Typography></Box> */}
                                <TextField
                                    select
                                    required label='Department'

                                    value={selectedDepartment}
                                    onChange={handleDepartmentChange}
                                    size="small"
                                    fullWidth
                                >

                                    {Array.isArray(departmentSearchResults) && departmentSearchResults.map((item, index) => (
                                        <MenuItem

                                            value={item.dept_id}
                                        >{item.dept_name}</MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box sx={{ marginTop: '20px' }}>

                                {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Activity Type   <span style={{ color: 'red' }}>*</span></Typography></Box> */}
                                <TextField
                                    select
                                    required label='Activity Type '
                                    value={selectedOption}
                                    onChange={handleOptionChange}
                                    size="small"
                                    fullWidth
                                >

                                    <MenuItem value="daytoday">Day to day</MenuItem>
                                    <MenuItem value="critical">Critical</MenuItem>
                                </TextField>
                            </Box>
                            <Box sx={{ marginTop: '20px' }}>

                                {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Project Name  <span style={{ color: 'red' }}>*</span></Typography></Box> */}
                                <Box><TextField required label='Project Name' placeholder="Add Project" onChange={handleInputChangeee} value={formData.projectName} sx={{ width: '100%' }} size="small" name="projectName" /></Box>
                            </Box>
                            <Box sx={{ marginTop: '20px' }}>
                                {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Agenda  <span style={{ color: 'red' }}>*</span></Typography></Box> */}
                                <Box><TextField required label='Agenda' placeholder="Event Agenda" onChange={handleInputChangeee} value={formData.agenda} name="agenda" sx={{ width: '100%' }} size="small" /></Box>
                            </Box>
                            <Box sx={{ marginTop: '20px' }}>
                                {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Description  <span style={{ color: 'red' }}>*</span></Typography></Box> */}
                                <Box><TextField multiline required label='Description' rows={3} onChange={handleInputChangeee} value={formData.description} name="description" placeholder="Add event description" sx={{ width: '100%' }} size="small" /></Box>
                            </Box>
                            <Box sx={{ marginTop: '20px' }}>
                                {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Assign To <span style={{ color: 'red' }}>*</span></Typography></Box> */}

                                <Box>
                                    {Array.isArray(userSearchResults) &&
                                        <Autocomplete

                                            // multiple
                                            id="tags-outlined"
                                            options={userSearchResults}
                                            getOptionLabel={getOptionLabel}
                                            renderOption={renderOption}
                                            filterOptions={filterOptions}
                                            onChange={(event, newValue) => { handleMultipleSelectChange(event, newValue, 'assignTo'); setIsModified(true) }}
                                            value={formData.assignTo}
                                            filterSelectedOptions

                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Select User"
                                                    size="small"
                                                    required label='Assign To'
                                                />
                                            )}
                                        />}
                                </Box>
                            </Box>
                            <Box sx={{ marginTop: '20px' }}>
                                {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Notification To <span style={{ color: 'red' }}>*</span></Typography></Box> */}

                                <Box>
                                    {Array.isArray(userSearchResults) &&
                                        <Autocomplete

                                            multiple
                                            id="tags-outlined"
                                            // options={userSearchResults.filter(option => !accessUser.includes(option))}
                                            // options={userSearchResults.filter(option => !accessUser.some(user => user.user_id === option.user_id))}
                                            options={userSearchResults}
                                            getOptionLabel={getOptionLabel}
                                            renderOption={renderOption}
                                            filterOptions={filterOptions}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip
                                                        key={index}
                                                        avatar={<img src={option.photo} alt={option.name} style={{ width: '24px', height: '24px', borderRadius: '50%' }} />}
                                                        label={option.name}
                                                        {...getTagProps({ index })}
                                                    />
                                                ))
                                            }
                                            onChange={(event, newValue) => { handleMultipleSelectChange(event, newValue, 'notificationTo'); setIsModified(true) }}
                                            value={formData.notificationTo}
                                            filterSelectedOptions
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    // label="Select User"
                                                    placeholder="Select User"
                                                    size="small"
                                                    label='Notification To'
                                                />
                                            )}


                                        />}
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '0px 0px 0px 0px' }}>
                                <Box sx={{ marginRight: '5px' }}>
                                    {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey' }}> Activity Start <span style={{ color: 'red' }}>*</span></Typography></Box> */}
                                    <Box>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
                                            <DemoContainer components={['DatePicker']} size="small">
                                                <Box sx={{ minWidth: '100px' }}>
                                                    <DatePicker
                                                        required label='Activity Start '
                                                        sx={{ marginTop: '20px' }}
                                                        value={formData.startDate}
                                                        onChange={handleStartDateChange}
                                                        slotProps={{
                                                            textField: { size: 'small', format: 'DD/MM/YYYY' },
                                                            // field: { error: errorStartDate ? true : false, },
                                                        }}
                                                    />
                                                </Box>
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Box>
                                </Box>
                                <Box sx={{ marginLeft: '5px' }}>
                                    {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey' }}>Activity End <span style={{ color: 'red' }}>*</span></Typography></Box> */}
                                    <Box>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                                <Box sx={{ minWidth: '100px' }}>
                                                    <DatePicker value={formData.endDate}

                                                        sx={{ marginTop: '20px' }}
                                                        onChange={handleEndDateChange}
                                                        slotProps={{
                                                            textField: { size: 'small', format: 'DD/MM/YYYY' },
                                                            field: { error: errorEndDate ? true : false, },
                                                        }}
                                                        label='Activity End *' required
                                                    />
                                                </Box>
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Box>
                                </Box>
                            </Box>
                            {/* <Box>
                            <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey' }}>Status</Typography></Box>
                            <Box>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        defaultValue="new"
                                    >
                                        <FormControlLabel value="new" control={<Radio />} label="New" />
                                        <FormControlLabel value="inprogress" control={<Radio />} label="InProgress" />
                                        <FormControlLabel value="complete" control={<Radio />} label="Complete" />
                                        <FormControlLabel value="hold" control={<Radio />} label="Hold" />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Box> */}
                            <Box>

                                <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Manage Milestone</Typography></Box>
                                <Box>
                                    {updatedMilestoneState && milestones.map((milestone, index) => (
                                        <div key={index}>
                                            <Box sx={{ margin: '10px 0px', padding: '3px', borderRadius: '8px', background: 'var(--Colors-Background-bg-secondary, #F9FAFB)' }} >
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Box sx={{ display: 'flex' }}>
                                                        <Checkbox
                                                            checked={milestone.status === 'complete'} // Check if status is completed
                                                            onChange={(e) => {
                                                                // Update the status of the milestone based on the checkbox
                                                                const newMilestones = [...milestones];
                                                                newMilestones[index].status = e.target.checked ? 'complete' : 'new';
                                                                setMilestones(newMilestones);
                                                                setIsModified(true)
                                                            }}

                                                        />
                                                        <Typography sx={{ marginTop: '10px' }}>{milestone.milestone_description}</Typography>
                                                    </Box>
                                                    <Box sx={{ display: 'flex' }}>
                                                        <Box sx={{ marginRight: '5px' }}>
                                                            <Avatar sx={{ width: 30, height: 30, top: 5 }} alt="User Avatar" src={milestone.assign_to_pic} title={milestone.assign_to_Name} />

                                                        </Box>
                                                        <Box sx={{ margin: '10px 10px' }}> {milestone.due_date}</Box>
                                                        <Box>
                                                            <IconButton onClick={() => cancelMilestone(index)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </div>
                                    ))}

                                    {addMilestoneState &&
                                        <Box>
                                            <Box>
                                                <TextField sx={{ width: '100%', marginBottom: '10px' }}
                                                    value={milestoneName}
                                                    onChange={(e) => { setMilestoneName(e.target.value); setIsModified(true) }}
                                                    size="small"
                                                />
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Box sx={{ display: 'flex' }}>
                                                    <Box>
                                                        <Button variant='contained' style={{ borderRadius: '8px', fontSize: '10px', textTransform: 'none', padding: '8px', fontWeight: 'bold' }} onClick={updateMilestone} >Add </Button>
                                                    </Box>
                                                    <Box>
                                                        <Button variant='none' style={{ fontSize: '10px', textTransform: 'none', padding: '8px', fontWeight: 'bold' }} color="primary" onClick={cancelMileStone} >Cancel</Button>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ display: 'flex' }}>
                                                    <Box sx={{ marginRight: '5px' }}>
                                                        <IconButton variant='none'
                                                            title='Asignee'
                                                            style={{ border: selectAsignee == null ? '1px dotted grey' : '' }}
                                                            //  style={{ borderColor: 'grey', color: 'grey', borderRadius: '8px', fontSize: '10px', textTransform: 'none', padding: '8px', fontWeight: 'bold' }}
                                                            aria-controls={openAssignee ? 'basic-menu' : undefined}
                                                            aria-haspopup="true"
                                                            aria-expanded={openAssignee ? 'true' : undefined}
                                                            sx={{ backgroundColor: selectAsignee != null ? '#D8E2FF' : '' }}
                                                            onClick={handleClick} >
                                                            {selectAsignee == null ?
                                                                <PersonAddAltOutlinedIcon sx={{ height: '20px', width: '20px' }} />
                                                                :
                                                                <Avatar src={assignees} title={assigneeName} sx={{ height: '20px', width: '20px' }} />
                                                            }
                                                        </IconButton>
                                                    </Box>
                                                    <Box>

                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <ButtonDatePicker

                                                                value={dueDate}
                                                                onChange={(newValue) => { setDueDate(newValue); setIsModified(true) }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    }
                                    {addMilestoneState == false &&
                                        <Box>
                                            <Button variant='outlined' style={{ border: '1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD)', color: '#344054', borderRadius: '8px', fontSize: '10px', textTransform: 'none', padding: '8px', fontWeight: 'bold' }} onClick={addMileStone} ><AddOutlinedIcon sx={{ height: '20px', width: '20px', color: "#344054" }} />Add Milestone</Button>
                                        </Box>
                                    }
                                </Box>
                            </Box>
                            <Box>
                                <Box sx={{ border: '1px solid var(--Colors-Border-border-secondary, #EAECF0)', background: ' var(--Colors-Background-bg-primary, #FFF)', borderRadius: '12px', textAlign: 'center', fontSize: '14px', color: 'grey', margin: '10px 0px' }}>
                                    <Box>
                                        <label htmlFor="contained-button-file">
                                            <CloudUploadOutlinedIcon style={{ cursor: 'pointer', margin: '10px 0px 0px 0px ', }} />
                                        </label>
                                    </Box>
                                    <Box onDrop={handleDrop}
                                        onDragOver={handleDragOver}>
                                        <Typography>
                                            <label htmlFor="contained-button-file">
                                                <span style={{ color: '#005AC1', fontWeight: 'bold', cursor: 'pointer' }}>Click to upload </span>
                                            </label>
                                            {" "}or drag and drop
                                            <input
                                                accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
                                                id="contained-button-file"
                                                multiple
                                                type="file"
                                                onChange={handleFileChange}
                                                style={{ display: 'none' }} // Hide the input field
                                            />
                                            {selectedFile && (
                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <CheckCircleIcon style={{ color: 'green', }} />
                                                    <Typography>file selected   </Typography>
                                                </Box>
                                            )}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography sx={{ fontSize: '12px', marginBottom: '5px' }}>PDF, DOC, PPT and XLSX  files (Max 5 gb or 100 files)</Typography>
                                    </Box>
                                </Box>

                            </Box>

                        </Box>
                        {uploadFiles.map((file, index) => (
                            <Box key={index} sx={{ backgroundColor: '', border: '1px solid var(--_components-input-outlined-enabledBorder, rgba(0, 0, 0, 0.23))', borderRadius: '4px', margin: '10px 0px ', overflow: 'auto' }}>

                                <Box display='flex' justifyContent='space-between' alignItems='center' padding='10px'>
                                    <Box display='flex' alignItems='center'>
                                        <Box>
                                            {file.doc_name.endsWith('.pdf') && <DescriptionIcon sx={{ color: 'red', margin: '0 10px 0 0' }} />}
                                            {file.doc_name.endsWith('.xls') && <DescriptionIcon sx={{ color: 'green', margin: '0 10px 0 0' }} />}
                                            {file.doc_name.endsWith('.xlsx') && <DescriptionIcon sx={{ color: 'green', margin: '0 10px 0 0' }} />}
                                            {file.doc_name.endsWith('.doc') && <DescriptionIcon sx={{ color: 'blue', margin: '0 10px 0 0' }} />}
                                            {file.doc_name.endsWith('.docx') && <DescriptionIcon sx={{ color: 'blue', margin: '0 10px 0 0' }} />}
                                            {file.doc_name.endsWith('.ppt') && <DescriptionIcon sx={{ color: '#FFA500', margin: '0 10px 0 0' }} />}
                                            {file.doc_name.endsWith('.pptx') && <DescriptionIcon sx={{ color: '#FFA500', margin: '0 10px 0 0' }} />}
                                        </Box>
                                        <Box >

                                            <Box sx={{ maxWidth: '120px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px', }}> {file.doc_name} </Box>
                                            <Box sx={{ color: 'grey', fontSize: '12px', }}>{file.doc_size} </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex' }}>
                                        <Box>
                                            {
                                                file.uploadstatus === 'new' ? (
                                                    <CircularProgress size={20} sx={{ marginTop: '8px' }} />
                                                ) : file.uploadstatus === 'processing' ? (
                                                    <CircularProgress variant="determinate" value={file.percentage} size={20} sx={{ marginTop: '8px' }} />
                                                ) : file.uploadstatus === 'complete' ? (
                                                    <CheckIcon sx={{ color: '#000000', marginTop: '8px' }} />
                                                ) : file.uploadstatus === 'failed' ? (
                                                    <SmsFailedIcon />
                                                ) : null // Ensure to have a default value or null at the end if necessary
                                            }
                                        </Box>
                                        {file.uploadstatus === 'complete' && <IconButton onClick={() => handleRemoveFile(index)}><CloseIcon sx={{ padding: '0px' }} /></IconButton>}
                                    </Box>
                                </Box>

                            </Box>
                        ))}
                    </DialogContent>
                    <DialogActions sx={{ padding: '10px 23px 15px 23px' }}>
                        <Box sx={{ display: 'flex', width: '100%' }}>
                            <Box sx={{ width: '50%', marginRight: '5px' }}>
                                <Button variant="outlined" sx={{ textTransform: 'none', width: '100%', fontWeight: 'bold' }} onClick={closeAddRedListModal}>Cancel</Button>
                            </Box>
                            <Box sx={{ width: '50%', marginLeft: '5px' }}>
                                <Button type="submit" variant="contained" sx={{ textTransform: 'none', width: '100%', fontWeight: 'bold' }} disabled={isLoadingMore}>  {isLoadingMore && <CircularProgress size={18} sx={{ marginRight: '12px' }} />} {/* Loader inside the button */}Add Activity</Button>
                            </Box>
                        </Box>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog
                open={updateRedList} onClose={closeUpdateRedListModal}>
                <form onSubmit={handleUpdateRedList}>
                    <DialogTitle sx={{ padding: '0px 5px ' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '15px 15px 0px 15px' }}>
                            <Box sx={{ display: 'flex', margin: '10px 0px 0px 10px' }}>
                                <Box>
                                    <AddCardOutlinedIcon sx={{}} />
                                </Box>
                                <Typography sx={{ fontWeight: 'bold', marginLeft: '10px' }}>Update Activity</Typography>
                            </Box>
                            <Box>
                                <IconButton onClick={closeUpdateRedListModal}> <CloseIcon /></IconButton>

                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent sx={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                        <Box >
                            <Box sx={{ marginTop: '20px' }}>

                                {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Activity Type   <span style={{ color: 'red' }}>*</span></Typography></Box> */}
                                <TextField
                                    select
                                    required label='Department'

                                    value={selectedUserData.dept_id}
                                    onChange={handleEditDepartmentChange}
                                    size="small"
                                    fullWidth
                                >

                                    {Array.isArray(departmentSearchResults) && departmentSearchResults.map((item, index) => (
                                        <MenuItem

                                            value={item.dept_id}
                                        >{item.dept_name}</MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box sx={{ marginTop: '20px' }}>

                                {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Activity Type   <span style={{ color: 'red' }}>*</span></Typography></Box> */}
                                <TextField
                                    select
                                    required label='Project Name'

                                    value={selectedUserData.activity_type}
                                    onChange={handleEditOptionChange}
                                    size="small"
                                    fullWidth
                                >
                                    <MenuItem value="daytoday">Daytoday</MenuItem>
                                    <MenuItem value="critical">Critical</MenuItem>
                                </TextField>
                            </Box>

                            <Box sx={{ marginTop: '20px' }}>
                                {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '0px 0px 10px 0px' }}>Project Name</Typography></Box> */}
                                <Box><TextField onChange={(e) => handleEditChange(e.target.value, 'project_name')}
                                    value={selectedUserData.project_name}
                                    placeholder="Add Project" sx={{ width: '100%' }} size="small" required label='Project Name' /></Box>
                            </Box>
                            <Box sx={{ marginTop: '20px' }}>
                                {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '10px 0px' }}>Agenda</Typography></Box> */}
                                <Box><TextField onChange={(e) => handleEditChange(e.target.value, 'agenda')}
                                    value={selectedUserData.agenda} placeholder="Event Agenda" sx={{ width: '100%' }} size="small" required label='Agenda' /></Box>
                            </Box>
                            <Box sx={{ marginTop: '20px' }}>
                                {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '10px 0px' }}>Description *</Typography></Box> */}
                                <Box><TextField onChange={(e) => handleEditChange(e.target.value, 'description')}
                                    value={selectedUserData.description} multiline rows={3} placeholder="Add event description" sx={{ width: '100%' }} size="small" required label='Description' /></Box>
                            </Box>
                            <Box sx={{ marginTop: '20px' }}>
                                {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '10px 0px' }}>Assign To</Typography></Box> */}

                                <Box>
                                    {Array.isArray(userSearchResults) &&
                                        <Autocomplete
                                            sx={{ marginTop: '10px' }}
                                            // multiple
                                            id="tags-outlined"
                                            options={userSearchResults}
                                            getOptionLabel={getOptionLabel}
                                            value={userSearchResults.find(option => selectedUserData.assignto === option.attendees_id)}
                                            onChange={(event, newValue) => {
                                                // When the value changes, newValue contains the updated selected user
                                                // Update the selectedUserData.assignto value accordingly
                                                setSelectedUserData(prevUserData => ({
                                                    ...prevUserData,
                                                    assignto: newValue ? newValue.attendees_id : '' // If newValue exists, set the attendees_id, otherwise set it to empty string
                                                }));
                                                setTempAttendees(newValue); // Update the temporary selected users state
                                                setIsModified(true)
                                            }}
                                            renderOption={renderOption}
                                            filterOptions={filterOptions}
                                            defaultValue={tempAtendees}
                                            filterSelectedOptions
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}

                                                    required label='Assign To'
                                                    placeholder="Add.."
                                                    size="small"
                                                />
                                            )}


                                        />}
                                </Box>
                            </Box>
                            <Box sx={{ marginTop: '20px' }}>
                                {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '10px 0px' }}>Notification To</Typography></Box> */}

                                <Box>

                                    {Array.isArray(userSearchResults) &&
                                        <Autocomplete
                                            sx={{ marginTop: '10px' }}
                                            multiple
                                            id="tags-outlined"

                                            getOptionLabel={getOptionLabel}
                                            renderOption={renderOption}
                                            filterOptions={filterOptions}

                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip
                                                        key={index}
                                                        avatar={<img src={option.photo} alt={option.name} style={{ width: '24px', height: '24px', borderRadius: '50%' }} />}
                                                        label={option.name}
                                                        {...getTagProps({ index })}
                                                    />
                                                ))
                                            }
                                            options={userSearchResults.filter(option => !Tempassign.some(user => user.user_id == option.attendees_id))}

                                            defaultValue={Tempassign}
                                            onChange={handleUserSelection}
                                            filterSelectedOptions
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label='Notification To'
                                                    placeholder="Add.."
                                                    size="small"
                                                />
                                            )}


                                        />}
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'space-between', }}>

                                <Box sx={{ marginRight: '5px' }}>
                                    {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey' }}>Activity Start</Typography></Box> */}
                                    <Box>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
                                            <DemoContainer components={['DatePicker']} size="small">
                                                <Box sx={{ minWidth: '100px' }}>
                                                    <DatePicker
                                                        sx={{ marginTop: '20px' }}
                                                        value={startDate}
                                                        onChange={(newValue) => { setstartDate(newValue); setIsModified(true) }}
                                                        //   onChange={(date) => setFromDate(date)}
                                                        slotProps={{ textField: { size: 'small', format: 'DD/MM/YYYY' } }}
                                                        label='Activity Start'
                                                    />
                                                </Box>
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Box>
                                </Box>

                                <Box sx={{ marginLeft: '5px' }}>
                                    {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey' }}>Activity End</Typography></Box> */}
                                    <Box>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                                <Box sx={{ minWidth: '100px' }}>
                                                    <DatePicker
                                                        // Parse the date string before passing it to DatePicker
                                                        sx={{ marginTop: '20px' }}
                                                        value={tempMeetingDate}
                                                        onChange={(newValue) => { setTempMeetingDate(newValue); setIsModified(true) }}
                                                        slotProps={{ textField: { size: 'small', format: 'DD/MM/YYYY' } }}
                                                        label='Activity End' required
                                                    />
                                                </Box>
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ marginTop: '20px' }}>
                                <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey' }}>Status</Typography></Box>
                                <Box>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            defaultValue={selectedUserData.status || "new"}
                                            onChange={(event) => {
                                                // Update selectedUserData.status with the new value
                                                setSelectedUserData(prevUserData => ({
                                                    ...prevUserData,
                                                    status: event.target.value
                                                }));
                                                setIsModified(true)
                                            }}// Set the default value based on selectedUserData.status
                                        >
                                            <FormControlLabel value="new" control={<Radio />} label="New" />
                                            <FormControlLabel value="inprogress" control={<Radio />} label="In Progress" />
                                            <FormControlLabel value="complete" control={<Radio />} label="Complete" />
                                            <FormControlLabel value="hold" control={<Radio />} label="On Hold" />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>

                            </Box>
                            <Box sx={{ marginTop: '20px' }}>
                                <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '10px 0px' }}>Manage Milestone</Typography></Box>
                                <Box>
                                    {(selectedUserData.milestone || []).map((milestone, index) => (
                                        <Box key={index} sx={{ margin: '10px 0px', padding: '0px 4px', borderRadius: '8px', background: 'var(--Colors-Background-bg-secondary, #F9FAFB)' }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Box> <FormControlLabel
                                                    control={<Checkbox
                                                        checked={milestone.status === 'complete'}
                                                        onChange={(e) => { handleMilestoneStatusChange(index, e.target.checked); setIsModified(true) }}
                                                        color="primary"
                                                    />}
                                                    label={milestone.milestone_description}
                                                /></Box>
                                                <Box sx={{ display: 'flex' }}>
                                                    <Box>
                                                        <Tooltip title={`Assign To: ${milestone.assign_to_name ? milestone.assign_to_name : milestone.assign_to_Name}`}>
                                                            <Avatar src={milestone.assign_to_photo ? milestone.assign_to_photo : milestone.assign_to_pic} sx={{ width: 25, height: 25, top: 9 }}>{milestone.assign_to_photo}</Avatar>
                                                        </Tooltip>
                                                    </Box>
                                                    <Box sx={{ margin: '10px 10px' }}>{milestone.due_date}</Box>
                                                    <Box>
                                                        <IconButton onClick={() => canceleditMilestone(index)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    ))}
                                    {addMilestoneState &&
                                        <Box>
                                            <Box>
                                                <TextField sx={{ width: '100%', marginBottom: '10px' }} value={milestoneName}
                                                    onChange={(e) => { setMilestoneName(e.target.value); setIsModified(true) }}
                                                    size="small"
                                                />
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Box sx={{ display: 'flex' }}>
                                                    <Box>
                                                        <Button variant='contained' style={{ borderRadius: '8px', fontSize: '10px', textTransform: 'none', padding: '8px', fontWeight: 'bold' }} onClick={EditMilestone} >Add </Button>
                                                    </Box>
                                                    <Box>
                                                        <Button variant='none' style={{ fontSize: '10px', textTransform: 'none', padding: '8px', fontWeight: 'bold' }} color="primary" onClick={cancelMileStone} >Cancel</Button>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ display: 'flex' }}>
                                                    <Box sx={{ marginRight: '5px' }}>
                                                        <IconButton variant='none'
                                                            title='Asignee'
                                                            style={{ border: selectAsignee == null ? '1px dotted grey' : '' }}
                                                            // style={{ borderColor: 'grey', color: 'grey', borderRadius: '8px', fontSize: '10px', textTransform: 'none', padding: '8px', fontWeight: 'bold' }}
                                                            aria-controls={openAssignee ? 'basic-menu' : undefined}
                                                            aria-haspopup="true"
                                                            aria-expanded={openAssignee ? 'truButtone' : undefined}
                                                            sx={{ backgroundColor: selectAsignee != null ? '#D8E2FF' : '' }}
                                                            onClick={handleClick} >
                                                            {selectAsignee == null ?
                                                                <PersonAddAltOutlinedIcon sx={{ height: '20px', width: '20px' }} />
                                                                :
                                                                <Avatar src={assignees} title={assigneeName} sx={{ height: '20px', width: '20px' }} />
                                                            }
                                                        </IconButton>
                                                    </Box>
                                                    <Box>

                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <ButtonDatePicker
                                                                title='Due date'
                                                                // label={value == null ? null : value.format('MM/DD/YYYY')}
                                                                value={dueDate}
                                                                onChange={(newValue) => { setDueDate(newValue); setIsModified(true) }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    }
                                    {addMilestoneState == false &&
                                        <Box>
                                            <Button variant='outlined' style={{ border: '1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD)', color: '#344054', borderRadius: '8px', fontSize: '10px', textTransform: 'none', padding: '8px', fontWeight: 'bold' }} onClick={addMileStone} ><AddOutlinedIcon sx={{ height: '20px', width: '20px' }} />Add Milestone</Button>
                                        </Box>
                                    }
                                </Box>
                            </Box>
                            <Box>
                                <Box sx={{ border: '1px solid var(--Colors-Border-border-secondary, #EAECF0)', background: ' var(--Colors-Background-bg-primary, #FFF)', borderRadius: '12px', textAlign: 'center', fontSize: '14px', color: 'grey', margin: '10px 0px' }}>
                                    <Box>
                                        <label htmlFor="contained-button-file">
                                            <CloudUploadOutlinedIcon style={{ cursor: 'pointer', margin: '10px 0px 0px 0px ', }} />
                                        </label>
                                    </Box>
                                    <Box onDrop={handleUpdateDrop}
                                        onDragOver={handleDragupdateOver}>
                                        <Typography>
                                            <label htmlFor="contained-button-file">
                                                <span style={{ color: '#005AC1', fontWeight: 'bold', cursor: 'pointer' }}>Click to upload </span>
                                            </label>
                                            {" "}or drag and drop
                                            <input
                                                accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
                                                id="contained-button-file"
                                                multiple
                                                type="file"

                                                onChange={() => handleUpdateFileChange(selectedUserData.redlist_id, selectedUserData.doc_path, selectedUserData.activity_type)}
                                                style={{ display: 'none' }} // Hide the input field
                                            />

                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography sx={{ fontSize: '12px', marginBottom: '5px' }}>PDF, DOC, PPT and XLSX  files (Max 5 gb or 100 files)</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            {/* <Box sx={{ backgroundColor: '#f5f3f7', border: '1px solid #74777f', borderRadius: '10px', margin: '0px 0px ', maxHeight: '60px', overflow: 'auto' }}>
    {selectedUserData.document && selectedUserData.document.map((document, index) => (
        <Box key={index} display='flex' justifyContent='space-between' alignItems='center' padding='10px'>
            <Box display='flex' alignItems='center'>
                <Box><PictureAsPdfIcon sx={{ color: 'red', margin: '0 10px 0 0' }} /></Box>
                <Box sx={{ maxWidth: '120px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px', fontWeight: 'bold' }}>{document.doc_name}</Box>
            </Box>
            <IconButton onClick={() => handleDocumentDelete(document.doc_id,selectedUserData.redlist_id,document.doc_name,document.doc_path,)}><CloseIcon /></IconButton>
        </Box>
    ))}
</Box> */}



                            {selectedUserData.document && selectedUserData.document.map((file, index) => (
                                <Box key={index} sx={{ backgroundColor: '', margin: '10px 0px ', overflow: 'auto' }}>

                                    <Box display='flex' justifyContent='space-between' alignItems='center' padding='10px'>
                                        <Box display='flex' alignItems='center'>
                                            <Box>
                                                {file.doc_name.endsWith('.pdf') && <DescriptionIcon sx={{ color: 'red', margin: '0 10px 0 0' }} />}
                                                {file.doc_name.endsWith('.xls') && <DescriptionIcon sx={{ color: 'green', margin: '0 10px 0 0' }} />}
                                                {file.doc_name.endsWith('.xlsx') && <DescriptionIcon sx={{ color: 'green', margin: '0 10px 0 0' }} />}
                                                {file.doc_name.endsWith('.doc') && <DescriptionIcon sx={{ color: 'blue', margin: '0 10px 0 0' }} />}
                                                {file.doc_name.endsWith('.docx') && <DescriptionIcon sx={{ color: 'blue', margin: '0 10px 0 0' }} />}
                                                {file.doc_name.endsWith('.ppt') && <DescriptionIcon sx={{ color: '#FFA500', margin: '0 10px 0 0' }} />}
                                                {file.doc_name.endsWith('.pptx') && <DescriptionIcon sx={{ color: '#FFA500', margin: '0 10px 0 0' }} />}
                                            </Box>
                                            <Box >

                                                <Box sx={{ maxWidth: '120px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px', }}> {file.doc_name} </Box>
                                                <Box sx={{ color: 'grey', fontSize: '12px', }}>{file.doc_size} </Box>
                                            </Box>
                                        </Box>
                                        <Box display='flex'>
                                            <Box >
                                                {
                                                    file.uploadstatus === 'new' ? (
                                                        <CircularProgress size={20} sx={{ marginTop: '8px' }} />
                                                    ) : file.uploadstatus === 'processing' ? (
                                                        <CircularProgress variant="determinate" value={file.percentage} size={20} sx={{ marginTop: '8px' }} />
                                                    ) : file.uploadstatus === 'complete' ? (
                                                        <CheckIcon sx={{ marginTop: '8px' }} />
                                                    ) : file.uploadstatus === 'failed' ? (
                                                        <SmsFailedIcon />
                                                    ) : null // Ensure to have a default value or null at the end if necessary
                                                }
                                            </Box>
                                            <IconButton onClick={() => handleDocumentDelete(file.doc_id, selectedUserData.redlist_id, file.doc_name, file.doc_path, selectedUserData.activity_type)}><CloseIcon /></IconButton>
                                        </Box>
                                    </Box>

                                </Box>
                            ))}
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ padding: '10px 23px 15px 23px' }}>
                        <Box sx={{ display: 'flex', width: '100%' }}>
                            <Box sx={{ width: '50%', marginRight: '5px' }}>
                                <Button variant="outlined" sx={{ textTransform: 'none', width: '100%', fontWeight: 'bold' }} onClick={closeUpdateRedListModal}>Cancel</Button>
                            </Box>
                            <Box sx={{ width: '50%', marginLeft: '5px' }}>
                                <Button type="submit" variant="contained" disabled={buttonLoading} sx={{ textTransform: 'none', width: '100%', fontWeight: 'bold' }}>{buttonLoading && <CircularProgress size={18} sx={{ marginRight: '12px' }} />} {/* Loader inside the button */}Update Activity</Button>
                            </Box>
                        </Box>
                    </DialogActions>
                </form>
            </Dialog>
            <Dialog fullWidth maxWidth="sm" open={viewRedList} onClose={closeViewRedListModal}>

                {viewisLoading ? (

                    <DialogContent style={{ textAlign: 'center' }}>
                        <LoadingSkeleton />
                    </DialogContent>
                ) : (
                    userData.map((item, index) => (
                        <div key={index}>
                            <DialogTitle sx={{ padding: '0px 5px ' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '15px 15px 0px 15px' }}>
                                    <Box sx={{ display: 'flex', margin: '10px 10px' }}>
                                        <Box>
                                            <AddCardOutlinedIcon sx={{}} />
                                        </Box>
                                        <Typography sx={{ fontWeight: 'bold', marginLeft: '10px' }}>View Activity</Typography>
                                    </Box>

                                    <Box display='flex'>
                                        {item.allow_edit === 'yes' && (
                                            <Box>
                                                <IconButton onClick={() => handleEditClick(item)}> <EditOutlinedIcon /></IconButton>
                                                <IconButton onClick={() => handleOpen(item.redlist_id)} > <DeleteOutlinedIcon /></IconButton>
                                            </Box>
                                        )}
                                        <Box>
                                            <IconButton onClick={closeViewRedListModal}> <CloseIcon /></IconButton>
                                        </Box>
                                    </Box>
                                </Box>
                            </DialogTitle>

                            <DialogContent sx={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto', }}>

                                <Box >
                                    <Box sx={{ display: 'flex', marginBottom: "20px", marginTop: '20px' }}>
                                        <BusinessIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Department:</Typography></Box>
                                        <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.dept_name}</Typography></Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px", marginTop: '20px' }}>
                                        <MeetingRoomIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Activity:</Typography></Box>
                                        <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.activity_type}</Typography></Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <WorkIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Project Name:</Typography></Box>
                                        <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.project_name}</Typography></Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <TaskIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Agenda:</Typography></Box>
                                        <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.agenda}</Typography></Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <ShortTextIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Description: </Typography></Box>
                                        <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.description}</Typography></Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <PersonAddIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Assign To:</Typography></Box>

                                        <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}> {item.assignto_name}</Typography>

                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <NotificationsIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>{"Notification To:"}</Typography></Box>
                                        <List sx={{ marginLeft: '10px', padding: '0px' }}>
                                            {item.notificationto.map((item, index) => (
                                                <ListItem key={item.attendees_id} sx={{ padding: '0px' }}> {/* Make sure to include a unique key for each item */}
                                                    <ListItemAvatar sx={{ minWidth: '30px', paddingTop: '5px' }}>
                                                        <img src={item.photo} style={{ width: '20px', height: '20px', borderRadius: '50px' }} />
                                                    </ListItemAvatar>
                                                    <Typography sx={{ fontSize: '14px' }}>
                                                        {item.name}
                                                    </Typography>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Box>

                                    <Box sx={{ marginBottom: "20px", display: 'flex' }}>
                                        <CalendarTodayIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>Activity Start:</Typography></Box>
                                        <Box>
                                            <Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.start_datetime == "" ? "N/A" : item.start_datetime}</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ marginBottom: "20px", display: 'flex' }}>
                                        <CalendarTodayIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Activity End:</Typography></Box>
                                        <Box>
                                            <Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.end_datetime}</Typography>
                                        </Box>
                                    </Box>


                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <HourglassTopIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box>
                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Status: </Typography>

                                        </Box>
                                        <Box sx={{ display: 'flex', marginLeft: '10px' }}>
                                            {item.status == 'new' &&
                                                <Chip label='New' size="small" color="primary" sx={{ width: '90px' }} />
                                            }
                                            {item.status == 'inprogress' &&
                                                <Chip label='In Progress' size="small" color="warning" sx={{ width: '90px' }} />
                                            }
                                            {item.status == 'complete' &&
                                                <Chip label='Complete' size="small" color="success" sx={{ width: '90px' }} />
                                            }
                                            {item.status == 'hold' &&
                                                <Chip label='Hold' size="small" color="error" sx={{ width: '90px' }} />
                                            }
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <ShowChartIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box>
                                            <Box>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Milestones: </Typography>

                                            </Box>
                                            <Box sx={{ display: 'flex', fontSize: '14px', marginTop: '10px' }}>
                                                <Box sx={{ display: 'flex' }}>
                                                    {item.milestone && item.milestone.length > 0 ? (

                                                        <TableContainer >

                                                            <Table size="small" aria-label="a dense table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell sx={{ backgroundColor: 'rgba(0, 0, 0, 0.00)', fontWeight: 'bold' }} > Description</TableCell>
                                                                        <TableCell sx={{ backgroundColor: 'rgba(0, 0, 0, 0.00)', fontWeight: 'bold' }}>Due Date</TableCell>
                                                                        <TableCell sx={{ backgroundColor: 'rgba(0, 0, 0, 0.00)', fontWeight: 'bold' }} >Assign To</TableCell>
                                                                        {/* <th style={{ border: '1px solid #dddddd', padding: '8px' }}>photo</th> */}
                                                                        <TableCell sx={{ backgroundColor: 'rgba(0, 0, 0, 0.00)', fontWeight: 'bold' }}>Status</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {item.milestone.map((milestone, index) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell >{milestone.milestone_description}</TableCell>
                                                                            <TableCell >{milestone.due_date}</TableCell>

                                                                            <TableCell >
                                                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>

                                                                                    <Avatar src={milestone.assign_to_photo} alt={milestone.assign_to_name} sx={{ height: 25, width: 25, right: 5 }} />
                                                                                    <Typography sx={{ fontSize: '14px' }}>{milestone.assign_to_name}</Typography>
                                                                                </Box>
                                                                            </TableCell>
                                                                            {/* <td style={{ border: '1px solid #dddddd', padding: '8px' }}>  </td> */}
                                                                            <TableCell >{milestone.status}</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>

                                                    ) : (

                                                        <Typography sx={{ textAlign: 'center' }} variant="body1">N/A</Typography>

                                                    )}
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <FilePresentIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box>
                                            <Box>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>Document: </Typography>

                                            </Box>
                                            <List>
                                                {item.document.map((file, docindex) => (
                                                    <Box key={docindex} sx={{ backgroundColor: '', border: '1px solid var(--_components-input-outlined-enabledBorder, rgba(0, 0, 0, 0.23))', borderRadius: '4px', margin: '0px 0px 10px 0px ', overflow: 'auto' }}>

                                                        <Box display='flex' justifyContent='space-between' alignItems='center' padding='10px'>
                                                            <Box display='flex' alignItems='center'>
                                                                <Box>
                                                                    {file.doc_name.endsWith('.pdf') && <DescriptionIcon sx={{ color: 'red', margin: '0 10px 0 0' }} />}
                                                                    {file.doc_name.endsWith('.xls') && <DescriptionIcon sx={{ color: 'green', margin: '0 10px 0 0' }} />}
                                                                    {file.doc_name.endsWith('.xlsx') && <DescriptionIcon sx={{ color: 'green', margin: '0 10px 0 0' }} />}
                                                                    {file.doc_name.endsWith('.doc') && <DescriptionIcon sx={{ color: 'blue', margin: '0 10px 0 0' }} />}
                                                                    {file.doc_name.endsWith('.docx') && <DescriptionIcon sx={{ color: 'blue', margin: '0 10px 0 0' }} />}
                                                                    {file.doc_name.endsWith('.ppt') && <DescriptionIcon sx={{ color: '#FFA500', margin: '0 10px 0 0' }} />}
                                                                    {file.doc_name.endsWith('.pptx') && <DescriptionIcon sx={{ color: '#FFA500', margin: '0 10px 0 0' }} />}
                                                                </Box>
                                                                <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px', fontWeight: 'bold' }} onClick={() => RedListDocviewPreview(docindex, file.doc_id, file.doc_name, file.doc_path, item.redlist_id, item.activity_type)}>{file.doc_name} {LoadingviewStates[docindex] && <CircularProgress size={18} sx={{ marginRight: '12px' }} />}</Box>

                                                            </Box>

                                                            <Box sx={{ fontSize: '12px' }}>
                                                                <Typography sx={{ fontSize: '13px', marginLeft: '10px' }}>{file.doc_size}</Typography>
                                                            </Box>
                                                        </Box>

                                                    </Box>
                                                ))}
                                            </List>
                                        </Box>
                                    </Box>
                                    {/* <Box>
                            <Box>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey' }}>Document : </Typography>

                                </Box> 

                             <Box>
    {item.document.includes(',') ? (
        // If item.document contains a comma, split it and map over the array
        item.document.split(',').map((document, index) => (
            <Box key={index} sx={{ backgroundColor: '#f5f3f7', border: '1px solid #74777f', borderRadius: '10px', margin: '0px 0px ', maxHeight: '60px' }}>
                <Box display='flex' flexGrow={1} sx={{ justifyContent: 'space-between' }}>
                    <Box display='flex'>
                        <Box><PictureAsPdfIcon sx={{ color: "red", margin: '10px', height: '35px', width: '35px' }} /></Box>
                        <Box sx={{ marginTop: '10px' }}>
                            <Box sx={{ fontSize: '14px', fontWeight: 'bold', maxWidth: '120px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={document}>{document}</Box>
                            
                            <Box sx={{ fontSize: '12px' }}>
                                <Typography sx={{ fontSize: '13px' }}>{'100'}kb</Typography>
                            </Box>
                        </Box>
                    </Box>
                    
                    <Box sx={{ margin: '18px 10px 10px 10px' }}>
                    </Box>
                </Box>
            </Box>
        ))
    ) : (
        // If item.document does not contain a comma, render a single Box
        <Box sx={{ backgroundColor: '#f5f3f7', border: '1px solid #74777f', borderRadius: '10px', margin: '0px 0px ', maxHeight: '60px' }}>
            <Box display='flex' flexGrow={1} sx={{ justifyContent: 'space-between' }}>
                <Box display='flex'>
                    <Box><PictureAsPdfIcon sx={{ color: "red", margin: '10px', height: '35px', width: '35px' }} /></Box>
                    <Box sx={{ marginTop: '10px' }}>
                        <Box sx={{ fontSize: '14px', fontWeight: 'bold', maxWidth: '120px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={item.document}>{item.document}</Box>
                        
                        <Box sx={{ fontSize: '12px' }}>
                            <Typography sx={{ fontSize: '13px' }}>{'100'}kb</Typography>
                        </Box>
                    </Box>
                </Box>
                
                <Box sx={{ margin: '18px 10px 10px 10px' }}>
                </Box>
            </Box>
        </Box>
    )}
</Box> 



                            </Box> */}
                                </Box>

                            </DialogContent>
                            {/* <DialogActions>
                    <Box sx={{ display: 'flex', width: '100%' }}>
                        <Box sx={{ width: '50%', marginRight: '5px' }}>
                            <Button variant="outlined" sx={{ textTransform: 'none', width: '100%',fontWeight:'bold' }} onClick={closeViewRedListModal}>Cancel</Button>
                        </Box>
                        <Box sx={{ width: '50%', marginLeft: '5px' }}>
                            <Button variant="contained" sx={{ textTransform: 'none', width: '100%',fontWeight:'bold' }}>Update Red List</Button>
                        </Box>
                    </Box>
                </DialogActions> */}
                        </div>
                    ))
                )}
            </Dialog>
            <Dialog
                open={confirmOpen}
                onClose={closeConfirmModal}
                aria-labelledby="confirm-modal"
                aria-describedby="confirm-modal-description"
            >
                <DialogTitle id="confirm-modal">Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-modal-description">
                        Are you sure you want to delete this item? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeConfirmModal} color="primary">
                        Cancel
                    </Button>
                    {/* <Button color="primary" onClick={handleDelete}>
                                        Apply
                                    </Button> */}
                    <Button onClick={confirmDelete} backgroundColor={deleteloading && '#616161'} disabled={deleteloading}>
                        {deleteloading && <CircularProgress size={16} sx={{ marginRight: '10px' }} />} Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this item?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>

                    <Button onClick={handleConfirmDelete} backgroundColor={loading && '#616161'} disabled={loading}>
                        {loading ? (
                            <>
                                <CircularProgress size={24} style={{ marginRight: '8px' }} />
                                Confirming...
                            </>
                        ) : (
                            "Confirm"
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={pdfDialogOpen} onClose={handleClosePdfDialog} fullScreen>
                <DialogTitle>
                    <Button variant="none" sx={{ textTransform: "none", fontWeight: "bold", marginLeft: '30px' }} onClick={handleClosePdfDialog} size="small">
                        <ArrowBackIcon />  Go back
                    </Button>
                </DialogTitle>
                <DialogContent>
                    <iframe
                        src={pdfUrl}
                        title="PDF Preview"
                        width="100%"
                        height="100%"
                    ></iframe>
                </DialogContent>
                {/* <DialogActions>
                    <Button variant="outlined" sx={{ textTransform: "none", fontWeight: "bold", marginRight: '30px' }} onClick={handleClosePdfDialog} size="small">
                        Close
                    </Button>

                </DialogActions> */}
            </Dialog>
            <Dialog
                open={closeConfirmation}
                onClose={closeCloseConfirm}
                aria-labelledby="confirm-modal"
                aria-describedby="confirm-modal-description"
            >
                <DialogTitle id="confirm-modal">Close Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-modal-description">
                        Are you sure you want to close this modal? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={closeCloseConfirm} color="primary">
                        Cancel
                    </Button>

                    <Button onClick={handleCloseConfirm} >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Menu
                id="basic-menu"
                anchorEl={assignee}
                open={openAssignee}
                onClose={handleAssigneeMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem>
                    <TextField sx={{ width: '100%' }}
                        placeholder="Search User"
                        onChange={(e) => handleSearchMilestoneAssignee(e.target.value)}
                        size="small"
                    />
                </MenuItem>
                {filterData.map((item, index) => (
                    <MenuItem
                        key={item.user_id}
                        value={index} // Change the value to index or a unique identifier
                        onClick={() => handleSelectAssignee(item.attendees_id, item.photo, item.name)} // Pass name directly to the handler
                    >
                        <Box sx={{ display: 'flex' }}>
                            <Box>
                                <img src={item.photo} style={{ height: '30px', width: '30px', borderRadius: '50px', marginRight: '10px' }} />
                            </Box>
                            <Box>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>{item.name}</Typography>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: 'grey' }}>{item.email}{"  "}</Typography>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: 'grey', margin: '0px 5px' }}>{" | "}{item.dept_name}</Typography>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: 'grey' }}>{" | "}{item.mobile_no}</Typography>
                                </Box>
                            </Box>
                        </Box>

                    </MenuItem>
                ))}
            </Menu>
            <Popover
                id={idStatus}
                open={openStatus}
                anchorEl={anchorStatus}
                onClose={handleStatusClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => handleStatusSelect('New', 'new')}>New</MenuItem>
                <MenuItem onClick={() => handleStatusSelect('In Progress', 'inprogress')}>In Progress</MenuItem>
                <MenuItem onClick={() => handleStatusSelect('Completed', 'completed')}>Completed</MenuItem>
                <MenuItem onClick={() => handleStatusSelect('Hold', 'hold')}>Hold</MenuItem>
            </Popover>
            <Popover
                id={idType}
                open={openType}
                anchorEl={anchorType}
                onClose={handleTypeClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => handleTypeSelect('Red List', 'redlist')}>Red List</MenuItem>
                <MenuItem onClick={() => handleTypeSelect('Critical', 'critical')}>Critical</MenuItem>
                <MenuItem onClick={() => handleTypeSelect('Day To Day', 'daytoday')}>Day To Day</MenuItem>
            </Popover>
            {/* <AppBar
                position="fixed"
                sx={{
                    backgroundColor: 'white',
                    top: "auto",
                    bottom: 0,
                    width: `calc(100% - ${drawerWidth}px)`,
                    ml: `${drawerWidth}px`,
                }}
            >


                <Typography component="div"
                      sx={{ flexGrow: 1 ,textAlign:'right',color:'grey',padding:'5px 10px'}}
                    className="cmd_footer"
                >
                    Powered by <span style={{ fontWeight: 'bold' }}>ZeonAI Labs</span>
                </Typography>

            </AppBar> */}
        </Box>
    );
}

export default AdminCmdLink;