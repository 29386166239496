import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import { getAutocompleteResults, getDepartment, fetchMoreData, downloadPDF, searchDocuments, } from '../ApiHelper/DocumentSearchapi';
import {
  IconButton,
  Paper,
  TextField,
  MenuList,
  Checkbox,
  ListItemText,
  MenuItem,
  Autocomplete,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
} from '@mui/material';

const DropdownWithCheckboxes = forwardRef((props, ref) => {
  const { data, onSelectionChange, onApplyButtonClick,onResetClick } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [checkedItems, setCheckedItems] = useState({});
  const [checked, setChecked] = useState(() => {
    const storedState = JSON.parse(localStorage.getItem('checkboxState')) || [];
    return storedState;
  });
 
  const [filterclicked, setFilterClicked] = useState(false);

  
  // const handleCheckboxChange = (name) => {
  //   setCheckedItems((prevCheckedItems) => ({
  //     ...prevCheckedItems,
  //     [name]: !prevCheckedItems[name],
  //   }));
  // };
  
  const [personName, setPersonName] = React.useState([]);
  const open = Boolean(anchorEl);
  const id = open ? 'dropdown-popover' : undefined;
  const paperStyle = {
    width: '320px',
    height: '400px',
  };

  
 // const data = props.data;
  const [filterData, setFilterData] = useState(data);
  const [searchdepartment, setsearchdepartment] = useState('');
  const [deptSearch,setDeptSearch] = useState([])
  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
    setFilterData(data);
  };
  const handleSearch = (query) => {
    setsearchdepartment(query);

    // Filter the data based on the search query
    const filteredData = data.filter((department) =>
      department.dept_name.toLowerCase().includes(query.toLowerCase())
    );
    setFilterData(filteredData);
  };
  const handleCheckboxChange = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    
    if (currentIndex === -1) {
    newChecked.push(value);
    } else {
    newChecked.splice(currentIndex, 1);
    }
    
    setChecked(newChecked);
    onSelectionChange(newChecked);
    };
    const handleApplyClick = () => {
      if (checked.length > 0) {
        setFilterClicked(true);
        onApplyButtonClick(checked);
        setAnchorEl(null);
      } else {
        setFilterClicked(false);
      }
    };
  
    // const resetClick = () => {
    //   setChecked([]); // Reset the selected checkboxes
    //   setAnchorEl(null); // Close the popover
    //   setFilterClicked(false);
    // };
    const resetClick = () => {
      setChecked([]);
      onSelectionChange([]); // Notify the parent about the reset
      setAnchorEl(null);
      setFilterClicked(false);
      // Call the onResetClick function if needed
    //  props.onResetClick();

    };
    React.useImperativeHandle(ref, () => ({
      handleResetClick: resetClick,
    }));
  return (
    <div>
      <Button
        onClick={handleButtonClick}
        sx={{
          '&:hover': {
            backgroundColor: '#2f80ed', // Set the hover color
          },
          backgroundColor: '#2f80ed',
          color: 'white',
          textTransform: 'none',
          
        }}
      >
        Department
        {filterclicked ? (
          <CheckIcon sx={{ height: '23px', width: '23px', marginLeft: '10px', marginTop: '-3px' }} />
        ) : (
          <ArrowDropDownIcon sx={{ height: '23px', width: '23px', marginLeft: '10px', marginTop: '-3px' }} />
        )}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        sx={{ height: '350px', width: '320px' }}
        style={paperStyle}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: '260px' }}>
          <Box sx={{ position: 'sticky', top: 0, backgroundColor: 'white', padding: '2px' }}>
            
                <TextField
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder="Search Department"
                  style={{
                    paddingTop: '0px',
                    margin: '10px',
                    width: '92%',
                    color: '#828282',
                    backgroundColor: '#e0e0e0',
                    border: 'none',
                  }}
                  InputProps={{
                   
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              
            
          </Box>
          <Box
            sx={{
              overflow: 'auto',
              maxHeight: '230px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              padding: '0px',
            }}
          >
            <MenuList>
            <List sx={{ '&fieldset': { padding: '0px' } }}>
{filterData.map((department) => (
<ListItem key={department.dept_id} disablePadding>
<ListItemButton
role={undefined}
onClick={handleCheckboxChange(department.dept_id)}
dense
margin="0"
paddingTop="0px"
>
<ListItemIcon>
<Checkbox
edge="start"
sx={{ paddingTop: '0px' }}
checked={checked.indexOf(department.dept_id) !== -1}
tabIndex={-1}
disableRipple
inputProps={{
'aria-labelledby': `checkbox-list-label-${department.dept_id}`,
}}
/>
</ListItemIcon>
<ListItemText
id={`checkbox-list-label-${department.dept_id}`}
primary={department.dept_name}
/>
</ListItemButton>
</ListItem>
))}
</List>
            </MenuList>
          </Box>
          <Box>
            <Divider orientation="horizontal" />
          </Box>
          <Box
            sx={{
              position: 'sticky',
              bottom: '0',
              backgroundColor: 'white',
              padding: '8px',
              display: 'flex',
              justifyContent: 'end',
              width: '100%',
              marginBottom: '0px',
            }}
          >
            <Button onClick={resetClick}>Reset</Button>
            <Button onClick={handleApplyClick}>Apply</Button>
          </Box>
        </Box>
      </Popover>
    </div>
  );
});

export default DropdownWithCheckboxes;
