import React, { useState, useEffect, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from '@mui/icons-material/Search';
import Typography from "@mui/material/Typography";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { NavLink } from "react-router-dom";
import DrawerComponent from "../Includes/Sidebar";
import { Button, Divider, TextField } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { CircularProgress, ListItemIcon } from '@mui/material';
import InfiniteScroll from "react-infinite-scroll-component";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import NotesIcon from '@mui/icons-material/Notes';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import a5 from '../../../a.png'
import Radio from '@mui/material/Radio';
import CreateIcon from '@mui/icons-material/Create'; // Import the MUI icon you want to use

import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import EditModal from "./EditUserModal";
import MenuItem from '@mui/material/MenuItem';
import { getAllUser, addUser, editUser, userMasterData, fetchMoreUser, userChangeStatus, undoInvitedUser } from "../AdminApiHelper";
import Skeleton from "@mui/material/Skeleton";
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import Appbar from "../Includes/Header";
import AddIcon from '@mui/icons-material/Add';
// import AddDepartment from "./addDepartment";
// import ViewDepartment from "./viewDepartment";
import "./User.css";
const drawerWidth = 140;

const theme = createTheme();
function User(props) {
    const navigate = useNavigate();
    const { windows } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <DrawerComponent />
        </div>
    );

    const container =
        windows !== undefined ? () => window().document.body : undefined;

    const [departmentbutton, setDepartmentbutton] = React.useState(false);
    const handlebutton = () => {
        setDepartmentbutton(!departmentbutton);
    };

    const [isLoading, setIsLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [usertoken, setusertoken] = useState("");
    const [index, setIndex] = useState(0);
    const [user_id, setuser_id] = useState("");
    const [superAdmin, setSuperAdmin] = useState('');
    const [userorg_id, setuserorg_id] = useState("");
    const [userSearchResults, setUserSearchResults] = useState([]);
    const [designationSearchResults, setDesignationSearchResults] = useState([]);
    const [roleSearchResults, setRoleSearchResults] = useState([]);
    const [departmentSearchResults, setDepartmentSearchResults] = useState([]);
    const [showStaticContent, setShowStaticContent] = useState(false);
    const [userconversation_id, setuserconversation_id] = useState("");
    const [dept_id, setDept_id] = useState([]);
    const [dept_desc, setDept_desc] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [toastmessage, settoastmessage] = useState('');
    const [hasMore, setHasMore] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [user_name, setUser_name] = useState('');
    const [user_gender, setUser_gender] = useState('male');
    const [user_email, setUser_email] = useState('');
    const [user_phone, setUser_phone] = useState('');
    const [user_empid, setUser_empid] = useState('');
    const [user_designation, setUser_designation] = useState('');
    const [user_role, setUser_role] = useState('');
    const [user_dept, setUser_dept] = useState('');
    const [user_photo, setUser_photo] = useState('');
    const [user_photo_view, setUser_photo_view] = useState('');
    const [addUserModalOpen, setAddUserModalOpen] = useState(false);
    const [tempUserId, setTempUserId] = useState('')
    const [tempUserName, setTempUserName] = useState('')
    const [tempUserGender, setTempUsergender] = useState('')
    const [tempUserStatus, setTempUserStatus] = useState('')
    const [tempUserPhoto, setTempUserPhoto] = useState('')
    const [tempUserPhoto_view, setTempUserPhoto_view] = useState('')
    const [tempUserEmail, setTempUserEmail] = useState('')
    const [tempUserPhone, setTempUserPhone] = useState('')
    const [tempUserEmpid, setTempUserEmpid] = useState('')
    const [tempUserDegn, setTempUserDegn] = useState('')
    const [tempUserRole, setTempUserRole] = useState('')
    const [tempUserDept, setTempUserDept] = useState('')
    const [stringSearch, setStringSearch] = useState('');
    const [newDesignation, setNewDesignation] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [isNewDesignation, setIsNewDesignation] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setNewDesignation('');
    };
    const openAddUserModal = () => {
        setAddUserModalOpen(true)
    };
    const closeAddUserModal = () => {
        setAddUserModalOpen(false)
    };
    const [editUserModalOpen, setEditUserModalOpen] = useState(false)
    const openEditUserModal = () => {
        setEditUserModalOpen(true)
    };
    const closeEditUserModal = () => {
        setEditUserModalOpen(false)
        handleActionClose()
    };



    useEffect(() => {
        if (stringSearch.length === 0 || stringSearch.length >= 3) {
            fetchUserData();
        }
        return () => { };
    }, [stringSearch]);

    const [isUndoModal, setIsUndoModal] = useState(false);
    const [undoUser_id, setUndoUser_id] = useState('');

    const openUndoModal = (index, user_id) => {
        setIsUndoModal(true);
        setUndoUser_id(user_id);

    }
    const closeUndoModal = () => {
        setIsUndoModal(false);

    }
    const [total_rec, setTotal_rec] = useState('')
    const fetchUserData = async () => {
        setIsLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
                super_admin: super_admin,
            } = userDataObj;

            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            setSuperAdmin(super_admin)
            setIndex(0);
            const requestData = await getAllUser(deepuser_id, deeporg_id, stringSearch, userToken);
            if (requestData.code == "200") {
                setuserconversation_id(requestData.conversation_id);
                // const newResults = requestData.data;
                setUserSearchResults(requestData.data);
                const userDetailToUpdate = requestData.data.find(item => item.user_id === tempUserId);

                if (userDetailToUpdate) {
                    setUserDetails(userDetailToUpdate);
                } else {
                    // Handle case where no user detail is found with matching user_id
                }

                setTotal_rec(requestData.total_rec)
                if (requestData.data.length >= 0 && requestData.data.length < 15) {
                    setHasMore(false)
                }
                else {
                    setHasMore(true);

                }

                setIsLoading(false)
                setShowStaticContent(false);
            } else {
                setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            setIsLoading(false);
        }
    };
    const fetchMasterData = async () => {
        setIsLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
            } = userDataObj;

            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            setIndex(0);
            const requestData = await userMasterData(deepuser_id, deeporg_id, userToken);
            if (requestData.code == "200") {
                setuserconversation_id(requestData.conversation_id);
                const designationResults = requestData.designation;
                setDesignationSearchResults(requestData.designation);
                const roleResults = requestData.role;
                setRoleSearchResults(requestData.role);
                const departmentResults = requestData.department;
                setDepartmentSearchResults(requestData.department);
                setIsLoading(false)
                setShowStaticContent(false);
            } else {
                setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            setIsLoading(false);
        }
    };
    useEffect(() => {


        fetchMasterData();

        return () => { };
    }, []);

    const LoadingSkeleton = () => {
        // You can customize the skeleton based on your design
        return (
            <TableRow >
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={200} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={200} height={20} />
                </TableCell>
            </TableRow>
        );
    };
    const handleAddUser = async () => {
        setButtonLoading(true)
        try {
            //   setdeleteLoading(true)
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;
            const responseData = await addUser(
                deepuser_id,
                deeporg_id,
                user_name,
                user_photo,
                user_gender,
                user_email,
                user_phone,
                user_empid,
                user_designation,
                isNewDesignation ? 'no' : 'yes',
                user_role,
                user_dept,
                userToken
            );

            if (responseData.code === "200") {
                closeAddUserModal();
                setButtonLoading(false)
                setUser_name('');
                setUser_phone('');
                setUser_email('');
                setUser_designation('');
                setUser_role('');
                setUser_dept('');
                setUser_photo_view('')
                fetchUserData();
                setSnackbarOpen(true);
                settoastmessage(responseData.message)
                setIsNewDesignation(false);
                fetchMasterData();
            } else {

                setButtonLoading(false);
                setSnackbarOpen(true);
                settoastmessage(responseData.message)
            }
        } catch (error) {
            setButtonLoading(false);
            console.error("Error:", error);
            //   setdeleteLoading(false)
        }
    };

    const handleEditUser = async () => {
        setButtonLoading(true)
        try {
            //   setdeleteLoading(true)
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            const responseData = await editUser(
                deepuser_id,
                deeporg_id,
                tempUserName,
                tempUserPhoto,
                tempUserGender,
                tempUserEmail,
                tempUserPhone,
                tempUserEmpid,
                tempUserDegn,
                isNewDesignation ? 'no' : 'yes', // Set existing_designation based on whether the designation already exists
                tempUserRole,
                tempUserDept,
                tempUserId,
                userToken
            );

            if (responseData.code === "200") {
                closeEditUserModal();
                setButtonLoading(false)
                setTempUserName('');
                setTempUsergender('');
                setTempUserEmail('');
                setTempUserDegn('');
                setTempUserRole('');
                setTempUserDept('');
                fetchUserData();
                setSnackbarOpen(true);
                setIsNewDesignation(false);
                settoastmessage(responseData.message);
                fetchMasterData();
                handleActionClose()

            } else {

                setButtonLoading(false);
                setSnackbarOpen(true);
                settoastmessage(responseData.message)
                handleActionClose()
            }
        } catch (error) {
            setButtonLoading(false);
            console.error("Error:", error);
            //   setdeleteLoading(false)
        }
    };

    const handleLoadMore = async () => {
        setIsLoadingMore(true);

        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,

            } = userDataObj;

            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            setIndex(index + 15);
            const data = await fetchMoreUser(
                deepuser_id,
                deeporg_id,
                stringSearch,
                userToken,
                index + 15
            );
            if (data.code == "200") {
                setuserconversation_id(data.conversation_id);
                const newResults = data.data;
                setUserSearchResults((prevResults) => [

                    ...prevResults, ...newResults,
                ]);
                if (data.data.length >= 0 && data.data.length < 15) {
                    setHasMore(false)
                }
                else {
                    setHasMore(data.data.length > 0);

                }
                // setHasMore(data.data.length > 0);
                setIsLoading(false)
                setShowStaticContent(false);
            } else {
                setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            setIsLoading(false);
        }
    };

    const StatusChange = async () => {
        setButtonLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
            } = userDataObj;

            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);

            const requestData = await userChangeStatus(deepuser_id, deeporg_id, tempUserId, tempUserStatus, userToken);
            if (requestData.code == "200") {
                console.log('status change', requestData.message)
                setuserconversation_id(requestData.conversation_id);
                setSnackbarOpen(true);
                settoastmessage(requestData.message)
                setButtonLoading(false)
                closeChangeStatusModal()
                setShowStaticContent(false);
                fetchUserData();

            } else {

                setButtonLoading(false);
                setSnackbarOpen(true);
                settoastmessage(requestData.message)
            }
        } catch (error) {
            setButtonLoading(false);
            console.error("Error:", error);
            //   setdeleteLoading(false)
        }
    };

    const handleUndoUser = async () => {
        setButtonLoading(true)
        try {
            //   setdeleteLoading(true)
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;
            const responseData = await undoInvitedUser(
                deepuser_id,
                deeporg_id,
                undoUser_id,
                userToken
            );

            if (responseData.code === "200") {
                setIsUndoModal(false);
                setSnackbarOpen(true);
                settoastmessage(responseData.message)
                setButtonLoading(false)
                fetchUserData()
            } else {
                setSnackbarOpen(true);
                settoastmessage(responseData.message);
                setButtonLoading(false)
            }
        } catch (error) {
            console.error("Error:", error);
            //   setdeleteLoading(false)
        }
    };
    const deptBtnStyle = {
        borderRadius: '200px',
        backgroundColor: 'var(--Secondary-blue, #003776)',
        textTransform: 'none'
    }

    const [moreActionMenu, setMoreActionMenu] = useState(null);
    const [actionMenu, setActionMenu] = useState(null);
    const [userDetails, setUserDetails] = useState([])
    const [openViewDetails, setOpenViewDetails] = useState(false)
    const [defaultView, setDefaultView] = useState(true)


    const DetailView = (user) => {
        setUserDetails(user);
        setOpenViewDetails(true);
        setDefaultView(false);
        setTempUserName(user.name);
        setTempUserId(user.user_id)
        setTempUsergender(user.gender);
        // setTempIsSuperAdmin(user.superadmin)
        setTempUserPhoto_view(user.photo);
        setTempUserStatus(user.status);
        setTempUserEmail(user.email);
        setTempUserPhone(user.phone);
        setTempUserEmpid(user.emp_id);
        setTempUserDegn(user.designation);
        setTempUserRole(user.role_id);
        setTempUserDept(user.department_id);

        // setTempUserDegn_view(user.designation);
        // setTempUserRole_view(user.role);
        // setTempUserDept_view(user.department);
        // navigate('/admin-console/userDetails');
    }
    const cancelDetailView = () => {
        setOpenViewDetails(false);
        setDefaultView(true)
        // navigate('/admin-console/user');
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };
    const [changeStatusModalOpen, setChangeStatusModalOpen] = useState(false)
    const openMoreActionMenu = Boolean(moreActionMenu);
    const openActionMenu = Boolean(actionMenu);
    const openChangeStatusModal = () => {
        setChangeStatusModalOpen(true);
        setActionMenu(null);
        setMoreActionMenu(null)
        if (tempUserStatus == 'active') {
            setTempUserStatus('inactive')
        } else {
            setTempUserStatus('active')
        }
    };

    const closeChangeStatusModal = () => {
        setChangeStatusModalOpen(false);
        // SetOpenmenuitem(null);
    };
    const handleMoreActionClick = (event) => {
        setMoreActionMenu(event.currentTarget);
    };

    const handleClose = () => {
        setMoreActionMenu(null);
    };
    const handleActionClick = (event, user) => {
        setActionMenu(event.currentTarget);
        setTempUserName(user.name);
        setTempUsergender(user.gender);
        // setTempIsSuperAdmin(user.superadmin)
        setTempUserStatus(user.status)
        setTempUserPhoto_view(user.photo);
        setTempUserEmail(user.email);
        setTempUserPhone(user.phone);
        setTempUserEmpid(user.emp_id);
        setTempUserDegn(user.designation);
        setTempUserRole(user.role_id);
        setTempUserDept(user.department_id);
        setTempUserId(user.user_id)
    };
    const handleActionClose = () => {
        setActionMenu(null);
    };
    const [changeStatus, setChangeStatus] = useState(false);

    const fetchUserName = (event) => {
        setUser_name(event.target.value)
    }
    const fetchUserGender = (event) => {
        setUser_gender(event.target.value)
    }
    const fetchUserEmail = (event) => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const userEmail = event.target.value;

        if (emailPattern.test(userEmail)) {
            setUser_email(userEmail);
        } else {
            setUser_email('');
        }
    };
    const fetchUserPhone = (event) => {
        setUser_phone(event.target.value)
    }
    const fetchUserEmpid = (event) => {
        setUser_empid(event.target.value)
    }
    const fetchUserDesignation = (event) => {
        setUser_designation(event.target.value)
    }
    const fetchUserRole = (event) => {
        setUser_role(event.target.value)
    }
    const fetchUserDept = (event) => {
        setUser_dept(event.target.value)
    }
    const handleFileChangeAdd = (event) => {
        const imgFile = event.target.files[0];
        setUser_photo(imgFile);

        const reader = new FileReader();
        reader.onloadend = () => {

            setUser_photo_view(reader.result);
        };
        reader.readAsDataURL(imgFile);
    }
    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChangeEdit = (event) => {
        const imgFile = event.target.files[0];
        setTempUserPhoto(imgFile);
        const reader = new FileReader();
        reader.onloadend = () => {

            setTempUserPhoto_view(reader.result);
        };
        reader.readAsDataURL(imgFile);
    };
    const tableContainerRef = useRef(null);
    useEffect(() => {
        const tableContainer = tableContainerRef.current;

        // Check if tableContainerRef.current is not null before adding event listener
        if (tableContainer) {
            const handleScroll = () => {
                // Check if the user has scrolled to the bottom of the table container
                if (
                    tableContainer.scrollTop + tableContainer.clientHeight >=
                    tableContainer.scrollHeight
                ) {
                    // Trigger the load more function when scrolled to the bottom
                    handleLoadMore();
                }
            };

            // Add scroll event listener to the table container
            tableContainer.addEventListener('scroll', handleScroll);

            return () => {
                // Remove scroll event listener on component unmount
                tableContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, [handleLoadMore, tableContainerRef]);
    const clearSearchQuery = () => {
        setStringSearch('');

    };
    const handleCreateNewDesignation = () => {
        setIsNewDesignation(true);
        setDesignationSearchResults([...designationSearchResults, { desg_id: newDesignation, desg_name: newDesignation }]);
        setTempUserDegn(newDesignation);
        setUser_designation(newDesignation);
        handleCloseDialog();
    };
    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            {/* <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    backgroundColor: "white",
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: "none" }, color: "black" }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }} flexGrow={1}>
                        <Box>
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                style={{
                                    color: "#003776",
                                    fontSize: "24px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "normal",
                                    letterSpacing: "0.46px",
                                }}
                            >
                                Admin Console
                            </Typography>
                        </Box>

                        <Box >
                            <NavLink to={`/query`} style={{ color: '#2F80ED', display: 'flex', textDecoration: 'none' }}>
                                <ArrowBackIcon />
                                <Typography>Go to DeepProbe</Typography>
                            </NavLink>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar> */}
            <AppBar
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    backgroundColor: "white",
                }}
            >
                <Appbar />
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: "none", sm: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            {openViewDetails &&
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        p: 3,
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        background: "#EFF1F3",
                        minHeight: "100vh",
                    }}
                >
                    <Toolbar />
                    <Box sx={{ textAlign: 'left' }}>
                        <Button sx={{ color: 'grey', textTransform: 'none' }} onClick={cancelDetailView}>
                            <KeyboardBackspaceIcon /> View All Users
                        </Button>
                    </Box>

                    <Box>
                        <Box sx={{ backgroundColor: 'white', borderRadius: '10px', textAlign: 'left', padding: '20px', margin: '20px' }}>
                            <Box variant='h3' fontWeight='bold'>
                                Personal Details
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ display: 'flex' }} flexGrow={1}>
                                    <Box>
                                        <img src={userDetails.photo} style={{ height: '80px', width: '80px', borderRadius: '50px', marginTop: '8px', marginRight: '10px' }} />
                                    </Box>
                                    <Box sx={{ margin: '10px', lineHeight: '25px' }}>
                                        <Box>{userDetails.name}</Box>
                                        <Box sx={{ color: 'grey' }}>{userDetails.gender ? userDetails.gender : '--'}</Box>
                                        <Box>
                                            {userDetails.status == 'active' &&
                                                <Button variant="contained" style={{ borderColor: '#4CAF50', backgroundColor: '#4CAF50', color: 'white', borderRadius: '10px', fontSize: '10px' }} disabled color="success">Active</Button>
                                            }
                                            {userDetails.status == 'inactive' &&
                                                <Button variant="contained" style={{ borderColor: '#aa2e25', backgroundColor: '#aa2e25', color: 'white', borderRadius: '10px', fontSize: '10px' }} disabled color="success">Inactive</Button>
                                            }
                                            {userDetails.status == 'invite' &&
                                                <Button variant="contained" style={{ borderColor: '#2F80ED', backgroundColor: '#2F80ED', color: 'white', borderRadius: '10px', fontSize: '10px' }} disabled color="success">Invited</Button>
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', marginTop: '25px' }}>
                                    <Box>
                                        {superAdmin == 'yes' ?

                                            (userDetails.status != 'invite' && userDetails.super_admin == 'no' ?

                                                <Button variant="outlined" borderColor='grey' sx={{ borderColor: 'grey', borderRadius: '200px', textTransform: 'none' }}
                                                    id="basic-button"
                                                    aria-controls={open ? 'basic-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    onClick={handleMoreActionClick}
                                                >More actions <KeyboardArrowDownIcon /> </Button>
                                                :
                                                ''

                                            )
                                            : ((2 != userDetails.role_id || userDetails.status == 'invite' || userDetails.super_admin == 'yes')
                                                ? ''
                                                :
                                                <Button variant="outlined" borderColor='grey' sx={{ borderColor: 'grey', borderRadius: '200px', textTransform: 'none' }}
                                                    id="basic-button"
                                                    aria-controls={open ? 'basic-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    onClick={handleMoreActionClick}
                                                >More actions <KeyboardArrowDownIcon /> </Button>
                                            )
                                        }
                                    </Box>
                                    <Box>
                                        {superAdmin == 'yes' ?

                                            (userDetails.status != 'invite' && userDetails.super_admin == 'no' ?

                                                <Button variant="contained" sx={{ borderRadius: '200px', marginLeft: '15px', textTransform: 'none' }} onClick={openEditUserModal}>Edit details</Button>
                                                :
                                                ''

                                            )
                                            : ((2 != userDetails.role_id || userDetails.status == 'invite' || userDetails.super_admin == 'yes')
                                                ?
                                                ''
                                                :
                                                <Button variant="contained" sx={{ borderRadius: '200px', marginLeft: '15px', textTransform: 'none' }} onClick={openEditUserModal}>Edit details</Button>
                                            )
                                        }
                                    </Box>

                                </Box>
                            </Box>

                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ backgroundColor: 'white', borderRadius: '10px', textAlign: 'left', padding: '20px', margin: '20px', width: '50%' }}>
                                <Box sx={{ fontWeight: 'bold' }}>
                                    Contact details
                                </Box>
                                <Box sx={{ marginBottom: '20px', marginTop: '20px', fontSize: '14px' }}>
                                    <Box sx={{ fontWeight: 'bold' }}>
                                        Email Address
                                    </Box>
                                    <Box sx={{ color: 'grey' }}>
                                        {userDetails.email}
                                    </Box>
                                </Box>
                                <Box sx={{ marginBottom: '20px', marginTop: '20px', fontSize: '14px' }}>
                                    <Box sx={{ fontWeight: 'bold' }}>
                                        Phone Number
                                    </Box>
                                    <Box sx={{ color: 'grey' }}>
                                        {userDetails.phone}
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ backgroundColor: 'white', borderRadius: '10px', textAlign: 'left', padding: '20px', margin: '20px', width: '50%' }}>
                                <Box sx={{ fontWeight: 'bold' }}>
                                    Employment Details
                                </Box>
                                <Box sx={{ display: 'flex', fontSize: '14px' }} >
                                    <Box flexGrow={1}>
                                        <Box sx={{ marginBottom: '20px', marginTop: '20px' }}>
                                            <Box sx={{ fontWeight: 'bold' }}>Employee ID</Box>
                                            <Box sx={{ color: 'grey' }}>{userDetails.emp_id}</Box>
                                        </Box>
                                        <Box sx={{ marginBottom: '20px', marginTop: '20px' }}>
                                            <Box sx={{ fontWeight: 'bold' }}>Designation</Box>
                                            <Box sx={{ color: 'grey' }}>{userDetails.designation}</Box>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Box sx={{ marginBottom: '20px', marginTop: '20px' }}>
                                            <Box sx={{ fontWeight: 'bold' }}>Role</Box>
                                            <Box sx={{ color: 'grey' }}>{userDetails.role}</Box>
                                        </Box>
                                        <Box sx={{ marginBottom: '20px', marginTop: '20px' }}>
                                            <Box sx={{ fontWeight: 'bold' }}>Department</Box>
                                            <Box sx={{ color: 'grey' }}>{userDetails.department}</Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>



                </Box>
            }
            {defaultView &&
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,

                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        background: "#EFF1F3",
                        minHeight: '100vh',
                    }}
                >


                    <div className="department_main">
                        <Box sx={{ padding: '20px 20px 0px 20px', height: '37.1vh', paddingTop: '14vh' }}>
                            <Box sx={{
                                // width: '100%', border: '1px solid grey', backgroundColor: 'white', height: '39px', borderRadius: '5px', marginTop: '5px'
                            }}>
                              
                                    <TextField
fullWidth
sx={{
    marginBottom: '10px',
      borderRadius: '25px',
      backgroundColor: isFocused ? 'white' : '#D0E2FB',
      '.MuiOutlinedInput-root': {
        borderRadius: '25px',
        paddingRight: 2,
       
        fontSize: '0.8rem', // Adjust font size to reduce height
        lineHeight: '1.2', // Adjust line height if needed
        minHeight: '30px', // Set minimum height for the input

      },
      '.MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiInputBase-input::placeholder': {
        color: 'black', // Set placeholder color to black
        opacity: 1, // Ensure the placeholder is fully opaque
    },
    }}
    onFocus={() => setIsFocused(true)}
    onBlur={() => setIsFocused(false)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault(); // Prevent default "Enter" behavior
                                                e.stopPropagation();
                                                fetchMasterData();
                                            }
                                        }}
                                        
                                        onChange={(e) => setStringSearch(e.target.value)}
                                        placeholder='Search by Name/Email/Phone '
                                        value={stringSearch}
                                        InputProps={{

                                            endAdornment: (
                                                <Box display="flex">
                                                    <Box display='flex'>
                                                        {stringSearch && (
                                                            <IconButton onClick={clearSearchQuery}>
                                                                <CloseIcon />
                                                            </IconButton>
                                                        )}
                                                        {stringSearch && stringSearch.length >= 3 && ( // Check if stringSearch has at least 3 characters
                                                            <IconButton onClick={fetchMasterData}>
                                                                <SearchIcon />
                                                            </IconButton>
                                                        )}
                                                    </Box>

                                                </Box>),
                                        }}
                                    />
                                


                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

                                <Box>
                                    <Button variant="contained" style={deptBtnStyle} onClick={openAddUserModal}>+ Add Users</Button>
                                </Box>
                            </Box>

                            <Box sx={{
                                fontSize: '13px', textAlign: 'left', marginTop: '15px', color: 'gray'
                            }}>
                                Total {total_rec ? total_rec : '0'} users
                            </Box>
                        </Box>

                        <Box>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>



                                <TableContainer ref={tableContainerRef}
                                    sx={{ height: '77.9vh', overflow: 'auto' }}>
                                    <Table stickyHeader size="small" aria-label="sticky table">
                                        <TableHead sx={{ backgroundColor: '#eff1f3', color: 'pink' }}>
                                            <TableRow >

                                                <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white', borderLeft: '4px solid white' }}>
                                                    Name
                                                </TableCell>
                                                <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white' }}>
                                                    Email
                                                </TableCell>
                                                <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white' }}>
                                                    Phone
                                                </TableCell>
                                                <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white' }}>
                                                    Status
                                                </TableCell>
                                                <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white' }}>
                                                    View Details
                                                </TableCell>
                                                <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white', borderRight: '4px solid white' }}>
                                                    Action
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {isLoading ? (
                                            // Render loading skeletons while data is being fetched
                                            Array.from({ length: 10 }).map((_, index) => (
                                                <LoadingSkeleton key={index} />
                                            ))
                                        ) : (
                                            <TableBody>
                                                {userSearchResults.map((item, index) => (
                                                    <TableRow sx={{ padding: '0px', height: '20px' }}>
                                                        <TableCell >
                                                            <Box sx={{ display: 'flex' }}>
                                                                <Box sx={{ marginTop: '5px' }}>
                                                                    {item.photo ?
                                                                        <img src={item.photo} style={{ width: '27px', height: '27px', borderRadius: '50px' }} />
                                                                        : <Avatar src="/broken-image.jpg" style={{ height: '100px', width: '100px', marginTop: '8px', marginBottom: '8px', marginRight: '10px', borderRadius: '50px' }} />
                                                                    }
                                                                </Box>
                                                                <Box sx={{ marginTop: '8px', marginLeft: '5px' }}>{item.name}</Box>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell sx={{ color: 'grey' }}>
                                                            {item.email}
                                                        </TableCell>
                                                        <TableCell sx={{ color: 'grey' }}>
                                                            {item.phone}
                                                        </TableCell>
                                                        <TableCell sx={{ color: 'grey' }}>
                                                            {item.status == 'active' &&
                                                                <Button disabled style={{ color: '#27AE60', backgroundColor: '#27AE6029', borderBlockColor: '#27AE603D', borderRadius: '8px', width: '90px', fontWeight: 'bold', textTransform: 'none' }}>
                                                                    Active
                                                                </Button>
                                                            }
                                                            {item.status == 'inactive' &&
                                                                <Button disabled style={{ color: '#F2994A', backgroundColor: '#F2994A29', borderBlockColor: '#F2994A3D', borderRadius: '8px', width: '90px', fontWeight: 'bold', textTransform: 'none' }}>
                                                                    Inactive
                                                                </Button>
                                                            }
                                                            {item.status == 'invite' &&
                                                                <Button disabled style={{ color: 'rgb(47, 128, 237)', backgroundColor: 'rgba(47, 128, 237, 0.12)', borderBlockColor: '#F2994A3D', borderRadius: '8px', width: '90px', fontWeight: 'bold', textTransform: 'none' }}>
                                                                    Invited
                                                                </Button>
                                                            }
                                                        </TableCell>
                                                        <TableCell sx={{ color: 'grey' }}>
                                                            <Button variant="outlined" sx={{ borderRadius: '40px', borderColor: '#1976D2', color: '#1976D2', width: '120px' }}
                                                                onClick={() => DetailView(item)}>View</Button>
                                                        </TableCell>
                                                        <TableCell sx={{ color: 'grey' }}>
                                                            {superAdmin == 'yes' ?

                                                                (item.super_admin == 'no' ?

                                                                    (item.status == 'invite' ?
                                                                        <Button variant="outlined" style={{ borderColor: '#FF0000', color: '#FF0000', borderRadius: '10px', fontSize: '10px' }} color="error" onClick={() => openUndoModal(index, item.user_id)}>Undo</Button>
                                                                        :
                                                                        <IconButton
                                                                            id="basic-button"
                                                                            aria-controls={open ? 'basic-menu' : undefined}
                                                                            aria-haspopup="true"
                                                                            aria-expanded={open ? 'true' : undefined}
                                                                            onClick={(e) => handleActionClick(e, item)}
                                                                        >
                                                                            <MoreVertIcon />
                                                                        </IconButton>)
                                                                    :
                                                                    ('N/A')

                                                                )
                                                                : (2 == item.role_id ? ((item.super_admin == 'no') ?
                                                                    (item.status == 'invite'
                                                                        ?
                                                                        <Button variant="outlined" style={{ borderColor: '#FF0000', color: '#FF0000', borderRadius: '10px', fontSize: '10px' }} color="error" onClick={() => openUndoModal(index, item.user_id)}>Undo</Button>
                                                                        :
                                                                        <IconButton
                                                                            id="basic-button"
                                                                            aria-controls={open ? 'basic-menu' : undefined}
                                                                            aria-haspopup="true"
                                                                            aria-expanded={open ? 'true' : undefined}
                                                                            onClick={(e) => handleActionClick(e, item)}
                                                                        >
                                                                            <MoreVertIcon />
                                                                        </IconButton>)
                                                                    : 'N/A') : 'N/A')

                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                ))}

                                            </TableBody>
                                        )}
                                    </Table>
                                    <InfiniteScroll
                                        dataLength={userSearchResults.length}
                                        next={handleLoadMore}
                                        hasMore={hasMore}
                                        loader={
                                            <CircularProgress disableShrink style={{ margin: "20px" }} />
                                        }
                                        endMessage={
                                            <div style={{ textAlign: "center", marginTop: "10px" }}>
                                                {userSearchResults.length == 0 ? (
                                                    <div
                                                        style={{
                                                            color: "grey",
                                                            fontSize: "13px",
                                                            marginTop: "20px",
                                                        }}
                                                    >
                                                        No record found
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div>No More Records</div>

                                                    </>
                                                )}
                                            </div>
                                        }
                                    ></InfiniteScroll>
                                </TableContainer>

                            </Paper>



                        </Box>

                    </div>
                </Box>
            }
            <Menu
                id="basic-menu"
                anchorEl={moreActionMenu}
                open={openMoreActionMenu}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {(tempUserStatus == 'inactive') ?
                    <MenuItem onClick={openChangeStatusModal}>
                        <ListItemIcon>
                            <PersonIcon />
                        </ListItemIcon>
                        Activate user
                    </MenuItem>
                    : null}
                {(tempUserStatus == 'active') ?
                    <MenuItem onClick={openChangeStatusModal}>
                        <ListItemIcon>
                            <PersonOffIcon />
                        </ListItemIcon>
                        Inactivate user
                    </MenuItem>
                    : null}
            </Menu>
            <Menu
                id="basic-menu"
                anchorEl={actionMenu}
                open={openActionMenu}
                onClose={handleActionClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <Box>
                    <MenuItem onClick={openEditUserModal}>
                        <ListItemIcon>
                            <BorderColorIcon />
                        </ListItemIcon>
                        Edit User
                    </MenuItem>
                    {(tempUserStatus == 'inactive') ?
                        <MenuItem onClick={openChangeStatusModal}>
                            <ListItemIcon>
                                <PersonIcon />
                            </ListItemIcon>
                            Activate user
                        </MenuItem>
                        : null}
                    {(tempUserStatus == 'active') ?
                        <MenuItem onClick={openChangeStatusModal}>
                            <ListItemIcon>
                                <PersonOffIcon />
                            </ListItemIcon>
                            Inactivate user
                        </MenuItem>
                        : null}
                </Box>


            </Menu>
            <Dialog open={addUserModalOpen}
                onClose={closeAddUserModal}
                sx={{
                    "& .MuiDialog-paper": {
                        borderRadius: "20px",
                        width: '530px'
                    },
                }}
                className={{ paper: 'custom-dialog-paper' }}
            >
                <DialogTitle sx={{ paddingBottom: '0px', paddingTop: '20px', marginBottom: '0px', display: 'flex', }} flexGrow={1}>
                    <Box>
                        <strong> Add New User</strong>

                    </Box>
                    <Box position='absolute' left='90%'>
                        <IconButton>
                            <CloseIcon onClick={closeAddUserModal} />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent >
                    <Box sx={{ marginTop: '20px' }}>
                        <Box>
                            <strong>Personal details</strong>

                            <Box sx={{ display: 'flex' }}>
                                <Box>
                                    <Box sx={{ width: '80%', marginTop: '15px' }}>
                                        <strong>Change profile photo</strong>

                                    </Box>
                                    <Box sx={{ color: 'grey', width: '60%', fontSize: '13px' }}>
                                        Customize profile photo by following this format PNG, JPG (max 800x800px)
                                    </Box>
                                </Box>
                                <Box sx={{ marginLeft: '-25%' }}>
                                    {user_photo_view ?
                                        <img src={user_photo_view} style={{ height: '100px', width: '100px', marginTop: '8px', marginRight: '10px', borderRadius: '50px' }} />
                                        :
                                        <Avatar src="/broken-image.jpg" style={{ height: '100px', width: '100px', marginTop: '8px', marginBottom: '8px', marginRight: '10px', borderRadius: '50px' }} />
                                    }
                                </Box>
                                <Box sx={{ color: 'grey', marginTop: '30px', fontSize: '13px' }}>
                                    <Button
                                        onClick={handleButtonClick}
                                        style={{ textTransform: 'none', color: 'grey' }}
                                    >
                                        Click here to upload image or Drag & Drop
                                    </Button>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        hidden
                                        ref={fileInputRef}
                                        onChange={handleFileChangeAdd}
                                    />

                                </Box>
                            </Box>
                            <Divider />
                            <Box sx={{ margin: '15px 0px', fontSize: '14px' }}>
                                <Box display='flex' flexGrow={1} justifyContent='space-between' >
                                    <Box fontWeight='bold'>
                                        Full Name <span style={{ color: 'red' }}>*</span>
                                    </Box>
                                    <Box >
                                        <TextField sx={{ width: '300px' }}
                                            size="small"
                                            placeholder="Enter full name"
                                            onChange={fetchUserName}
                                            InputProps={{
                                                startAdornment: (
                                                    <PersonOutlineOutlinedIcon sx={{ marginRight: '5px', color: 'grey' }} />
                                                ),
                                            }} />
                                    </Box>
                                </Box>
                                <Box>

                                    <Box sx={{ display: 'flex', marginTop: '10px' }} justifyContent='space-between'>

                                        <Box fontWeight='bold'>
                                            <FormLabel sx={{ fontWeight: 'bold', color: 'black', fontSize: '14px', lineHeight: "2.5em" }}>Gender</FormLabel>
                                        </Box>
                                        <Box sx={{}}>
                                            <FormControl
                                                onChange={fetchUserGender}
                                            >
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue="male"
                                                    name="radio-buttons-group"
                                                >
                                                    <Box sx={{ display: 'flex' }}>
                                                        <FormControlLabel value="male" control={<Radio />} label="Male" />
                                                        <FormControlLabel value="female" control={<Radio />} label="Female" />
                                                    </Box>


                                                </RadioGroup>
                                            </FormControl>
                                        </Box>

                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Divider />
                        <Box sx={{ margin: '15px 0px', fontSize: '14px' }} fontWeight='bold'>
                            Contact details
                            <Box sx={{ margin: '15px 0px' }}>
                                <Box sx={{ display: 'flex', margin: '20px 0px' }} justifyContent='space-between'>
                                    <Box fontWeight='bold'>Email<span style={{ color: 'red' }}>*</span> </Box>
                                    <Box sx={{}}>
                                        <TextField sx={{ width: '300px' }}
                                            size="small"
                                            placeholder="Enter email address"
                                            onChange={fetchUserEmail}
                                            inputProps={{
                                                pattern: "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}",
                                                title: "Please enter a valid email address",
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <MailOutlineIcon sx={{ marginRight: '5px', color: 'grey' }} />
                                                ),
                                            }} />
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', margin: '20px 0px' }} justifyContent='space-between'>
                                    <Box fontWeight='bold'>Mobile<span style={{ color: 'red' }}>*</span> </Box>
                                    <Box sx={{}}>
                                        <TextField sx={{ width: '300px' }}
                                            size="small"
                                            placeholder="Enter mobile number"
                                            onChange={fetchUserPhone}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PhoneIcon sx={{ marginRight: '5px', color: 'grey' }} />
                                                        +91
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Divider />
                        <Box fontWeight='bold' sx={{ marginTop: '20px', fontSize: '14px' }}>
                            Employment details
                            <Box>
                                <Box sx={{ display: 'flex', margin: '20px 0px' }} justifyContent='space-between'>
                                    <Box fontWeight='bold'>
                                        Employee id
                                    </Box>
                                    <Box sx={{}}>
                                        <TextField sx={{ width: '300px' }}
                                            size="small"
                                            placeholder="Enter employee id"
                                            onChange={fetchUserEmpid}
                                            InputProps={{
                                                startAdornment: (
                                                    <WorkOutlineIcon sx={{ marginRight: '5px', color: 'grey' }} />
                                                ),
                                            }} />
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', margin: '20px 0px' }} justifyContent='space-between'>
                                    <Box fontWeight='bold'>
                                        Designation
                                    </Box>
                                    <Box sx={{}}>

                                        <FormControl sx={{ width: '300px' }}>
                                            <InputLabel sx={{ top: '-5px' }} id="demo-simple-select-label">Choose Designaton</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                size="small"
                                                label="Choose Designaton"
                                                value={user_designation}
                                                onChange={(e) => setUser_designation(e.target.value)}
                                            >
                                                <MenuItem onClick={handleOpenDialog}>
                                                    <ListItemIcon>
                                                        <AddIcon sx={{ fontWeight: "bold" }} /> {/* Render the MUI icon */}
                                                    </ListItemIcon>
                                                    <b>Create New Designation</b>
                                                </MenuItem>
                                                {designationSearchResults.map((item, index) => (
                                                    <MenuItem
                                                        onChange={fetchUserDesignation}
                                                        value={item.desg_id}>{item.desg_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', margin: '20px 0px' }} justifyContent='space-between'>
                                    <Box fontWeight='bold'>
                                        Select Role<span style={{ color: 'red' }}>*</span>
                                    </Box>
                                    <Box sx={{}}>

                                        <FormControl sx={{ width: '300px' }} >
                                            <InputLabel sx={{ top: '-5px' }} id="demo-simple-select-label">Select Role</InputLabel>
                                            <Select
                                                size="small"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                onChange={fetchUserRole}
                                                label="Select Role"
                                            >
                                                {roleSearchResults.map((item, index) => (
                                                    <MenuItem

                                                        value={item.role_id}
                                                    >{item.role_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', margin: '20px 0px' }} justifyContent='space-between'>
                                    <Box fontWeight='bold'>
                                        Select Department<span style={{ color: 'red' }}>*</span>
                                    </Box>
                                    <Box sx={{}}>

                                        <FormControl sx={{ width: '300px' }}>
                                            <InputLabel sx={{ top: '-5px' }} id="demo-simple-select-label">Select Department</InputLabel>
                                            <Select
                                                size="small"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                onChange={fetchUserDept}
                                                label="Select Department"

                                            >
                                                {departmentSearchResults.map((item, index) => (
                                                    <MenuItem

                                                        value={item.dept_id}
                                                    >{item.dept_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ marginBottom: '10px' }}>
                    <Button onClick={closeAddUserModal} variant="outlined" sx={{ borderRadius: '200px' }}>
                        Cancel
                    </Button >
                    <Button variant="contained" sx={{ borderRadius: '200px', }} backgroundColor={buttonLoading && '#616161'} disabled={buttonLoading} onClick={handleAddUser}>
                        {buttonLoading && <CircularProgress size={16} sx={{ marginRight: '10px' }} />} Save User
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={editUserModalOpen}
                onClose={closeEditUserModal}
                sx={{
                    "& .MuiDialog-paper": {
                        borderRadius: "20px",
                        width: '530px'
                    },
                }}
                className={{ paper: 'custom-dialog-paper' }}
            >
                <DialogTitle sx={{ paddingBottom: '0px', paddingTop: '20px', marginBottom: '0px', display: 'flex', }} flexGrow={1}>
                    <Box>
                        <strong>Edit User Details</strong>

                    </Box>
                    <Box position='absolute' left='90%'>
                        <IconButton>
                            <CloseIcon onClick={closeEditUserModal} />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent >

                    <Box sx={{ marginTop: '20px' }}>
                        <Box>
                            <strong>Personal details</strong>

                            <Box sx={{ display: 'flex' }}>
                                <Box>
                                    <Box sx={{ width: '80%', marginTop: '15px' }}>
                                        <strong>Change profile photo</strong>

                                    </Box>
                                    <Box sx={{ color: 'grey', width: '60%', fontSize: '13px' }}>
                                        Customize profile photo by following this format PNG, JPG (max 800x800px)
                                    </Box>
                                </Box>
                                <Box sx={{ marginLeft: '-25%' }}>
                                    <img src={tempUserPhoto_view} style={{ height: '100px', width: '100px', marginTop: '8px', marginRight: '10px' }} />
                                </Box>
                                <Box sx={{ color: 'grey', marginTop: '30px', fontSize: '13px' }}>
                                    <Button
                                        onClick={handleButtonClick}
                                        style={{ textTransform: 'none', color: 'grey' }}
                                    >
                                        Click here to upload image or Drag & Drop
                                    </Button>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        hidden
                                        ref={fileInputRef}
                                        onChange={handleFileChangeEdit}
                                    />

                                </Box>
                            </Box>
                            <Divider />
                            <Box sx={{ margin: '15px 0px', fontSize: '14px' }}>
                                <Box display='flex' flexGrow={1} justifyContent='space-between' >
                                    <Box fontWeight='bold'>
                                        Full Name <span style={{ color: 'red' }}>*</span>
                                    </Box>
                                    <Box sx={{}}>
                                        <TextField sx={{ width: '300px' }}
                                            size="small"
                                            placeholder="Enter full name"
                                            value={tempUserName}
                                            onChange={(e) => setTempUserName(e.target.value)}
                                            InputProps={{
                                                startAdornment: (
                                                    <PersonOutlineOutlinedIcon sx={{ marginRight: '5px', color: 'grey' }} />
                                                ),
                                            }} />
                                    </Box>
                                </Box>
                                <Box>

                                    <Box sx={{ display: 'flex', marginTop: '10px' }} justifyContent='space-between'>

                                        <Box fontWeight='bold'>
                                            <FormLabel sx={{ fontWeight: 'bold', color: 'black', fontSize: '14px', lineHeight: "2.5em" }}>Gender</FormLabel>
                                        </Box>
                                        <Box sx={{}}>
                                            <FormControl

                                                onChange={(e) => setTempUsergender(e.target.value)}
                                            >
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    value={tempUserGender}
                                                    name="radio-buttons-group"
                                                >
                                                    <Box sx={{ display: 'flex' }}>
                                                        <FormControlLabel value="male" control={<Radio />} label="Male" />
                                                        <FormControlLabel value="female" control={<Radio />} label="Female" />
                                                    </Box>


                                                </RadioGroup>
                                            </FormControl>
                                        </Box>

                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Divider />
                        <Box sx={{ margin: '15px 0px', fontSize: '14px' }} fontWeight='bold'>
                            Contact details
                            <Box sx={{ margin: '15px 0px' }}>
                                <Box sx={{ display: 'flex', margin: '20px 0px' }} justifyContent='space-between'>
                                    <Box fontWeight='bold'>Email<span style={{ color: 'red' }}>*</span> </Box>
                                    <Box sx={{}}>
                                        <TextField sx={{ width: '300px' }}
                                            size="small"
                                            placeholder="Enter email address"
                                            value={tempUserEmail}
                                            onChange={(e) => setTempUserEmail(e.target.value)}
                                            InputProps={{
                                                startAdornment: (
                                                    <MailOutlineIcon sx={{ marginRight: '5px', color: 'grey' }} />
                                                ),
                                            }} />
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', margin: '20px 0px' }} justifyContent='space-between'>
                                    <Box fontWeight='bold'>Mobile<span style={{ color: 'red' }}>*</span> </Box>
                                    <Box sx={{}}>
                                        <TextField sx={{ width: '300px' }}
                                            size="small"
                                            placeholder="Enter mobile number"
                                            value={tempUserPhone}
                                            onChange={(e) => setTempUserPhone(e.target.value)}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PhoneIcon sx={{ marginRight: '5px', color: 'grey' }} />
                                                        +91
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Divider />
                        <Box fontWeight='bold' sx={{ marginTop: '20px', fontSize: '14px' }}>
                            Employment details
                            <Box>
                                <Box sx={{ display: 'flex', margin: '20px 0px' }} justifyContent='space-between'>
                                    <Box fontWeight='bold'>
                                        Employee id
                                    </Box>
                                    <Box sx={{}}>
                                        <TextField sx={{ width: '300px' }}
                                            size="small"
                                            placeholder="Enter employee id"
                                            value={tempUserEmpid}
                                            onChange={(e) => setTempUserEmpid(e.target.value)}
                                            InputProps={{
                                                startAdornment: (
                                                    <WorkOutlineIcon sx={{ marginRight: '5px', color: 'grey' }} />
                                                ),
                                            }} />
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', margin: '20px 0px' }} justifyContent='space-between'>
                                    <Box fontWeight='bold'>
                                        Designation
                                    </Box>
                                    <Box sx={{}}>

                                        <FormControl sx={{ width: '300px' }}>
                                            <InputLabel sx={{ top: '-5px' }} id="demo-simple-select-label">Choose Designaton</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                size="small"
                                                id="demo-simple-select"
                                                value={tempUserDegn}
                                                onChange={(e) => setTempUserDegn(e.target.value)}
                                                label="Choose Destination"
                                            >

                                                <MenuItem onClick={handleOpenDialog}>
                                                    <ListItemIcon>
                                                        <AddIcon sx={{ fontWeight: "bold" }} /> {/* Render the MUI icon */}
                                                    </ListItemIcon>
                                                    <b>Create New Designation</b>
                                                </MenuItem>
                                                {designationSearchResults.map((item, index) => (
                                                    <MenuItem
                                                        onChange={fetchUserDesignation}
                                                        value={item.desg_id}>{item.desg_name}</MenuItem>

                                                ))}
                                            </Select>
                                        </FormControl>

                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', margin: '20px 0px' }} justifyContent='space-between'>
                                    <Box fontWeight='bold'>
                                        Select Role<span style={{ color: 'red' }}>*</span>
                                    </Box>
                                    <Box sx={{}}>

                                        <FormControl sx={{ width: '300px' }} >
                                            <InputLabel sx={{ top: '-5px' }} id="demo-simple-select-label">Select Role</InputLabel>
                                            <Select
                                                size="small"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={tempUserRole}
                                                onChange={(e) => setTempUserRole(e.target.value)}
                                                label="Select Role"
                                            >
                                                {roleSearchResults.map((item, index) => (
                                                    <MenuItem

                                                        value={item.role_id}
                                                    >{item.role_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', margin: '20px 0px' }} justifyContent='space-between'>
                                    <Box fontWeight='bold'>
                                        Select Department<span style={{ color: 'red' }}>*</span>
                                    </Box>
                                    <Box sx={{}}>

                                        <FormControl sx={{ width: '300px' }}>
                                            <InputLabel sx={{ top: '-5px' }} id="demo-simple-select-label">Select Department</InputLabel>
                                            <Select
                                                size="small"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={tempUserDept}
                                                onChange={(e) => setTempUserDept(e.target.value)}
                                                label="Select Department"

                                            >
                                                {departmentSearchResults.map((item, index) => (
                                                    <MenuItem

                                                        value={item.dept_id}
                                                    >{item.dept_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ marginBottom: '10px' }}>
                    <Button onClick={closeEditUserModal} variant="outlined" sx={{ borderRadius: '200px' }}>
                        Cancel
                    </Button >
                    <Button variant="contained" sx={{ borderRadius: '200px', }} backgroundColor={buttonLoading && '#616161'} disabled={buttonLoading} onClick={handleEditUser}>
                        {buttonLoading && <CircularProgress size={16} sx={{ marginRight: '10px' }} />} Save User
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={changeStatusModalOpen} onClose={closeChangeStatusModal} >
                <DialogTitle sx={{ paddingBottom: '0px', paddingTop: '32px', marginBottom: '0px' }}>Status Change Confirmation</DialogTitle>
                <DialogContent sx={{ width: '500px' }}>
                    Are you sure you want to change your status?
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeChangeStatusModal} color="primary">
                        No
                    </Button>
                    {/* <Button color="primary" onClick={handleDelete}>
                                        Apply
                                    </Button> */}
                    <Button onClick={StatusChange} backgroundColor={buttonLoading && '#616161'} disabled={buttonLoading}>
                        {buttonLoading && <CircularProgress size={16} sx={{ marginRight: '10px' }} />} Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={isUndoModal} onClose={closeUndoModal} >
                <DialogTitle sx={{ paddingBottom: '0px', paddingTop: '32px', marginBottom: '0px' }}>Delete Confirmation</DialogTitle>
                <DialogContent sx={{ width: '500px' }}>
                    Are you sure you want to undo this user?
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeUndoModal} color="primary">
                        Cancel
                    </Button>
                    {/* <Button color="primary" onClick={handleDelete}>
                                        Apply
                                    </Button> */}
                    <Button onClick={handleUndoUser} backgroundColor={buttonLoading && '#616161'} disabled={buttonLoading}>
                        {buttonLoading && <CircularProgress size={16} sx={{ marginRight: '10px' }} />} Undo
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                mode="outlined"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center', // Center bottom
                }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <SnackbarContent
                    style={{
                        backgroundColor: 'black',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                    message={
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            {toastmessage}
                        </span>
                    }
                    action={[
                        <IconButton key="close" color="inherit" onClick={handleSnackbarClose}>
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </Snackbar>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Create New Designation</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="New Designation"
                        value={newDesignation}
                        onChange={(e) => setNewDesignation(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleCreateNewDesignation} disabled={!newDesignation.trim()} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default User;
