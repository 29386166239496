
// import AsyncStorage from '@react-native-async-storage/async-storage';
 // Import AsyncStorage
/**
 * Updates user profile information including their full name and profile picture.
 *
 * @param {string} user_id - The ID of the user whose profile is to be updated.
 * @param {string} org_id - The organization ID related to the user's profile.
 * @param {string} full_name - The new full name to update in the user's profile.
 * @param {string} token - User authentication token for API authorization.
 * @param {object} image - The user's profile image to update (optional).
 * @returns {Promise<object>} - The API response after updating the profile.
 */
export const updateProfile = async (user_id, org_id, full_name,email,phone, token, profilePhoto) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("token", token);

    var formdata = new FormData();
    formdata.append("user_id", user_id.toString());
    formdata.append("org_id", org_id.toString());
    formdata.append("full_name", full_name.toString());
    formdata.append("photo", profilePhoto);
    formdata.append("email", email.toString());
    formdata.append("phone", phone.toString());

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };

    const response = await fetch(`${window.constants.api_url}/profile-update`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log('error', error);
  }
};


//Changes the user's password by sending a request to the  API endpoint.
export async function changePassword(
  user_id,
  old_password,
  new_password,
  token
) {
  try {
    const userData = {
      user_id,
      old_password,
      new_password,
    };

    const headers = {
      token: token,
      "Content-Type": "application/json",
    };

    const response = await fetch(`${window.constants.api_url}/changepassword`, {
      method: "POST",
      headers,
      body: JSON.stringify(userData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
}
/**
 * Logs a user in by sending a request to the  login API endpoint.
 *
 * @param {string} email - The user's email address for login.
 * @param {string} password - The user's password for login authentication.
 * @returns {Promise<object>} - The API response after the login attempt.
 */
export const loginUser = async (email, password) => {
  let BaseURL =`${window.constants.api_url}/login`;
  let userData = {
    email_id: email,
    password: password,
  };

  try {
    const response = await fetch(BaseURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    });

    if (response.status === 200) {
      const res = await response.json();
      return res;
    } else {
      return { code: 'error', message: 'Login failed' };
    }
  } catch (error) {
    console.error('Error:', error);
    return { code: 'error', message: 'An error occurred' };
  }
};

export const newPassword = async ( password,reset_token) => {
  let BaseURL =`${window.constants.api_url}/setnew-password`;
  let userData = {
    password: password,
    reset_token: reset_token,
  };

  try {
    const response = await fetch(BaseURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    });

    if (response.status === 200) {
      const res = await response.json();
      return res;
    } else {
      return { code: 'error', message: 'Login failed' };
    }
  } catch (error) {
    console.error('Error:', error);
    return { code: 'error', message: 'An error occurred' };
  }
};