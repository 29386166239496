import React, { useState, useEffect, useRef } from "react";
import { PieChart, Pie, Cell, Legend } from "recharts";
import Box from "@mui/material/Box";
import { Grid, List, ListItem, Typography, TextField, Tabs, Badge, Tab, IconButton, Pagination, TablePagination, TableFooter } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import GroupsIcon from '@mui/icons-material/Groups';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { Skeleton, Dialog, DialogContent, DialogTitle, ListItemAvatar, Chip } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ListIcon from '@mui/icons-material/List';
import Stack from '@mui/material/Stack';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import { GetActivityforDashboard } from "./DeptApiHelper";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { getAllRedListbyid } from "../CriticalActivitty/CriticalActivitiesApi";
import CloseIcon from '@mui/icons-material/Close';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import WorkIcon from '@mui/icons-material/Work';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import TaskIcon from '@mui/icons-material/Task';
import ShortTextIcon from '@mui/icons-material/ShortText';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
const Activities = ({ variable }) => {
    const departmentType = variable
    const [selectedLabel, setSelectedLabel] = useState(''); // Store the selected label

    const [loading, setLoading] = useState(false);
    const [initialFetchDone, setInitialFetchDone] = useState(false); // State to track if initial data fetch has been done

    const [tabValue, setTabValue] = useState(0);
    const [page, setPage] = useState(0);
    const [Docpath, SetDocpath] = useState('');
    const [totalRowCount, setTotalRowCount] = useState(0); // Total count of rows
    const [rowsPerPage, setRowsPerPage] = useState(5); // Number of rows per page
    const [tabCounts, setTabCounts] = useState({ upcoming: 0, overdue: 0, complete: 0 });
    const [viewRedList, setViewRedList] = useState(false);
    const [viewisLoading, setviewisLoading] = useState(false);
    const [tablabel, settablabel] = useState('');
    const [userData, setUserData] = useState([]);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        setPage(0); // Reset page to 0 when changing tabs
    };
    const LoadingSkeleton = () => {
        // You can customize the skeleton based on your design
        return (
            <Stack spacing={2} sx={{ padding: '30px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box display='flex'>
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                        <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={80} height={30} />
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    </Box>
                    <Box display='flex'>
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    </Box>
                </Box>
                <Box display='flex'>
                    <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={500} height={30} />
                </Box>
                <Box display='flex'>
                    <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={500} height={90} />
                </Box>
                <Box display='flex'>
                    <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={500} height={30} />
                </Box>
                <Box display='flex'>
                    <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={500} height={30} />
                </Box>
                <Box display='flex'>
                    <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={500} height={30} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box display='flex'>
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                        <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                        <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    </Box>
                    <Box display='flex'>
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                        <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                        <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    </Box>
                </Box>
                <Box display='flex'>
                    <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={500} height={30} />
                </Box>


            </Stack>
        );
    };
    const TableSkeleton = () => {
        return (
            <table>
                <thead>
                    <tr>
                        <th align="center">#</th>
                        <th align="center">Event</th>
                        <th align="center">{departmentType == 'department' ? "Departments" : "Areas"}</th>
                        <th align="center">Date</th>
                    </tr>
                </thead>
                <tbody>
                    {[...Array(10)].map((_, index) => (
                        <tr key={index}>
                            <td align="center">
                                <Skeleton width={40} height={20} />
                            </td>
                            <td align="center">
                                <Skeleton width={150} height={20} />
                            </td>
                            <td align="center">
                                <Skeleton width={150} height={20} />
                            </td>
                            <td align="center">
                                <Skeleton width={150} height={20} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };




    const cardStyle = {
        display: "flex",

        height: "400px",
        padding: "16px",
        flexDirection: "column",
        alignItems: "flex-start",

        borderRadius: "8px",
        border: "1px solid rgba(0, 0, 0, 0.50)", // Adjust this line if you need to use custom variables
    };
    const [deptActivityResults, setdeptActivityResults] = useState([]);
    useEffect(() => {
        fetchData();


    }, [totalRowCount, rowsPerPage, page, selectedLabel]);
    const fetchData = async (label) => {

        setLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
            } = userDataObj;

            let startIndex = page * rowsPerPage;
            if (!initialFetchDone) { // If initial fetch is not done, set startIndex to 0
                startIndex = 0;
            }
            const data = await GetActivityforDashboard(
                deepuser_id,
                deeporg_id,
                departmentType,
                selectedLabel,
                userToken,
                startIndex, rowsPerPage
            );
            if (data.code == "200") {

                const newResults = data.data;


                setLoading(false);
                const totalCount = selectedLabel === "overdue" ? data.total_overdue : selectedLabel === "complete" ? data.total_complete : data.total_upcoming;
                setTotalRowCount(totalCount);
                setdeptActivityResults(data.data);
                setTabCounts({ upcoming: data.total_upcoming, overdue: data.total_overdue, complete: data.total_complete });
                setInitialFetchDone(true);
            } else {
                console.error("Error fetching user data:", error);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            setLoading(false);
        } finally {
            setLoading(false);
            // Make sure to set isLoading to false whether the fetch was successful or not
            //setIsLoading(false);
        }
    };


    const handleTabClick = (label) => {
        // Call your function here with the label data
        // For example:

        setSelectedLabel(label)

        settablabel(label);
    };
    const handleChangePage = (event, newPage) => {
        fetchData(tablabel);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset page to 0 when changing rows per page
    };

    // Dynamic rowsPerPageOptions based on your preferences

    const openViewAddRedListModal = async (redlistid) => {

        setviewisLoading(true);
        setViewRedList(true);
        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            // Use the API helper function to fetch query history data
            const data = await getAllRedListbyid(deepuser_id, deeporg_id, redlistid, userToken);

            if (data.code === "200") {
                setviewisLoading(false)
                setUserData(data.data);

                SetDocpath(data.doc_path);
                if (data.data[0]?.notificationto) { // Check if notificationto is defined
                    setTempassign(data.data[0].notificationto.map(attendee => attendee));
                }
                console.log(data.data);
            } else {
                console.log(data.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error, show a message or log it
            // seterrorSnackbar(true);
        }



    };
    const closeViewRedListModal = () => {
        setViewRedList(false);
    }
    const tabStyle = (isActive) => ({
        textDecoration: 'none',
        textTransform: 'none',
        fontWeight: 'bold',
        marginRight: '10px',
        backgroundColor: isActive ? '#D8E2FF' : 'inherit',
        borderRadius: "10px 10px 0px 0px",
        borderBottom: isActive ? "2px solid #005AC1" : 'none',
        color: isActive ? "#005AC1" : 'inherit',
    });

    return (
        <Box sx={{ padding: "10px", }} style={cardStyle}>
            <Box display="flex"
            //  alignItems="center"
            >
                <IconButton
                    size="small"
                    sx={{
                        borderRadius: "100px",
                        background: "var(--blueGrey-50, #ECEFF1)",
                    }}
                >
                    <DirectionsRunIcon color="primary" />
                </IconButton>
                <Typography
                    variant="h5"
                    component="div"
                    sx={{
                        color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
                        // fontFamily: "Roboto",
                        fontSize: "22px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "133.4%",
                        marginLeft: "10px",
                    }}
                >
                    Activities  (Critical)
                </Typography>
            </Box>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                sx={{
                    marginBottom: '10px',

                }}
                indicatorColor="primary"
                textColor="primary"
                aria-label="disabled tabs example"
            >
                <Tab sx={{ fontWeight: "bold", position: "relative", padding: '12px 25px 12px 8px' }} onClick={() => handleTabClick("upcoming")} label={
                    <React.Fragment>
                        Upcoming
                        <Badge badgeContent={tabCounts.upcoming} color="primary" sx={{ position: "relative", marginLeft: 12, top: -10, right: -9 }} />
                    </React.Fragment>
                } />
                <Tab sx={{ fontWeight: "bold", position: "relative", padding: '12px 25px 12px 8px' }} onClick={() => handleTabClick("overdue")} label={
                    <React.Fragment>
                        Overdue
                        <Badge badgeContent={tabCounts.overdue} color="error" sx={{ position: "relative", marginLeft: 12, top: -10, right: -8 }} />
                    </React.Fragment>
                } />
                <Tab sx={{ fontWeight: "bold", position: "relative", padding: '12px 25px 12px 8px' }} onClick={() => handleTabClick("complete")} label={
                    <React.Fragment>
                        Completed
                        <Badge badgeContent={tabCounts.complete} color="success" sx={{ position: "relative", marginLeft: 14, top: -10, right: -9 }} />
                    </React.Fragment>
                } />
            </Tabs>
            {loading ? (
                <TableSkeleton />
            ) : (
                <>

                    <TableContainer>
                        {deptActivityResults && deptActivityResults.length > 0 ? (
                            <>
                                <Table size="small" stickyHeader>
                                    <TableHead >
                                        <TableRow>
                                            <TableCell sx={{ backgroundColor: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))' }}>#</TableCell>
                                            <TableCell sx={{ backgroundColor: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))' }}>Event</TableCell>
                                            <TableCell sx={{ backgroundColor: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))' }}>{departmentType == 'department' ? "Departments" : "Areas"}</TableCell>
                                            <TableCell sx={{ backgroundColor: 'var(--action-disabledBackground, rgba(0, 0, 0, 0.12))' }}>Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* Render only the data for the current page */}
                                        {(deptActivityResults || []).map((activity, index) => (
                                            <TableRow align="center" key={index} >
                                                <TableCell>{activity.sl_no}</TableCell>
                                                <TableCell onClick={() => openViewAddRedListModal(activity.redlist_id)}>
                                                    <Typography cursor="pointer"
                                                        sx={{
                                                            color: '#101828', cursor: 'pointer', fontSize: '14px', fontWeight: 'bold', width: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                                                            '&:hover': {
                                                                textDecoration: 'underline',// Set the hover color
                                                                color: '#2196F3'
                                                            },
                                                        }}>
                                                        {activity.agenda}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ width: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', }}>{activity.dept_name}</TableCell>
                                                <TableCell>{activity.end_datetime}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>

                                </Table>
                                <TablePagination
                                    component="div"
                                    count={totalRowCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />

                            </>
                        ) : (
                            <h4 style={{ textAlign: 'center', justifyContent: 'center' }}>No activities to display</h4>
                        )}
                    </TableContainer>

                </>

            )}
            <Dialog fullWidth maxWidth="sm" open={viewRedList} onClose={closeViewRedListModal}>

                {viewisLoading ? (

                    <DialogContent >
                        <LoadingSkeleton />
                    </DialogContent>
                ) : (
                    userData.map((item, index) => (
                        <div key={index}>
                            <DialogTitle sx={{ padding: '0px 5px ' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '15px 15px 0px 15px' }}>
                                    <Box sx={{ display: 'flex', margin: '10px ' }}>
                                        <Box>
                                            <AddCardOutlinedIcon sx={{}} />
                                        </Box>
                                        <Typography sx={{ fontWeight: 'bold', marginLeft: '10px' }}>View Critical Activity</Typography>
                                    </Box>
                                    <Box display='flex'>

                                        <Box>
                                            <IconButton onClick={closeViewRedListModal}> <CloseIcon /></IconButton>
                                        </Box>
                                    </Box>

                                </Box>
                            </DialogTitle>

                            <DialogContent sx={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto', }}>

                                <Box >
                                    <Box sx={{ display: 'flex', marginBottom: "20px", marginTop: '20px' }}>
                                        <MeetingRoomIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>{departmentType == 'department' ? "Department:" : "Area:"}</Typography></Box>
                                        <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.dept_name}</Typography></Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <WorkIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Project Name:</Typography></Box>
                                        <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.project_name}</Typography></Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <TaskIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Agenda:</Typography></Box>
                                        <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.agenda}</Typography></Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <ShortTextIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Description: </Typography></Box>
                                        <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.description}</Typography></Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <PersonAddIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Assign To:</Typography></Box>

                                        <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}> {item.assignto_name}</Typography>

                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <NotificationsIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>{"Notification To:"}</Typography></Box>
                                        <List sx={{ marginLeft: '10px', padding: '0px' }}>
                                            {item.notificationto.map((item, index) => (
                                                <ListItem key={item.attendees_id} sx={{ padding: '0px' }}> {/* Make sure to include a unique key for each item */}
                                                    <ListItemAvatar sx={{ minWidth: '30px', paddingTop: '5px' }}>
                                                        <img src={item.photo} style={{ width: '20px', height: '20px', borderRadius: '50px' }} />
                                                    </ListItemAvatar>
                                                    <Typography sx={{ fontSize: '14px' }}>
                                                        {item.name}
                                                    </Typography>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Box>

                                    <Box sx={{ marginBottom: "20px", display: 'flex' }}>
                                        <CalendarTodayIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>Activity Start:</Typography></Box>
                                        <Box>
                                            <Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.start_datetime != "" ? item.start_datetime : "N/A"}</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ marginBottom: "20px", display: 'flex' }}>
                                        <CalendarTodayIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Activity End:</Typography></Box>
                                        <Box>
                                            <Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.end_datetime}</Typography>
                                        </Box>
                                    </Box>


                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <HourglassTopIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box>
                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Status: </Typography>

                                        </Box>
                                        <Box sx={{ display: 'flex', marginLeft: '10px' }}>
                                            {item.status == 'new' &&
                                                <Chip label='New' size="small" color="primary" sx={{ width: '90px' }} />
                                            }
                                            {item.status == 'inprogress' &&
                                                <Chip label='In Progress' size="small" color="warning" sx={{ width: '90px' }} />
                                            }
                                            {item.status == 'complete' &&
                                                <Chip label='Complete' size="small" color="success" sx={{ width: '90px' }} />
                                            }
                                            {item.status == 'hold' &&
                                                <Chip label='Hold' size="small" color="error" sx={{ width: '90px' }} />
                                            }
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <ShowChartIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box>
                                            <Box>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Milestones: </Typography>

                                            </Box>
                                            <Box sx={{ display: 'flex', fontSize: '14px', marginTop: '10px' }}>
                                                <Box sx={{ display: 'flex' }}>
                                                    {item.milestone && item.milestone.length > 0 ? (

                                                        <TableContainer >

                                                            <Table size="small" aria-label="a dense table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell sx={{ backgroundColor: 'rgba(0, 0, 0, 0.00)', fontWeight: 'bold' }} > Description</TableCell>
                                                                        <TableCell sx={{ backgroundColor: 'rgba(0, 0, 0, 0.00)', fontWeight: 'bold' }}>Due Date</TableCell>
                                                                        <TableCell sx={{ backgroundColor: 'rgba(0, 0, 0, 0.00)', fontWeight: 'bold' }} >Assign To</TableCell>
                                                                        {/* <th style={{ border: '1px solid #dddddd', padding: '8px' }}>photo</th> */}
                                                                        <TableCell sx={{ backgroundColor: 'rgba(0, 0, 0, 0.00)', fontWeight: 'bold' }}>Status</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {item.milestone.map((milestone, index) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell >{milestone.milestone_description}</TableCell>
                                                                            <TableCell >{milestone.due_date}</TableCell>

                                                                            <TableCell >
                                                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>

                                                                                    <Avatar src={milestone.assign_to_photo} alt={milestone.assign_to_name} sx={{ height: 25, width: 25, right: 5 }} />
                                                                                    <Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{milestone.assign_to_name}</Typography>
                                                                                </Box>
                                                                            </TableCell>
                                                                            {/* <td style={{ border: '1px solid #dddddd', padding: '8px' }}>  </td> */}
                                                                            <TableCell >{milestone.status}</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>

                                                    ) : (

                                                        <Typography sx={{ textAlign: 'center' }} variant="body1">N/A</Typography>

                                                    )}
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <FilePresentIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box>
                                            <Box>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>Document: </Typography>

                                            </Box>
                                            <List>
                                                {item.document.map((file, docindex) => (
                                                    <Box key={docindex} sx={{
                                                        backgroundColor: '', cursor: 'pointer', '&:hover': {
                                                            textDecoration: 'underline',// Set the hover color
                                                            color: '#2196F3'
                                                        }, margin: '0px 0px 10px 0px ', overflow: 'auto'
                                                    }}>

                                                        <Box display='flex' justifyContent='space-between' alignItems='center' padding='10px'>
                                                            <Box display='flex' alignItems='center'>
                                                                <Box>
                                                                    <Box>
                                                                        {file.doc_name.endsWith('.pdf') && <DescriptionIcon sx={{ color: 'red', margin: '0 10px 0 0' }} />}
                                                                        {file.doc_name.endsWith('.xls') && <DescriptionIcon sx={{ color: 'green', margin: '0 10px 0 0' }} />}
                                                                        {file.doc_name.endsWith('.xlsx') && <DescriptionIcon sx={{ color: 'green', margin: '0 10px 0 0' }} />}
                                                                        {file.doc_name.endsWith('.doc') && <DescriptionIcon sx={{ color: 'blue', margin: '0 10px 0 0' }} />}
                                                                        {file.doc_name.endsWith('.docx') && <DescriptionIcon sx={{ color: 'blue', margin: '0 10px 0 0' }} />}
                                                                        {file.doc_name.endsWith('.ppt') && <DescriptionIcon sx={{ color: '#FFA500', margin: '0 10px 0 0' }} />}
                                                                        {file.doc_name.endsWith('.pptx') && <DescriptionIcon sx={{ color: '#FFA500', margin: '0 10px 0 0' }} />}
                                                                    </Box>
                                                                </Box>
                                                                <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px', fontWeight: 'bold' }} onClick={() => RedListDocviewPreview(docindex, file.doc_id, file.doc_name, file.doc_path, item.redlist_id)}>{file.doc_name} {LoadingviewStates[docindex] && <CircularProgress size={18} sx={{ marginRight: '12px' }} />}</Box>
                                                            </Box>

                                                            <Box sx={{ fontSize: '12px' }}>
                                                                <Typography sx={{ fontSize: '13px', marginLeft: '10px' }}>{file.doc_size}</Typography>
                                                            </Box>
                                                        </Box>

                                                    </Box>
                                                ))}
                                            </List>
                                        </Box>
                                    </Box>

                                </Box>

                            </DialogContent>

                        </div>
                    ))
                )}
            </Dialog>
        </Box>
    );
};

export default Activities;
