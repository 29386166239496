import React, { useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Typography, Badge, Link } from "@mui/material";
import { GetNotificationCount } from "../CmdLink/CmdLinkApi.js";
import logo from '../../../image 1.png';
import favicon from '../../../favicon.png';
import "./Sidebar.css";
import departmentLogo from "../images/domain_24px.svg";
import personLogo from "../images/person_24px.svg";
import peopleLogo from "../images/people_24px.svg";
import arrowLogo from "../images/arrow_forward_24px.svg";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import Logout from '@mui/icons-material/Logout';
import LockIcon from '@mui/icons-material/Lock';
import PeopleIcon from '@mui/icons-material/People';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import BusinessIcon from '@mui/icons-material/Business';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
const drawerWidth = 130;
import image1 from '../../../soochna kosh-black.png';
export default function DrawerComponent({ updateNotifications }) {

  const icon = [ <InsertLinkIcon />,<BusinessIcon />, <PeopleIcon />, <InsertDriveFileIcon />, <ThumbsUpDownIcon />];
  const adminIcon = [ <InsertLinkIcon />,<PeopleIcon />, <InsertDriveFileIcon />, <ThumbsUpDownIcon />,];
  const text = [ "General Info","Department", "Users", "Documents", "Feedbacks",];
  const adminText = [ "General Info","Users", "Documents", "Feedbacks"];
  const page = ["department", "user", "document-manager", 'general-info'];
  const routes = [ 'general-info',"department", "user", "document-manager", "feedback-review",];
  const adminRoutes = [ 'general-info',"user", "document-manager", "feedback-review"];
  const pathName = useLocation().pathname;
  const [isHovering, setIsHovering] = useState(false);
  const [notificationCounts, setNotificationCounts] = useState({});
  const StyledList = styled(List)({
    // "&& .Mui-selected, && .Mui-selected:hover": {
    //   backgroundColor: "#30344A",
    //   "&, & .MuiListItemIcon-root": {
    //     color: "#4361EE",
    //   },
    // },
    "& .MuiListItemButton-root:hover": {
      color: "#2F80ED",
      //   borderLeft:"2px solid #2F80ED",
      "&, & .MuiListItemIcon-root": {
        color: "#2F80ED",
      },
      "&, & .MuiListItemText-root": {
        color: "#2F80ED",
      },
    },
    "& .MuiListItemText-root": {
      color: "#606060",
    },
  });

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const isActive = (route) => {

    return pathName.substring(1) === "admin-console/" + route;
  };
  const [usertoken, setusertoken] = useState('');
  const [user_id, setuser_id] = useState('');
  const [userorg_id, setuserorg_id] = useState('');
  const [userType, setUserType] = useState('');
  const updateUserDataFromLocalStorage = () => {
    const storedUserData = localStorage.getItem('userdata');
    if (storedUserData) {
      const userDataObj = JSON.parse(storedUserData);
      const { token: userToken, user_id: deepuser_id, org_id: deeporg_id, user_priviledge: user_priviledge } = userDataObj;

      setusertoken(userToken);
      setuser_id(deepuser_id);
      setuserorg_id(deeporg_id);
      setUserType(user_priviledge)
    }
  };
  useEffect(() => {
    updateUserDataFromLocalStorage();
  }, []);



  // const memoizedFetchNotificationCounts = useCallback(async () => {
  //   try {
  //     const userData = localStorage.getItem("userdata");
  //     const userDataObj = JSON.parse(userData);
  //     if (!userDataObj || !userDataObj.token) {
  //       console.error("User token not found in AsyncStorage.");
  //       return;
  //     }
  //     const userToken = userDataObj.token;
  //     const deepuser_id = userDataObj.user_id;
  //     const deeporg_id = userDataObj.org_id;
  //     const dept_id = userDataObj.dept_id;

  //     const data = await GetNotificationCount(deepuser_id, deeporg_id, userToken);

  //     if (data.code === "200") {
  //       setNotificationCounts(data.notification_count);
  //     } else {
  //       console.log("Error fetching notification counts:", data);
  //     }

  //   } catch (error) {
  //     console.error("Error fetching notification counts:", error);

  //   }
  // }, [updateNotifications]); // Empty dependency array indicates that there are no dependencies

  // useEffect(() => {
  //   memoizedFetchNotificationCounts();
  // }, [memoizedFetchNotificationCounts,]); // Include memoizedFetchNotificationCounts in the dependency array

  return (
    <div className="main_drawer">
      <div className="above_drawer">
        <div>
          <img alt="" src={image1} style={{ width: '100px', height: '40px', marginTop: '15px' }} />
        </div>
        {userType == '0' &&
          <StyledList>
            <List>
              {text.map((item, index) => {
                const active = isActive(routes[index]);

                return (
                  <>
                    <div >
                      <ListItem
                        className={active ? "activeMenu" : ""}
                        style={{
                          textAlign: 'center',
                          justifyContent: 'center',
                          padding: '8px',
                          backgroundColor: location.pathname === `/admin-console/${routes[index]}` ? 'rgba(25, 118, 210, 0.2)' : 'inherit',
                          // width:"10vw"
                        }}
                      >
                        <NavLink to={`/admin-console/${routes[index]}`}
                          style={{ textDecoration: "none", color: active ? "#2F80ED" : "", padding: '10px 0px', width: '100px' }}>
                          <ListItemIcon  style={{justifyContent: 'center', color: active ? "#2F80ED" : "",height:'20px',width:'20px' }}>
                            {icon[index]}
                          </ListItemIcon>

                          {/* {index === 4 ? (
                            <Badge badgeContent={typeof notificationCounts === 'number' ? notificationCounts : 0} color="primary">
                              <ListItemIcon style={{ justifyContent: 'center', color: active ? "#2F80ED" : "", height: '20px', width: '20px' }}>
                                {icon[index]}
                              </ListItemIcon>
                            </Badge>
                          ) : (
                            <ListItemIcon style={{ justifyContent: 'center', color: active ? "#2F80ED" : "", height: '20px', width: '20px' }}>
                              {icon[index]}
                            </ListItemIcon>
                          )} */}
                          <ListItemText primary={text[index]} style={{ color: active ? "#2F80ED" : "", fontSize: '15px' }} />

                        </NavLink>
                      </ListItem>
                    </div>
                  </>
                  // <ListItem key={text[index]}>
                  //      <NavLink
                  //       to={`/admin-console/${routes[index]}`}
                  //       style={{ textDecoration: "none" }}
                  //     >
                  //   <ListItemButton
                  //     className={active ? "activeMenu" : ""}
                  //     onMouseEnter={handleMouseEnter}
                  //     onMouseLeave={handleMouseLeave}
                  //     style={{
                  //       borderRadius: "0px 8px 8px 0px",
                  //       borderLeft: active ? "3px solid #2F80ED" : "",
                  //       background: active ? "#ECF4FD" : "",

                  //       width:"280px"
                  //     }}
                  //   >
                  //     <ListItemIcon style={{ color: active ? "#2F80ED" : "" }}>
                  //       {icon[index]}
                  //     </ListItemIcon>

                  //       <ListItemText
                  //         style={{ color: active ? "#2F80ED" : "" }}
                  //         primary={text[index]}
                  //       />

                  //   </ListItemButton>
                  //   </NavLink>
                  // </ListItem>
                );
              })}
            </List>
          </StyledList>
        }
        {userType == '1' &&
          <StyledList>
            <List>
              {adminText.map((item, index) => {
                const active = isActive(adminRoutes[index]);

                return (
                  <>
                    <div >
                      <ListItem
                        className={active ? "activeMenu" : ""}
                        style={{
                          textAlign: 'center',
                          justifyContent: 'center',
                          padding: '8px',
                          backgroundColor: location.pathname === `/admin-console/${adminRoutes[index]}` ? 'rgba(25, 118, 210, 0.2)' : 'inherit',
                          // width:"10vw"
                        }}
                      >
                        <NavLink to={`/admin-console/${adminRoutes[index]}`}
                          style={{ textDecoration: "none", color: active ? "#2F80ED" : "", padding: '5px', width: '100px' }}>
                          {/* <ListItemIcon  style={{justifyContent: 'center', color: active ? "#2F80ED" : "" ,height:'20px',width:'20px'}}>
                            {adminIcon[index]}
                          </ListItemIcon> */}
                          {index === 3 ? (
                            <Badge badgeContent={typeof notificationCounts === 'number' ? notificationCounts : 0} color="primary">
                              <ListItemIcon style={{ justifyContent: 'center', color: active ? "#2F80ED" : "", height: '20px', width: '20px' }}>
                                {adminIcon[index]}
                              </ListItemIcon>
                            </Badge>
                          ) : (
                            <ListItemIcon style={{ justifyContent: 'center', color: active ? "#2F80ED" : "", height: '20px', width: '20px' }}>
                              {adminIcon[index]}
                            </ListItemIcon>
                          )}

                          <ListItemText primary={adminText[index]} style={{ color: active ? "#2F80ED" : "", fontSize: '15px' }} />

                        </NavLink>
                      </ListItem>
                    </div>

                    {/* <ListItem key={adminText[index]}>
                    <NavLink
                      to={`/admin-console/${adminRoutes[index]}`}
                      style={{ textDecoration: "none" }}
                    >
                      <ListItemButton
                        className={active ? "activeMenu" : ""}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        style={{
                          borderRadius: "0px 8px 8px 0px",
                          borderLeft: active ? "3px solid #2F80ED" : "",
                          background: active ? "#ECF4FD" : "",

                          width: "280px"
                        }}
                      >
                        <ListItemIcon style={{ color: active ? "#2F80ED" : "" }}>
                          {adminIcon[index]}
                        </ListItemIcon>

                        <ListItemText
                          style={{ color: active ? "#2F80ED" : "" }}
                          primary={adminText[index]}
                        />

                      </ListItemButton>
                    </NavLink>
                  </ListItem> */}
                  </>
                );
              })}
            </List>
          </StyledList>
        }
      </div>
      <div className="drawer_lower_list">
        <Divider 
        // sx={{ margin: '10px' }}
         />
        {/* <img className='image-3' src={image2} />  */}
        <Typography component="div"
                    sx={{ flexGrow: 1, textAlign: 'center', color: '#575E71', padding: '10px 20px', fontSize: '12px' }}
                    // className="cmd_footer"
                >
                    Powered by <span style={{ fontWeight: 'bold', fontSize: '13px' }}>ZeonAI Labs</span>
                </Typography>
      </div> 
    </div>
  );
}
