export const getAllDepartment = async (user_id, userorg_id,searchtext, token) => {
  try {
    const apiEndpoint = "admin/getalldepartment";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      searchtext,
      start_index: 0,
      limit: 15
    };

    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const getAllWizardDepartment = async (user_id, userorg_id,department_type, token) => {
  try {
    const apiEndpoint = "admin/getalldepartment";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      department_type:department_type,
     
      start_index: 0,
      limit: 100
    };

    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const getAllCmdDepartment = async (user_id, userorg_id, token) => {
  try {
    const apiEndpoint = "admin/getalldepartment";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      start_index: '',
      limit:''
    };

    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const fetchMoreDepartment = async (user_id, userorg_id,  searchtext, token, index) => {
  try {
    const apiEndpoint = "admin/getalldepartment";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      searchtext,
      start_index: index,
      limit: 15
    };

    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const deleteDepartment = async (
  user_id,
  userorg_id,
  dept_id,
  token
) => {
  const BASE_URL = `${window.constants.api_url}/admin/deletedepartment`;

  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
      department_id: dept_id,
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const addDepartment = async (
  user_id,
  userorg_id,
  dept_name,
  dept_desc,
  department_type,
  token
) => {
  const BASE_URL = `${window.constants.api_url}/admin/adddepartment`;

  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
      department_name: dept_name,
      department_desc: dept_desc,
      department_type: department_type,
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const getAllUser = async (user_id, userorg_id,stringSearch, token) => {
  try {
    const apiEndpoint = "admin/getallusers";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
     searchtext: stringSearch,
      start_index: 0,
      limit: 15
    };

    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const fetchMoreUser = async (user_id, userorg_id, stringSearch,token, index) => {
  try {
    const apiEndpoint = "admin/getallusers";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      searchtext: stringSearch,
      start_index: index,
      limit: 15
    };

    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const addUser = async (
  user_id,
  userorg_id,
  user_name,
  photo,
  gender,
  email,
  phone,
  emp_id,
  designation,
  existingDesignation,
  role,
  department,
  token
) => {
  // const BASE_URL = `${window.constants.api_url}/admin-console/adduser`;
  try {
    var myHeaders = new Headers();
    myHeaders.append("token", token);

    var formdata = new FormData();
    formdata.append("user_id", user_id.toString());
    formdata.append("org_id", userorg_id.toString());
    formdata.append("name", user_name.toString());
    formdata.append("photo", photo);
    formdata.append("gender", gender.toString());
    formdata.append("email", email.toString());
    formdata.append("phone", phone.toString());
    formdata.append("emp_id", emp_id.toString());
    formdata.append("designation", designation.toString());
    formdata.append("role", role.toString());
    formdata.append("department", department.toString());
    formdata.append("existing_designation",existingDesignation.toString());
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };

    const response = await fetch(`${window.constants.api_url}/admin/adduser`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log('error', error);
  }
};

export const editUser = async (
  user_id,
  userorg_id,
  user_name,
  photo,
  gender,
  email,
  phone,
  emp_id,
  designation,
  existingDesignation,
  role,
  department,
  profileId,
  token
) => {
  // const BASE_URL = `${window.constants.api_url}/admin-console/adduser`;
  try {
    var myHeaders = new Headers();
    myHeaders.append("token", token);

    var formdata = new FormData();
    formdata.append("user_id", user_id.toString());
    formdata.append("org_id", userorg_id.toString());
    formdata.append("name", user_name.toString());
    formdata.append("photo", photo);
    formdata.append("gender", gender.toString());
    formdata.append("email", email.toString());
    formdata.append("phone", phone.toString());
    formdata.append("emp_id", emp_id.toString());
    formdata.append("designation", designation.toString());
    formdata.append("role", role.toString());
    formdata.append("department", department.toString());
    formdata.append("profile_id", profileId.toString());
    formdata.append("existing_designation",existingDesignation.toString());
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };

    const response = await fetch(`${window.constants.api_url}/admin/edituser`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log('error', error);
  }
};

export const userMasterData = async (
  user_id,
  userorg_id,
  token
) => {
  const BASE_URL = `${window.constants.api_url}/admin/usermaster`;

  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const getAllDocument = async (user_id, userorg_id,searchstring,department,dateList,fromDate,toDate ,token) => {
  try {
    const apiEndpoint = "admin/getalldocuments";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      start_index: 0,
      limit: 15,
      searchstring: searchstring,
      department: department,
      date: {
        type: "1",
        value: dateList,
      },
    };
    if (fromDate !== "" && toDate !== "") {
      requestData.date = {
        type: "2",
        value: `${fromDate},${toDate}`,
      };
    }
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const fetchMoreDocument = async (user_id, userorg_id,searchstring,department,dateList,fromDate,toDate , token, index) => {
  try {
    const apiEndpoint = "admin/getalldocuments";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      start_index: index,
      limit: 15,
      searchstring: searchstring,
      department: department,
      date: {
        type: "1",
        value: dateList,
      },
    };
    if (fromDate !== "" && toDate !== "") {
      requestData.date = {
        type: "2",
        value: `${fromDate},${toDate}`,
      };
    }
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const shareUser = async (user_id, userorg_id,doc_id,token) => {
  try {
    const apiEndpoint = "admin/sharetousers";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      doc_id: doc_id,
      
    };
    
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const userChangeStatus = async (
  user_id,
  userorg_id,
  profile_id,
  status,
  token
) => {
  const BASE_URL = `${window.constants.api_url}/admin/changeuserstatus`;

  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
      profile_id: profile_id,
      status: status

    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const updateDocDescription = async (
  user_id,
  userorg_id,
  doc_id,
  doc_desc,
  token
) => {
  const BASE_URL = `${window.constants.api_url}/admin/editdocumentdesc`;

  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
      doc_id: doc_id,
      doc_desc: doc_desc,
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const deleteDocument = async (
  user_id,
  userorg_id,
  doc_id,
  token
) => {
  const BASE_URL = `${window.constants.api_url}/admin/deletedocument`;

  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
      doc_id: doc_id,
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const updateDocumentIndex = async (
  user_id,
  userorg_id,
  doc_id,
  index_type,
  token
) => {
  const BASE_URL = `${window.constants.api_url}/admin/updatedocumentindex`;

  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
      doc_id: doc_id,
      index_type: index_type,
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const initiateDocumentUpload = async (
  user_id,
  txtCompany,
  docs,
  txtDepartment,
  token
) => {
  const BASE_URL = `${window.constants.api_url}/initiate-upload`;

  try {
    const requestData = {
      user_id,
      txtCompany: txtCompany,
      docs: docs,
      txtDepartment: txtDepartment,
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const completeDocumentUpload = async (
  user_id,
  txtCompany,
  docs,
  fileSize,
  txtDepartment,
  accessType,
  accessuserlist,
  token
) => {
  const BASE_URL = `${window.constants.api_url}/complete-upload`;

  try {
    const requestData = {
      user_id,
      txtCompany: txtCompany,
      uploadImage: docs,
      fileSize: fileSize,
      txtDepartment: txtDepartment,
      doc_access_type :accessType,
      accessuserlist:accessuserlist

    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const updateUserAccess = async (
  user_id,
  userorg_id,
  doc_id,
  users,
  token
) => {
  const BASE_URL = `${window.constants.api_url}/admin/updatedocumentuseraccess`;

  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
      doc_id: doc_id,
      users: users,
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const undoInvitedUser = async (
  user_id,
  userorg_id,
  invite_user_id,
  token
) => {
  const BASE_URL = `${window.constants.api_url}/admin/undoinviteuser`;

  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
      invite_user_id: invite_user_id,
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const getAllShareUser = async (user_id, userorg_id, token) => {
  try {
    const apiEndpoint = "admin/getallshareusers";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      
    };

    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};