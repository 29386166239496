import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import DrawerComponent from "../Includes/Sidebar";
import SearchIcon from '@mui/icons-material/Search';
import {
    Button, Divider, TextField, Popover, MenuList, List, ListItem, ListItemButton,
    ListItemIcon, Checkbox, ListItemText, Link,Grid
} from "@mui/material";
import { NavLink } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InfiniteScroll from "react-infinite-scroll-component";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import NotesIcon from '@mui/icons-material/Notes';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getAllDepartment, deleteDepartment, addDepartment, fetchMoreDepartment } from "../AdminApiHelper";
import Skeleton from "@mui/material/Skeleton";
import { CircularProgress } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DateFillter from "./DateFillter";
import Appbar from "../Includes/Header";
// import AddDepartment from "./addDepartment";
// import ViewDepartment from "./viewDepartment";

// import "./Department.css";
import { borderRadius } from "@mui/system";
const drawerWidth = 140;
const theme = createTheme();
function FeedbackReview(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [checked, setChecked] = useState([]);
    //     () => {
    //     const storedState = JSON.parse(localStorage.getItem('checkboxState')) || [];
    //     return storedState;
    //   });
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <DrawerComponent />
        </div>
    );

    const container =
        window !== undefined ? () => window().document.body : undefined;
    const rawData = [
        {
            query: 'What is an offence relating to bribing a public servant?',
            feedback_provider: 'Biswa Singh',
            email: 'biswa@zeonai.com',
            date_and_time: '13 Jun, 2023 02:06 PM',
            feedback: 'inaccurate Info',
            feedback_id: '1',
            additional_feedback_1: 'Answer to the question and description goes here.',
            additional_feedback_2: 'Answer to the question and description goes here.',
        },
        {
            query: 'What is an offence relating to bribing a public servant?',
            feedback_provider: 'Amrit Pattnaik',
            email: 'biswa@zeonai.com',
            date_and_time: '13 Jun, 2023 02:06 PM',
            feedback: 'inaccurate Info',
            feedback_id: '2',
            additional_feedback_1: 'Answer to the question and description goes here.',
            additional_feedback_2: 'Answer to the question and description goes here.',

        },
        {
            query: 'fjgherbfawhb',
            feedback_provider: 'Sukanta Mishra',
            email: 'biswa@zeonai.com',
            date_and_time: '13 Jun, 2023 02:06 PM',
            feedback: 'inaccurate Info',
            feedback_id: '2',
            additional_feedback_1: 'Answer to the question and description goes here.',
            additional_feedback_2: 'Answer to the question and description goes here.',

        },
        {
            query: 'What is an offence relating to bribing a public servant?',
            feedback_provider: 'Himadri Bhusan Dikshit',
            email: 'biswa@zeonai.com',
            date_and_time: '13 Jun, 2023 02:06 PM',
            feedback: 'inaccurate Info',
            feedback_id: '1',
            additional_feedback_1: 'Answer to the question and description goes here.',
            additional_feedback_2: 'Answer to the question and description goes here.',

        },
        {
            query: 'fjgherbfawhb',
            feedback_provider: 'Tapan Sahoo',
            email: 'biswa@zeonai.com',
            date_and_time: '13 Jun, 2023 02:06 PM',
            feedback: 'inaccurate Info',
            feedback_id: '1',
            additional_feedback_1: 'Answer to the question and description goes here.',
            additional_feedback_2: 'Answer to the question and description goes here.',

        },
        {
            query: 'fjgherbfawhb',
            feedback_provider: 'Padia Nanda',
            email: 'biswa@zeonai.com',
            date_and_time: '13 Jun, 2023 02:06 PM',
            feedback: 'inaccurate Info',
            feedback_id: '2',
            additional_feedback_1: 'Answer to the question and description goes here.',
            additional_feedback_2: 'Answer to the question and description goes here.',

        },
        {
            query: 'fjgherbfawhb',
            feedback_provider: 'Amlan Das',
            email: 'biswa@zeonai.com',
            date_and_time: '13 Jun, 2023 02:06 PM',
            feedback: 'inaccurate Info',
            feedback_id: '1',
            additional_feedback_1: 'Answer to the question and description goes here.',
            additional_feedback_2: 'Answer to the question and description goes here.',

        },
        {
            query: 'fjgherbfawhb',
            feedback_provider: 'SK Ayaz',
            email: 'biswa@zeonai.com',
            date_and_time: '13 Jun, 2023 02:06 PM',
            feedback: 'inaccurate Info',
            feedback_id: '2',
            additional_feedback_1: 'Answer to the question and description goes here.',
            additional_feedback_2: 'Answer to the question and description goes here.',

        },
    ];
    const staticQueryFilter = [
        {
            department_id: '1',
            department_name: 'What is an offence relating to bribing a public servant?'
        },
        {
            department_id: '2',
            department_name: 'what is ayodhya judgement?'
        },
        {
            department_id: '3',
            department_name: 'what was the final decision in ayodhya case?'
        },
        {
            department_id: '4',
            department_name: 'java'
        },
        {
            department_id: '5',
            department_name: 'angular'
        },
        {
            department_id: '6',
            department_name: 'node'
        },
        {
            department_id: '7',
            department_name: 'react'
        },
    ]
    const [tempFeedbackProvider, setTempFeedbackProvider] = useState('')
    const [tempFeedback, setTempFeedback] = useState('')
    const [tempFeedbackId, setTempFeedbackId] = useState('')
    const [tempFeedbackQuery, setTempFeedbackQuery] = useState('')
    const [tempFeedbackEmail, setTempFeedbackEmail] = useState('')
    const [tempAdditionalFB1, setTempAdditionalFB1] = useState('')
    const [tempAdditionalFB2, setTempAdditionalFB2] = useState('')
    const [viewDetalsModalOpen, setViewDetailsModalOpen] = useState(false)
    const handleDetailsClick = (event, user) => {
        setViewDetailsModalOpen(true)
        console.log('user', user);
        setTempFeedbackQuery(user.query)
        setTempFeedbackProvider(user.feedback_provider);
        setTempFeedback(user.feedback);
        setTempFeedbackId(user.feedback_id);
        setTempFeedbackEmail(user.email);
        setTempAdditionalFB1(user.additional_feedback_1);
        setTempAdditionalFB2(user.additional_feedback_2);
    }
    const closeViewDetalsModal = () => {
        setViewDetailsModalOpen(false)

    }

    const [searchQuery, setSearchQuery] = useState('')
    const handleSearch = (query) => {
        setSearchQuery(query);
    }
    const [queryPopover, setQueryPopover] = useState(null)
    const openQueryPopover = Boolean(queryPopover);
    const queryPopoverId = openQueryPopover ? 'dropdown-popover' : undefined;
    const handleButtonClick = (e) => {
        setQueryPopover(e.currentTarget)
    }
    const [likeCkicked, setLikeCkicked] = useState(false)
    const [dislikeCkicked, setDislikeikeCkicked] = useState(false)
    const handleLikeClick = () => {
        setLikeCkicked(true)
    }
    const revertLikeClick = () => {
        setLikeCkicked(false)
    }
    const handleDislikeClick = () => {
        setDislikeikeCkicked(true)
    }
    const revertDislikeClick = () => {
        setDislikeikeCkicked(false)
    }
    // const handleLoadMore = async () => {
    //     setIsLoadingMore(true);

    //     try {
    //         const storedUserData = localStorage.getItem("userdata");

    //         if (!storedUserData) {
    //             console.error("User data not found in localStorage.");
    //             return;
    //         }

    //         const userDataObj = JSON.parse(storedUserData);
    //         if (!userDataObj || !userDataObj.token) {
    //             console.error(
    //                 "User token not found in localStorage or component unmounted."
    //             );
    //             return;
    //         }

    //         const {
    //             token: userToken,
    //             user_id: deepuser_id,
    //             org_id: deeporg_id,
    //         } = userDataObj;

    //         setusertoken(userToken);
    //         setuser_id(deepuser_id);
    //         setuserorg_id(deeporg_id);
    //         setIndex(index + 15);
    //         const data = await fetchMoreDepartment(
    //             deepuser_id,
    //             deeporg_id,
    //             userToken,
    //             index + 15
    //         );
    //         if (data.code == "200") {
    //             setuserconversation_id(data.conversation_id);
    //             const newResults = data.data;
    //             setDeptSearchResults((prevResults) => [
    //                 ...newResults,
    //                 ...prevResults,
    //             ]);
    //             setHasMore(data.data.length > 0);
    //             setIsLoading(false)
    //             setShowStaticContent(false);
    //         } else {
    //             setShowStaticContent(true);
    //         }
    //     } catch (error) {
    //         console.error("Error fetching user data:", error);
    //     } finally {
    //         // Make sure to set isLoading to false whether the fetch was successful or not
    //         setIsLoading(false);
    //     }
    // };
    // const handleSnackbarClose = (event, reason) => {
    //     if (reason === 'clickaway') {
    //         return;
    //     }

    //     setSnackbarOpen(false);
    // };

    const LoadingSkeleton = () => {
        // You can customize the skeleton based on your design
        return (
            <TableRow>
                <TableCell>
                    <Skeleton width={400} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={200} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={200} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={400} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={200} height={20} />
                </TableCell>

            </TableRow>
        );
    };
    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            {/* <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    backgroundColor: "white",
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: "none" }, color: "black" }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }} flexGrow={1}>
                        <Box>
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                style={{
                                    color: "#003776",
                                    fontSize: "24px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "normal",
                                    letterSpacing: "0.46px",
                                }}
                            >
                                Admin Console
                            </Typography>
                        </Box>

                        <Box >
                            <NavLink to={`/query`} style={{ color: '#2F80ED', display: 'flex', textDecoration: 'none' }}>
                                <ArrowBackIcon />
                                <Typography>Go to DeepProbe</Typography>
                            </NavLink>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar> */}
            <AppBar
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "white",
        }}
      >
        <Appbar />
      </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: "none", sm: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    background: "#EFF1F3",
                    minHeight: "100vh",
                }}
            >
                <Toolbar />

                <div className="department_main">
                    <Box sx={{ display: 'flex' }}>
                        <Box className="department_text" display='flex' flexGrow={1}>
                            Feedback Review
                        </Box>
                    </Box>
                    <Box>
                    {/*  */}
                    </Box>
                    <Box sx={{ margin: '15px 0px',width:'100%'}}>
                       
                        <Box >
                           
                            <TextField
                            fullWidth
                                  sx={{
                                    marginBottom: '10px',
                                      borderRadius: '25px',
                                      backgroundColor: isFocused ? 'white' : '#D0E2FB',
                                      '.MuiOutlinedInput-root': {
                                        borderRadius: '25px',
                                        paddingRight: 2,
                                       
                                        fontSize: '0.8rem', // Adjust font size to reduce height
                                        lineHeight: '1.2', // Adjust line height if needed
                                        minHeight: '30px', // Set minimum height for the input
                                
                                      },
                                      '.MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                      },
                                      '& .MuiInputBase-input::placeholder': {
                                        color: 'black', // Set placeholder color to black
                                        opacity: 1, // Ensure the placeholder is fully opaque
                                    },
                                    }}
                                    onFocus={() => setIsFocused(true)}
                                    onBlur={() => setIsFocused(false)}
                                placeholder="Search Query"
                                InputProps={{
                                    startAdornment: (
                                        <IconButton>
                                            <SearchIcon />
                                        </IconButton>
                                    ),
                                }}
                            />
                        </Box>
                        
                    </Box>
                    <Box sx={{ display: 'flex' ,marginBottom:'15px'}}>

                            <Box sx={{ marginRight: '10px' }}>
                                <Button
                                    // onClick={handleLikeClick}
                                    sx={likeCkicked ? {
                                        '&:hover': {
                                            backgroundColor: '#DBE2F9', // Set the hover color
                                        },
                                        color: '#2B74E2',
                                        backgroundColor: '#DBE2F9',
                                        textTransform: 'none',
                                        borderRadius: '4px',
                                        border: '1px solid #DBE2F9',
                                        // marginRight: '15px',
                                        padding: '6px 20px',
                                        height: '40px',
                                        minWidth: '100px'
                                    } : {
                                        '&:hover': {
                                            backgroundColor: '', // Set the hover color
                                        },
                                        backgroundColor: '',
                                        color: 'grey',
                                        textTransform: 'none',
                                        borderRadius: '4px',
                                        border: '1px solid #606060',
                                        padding: '7.5px 20px',
                                        height: '40px',
                                        minWidth: '100px'
                                    }}
                                >
                                    <Typography onClick={handleLikeClick} display='flex'>
                                        <ThumbUpIcon sx={{ height: '18px', width: '18px', marginRight: '7px', marginTop: '4px' }} />
                                        <Typography onClick={handleLikeClick} sx={{ fontSize: '15px' }}>Like</Typography>
                                    </Typography>
                                    {likeCkicked &&
                                        <IconButton onClick={revertLikeClick} style={{ padding: '0px 0px', }}>
                                            <CloseIcon sx={{ height: '15px', width: '15px', color: '#2B74E2' }} />
                                        </IconButton>}
                                </Button>
                            </Box>
                            <Box sx={{ marginRight: '10px' }}>
                                <Button


                                    sx={dislikeCkicked ? {
                                        '&:hover': {
                                            backgroundColor: '#DBE2F9', // Set the hover color
                                        },
                                        color: '#2B74E2',
                                        backgroundColor: '#DBE2F9',
                                        textTransform: 'none',
                                        borderRadius: '4px',
                                        border: '1px solid #DBE2F9',
                                        // marginRight: '15px',
                                        padding: '7.5px 20px',
                                        height: '40px',
                                        minWidth: '100px'
                                    } : {
                                        '&:hover': {
                                            backgroundColor: '', // Set the hover color
                                        },
                                        backgroundColor: '',
                                        color: 'grey',
                                        textTransform: 'none',
                                        borderRadius: '4px',
                                        border: '1px solid #606060',
                                        display: 'flex',
                                        padding: '7.5px 20px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '8px',
                                        height: '40px',
                                        minWidth: '100px'
                                    }}
                                >
                                    <Typography onClick={handleDislikeClick} display='flex'>
                                        <ThumbDownIcon sx={{ height: '18px', width: '18px', marginRight: '7px', marginTop: '4px' }} />
                                        <Typography onClick={handleDislikeClick} sx={{ fontSize: '15px' }}>Dislike</Typography>
                                    </Typography>

                                    {dislikeCkicked &&
                                        <IconButton onClick={revertDislikeClick} style={{ padding: '0px 0px', }}>
                                            <CloseIcon sx={{ height: '15px', width: '15px', color: '#2B74E2' }} />
                                        </IconButton>}
                                </Button>
                            </Box>
                            <Box sx={{ marginRight: '10px' }}>
                                {/* <Button
                                    // onClick={handleButtonClick}
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: '#FFFFFF', // Set the hover color
                                        },
                                        backgroundColor: '#FFFFFF',
                                        color: 'grey',
                                        textTransform: 'none',
                                        borderRadius: '8px',
                                        border: '1px solid #606060',

                                    }}
                                >
                                    <CalendarTodayIcon sx={{ height: '18px', width: '18px', marginRight: '7px' }} />
                                    Date Range
                                </Button> */}
                                <DateFillter />
                            </Box>
                            <Box>
                                <Button
                                    // onClick={handleButtonClick}
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: '', // Set the hover color
                                        },
                                        backgroundColor: '',
                                        color: 'grey',
                                        textTransform: 'none',
                                        borderRadius: '4px',
                                        border: '1px solid #606060',
                                        padding: '7.5px 20px',
                                        height: '40px',
                                        minWidth: '100px'
                                    }}
                                >
                                    Clear
                                </Button>
                            </Box>

                        </Box>
                    <Box>
                        <Paper sx={{ width: '100%', overflow: 'hidden', marginLeft: '0px', borderRadius: '5px' }}>
                            {
                                // isLoading ? (
                                //     // Render loading skeletons while data is being fetched
                                //     Array.from({ length: 10 }).map((_, index) => (
                                //         <LoadingSkeleton key={index} />
                                //     ))
                                // ) : 
                                (
                                    <TableContainer >
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead sx={{ backgroundColor: '#eff1f3', color: 'pink' }}>
                                                <TableRow >

                                                    <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white', borderLeft: '4px solid white' }}>
                                                        Query
                                                    </TableCell>
                                                    <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white' }}>
                                                        Feedback Provider
                                                    </TableCell>
                                                    <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white' }}>
                                                        Email
                                                    </TableCell>
                                                    <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white' }}>
                                                        Date & Time
                                                    </TableCell>
                                                    <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white', borderRight: '4px solid white' }}>
                                                        Feedback
                                                    </TableCell>
                                                    {/* <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white', borderRight: '4px solid white' }}>
                                                        Action
                                                    </TableCell> */}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rawData.map((item, index) => (
                                                    <TableRow sx={{ padding: '0px', height: '20px' }}>
                                                        <TableCell sx={{ padding: '0px 0px 0px 20px', maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                            <Typography onClick={(e) => handleDetailsClick(e, item)} style={{ cursor: 'pointer',textDecoration:'underline',color:'#2F80ED' }}>{item.query}</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ color: 'grey' }}>
                                                            {item.feedback_provider}
                                                        </TableCell>
                                                        <TableCell sx={{ color: 'grey' }}>
                                                            {item.email}
                                                        </TableCell>
                                                        <TableCell sx={{ color: 'grey' }}>
                                                            {item.date_and_time}
                                                        </TableCell>
                                                        <TableCell sx={{ color: 'grey' }}>
                                                            <Box style={{ display: 'flex', padding: '0px', margin: '0px' }}>

                                                                <Box>
                                                                    {item.feedback_id == "1" ? <ThumbUpIcon sx={{ marginTop: '0', marginRight: '5px', color: '#27AE60' }} />
                                                                        : <ThumbDownIcon sx={{ marginTop: '0px', marginRight: '5px', color: '#EB5757' }} />
                                                                    }
                                                                </Box>
                                                                <Box> {item.feedback}</Box>

                                                            </Box>
                                                        </TableCell>
                                                        {/* <TableCell >
                                                            <Button variant="none" style={{ textTransform: 'none', color: '#005AC1', fontWeight: 'bold' }} color="error"
                                                                onClick={(e) => handleDetailsClick(e, item)} >View Details</Button>
                                                        </TableCell> */}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                )}

                        </Paper>
                        {/* <InfiniteScroll
                            dataLength={deptSearchResults.length}
                            next={handleLoadMore}
                            hasMore={hasMore}
                            loader={
                                <CircularProgress disableShrink style={{ margin: "20px" }} />
                            }
                            endMessage={
                                <div style={{ textAlign: "center", marginTop: "10px" }}>
                                    {deptSearchResults.length == 0 ? (
                                        <div
                                            style={{
                                                color: "grey",
                                                fontSize: "13px",
                                                marginTop: "20px",
                                            }}
                                        >
                                            No record found
                                        </div>
                                    ) : (
                                        <>
                                            <div>No More Records</div>
                                            <IconButton
                                                onClick={() => window.scrollTo(0, 0)}
                                                style={{ color: "#2F80ED" }}
                                                size="medium"
                                            >
                                                <KeyboardArrowUpIcon />
                                            </IconButton>
                                        </>
                                    )}
                                </div>
                            }
                        ></InfiniteScroll> */}
                        <Dialog open={viewDetalsModalOpen}
                            onClose={closeViewDetalsModal}
                            sx={{
                                "& .MuiDialog-paper": {
                                    borderRadius: "20px",
                                    width: '800px'
                                },
                            }}
                            className={{ paper: 'custom-dialog-paper' }}
                        >
                            <DialogTitle sx={{ fontSize: '18px', paddingBottom: '0px', paddingTop: '20px', marginBottom: '0px', display: 'flex', justifyContent: 'space-between' }} flexGrow={1}>
                                <Box sx={{ fontWeight: 'bold' }}>
                                    Feedback Details
                                </Box>
                                <Box >
                                    <IconButton>
                                        <CloseIcon onClick={closeViewDetalsModal} />
                                    </IconButton>
                                </Box>
                            </DialogTitle>
                            <DialogContent sx={{ fontSize: '15px', }}>
                                <Box>
                                    <Box sx={{ lineHeight: '30px' }}>
                                        <Box>{tempFeedbackQuery} </Box>
                                        <Box sx={{ display: 'flex' }}>
                                            <Box sx={{ fontWeight: 'bold' }}>Feedback Provider</Box>
                                            <Box sx={{ marginLeft: '145px', fontSize: '14px' }}>{tempFeedbackProvider}</Box>
                                        </Box>
                                        <Box sx={{ display: 'flex' }}>
                                            <Box sx={{ fontWeight: 'bold' }}>Email</Box>
                                            <Box sx={{ marginLeft: '240px', fontSize: '14px' }}>{tempFeedbackEmail} </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex' }}>
                                            <Box sx={{ fontWeight: 'bold' }}>Feedback</Box>
                                            <Box sx={{ display: 'flex', marginLeft: '210px' }}>
                                                <Box>{tempFeedbackId == "1" ? <ThumbUpIcon sx={{ marginTop: '0', marginRight: '5px', color: '#27AE60', height: '20px', width: '20px' }} />
                                                    : <ThumbDownIcon sx={{ marginTop: '0px', marginRight: '5px', color: '#EB5757', height: '20px', width: '20px' }} />
                                                }
                                                </Box>
                                                <Box sx={{ fontSize: '14px' }}>{tempFeedback} </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box><Divider sx={{ margin: '15px 0px' }} /></Box>
                                    <Box sx={{ lineHeight: '30px' }}>
                                        <Box sx={{ marginBottom: '15px' }}>Additional details</Box>
                                        <Box sx={{ display: 'flex' }}>
                                            <Box sx={{ fontWeight: 'bold' }}>What would be the correct answer?</Box>
                                            <Box sx={{ marginLeft: '70px', fontSize: '14px' }}>{tempAdditionalFB1} </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex' }}>
                                            <Box sx={{ fontWeight: 'bold' }}>Provide additional feedback</Box>
                                            <Box sx={{ marginLeft: '113px', fontSize: '14px' }}>{tempAdditionalFB2} </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </DialogContent>
                            <DialogActions sx={{ marginBottom: '10px', marginRight: '10px', fontWeight: 'bold' }}>
                                <Button onClick={closeViewDetalsModal} variant="outlined" sx={{ borderRadius: '200px', border: '1px solid #606060', fontWeight: '400', textTransform: 'none' }}>
                                    Close
                                </Button>
                                {/* <Button variant="contained" sx={{ borderRadius: '200px' }} onClick={handleAddDepartment}>
                                            Save Department
                                        </Button> */}
                                <Button variant="contained" sx={{ borderRadius: '200px', fontWeight: '400', textTransform: 'none' }}
                                    // onClick={handleAddDepartment} backgroundColor={buttonLoading && '#616161'} 
                                    // disabled={buttonLoading}
                                    startIcon={<MailOutlinedIcon />}>
                                    {/* {buttonLoading && <CircularProgress size={16} />}  */}
                                    Send Email
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Popover
                            id={queryPopoverId}
                            open={openQueryPopover}
                            anchorEl={queryPopover}
                            onClose={() => setQueryPopover(null)}
                            sx={{ width: '320px' }}
                            // style={paperStyle}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <Box sx={{ width: '260px' }}>
                                <Box sx={{ position: 'sticky', top: 0, backgroundColor: 'white', padding: '2px' }}>

                                    <TextField
                                        onChange={(e) => handleSearch(e.target.value)}
                                        placeholder="Search Query Name"
                                        sx={{
                                            // paddingTop: '0px',
                                            margin: '10px',
                                            // height: '45px',
                                            // color: '#828282',
                                            backgroundColor: '#60606029',
                                            border: '#60606029',
                                            '& .MuiInputBase-input': {
                                                height: '6px',
                                            },
                                        }}
                                        InputProps={{

                                            startAdornment: (
                                                <IconButton>
                                                    <SearchIcon />
                                                </IconButton>
                                            ),
                                        }}
                                    />


                                </Box>
                                <Box
                                    sx={{
                                        overflow: 'auto',
                                        maxHeight: '280px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'stretch',
                                        padding: '0px',
                                    }}
                                >
                                    <MenuList>
                                        <List sx={{ '&fieldset': { padding: '0px' } }}>
                                            {staticQueryFilter.map((department) => (
                                                <ListItem key={department.department_id} disablePadding>
                                                    <ListItemButton
                                                        role={undefined}
                                                        // onClick={handleCheckboxChange(department.department_id)}
                                                        dense
                                                        margin="0"
                                                        padding="0px"
                                                    >
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                edge="start"
                                                                sx={{ padding: '0px 10px' }}
                                                                checked={checked.indexOf(department.department_id) !== -1}
                                                                tabIndex={-1}
                                                                disableRipple
                                                                inputProps={{
                                                                    'aria-labelledby': `checkbox-list-label-${department.department_id}`,
                                                                }}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            id={`checkbox-list-label-${department.department_id}`}
                                                            primary={department.department_name}
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </MenuList>
                                </Box>
                                {/* <Box>
                                    <Divider orientation="horizontal" />
                                </Box>
                                <Box
                                    sx={{
                                        position: 'sticky',
                                        bottom: '0',
                                        backgroundColor: 'white',
                                        padding: '8px',
                                        display: 'flex',
                                        justifyContent: 'end',
                                        width: '100%',
                                        marginBottom: '0px',
                                    }}
                                >
                                    <Button onClick={resetClick}>Reset</Button>
                                    <Button onClick={handleApplyClick}>Apply</Button>
                                </Box> */}
                            </Box>
                        </Popover>
                    </Box>

                </div>
            </Box>
            {/* <Snackbar
                mode="outlined"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center', // Center bottom
                }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <SnackbarContent
                    style={{
                        backgroundColor: 'black',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                    message={
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            {toastmessage}
                        </span>
                    }
                    action={[
                        <IconButton key="close" color="inherit" onClick={handleSnackbarClose}>
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </Snackbar> */}
        </Box>
    );
}

export default FeedbackReview;
