import React, { useEffect, useState, useRef } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InputAdornment from '@mui/material/InputAdornment';
import AppBar from '@mui/material/AppBar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Collapse from '@mui/material/Collapse';
import Logout from '@mui/icons-material/Logout';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NotificationsIcon from '@mui/icons-material/Notifications';
import mcl_logo from '../../../mcl-small.png';
// import './SignIn.css';
import { useAppContext } from '../../../Includes/AppContext';
import List from '@mui/material/List';
import { usePath } from '../../../Includes/PathContext';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { NavLink } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Button from '@mui/material/Button';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import changePass from '../../../wrong-password.png';
import userUpdate from '../../../settings.png'
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/Close';
import { updateProfile, changePassword, updateAccount } from '../../ApiHelper/Profile';
import { CircularProgress, Drawer } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Alert from '@mui/material/Alert';
import DrawerComponent from './Sidebar';
// import { useNavigate } from 'react-router-dom'
const Footer = () => {

    const drawerWidth = 200;
    const location = useLocation();
    const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavigate = () => {
    navigate('/cmd-dashboard/support'); // Navigate to the specified route
};

  
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
            top: "auto",
            bottom: 0,
          width: { sm: `calc(100% - 200px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "white",
          color: '#003776',
           
        }}
      >
        <Box sx={{display:'flex',justifyContent:'center'}}>
<Typography sx={{  textAlign: 'right', color: '#575E71', padding: '10px 20px', fontSize: '12px' }}>© 2021 Mahanadi Coalfields Limited. All Rights Reserved. </Typography>
<Typography sx={{  textAlign: 'right', color: '#575E71', padding: '10px 0px', fontSize: '12px' }}>|</Typography>
<Typography component="div"
                    sx={{  textAlign: 'right', color: '#575E71', padding: '10px 20px', fontSize: '12px' }}
                    // className="cmd_footer"
                >
                    Powered by <span style={{ fontWeight: 'bold', fontSize: '13px' }}>ZeonAI Labs</span>
                </Typography>
                <Typography sx={{  textAlign: 'right', color: '#575E71', padding: '10px 0px', fontSize: '12px' }}>|</Typography>
                <Typography
    sx={{
        textAlign: 'right',
        color: '#575E71',
        padding: '10px 20px',
        fontSize: '12px',
        textDecoration:'underline',
        cursor: 'pointer', // Add cursor pointer to indicate clickability
    }}
    onClick={handleNavigate} // Assign the function to onClick
>
    Support
</Typography>
        </Box>
      </AppBar>
      {/* <Drawer

        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer> */}

    </>
  )

}
export default Footer;