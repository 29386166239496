import React, { useState, useEffect, useRef,useCallback } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Sidebar from "../../Includes/Menu";
import ClearIcon from "@mui/icons-material/Clear";
import Appbar from "../../Includes/Appbar";
import InfiniteScroll from "react-infinite-scroll-component";
import debounce from 'lodash.debounce';
import {
  IconButton,
  TextField,
  Drawer,
  AppBar,
  Typography,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { BeatLoader, RingLoader, MoonLoader } from "react-spinners";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import "./DocumentSearch.css";
import DropdownWithCheckboxes from "./DeptFilter.jsx";
import BasicPopover from "./DateFilter";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import {
  getAutocompleteResults,
  getDepartment,
  fetchMoreData,
  downloadPDF,
  searchDocuments,
  userMasterData,
} from "../ApiHelper/DocumentSearchapi";



const drawerWidth = 240;
const DocumentSearch = (props) => {
  const navigate = useNavigate();

  const childRef = useRef(null);

  const { windows } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleNewQueryClick = () => {
    console.log('New Query Clicked');
   
  };
  const [hasMore, setHasMore] = useState(true);
  const drawer = (
    <div>
      <Sidebar onNewQueryClick={handleNewQueryClick} />
    </div>
  );
  const container =
    windows !== undefined ? () => window().document.body : undefined;
  const date = new Date(); // 
  const [isLoading, setIsLoading] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [usertoken, setusertoken] = useState("");
  const [index, setIndex] = useState("");
  const [user_id, setuser_id] = useState("");
  const [userorg_id, setuserorg_id] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [deptSearchResults, setDeptSearchResults] = useState([]);
  const [showStaticContent, setShowStaticContent] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [userconversation_id, setuserconversation_id] = useState("");
  const [deptlist, setDeptlist] = useState([]); // Add your state variables
  const [datelist, setDatelist] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [todate, settodate] = useState("");
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [documentNameAutocomplete, setDocumentNameAutocomplete] =
    React.useState("");
  const [suggestions, setSuggestions] = useState([]);
  // const [showLoadMore,setShowLoadMore] = useState(true);
  const [searchAutocompleteResults, setSearchAutocompleteResults] = useState([]);
  const [showLoadMore, setShowLoadMore] = useState("");
  const [fullloading, setfullLoading] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(null);
  const inputRef = useRef(null);
  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      // Clicked outside the Autocomplete component
      setIsDropdownOpen(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearchsuggest = (query) => {
    setSearchQuery(query);
  };
  const getOptionSelected = (option, value) => {
    return option === value;
  };
  useEffect(() => {
    fetchpreData();
    return () => { };
  }, []);
  const fetchpreData = async () => {
    setIsLoading(true);
    setfullLoading(true);

    console.log('ser query',searchQuery)
    try {
      const storedUserData = localStorage.getItem("userdata");

      if (!storedUserData) {
        console.error("User data not found in localStorage.");
        return;
      }

      const userDataObj = JSON.parse(storedUserData);
      console.log(userDataObj);
      if (!userDataObj || !userDataObj.token) {
        console.error(
          "User token not found in localStorage or component unmounted."
        );
        return;
      }

      const {
        token: userToken,
        user_id: deepuser_id,
        org_id: deeporg_id,
      } = userDataObj;

      setusertoken(userToken);
      setuser_id(deepuser_id);
      setuserorg_id(deeporg_id);
      setIndex(0);
      const data = await searchDocuments(
        deepuser_id,
        deeporg_id,
        searchQuery,
        deptlist,
        datelist,
        fromDate,
        "",
        userToken
      );
      if (data.code == "200") {
        setfullLoading(false);
        setuserconversation_id(data.conversation_id);
        const newResults = data.data;
        setSearchResults(newResults);
        // console.log(newResults)
        setShowStaticContent(false);
        setShowLoadMore(data.total_docs);
        console.log('total d',data.total_docs)
        console.log('total l',data.data.length)
        if (data.data.length >= 0 && data.data.length <15) {
          setHasMore(false)
        }
        else {
          setHasMore(true);

        }
      } else {
        setShowStaticContent(true);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      // Make sure to set isLoading to false whether the fetch was successful or not
      setIsLoading(false);
    }
  };
  const fetchpreclearData = async () => {
    setIsLoading(true);
    setfullLoading(true);

    console.log('ser query',searchQuery)
    try {
      const storedUserData = localStorage.getItem("userdata");

      if (!storedUserData) {
        console.error("User data not found in localStorage.");
        return;
      }

      const userDataObj = JSON.parse(storedUserData);
      console.log(userDataObj);
      if (!userDataObj || !userDataObj.token) {
        console.error(
          "User token not found in localStorage or component unmounted."
        );
        return;
      }

      const {
        token: userToken,
        user_id: deepuser_id,
        org_id: deeporg_id,
      } = userDataObj;

      setusertoken(userToken);
      setuser_id(deepuser_id);
      setuserorg_id(deeporg_id);
      setIndex(0);
      const data = await searchDocuments(
        deepuser_id,
        deeporg_id,
        '',
        deptlist,
        datelist,
        fromDate,
        "",
        userToken
      );
      if (data.code == "200") {
        setfullLoading(false);
        setuserconversation_id(data.conversation_id);
        const newResults = data.data;
        setSearchResults(newResults);
        // console.log(newResults)
        setShowStaticContent(false);
        setShowLoadMore(data.total_docs);
        console.log('total d',data.total_docs)
        console.log('total l',data.data.length)
        if (data.data.length >= 0 && data.data.length <15) {
          setHasMore(false)
        }
        else {
          setHasMore(true);

        }
      } else {
        setShowStaticContent(true);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      // Make sure to set isLoading to false whether the fetch was successful or not
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const fetchMasterData = async () => {
      setIsLoading(true);
      try {
          const storedUserData = localStorage.getItem("userdata");

          if (!storedUserData) {
              console.error("User data not found in localStorage.");
              return;
          }

          const userDataObj = JSON.parse(storedUserData);
          console.log(userDataObj);
          if (!userDataObj || !userDataObj.token) {
              console.error(
                  "User token not found in localStorage or component unmounted."
              );
              return;
          }

          const {
              token: userToken,
              user_id: deepuser_id,
              org_id: deeporg_id,
          } = userDataObj;

          setusertoken(userToken);
          setuser_id(deepuser_id);
          setuserorg_id(deeporg_id);
          setIndex(0);
          const requestData = await userMasterData(deepuser_id, deeporg_id, userToken);
          if (requestData.code == "200") {
              setuserconversation_id(requestData.conversation_id);
              setDeptSearchResults(requestData.department);
              setIsLoading(false)
              setShowStaticContent(false);
          } else {
              setShowStaticContent(true);
          }
      } catch (error) {
          console.error("Error fetching user data:", error);
      } finally {
          // Make sure to set isLoading to false whether the fetch was successful or not
          setIsLoading(false);
      }
  };

  fetchMasterData();

    return () => { };
  }, []);
  const autoCompleteResult = async (searchQueryvalue) => {
    console.log('autoc:',searchQuery)
    setSearchQuery(searchQuery);
    const hasOptions = searchResults.length >= 3;
    const storedUserData = localStorage.getItem("userdata");
    const userDataObj = JSON.parse(storedUserData);
    const { token: userToken } = userDataObj;
    setusertoken(userToken);
    if (searchQuery.length >= 3) {
      3;
      const data = await getAutocompleteResults(
        user_id,
        userorg_id,
        searchQueryvalue,
        userToken
      );
      if (data.code == "200") {
        setuserconversation_id(data.conversation_id);
        const newResults = data.data;
        setSearchAutocompleteResults(data.data);
        setShowStaticContent(false);
        setSuggestions([]);
        // setSearchQuery(searchQuery)
        setDocumentNameAutocomplete(documentNameAutocomplete);
      } else {
        setShowStaticContent(true);
      }
    }
  };

  const autoCompleteSearchResult = async () => {
    // console.log('res:',searchQuery)
    // setSearchQuery(searchQuery)
    setIsDropdownOpen(null);
    setfullLoading(true);
    try {
      const storedUserData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(storedUserData);
      const { token: userToken } = userDataObj;

      setusertoken(userToken);

      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }
      setusertoken(userToken);
      setIndex(0);
      const data = await searchDocuments(
        user_id,
        userorg_id,
        searchQuery,
        selectedDepartments,
        datelist,
        fromDate,
        todate,
        userToken,
        index
      );

      if (data.code == "200") {

        setfullLoading(false);
        setSearchResults(() => [...data.data]);
        setShowLoadMore(data.total_docs);
        setShowStaticContent(false);
        if (data.data.length >= 0 && data.data.length <15) {
          setHasMore(false)
        }
        else {
          setHasMore(true);

        }
        setSearchQuery(searchQuery)
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleLoadMore = async () => {
    setIsLoadingMore(true);

    try {
      const storedUserData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(storedUserData);
      const { token: userToken } = userDataObj;
      // setIndex(index + 2);
      setusertoken(userToken);
      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }
      setusertoken(userToken);
      setIndex(index + 15);
      const data = await fetchMoreData(
        user_id,
        userorg_id,
        searchQuery,
        selectedDepartments,
        datelist,
        fromDate,
        todate,
        userToken,
        index + 15
      );
      if (data.code == "200") {
        setSearchResults((prevResults) => [...prevResults, ...data.data]);
        setShowStaticContent(false);
        setIsLoadingMore(false);
        if (data.data.length >= 0 && data.data.length <15) {
          setHasMore(false)
        }
        else {
          setHasMore(true);

        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleAutocompleteClick = (event) => {
    // Prevent the click event from propagating to the document body
    event.preventDefault();
    // Stop the event from propagating to the document body
    event.stopPropagation();
  };
  const handleApplyButtonClick = (selectedCheckboxValues) => {
    setSelectedDepartments(selectedCheckboxValues);
    autoCompleteSearchResult();
  };
  const handleApplydate = async (selectedValue) => {
    setDatelist(selectedValue);
    setfullLoading(true);
    try {
      const storedUserData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(storedUserData);
      const { token: userToken } = userDataObj;

      setusertoken(userToken);

      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }
      setusertoken(userToken);
      setIndex(0);
      const data = await searchDocuments(
        user_id,
        userorg_id,
        searchQuery,
        selectedDepartments,
        selectedValue,
        "",
        "",
        userToken,
        index
      );
      if (data.code == "200") {
        setfullLoading(false);
        setSearchResults(() => [...data.data]);
        setShowLoadMore(data.total_docs);
        setShowStaticContent(false);
        if ( data.data.length >= 0 && data.data.length <15) {
          setHasMore(false)
        }
        else {
          setHasMore(true);

        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
    // Handle the selected value in the parent page
  };
  const handleApplydatecustom = async ({ fromDateval, toDateval }) => {
    settodate(toDateval);
    setFromDate(fromDateval);
    setfullLoading(true);
    try {
      const storedUserData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(storedUserData);
      const { token: userToken } = userDataObj;

      //setusertoken(userToken)

      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }
      // setusertoken(userToken);
      setIndex(0);
      const data = await searchDocuments(
        user_id,
        userorg_id,
        searchQuery,
        selectedDepartments,
        datelist,
        fromDateval,
        toDateval,
        userToken,
        index
      );
      if (data.code == "200") {
        setfullLoading(false);
        setSearchResults(data.data);
        setShowLoadMore(data.total_docs);
        setShowStaticContent(false);
        if (data.data.length >= 0 && data.data.length <15) {
          setHasMore(false)
        }
        else {
          setHasMore(true);

        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
    // Handle the selected value in the parent page
  };
  const dateRef = useRef();
  const deptRef = useRef();

  // const handleClearButtonClick = () => {
  //   clearSearchQuery()
  //   settodate("");
  //   setFromDate("");
  //   setSelectedDepartments([]);
  //   setDatelist("");
  //   setSearchQuery('')
  //   dateRef.current.handleResetClick();
  //   // Access the handleResetClick function from the department component using the ref
  //   deptRef.current.handleResetClick();
  //   fetchpreData();
  // };
  const handleClearButtonClick = async () => {

    setfullLoading(true)
    clearSearchQuery()
    settodate("");
    setFromDate("");
    setSelectedDepartments([]);
    setDatelist("");
    setSearchQuery('')
    dateRef.current.handleResetClick();
    // Access the handleResetClick function from the department component using the ref
    deptRef.current.handleResetClick();
    try {
      setfullLoading(true);
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }
      const userToken = userDataObj.token;
      const deepuser_id = userDataObj.user_id;
      const deeporg_id = userDataObj.org_id;

      setusertoken(userToken);
      setuser_id(deepuser_id);
      setuserorg_id(deeporg_id);
      const index = 0;

      // Use the API helper function to fetch query history data
      const data = await searchDocuments(deepuser_id, deeporg_id, '', [], '', '', '', userToken, index);

      if (data.code == "200") {
        setfullLoading(false);
        setuserconversation_id(data.conversation_id);
        const newResults = data.data;
        setSearchResults(newResults);
        // console.log(newResults)
        setShowStaticContent(false);
        setShowLoadMore(data.total_docs);
        if (data.data.length >= 0 && data.data.length <15) {
          setHasMore(false)
        }
        else {
          setHasMore(true);

        }
      } else {
        setShowStaticContent(true);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      // Make sure to set isLoading to false whether the fetch was successful or not
      setIsLoading(false);
    }
  };
  const clearSearchQuery = () => {
    setSearchQuery("");
    fetchpreclearData();

  };

  const handlePDfdata = async (docName, docId, index) => {
    const pdfFileName = docName; // Use docName as the filename

    // Open the new tab with parameters
    window.open(
      `/pdf-viewer?docName=${encodeURIComponent(
        pdfFileName
      )}&docId=${encodeURIComponent(docId)}&index=${encodeURIComponent(index)}`,
      "_blank"
    );
  };

  const LoadingSkeleton = () => {
    // You can customize the skeleton based on your design
    return (
      <TableRow>
        <TableCell>
          <Skeleton width={400} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton width={200} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton width={200} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton width={400} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton width={200} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton width={200} height={20} />
        </TableCell>
      </TableRow>
    );
  };
  const autocompletePdfResult = (selectedOption) => {
    let pdfName = selectedOption.doc_name;
    let pdfId = selectedOption.doc_id;
    handlePDfdata(pdfName, pdfId, index)
  }
  const getOptionLabel = (option) => `${option.doc_name} `;

  const renderOption = (props, option) => (
    <li {...props} style={{ display: "block" }}>
      <Box>
        <Box display="flex" style={{ fontSize: "0.8em" }}>
          <Box>
            <PictureAsPdfIcon
              sx={{
                color: "red",
                height: "19px",
                width: "19px",
                marginTop: "5px",
              }}
            />
          </Box>
          <Box fontWeight="bold" sx={{ marginTop: "5px" }}>
            {option.doc_name}
          </Box>
        </Box>
        <Box display="flex" style={{ fontSize: "0.8em", color: "gray" }}>
          <Box>
            {"Upload Date: "}
            {option.upload_date}{" "}
          </Box>
          <Box sx={{ margin: "0px 5px" }}> || </Box>
          <Box>
            {"Department: "}
            {option.dept_name}
          </Box>
        </Box>
      </Box>
    </li>
  );
  const [onChangeTriggered, setOnChangeTriggered] = useState(false);

  const handleAutocompleteChange = (event, option) => {
    // Call your API with the selected option
    if (option) {
      autocompletePdfResult(option);
      setOnChangeTriggered(true);
    }
  };

  const handleTextFieldKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      autoCompleteSearchResult(e.target.value);
    }
    
  };
  const [dataCount, setDataCount] = useState(0); // Track the number of data items loaded
  const tableContainerRef = useRef(null);
  const isLoadingRef = useRef(false);

  const debouncedHandleScroll = useCallback(debounce(() => {
    const tableContainer = tableContainerRef.current;
    if (
      tableContainer &&
      tableContainer.scrollTop + tableContainer.clientHeight >= tableContainer.scrollHeight &&
      !isLoadingRef.current
    ) {
      isLoadingRef.current = true;
      handleLoadMore().finally(() => {
        isLoadingRef.current = false;
      });
    }
  },200), [handleLoadMore]);
  const handleSearch = () => {
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.scrollTop = 0; // Reset scroll position to top
    }
    autoCompleteSearchResult().then((newDataCount) => {
      setDataCount(newDataCount); // Update data count with the result of the search
      // Optionally check if additional data needs to be loaded if not enough data is displayed
      if (newDataCount < 15) {
        handleLoadMore();
      }
    });
  };
  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    if (tableContainer) {
      tableContainer.addEventListener('scroll', debouncedHandleScroll);

      return () => {
        tableContainer.removeEventListener('scroll', debouncedHandleScroll);
        debouncedHandleScroll.cancel();
      };
    }
  }, [debouncedHandleScroll]);

 
  const handleInputChange = (event, value) => {
    setSearchQuery(value);
    autoCompleteResult(value);
  };

  return (
    <>
      <Box sx={{ display: 'flex', maxWidth: '100%' }} >
        <CssBaseline />
        <AppBar
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            backgroundColor: "white",
          }}
        >
          <Appbar />
        </AppBar>

        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            // onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            background: "#EFF1F3",
            minHeight: "100vh",
            // minWidth:'100%',
          }}
          className="mobile_view_docsearch"
        >
         
         <Box  sx={{padding: '20px 20px 0px 20px', height:'37vh',paddingTop:'12vh' }}>
          <Paper
            elevation={0}
            sx={{
              borderRadius: '25px',
              width: "100%",
             
             margin:'15px 0px',
              "& fieldset": { border: "none" },
            }}
          >
            <Autocomplete
              id="free-solo-demo"
              freeSolo
              clearOnBlur={false}
              sx={{
             
                borderRadius: '25px',
                backgroundColor: isFocused ? 'white' : '#D0E2FB',
                '.MuiOutlinedInput-root': {
                  borderRadius: '25px',
                  paddingRight: 2,
              
                  fontSize: '0.8rem', // Adjust font size to reduce height
            
                  height: "50px",
          
                },
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                 
                },
                '& .MuiInputBase-input::placeholder': {
                  color: 'black', // Set placeholder color to black
                  opacity: 1, // Ensure the placeholder is fully opaque
              },
              }}
              options={searchAutocompleteResults}
              getOptionLabel={getOptionLabel}
              renderOption={renderOption}
              inputValue={searchQuery}
              onChange={handleAutocompleteChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onInputChange={handleInputChange}
              getOptionSelected={getOptionSelected}
              
              renderInput={(params) => (
                <TextField
                  disablePortal
              
                  {...params}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault(); // Prevent default "Enter" behavior
                      e.stopPropagation(); 
                      autoCompleteSearchResult();
                    }
                  }}
                  // onKeyDown={handleTextFieldKeyDown}
          
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search for documents"
                 
      
                  InputProps={{
                    
                    ...params.InputProps,
                  
                    startAdornment: (
                     
                      <IconButton
                       
                       onClick={autoCompleteSearchResult}
                     >
                       <SearchIcon />
                     </IconButton>
                    
                    ),
                    endAdornment: (
                      <>
                        
                          {searchQuery && (
                            <IconButton onClick={clearSearchQuery}>
                              <ClearIcon />
                            </IconButton>
                          )}
                      
                      
                      </>
                    ),
                  }}
                />
              )}
            />
          </Paper>
          <Box sx={{ display: "flex" }}>
            {/* <Box>

                    <FilterListIcon style={{ backgroundColor: '#2F80ED', color: 'white', fontSize: '35px', borderRadius: '50px', padding: '5px' }} />

                </Box> */}
            <Box>
              <DropdownWithCheckboxes
                data={deptSearchResults}
                ref={deptRef}
                onApplyButtonClick={handleApplyButtonClick}
                onSelectionChange={setSelectedDepartments}
              />
            </Box>
            <Box>
              <BasicPopover
                ref={dateRef}
                onApplycustomdate={handleApplydatecustom}
                onApply={handleApplydate}
              />
            </Box>
            <Box sx={{ marginLeft: "10px" }}>
              <Button variant="outlined" onClick={handleClearButtonClick}>
                Clear All
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              textAlign: "left",
              color: "gray",
              fontSize: "14px",
              marginTop: "10px",
            }}
          >
            {showLoadMore ? showLoadMore : 0} records found
          </Box>
          </Box>
          <Box>
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
             
            }}
          >
            <TableContainer ref={tableContainerRef}
                                sx={{ height: '62.8vh', overflow: 'auto' }}
                                >
             
                <Table stickyHeader size="small" aria-label="a dense table">
                  <TableHead sx={{ backgroundColor: "#eff1f3", color: "pink" }}>
                    <TableRow sx={{ backgroundColor: "#eff1f3" }}>
                      <TableCell
                        sx={{
                          backgroundColor: "#eff1f3",
                          color: "Black",
                          borderTop: "3px solid white",
                          borderLeft: "3px solid white",
                          borderRadius: "5px",
                        }}
                      >
                        Document Name
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "#eff1f3",
                          color: "Black",
                          borderTop: "3px solid white",
                        }}
                      >
                        Owner
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "#eff1f3",
                          color: "Black",
                          borderTop: "3px solid white",
                        }}
                      >
                        Upload Date
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "#eff1f3",
                          color: "Black",
                          borderTop: "3px solid white",
                        }}
                      >
                        File Size
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "#eff1f3",
                          color: "Black",
                          borderTop: "3px solid white",
                          borderRight: "3px solid white",
                        }}
                      >
                        Department
                      </TableCell>
                      {/* <TableCell
                        sx={{
                          backgroundColor: "#eff1f3",
                          color: "Black",
                          borderTop: "3px solid white",
                          borderRight: "3px solid white",
                          borderRadius: "5px",
                        }}
                      >
                        Status
                      </TableCell> */}
                    </TableRow>
                  </TableHead>
                  {fullloading ? (
                // Render loading skeletons while data is being fetched
                Array.from({ length: 10 }).map((_, index) => (
                  <LoadingSkeleton key={index} />
                ))
              ) : (
                  <TableBody>
                    {searchResults.map((item, index) => (
                      <TableRow sx={{ padding: "0px", }}>
                        <TableCell sx={{ padding: "0px 0px 0px 10px",maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'  }}>
                          <div
                            style={{
                              display: "flex",
                              padding: "0px",
                              margin: "0px",
                            }}
                          >
                            <PictureAsPdfIcon
                              sx={{
                                color: "red",
                                marginTop: "10px",
                                marginRight: "5px",
                              }}
                            />
                            <Link
                              to="#"
                              style={{ 
                                // textDecoration: "none", 
                                color: "#2F80ED" }}
                            >
                              <p
                                onClick={() =>
                                  handlePDfdata(
                                    item.doc_name,
                                    item.doc_id,
                                    index
                                  )
                                }
                              >
                                {item.doc_name}
                              </p>
                            </Link>
                          </div>
                        </TableCell>
                        <TableCell >
                          <div style={{ display: 'flex' }}>
                            <img src={item.owner_photo} style={{ height: '30px', width: '30px', marginTop: '8px', marginRight: '10px', borderRadius: '50px' }} />
                            <p>{item.owner_name}</p>
                          </div>
                        </TableCell>
                        <TableCell >
                          {item.upload_date}
                        </TableCell>
                        <TableCell >
                          {item.file_size}
                        </TableCell>
                        <TableCell >
                          {item.dept_name}
                        </TableCell>
                        {/* <TableCell >
                          {item.document_index_status == 'indexed' &&
                            <Button variant="outlined" style={{ borderColor: '#27AE603D', backgroundColor: '#27AE6029', color: 'green', borderRadius: '10px', fontSize: '10px', width: '100px' }} disabled color="primary"> {item.document_index_status}</Button>
                          }
                          {item.document_index_status == 'processed' &&
                            <Button variant="outlined" style={{ borderColor: 'blue', backgroundColor: '#2F80ED3D', color: 'blue', borderRadius: '10px', fontSize: '10px', width: '100px' }} disabled color="primary"> {item.document_index_status}</Button>
                          }
                          {item.document_index_status == 'failed' &&
                            <Button variant="outlined" style={{ borderColor: '#EB57573D', backgroundColor: '#EB57571F', color: 'red', borderRadius: '10px', fontSize: '10px', width: '100px' }} disabled color="primary"> {item.document_index_status}</Button>
                          }

                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                   )}
                </Table>
                <InfiniteScroll
              dataLength={searchResults.length}
              next={handleLoadMore}
              hasMore={hasMore}
              loader={
                <CircularProgress disableShrink style={{ margin: "20px" }} />
              }
              endMessage={
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  {searchResults.length == 0 ? (
                    <div
                      style={{
                        color: "grey",
                        fontSize: "13px",
                        marginTop: "20px",
                      }}
                    >
                      No record found
                    </div>
                  ) : (
                    <>
                      <div>No More Records</div>
                      
                    </>
                  )}
                </div>
              }
            ></InfiniteScroll>
            </TableContainer>
          </Paper>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default DocumentSearch;
