//Function to search documents based on various parameters.
export const searchDocuments = async (user_id, userorg_id, searchQuery, deptlist, datelist, fromDate, toDate, token) => {
  const BASE_URL = `${window.constants.api_url}`;
  try {
    // console.log(BASE_URL)

    const apiEndpoint = "documentsearch";
    const API_URL = `${BASE_URL}/${apiEndpoint}`;

    const requestData = {
      user_id,
      org_id: userorg_id,
      searchstring: searchQuery,
      department: deptlist,
      date: {
        type: "1",
        value: datelist,
      },
      start_index: 0,
      limit: 15,
    };

    if (fromDate !== "" && toDate !== "") {
      requestData.date = {
        type: "2",
        value: `${fromDate},${toDate}`,
      };
    }

    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
//download doucment api helper for view document and download doucment .
export const downloadPDF = async (user_id, userorg_id, pdf_id, pdfdoc, token) => {
  const BASE_URL = `${window.constants.api_url}`;
  try {
    const apiEndpoint = "downloaddoucment"; // Double-check the correct endpoint
    const API_URL = `${BASE_URL}/${apiEndpoint}`;
    const requestData = {
      user_id,
      org_id: userorg_id,
      doc_id: pdf_id,
      doc_name: pdfdoc,
    };

    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
// Add more API request functions as needed
// document search api helper for get all document when search or loadmore
export const fetchMoreData = async (user_id, userorg_id, searchQuery, deptlist, datelist, fromDate, toDate, token, index) => {
  try {
    const apiEndpoint = "documentsearch";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    let requestData = {
      user_id,
      org_id: userorg_id,
      searchstring: searchQuery,
      department: deptlist,
      date: {
        type: "1",
        value: datelist,
      },
      start_index: index,
      limit: 15,
    };

    if (fromDate !== "" && toDate !== "") {
      requestData.date = {
        type: "2",
        value: `${fromDate},${toDate}`,
      };
    }

    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
//Retrieves the list of departments available for the user.
export const getDepartment = async (user_id, userorg_id, token) => {
  try {
    const apiEndpoint = "getdepartmentlist";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
    };

    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
//Fetches autocomplete results based on the provided query for document names.
export const getAutocompleteResults = async (user_id, userorg_id, query, token) => {
  const BASE_URL = `${window.constants.api_url}`;

  try {
    const apiEndpoint = "docnameautocomplete";
    const API_URL = `${BASE_URL}/${apiEndpoint}`;
    const userData = {
      org_id: userorg_id,
      user_id,
      searchstring: query,
    };

    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const saveannotation = async (user_id, orgid, projectid, doc_id, annotation_refid, annotation, token) => {
  const BASE_URL = `${window.constants.api_url}`;
  try {
    const apiEndpoint = "saveannotation"; // Double-check the correct endpoint
    const API_URL = `${BASE_URL}/${apiEndpoint}`;
    const requestData = {
      orgid: orgid,
      user_id: user_id,
      projectid: projectid,
      doc_id: doc_id,
      annotation_refid: annotation_refid,
      annotation: annotation,
    };

    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const updateannotation = async (user_id, orgid, projectid, doc_id, annotation_refid, annotation, token) => {
  const BASE_URL = `${window.constants.api_url}`;
  try {
    const apiEndpoint = "updateannotation"; // Double-check the correct endpoint
    const API_URL = `${BASE_URL}/${apiEndpoint}`;
    const requestData = {
      orgid: orgid,
      user_id: user_id,
      projectid: projectid,
      doc_id: doc_id,
      annotation_refid: annotation_refid,
      annotation: annotation,
    };

    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const deleteannotation = async (user_id, orgid, projectid, doc_id, annotation_refid, token) => {
  const BASE_URL = `${window.constants.api_url}`;
  try {
    const apiEndpoint = "deleteannotation"; // Double-check the correct endpoint
    const API_URL = `${BASE_URL}/${apiEndpoint}`;
    const requestData = {
      orgid: orgid,
      user_id: user_id,
      projectid: projectid,
      doc_id: doc_id,
      annotation_refid: annotation_refid,
    };

    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const getallannotation = async (user_id, doc_id, token) => {
  const BASE_URL = `${window.constants.api_url}`;
  try {
    const apiEndpoint = "getallannotation"; // Double-check the correct endpoint
    const API_URL = `${BASE_URL}/${apiEndpoint}`;
    const requestData = {
      
      user_id: user_id,
      doc_id: doc_id,
    };

    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const userMasterData = async (
  user_id,
  userorg_id,
  token
) => {
  const BASE_URL = `${window.constants.api_url}/admin/usermaster`;

  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};