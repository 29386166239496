import React, { useState, useEffect, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Popover from '@mui/material/Popover';
import Toolbar from "@mui/material/Toolbar";
import InfiniteScroll from "react-infinite-scroll-component";
import Typography from "@mui/material/Typography";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import DrawerComponent from "../Includes/Sidebar";
import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress } from '@mui/material';
import { NavLink } from "react-router-dom";
import { Chip, Divider, Stack } from '@mui/material';
import {
    Button, TextField, Link,
    Autocomplete, List, Avatar, ListItem, ListItemAvatar, ListItemText
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import CheckIcon from '@mui/icons-material/Check';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import NotesIcon from '@mui/icons-material/Notes';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ActionMenu from "./ActionMenu";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import AddDepartment from "./addDepartment";
// import ViewDepartment from "./viewDepartment";
import { format } from 'date-fns';
import "./DocumentManager.css";
import { borderRadius, display, padding } from "@mui/system";
import MobileStepper from '@mui/material/MobileStepper';
import CachedIcon from '@mui/icons-material/Cached';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { Step1Modal, Step2Modal, Step3Modal, Step4Modal } from './StepperModal'
import { getAllDocument, getAllDepartment, completeDocumentUpload, fetchMoreDocument, userMasterData, accessUser, getAllShareUser } from "../AdminApiHelper";
import DepartmentFilter from "./DepartmentFilter";
import DateModifiedFilter from "./DateModifiedFilter";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Snackbar, SnackbarContent } from '@mui/material';
import Appbar from "../Includes/Header";
// const steps = [
//     {
//         text: 'Step 1: Introduction',
//         progress: 25,
//         content: <Step1Modal onDepartmentChange={handleDepartmentChange}/>
//     },
//     {
//         text: 'Step 2: Details',
//         progress: 50,
//         content: <Step2Modal />
//     },
//     {
//         text: 'Step 3: Review',
//         progress: 75,
//         content: <Step3Modal />
//     },
//     {
//         text: 'Step 4: Confirmation',
//         progress: 100,
//         content: <Step4Modal />
//     },

// ];
const drawerWidth = 140;
const theme = createTheme();
function DocumentManager(props) {
    // const { window } = props;
    const { windows } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <DrawerComponent />
        </div>
    );

    const container =
        windows !== undefined ? () => window().document.body : undefined;

    const [departmentbutton, setDepartmentbutton] = React.useState(false);
    const handlebutton = () => {
        setDepartmentbutton(!departmentbutton);
    };
    const [accessType, setAccessType] = useState('public'); // Default value is 'public'
    const handleAccessTypeChange = (event) => {
        if (event.target && event.target.value) {
            setAccessType(event.target.value);
        }
    };
    
    const [uploadComplete, setUploadComplete] = useState(false)
    const compUpload = (value) => {
        setUploadComplete(value)
    }
    const tableTitle = ['Sl. No.', 'Organisation', 'Department', 'Description', 'Action'];
    // const tableData = [
    //     'a1.pdf', 'a2.pdf', 'a3.pdf', 'a4.pdf', 'a5.pdf', 'a6.pdf', 'a7.pdf', 'a7.pdf', 'a7.pdf', 'a7.pdf', 'a7.pdf', 'a7.pdf', 'a7.pdf', 'a7.pdf', 'a7.pdf', 'a7.pdf', 'a7.pdf', 'a7.pdf', 'a7.pdf', 'a7.pdf'
    // ];
    const deptBtnStyle = {
        borderRadius: '200px',
        backgroundColor: 'var(--Secondary-blue, #003776)',
        textTransform: 'none'
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [addDocumentModalOpen, setAddDocumentModalOpen] = useState(false)
    const openAddDocumentModal = () => {
        setAddDocumentModalOpen(true)
    };
    const closeAddDocumentModal = () => {
        setAddDocumentModalOpen(false)
        closeConfirmationModalClose()
        setActiveStep(0)
        setUploadComplete(false)
        setSelectedDepartment('');
        setUploadFiles([]);
        setAccessUser([]);
        // fetchDepartment()
    };
    const modalStyle = {
        borderRadius: '200px'
    }
    const [isDeleteModal, setIsDeleteModal] = useState(false)

    const openDeleteModal = () => {
        setIsDeleteModal(true);

    }
    const closeDeleteModal = () => {
        setIsDeleteModal(false);

    }
    const handleClickDelete = () => {

        setIsDeleteModal(false);

    }
    const [activeStep, setActiveStep] = useState(0);
    const [selectedDepartments, setSelectedDepartments] = useState([]);
    const handleNext = () => {
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        // console.log(steps[activeStep])
        if (activeStep == 3) {
            // setAddDocumentModalOpen(false)
            const userIdsArray = accessUser.map(user => user.user_id).join(',');
            console.log('userIdsArray', userIdsArray)
            const fileName = uploadFiles.map(file => file.name);
            const fileSize = uploadFiles.map(file => file.size);
            const confirmUpload = async () => {
                // setIsLoading(true);
                setButtonLoading(true)
                try {
                    const storedUserData = localStorage.getItem("userdata");

                    if (!storedUserData) {
                        console.error("User data not found in localStorage.");
                        return;
                    }

                    const userDataObj = JSON.parse(storedUserData);
                    console.log(userDataObj);
                    if (!userDataObj || !userDataObj.token) {
                        console.error(
                            "User token not found in localStorage or component unmounted."
                        );
                        return;
                    }

                    const userToken = userDataObj.token;
                    const deepuser_id = userDataObj.user_id;
                    const deeporg_id = userDataObj.org_id;
                    for (let i = 0; i < uploadFiles.length; i++) {
                        const file = uploadFiles[i];
                        const requestData = await completeDocumentUpload(
                            deepuser_id,
                            deeporg_id,
                            file.name,
                            file.size,
                          
                            selectedDepartment,
                            accessType,
                            userIdsArray,
                            userToken
                        );

                        if (requestData.code === "200") {
                            // Handle success for each file if needed
                            setAccessType('public');
                            setSnackbarOpen(true);
                            settoastmessage(requestData.message)
                            setButtonLoading(false)
                            setAddDocumentModalOpen(false)

                            setActiveStep(0)
                            setSelectedDepartment('');
                            setUploadFiles([]);
                            setAccessUser([]);
                        } else {
                            // Handle failure for each file if needed
                            setSnackbarOpen(true);
                            settoastmessage(requestData.message)
                        }
                    }
                } catch (error) {
                    console.error("Error fetching user data:", error);
                } finally {
                    // Make sure to set isLoading to false whether the fetch was successful or not
                    // setIsLoading(false);
                    fetchAllDocuments()
                }
            };
            confirmUpload()

        }
        else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

    };
    const handleApplyButtonClick = (selectedCheckboxValues) => {
        setSelectedDepartments(selectedCheckboxValues);
        autoCompleteSearchResult();
    };

    const dateRef = useRef();
    const deptRef = useRef();
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const date = new Date(); // Or, you can get the date from an API or other data source

    // Format the date using date-fns

    const [superAdmin, setSuperAdmin] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [usertoken, setusertoken] = useState("");
    const [index, setIndex] = useState(0);
    const [user_id, setuser_id] = useState("");
    const [userorg_id, setuserorg_id] = useState("");
    const [documentResults, setDocumentResults] = useState([]);
    const [deptSearchResults, setDeptSearchResults] = useState([]);
    const [departmentSearchResults, setDepartmentSearchResults] = useState([]);
    const [showStaticContent, setShowStaticContent] = useState(false);
    const [userconversation_id, setuserconversation_id] = useState("");
    const [dept_id, setDept_id] = useState([]);
    const [dept_desc, setDept_desc] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [toastmessage, settoastmessage] = useState('');
    const [hasMore, setHasMore] = useState(true);
    const [datelist, setDatelist] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [todate, settodate] = useState("");
    const [stringSearch, setStringSearch] = useState('');
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    useEffect(() => {


        fetchAllDocuments();

        return () => { };
    }, []);
    const [totalDoc, setTotalDoc] = useState('')
    const fetchAllDocuments = async () => {
        setIsLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
                super_admin: super_admin,
            } = userDataObj;
            setSuperAdmin(super_admin)
            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            setIndex(0);
            const requestData = await getAllDocument(
                deepuser_id,
                deeporg_id,
                stringSearch, selectedDepartments, datelist, fromDate, todate,
                userToken,
                index
            );
            if (requestData.code == "200") {
                setuserconversation_id(requestData.conversation_id);
                setTotalDoc(requestData.total_rec)
                const newResults = requestData.data;
                setDocumentResults(requestData.data);
                console.log(newResults)
                console.log('requestData.total_rec', requestData.total_rec)
                console.log('requestData.length', requestData.data.length)
                if (requestData.data.length >= 0 && requestData.data.length < 15) {
                    setHasMore(false)
                }
                else {
                    setHasMore(true);

                }
                // setHasMore(requestData.data.length > 0);

                setIsLoading(false)
                setShowStaticContent(false);
            } else {
                setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            setIsLoading(false);
        }
    };

    const fetchclearAllDocuments = async () => {
        setIsLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
                super_admin: super_admin,
            } = userDataObj;
            setSuperAdmin(super_admin)
            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            setIndex(0);
            const requestData = await getAllDocument(
                deepuser_id,
                deeporg_id,
                selectedDepartments, datelist, fromDate, todate,
                userToken,
                index
            );
            if (requestData.code == "200") {
                setuserconversation_id(requestData.conversation_id);
                setTotalDoc(requestData.total_rec)
                const newResults = requestData.data;
                setDocumentResults(requestData.data);
                console.log(newResults)
                console.log('requestData.total_rec', requestData.total_rec)
                console.log('requestData.length', requestData.data.length)
                if (requestData.data.length >= 0 && requestData.data.length < 15) {
                    setHasMore(false)
                }
                else {
                    setHasMore(true);

                }
                // setHasMore(requestData.data.length > 0);

                setIsLoading(false)
                setShowStaticContent(false);
            } else {
                setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            setIsLoading(false);
        }
    };
    const handleLoadMore = async () => {
        console.log('loadmore...')
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
            } = userDataObj;

            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            setIndex(index + 15);
            const requestData = await fetchMoreDocument(deepuser_id, deeporg_id, stringSearch, selectedDepartments, datelist, fromDate, todate, userToken, index + 15);
            if (requestData.code == "200") {
                setuserconversation_id(requestData.conversation_id);
                const newResults = requestData.data;
                setDocumentResults((prevResults) => [

                    ...prevResults, ...newResults,
                ]);
                console.log(newResults)
                console.log('requestData.total_rec', requestData.total_rec)
                console.log('requestData.length', requestData.length)
                if (requestData.data.length == 0) {
                    setHasMore(false)
                }
                else {
                    setHasMore(requestData.data.length > 0);
                }


                setShowStaticContent(false);
            } else {
                setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const fetchMasterData = async () => {
            setIsLoading(true);
            try {
                const storedUserData = localStorage.getItem("userdata");

                if (!storedUserData) {
                    console.error("User data not found in localStorage.");
                    return;
                }

                const userDataObj = JSON.parse(storedUserData);
                console.log(userDataObj);
                if (!userDataObj || !userDataObj.token) {
                    console.error(
                        "User token not found in localStorage or component unmounted."
                    );
                    return;
                }

                const {
                    token: userToken,
                    user_id: deepuser_id,
                    org_id: deeporg_id,
                } = userDataObj;

                setusertoken(userToken);
                setuser_id(deepuser_id);
                setuserorg_id(deeporg_id);
                setIndex(0);
                const requestData = await userMasterData(deepuser_id, deeporg_id, userToken);
                if (requestData.code == "200") {
                    setuserconversation_id(requestData.conversation_id);
                    setDepartmentSearchResults(requestData.department);
                    setIsLoading(false)
                    setShowStaticContent(false);
                } else {
                    setShowStaticContent(true);
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            } finally {
                // Make sure to set isLoading to false whether the fetch was successful or not
                setIsLoading(false);
            }
        };

        fetchMasterData();

        return () => { };
    }, []);
  
    const [share_data, setShare_data] = useState([]);
    const [moreShared_with, setMoreShared_with] = useState(null);
    const handleMoreShare = (event, item) => {
        setMoreShared_with(event.currentTarget);
        setShare_data(item);
        console.log('item', item)
    };

    const handleMoreShareClose = () => {
        setMoreShared_with(null);
    };

    const openMoreShare = Boolean(moreShared_with);
    const moreShareId = openMoreShare ? 'simple-popover' : undefined;
    const handlePDfdata = async (docName, docId, index) => {
        const pdfFileName = docName; // Use docName as the filename

        // Open the new tab with parameters
        window.open(
            `/pdf-viewer?docName=${encodeURIComponent(
                pdfFileName
            )}&docId=${encodeURIComponent(docId)}&index=${encodeURIComponent(index)}`,
            "_blank"
        );
    };

    const [selectedDepartment, setSelectedDepartment] = useState('');
    const handleDepartmentChange = (selectedDept) => {
        setSelectedDepartment(selectedDept);
    };
    const [uploadFiles, setUploadFiles] = useState([]);
    const handleFileUpload = (selectedFiles) => {
        setUploadFiles(selectedFiles);
    };
    const [accessUser, setAccessUser] = useState([])
    const handleAccessUser = (userValue) => {
        setAccessUser(userValue)
        // console.log('accessUser1',accessUser)
    }
    console.log('accessUser2', accessUser)
    console.log('uploadFile', uploadFiles)
    console.log('uploadFileName', uploadFiles.name)
    console.log('uploadFileSize', uploadFiles.file_size)
    console.log('department', selectedDepartment)
    const handleFetchDocuments = () => {
        fetchAllDocuments()
    }
    const LoadingSkeleton = () => {
        // You can customize the skeleton based on your design
        return (
            <TableRow >
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
            </TableRow>
        );
    };

    const options1 = ['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5'];
    const steps = [
        {
            text: 'Step 1: Introduction',
            progress: 25,
            content: <Step1Modal onDepartmentChange={handleDepartmentChange} selectedDepartment={selectedDepartment} />
        },
        {
            text: 'Step 2: Details',
            progress: 50,
            content: <Step2Modal selectedDepartment={selectedDepartment} onUploadFiles={handleFileUpload} uploadFiles={uploadFiles} compUpload={compUpload} />
        },
        {
            text: 'Step 3: Review',
            progress: 75,
            content: <Step3Modal selectedUsers={handleAccessUser} accessUser={accessUser} onChangeClick={handleAccessTypeChange}  accessType={accessType}  
            setAccessType={setAccessType}/>
        },
        {
            text: 'Step 4: Confirmation',
            progress: 100,
            content: <Step4Modal selectedDepartment={selectedDepartment} accessUser={accessUser} uploadFiles={uploadFiles} accessType={accessType} 
            setAccessType={setAccessType}/>
        },

    ];

   
  
    const autoCompleteSearchResult = async () => {

        setStringSearch(stringSearch)

        // setfullLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");
            const userDataObj = JSON.parse(storedUserData);
            const { token: userToken } = userDataObj;

            setusertoken(userToken);

            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            setusertoken(userToken);
            setIndex(0);
            const data = await getAllDocument(
                user_id,
                userorg_id,
                stringSearch,
                selectedDepartments,
                datelist,
                fromDate,
                todate,
                userToken,
                index
            );

            if (data.code == "200") {

                setuserconversation_id(data.conversation_id);
                setTotalDoc(data.total_rec)
                const newResults = data.data;
                setDocumentResults(data.data);
                console.log(newResults)
                console.log('requestData.total_rec', data.total_rec)
                console.log('requestData.length', data.data.length)
                if (data.total_rec == data.data.length) {
                    setHasMore(false)
                }
                else {
                    setHasMore(data.data.length > 0);

                }
                // setHasMore(requestData.data.length > 0);

                setIsLoading(false)
                setShowStaticContent(false);
                setStringSearch(stringSearch)
            } else {
                setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };
    const handleApplydate = async (selectedValue) => {
        setDatelist(selectedValue);
        // setfullLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");
            const userDataObj = JSON.parse(storedUserData);
            const { token: userToken } = userDataObj;

            setusertoken(userToken);

            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            setusertoken(userToken);
            setIndex(0);
            const data = await getAllDocument(
                user_id,
                userorg_id,
                stringSearch,
                selectedDepartments,
                selectedValue,
                "",
                "",
                userToken,
                index
            );
            if (data.code == "200") {
                // setfullLoading(false);
                setDocumentResults(data.data);
                setTotalDoc(data.total_rec);
                // setShowStaticContent(false);
                if (data.total_rec == data.data.length) {
                    setHasMore(false)
                }
                else {
                    setHasMore(data.data.length > 0);

                }
            }
        } catch (error) {
            console.error("Error:", error);
        }
        // Handle the selected value in the parent page
    };
    const handleApplydatecustom = async ({ fromDateval, toDateval }) => {
        settodate(toDateval);
        setFromDate(fromDateval);
        // setfullLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");
            const userDataObj = JSON.parse(storedUserData);
            const { token: userToken } = userDataObj;

            //setusertoken(userToken)

            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            // setusertoken(userToken);
            setIndex(0);
            const data = await getAllDocument(
                user_id,
                userorg_id,
                stringSearch,
                selectedDepartments,
                datelist,
                fromDateval,
                toDateval,
                userToken,
                index
            );
            if (data.code == "200") {
                // setfullLoading(false);
                setDocumentResults(data.data);
                setTotalDoc(data.total_rec);
                setShowStaticContent(false);
                if (data.total_rec == data.data.length) {
                    setHasMore(false)
                }
                else {
                    setHasMore(data.data.length > 0);

                }
            }
        } catch (error) {
            console.error("Error:", error);
        }
        // Handle the selected value in the parent page
    };

    const handleClearButtonClick = async () => {
        setDatelist([]);
        setFromDate('');
        settodate('');

        clearSearchQuery()
        dateRef.current.handleResetClick();
        // Access the handleResetClick function from the department component using the ref
        deptRef.current.handleResetClick();
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
                super_admin: super_admin,
            } = userDataObj;
            setSuperAdmin(super_admin)
            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            setIndex(0);
            const requestData = await getAllDocument(
                deepuser_id,
                deeporg_id,
                '', [], '', '', '',
                userToken,
                index
            );
            if (requestData.code == "200") {
                setuserconversation_id(requestData.conversation_id);
                setTotalDoc(requestData.total_rec)
                const newResults = requestData.data;
                setDocumentResults(requestData.data);
                console.log(newResults)
                console.log('requestData.total_rec', requestData.total_rec)
                console.log('requestData.length', requestData.data.length)
                if (requestData.total_rec == requestData.data.length) {
                    setHasMore(false)
                }
                else {
                    setHasMore(requestData.data.length > 0);

                }
                // setHasMore(requestData.data.length > 0);

                setIsLoading(false)
                setShowStaticContent(false);
            } else {
                setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            setIsLoading(false);
        }
    };

    const [confirmClose, setConfirmClose] = useState(false)
    const closeConfirmationModalOpen = () => {
        if (activeStep > 0 || selectedDepartment) {
            setConfirmClose(true)
        } else {
            closeAddDocumentModal()
        }

    }
    const closeConfirmationModalClose = () => {
        setConfirmClose(false)
    }

    const tableContainerRef = useRef(null);
    useEffect(() => {
        const tableContainer = tableContainerRef.current;

        // Check if tableContainerRef.current is not null before adding event listener
        if (tableContainer) {
            const handleScroll = () => {
                // Check if the user has scrolled to the bottom of the table container
                if (
                    tableContainer.scrollTop + tableContainer.clientHeight >=
                    tableContainer.scrollHeight
                ) {
                    // Trigger the load more function when scrolled to the bottom
                    handleLoadMore();
                }
            };

            // Add scroll event listener to the table container
            tableContainer.addEventListener('scroll', handleScroll);

            return () => {
                // Remove scroll event listener on component unmount
                tableContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, [handleLoadMore, tableContainerRef]);
    const [openAction, setOpenAction] = useState(Array(documentResults.length).fill(false));
    const handleRowClick = (index) => {
        const newOpenRows = [...openAction];
        newOpenRows[index] = !newOpenRows[index];
        setOpenAction(newOpenRows);
    }; 
     const clearSearchQuery = () => {
        setStringSearch('');
        fetchclearAllDocuments();
    };
    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            
            <AppBar
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    backgroundColor: "white",
                }}
            >
                <Appbar />
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: "none", sm: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,

                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    background: "#EFF1F3",
                    minHeight: "100vh",
                }}
            >
               

                <div className="department_main">

                    <Box sx={{ padding: '20px 20px 0px 20px', height:'37.1vh',paddingTop:'14vh' }}>
                        <Box sx={{
                            // width: '100%', border: '1px solid grey', backgroundColor: 'white', height: '39px', borderRadius: '5px', marginTop: '5px'
                        }}>
                           

                                <TextField
                                fullWidth
                                sx={{
                                    marginBottom: '10px',
                                      borderRadius: '25px',
                                      backgroundColor: isFocused ? 'white' : '#D0E2FB',
                                      '.MuiOutlinedInput-root': {
                                        borderRadius: '25px',
                                        paddingRight: 2,
                                       
                                        fontSize: '0.8rem', // Adjust font size to reduce height
                                        lineHeight: '1.2', // Adjust line height if needed
                                        minHeight: '30px', // Set minimum height for the input
                                
                                      },
                                      '.MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                      },
                                      '& .MuiInputBase-input::placeholder': {
                                        color: 'black', // Set placeholder color to black
                                        opacity: 1, // Ensure the placeholder is fully opaque
                                    },
                                    }}
                                    onFocus={() => setIsFocused(true)}
                                    onBlur={() => setIsFocused(false)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault(); // Prevent default "Enter" behavior
                                            e.stopPropagation();
                                         autoCompleteSearchResult();
                                        }
                                    }}
                                    value={stringSearch}
                                 
                                    onChange={(e) => setStringSearch(e.target.value)}
                                    placeholder='Search for document title'
                                    InputProps={{

                                        endAdornment: (
                                            <Box display="flex">
                                                <Box display='flex'>
                                                    {stringSearch && (
                                                        <IconButton onClick={clearSearchQuery}>
                                                            <CloseIcon />
                                                        </IconButton>
                                                    )}
                                                    {stringSearch &&
                                                        <IconButton onClick={autoCompleteSearchResult}>
                                                            < SearchIcon />
                                                        </IconButton>
                                                    }
                                                </Box>
                                                
                                            </Box>),
                                    }}
                                />



                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ marginRight: '10px' }}><DepartmentFilter data={departmentSearchResults}
                                    ref={deptRef}
                                    onApplyButtonClick={handleApplyButtonClick}
                                    onSelectionChange={setSelectedDepartments} /> </Box>
                                <Box><DateModifiedFilter
                                    ref={dateRef}
                                    onApplycustomdate={handleApplydatecustom}
                                    onApply={handleApplydate}
                                /></Box>
                                <Box sx={{ marginLeft: '10px' }}>
                                    <Button variant="outlined"
                                        sx={{ color: 'grey', backgroundColor: 'none', border: '1px solid grey', textTransform: 'none', padding: '5px 20px', fontSize: '14px', height: '40px', }}
                                        onClick={handleFetchDocuments} startIcon={<CachedIcon />}>
                                        Reload
                                    </Button>
                                </Box>
                                <Box sx={{ marginLeft: '10px' }}>
                                    <Button variant="outlined"
                                        sx={{ color: 'grey', backgroundColor: 'none', border: '1px solid grey', textTransform: 'none', padding: '5px 20px', fontSize: '14px', height: '40px', }}
                                        onClick={handleClearButtonClick}>
                                        Clear All
                                    </Button>
                                </Box>
                            </Box>
                            <Box>
                                <Button variant="contained" style={deptBtnStyle} onClick={openAddDocumentModal}>+ Upload New Document</Button>
                                <ThemeProvider theme={theme}>
                                    <Dialog open={addDocumentModalOpen}
                                        onClose={closeConfirmationModalOpen}
                                        sx={{
                                            "& .MuiDialog-paper": {
                                                borderRadius: "20px",
                                                width: '550px',
                                            },
                                        }}
                                        className={{ paper: 'custom-dialog-paper' }}
                                    >
                                        <DialogTitle sx={{ paddingBottom: '0px', paddingTop: '20px', marginBottom: '0px', display: 'flex', }} flexGrow={1}>
                                            <Box style={{ display: 'block', width: '100%' }}>
                                                <Box style={{ display: 'flex', flexGrow: '1' }} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Box sx={{ fontWeight: 'bold' }}>
                                                        <Typography variant="h5" fontWeight='bold'> Upload New Document</Typography>
                                                    </Box>
                                                    <Box >
                                                        <IconButton>
                                                            <CloseIcon onClick={closeConfirmationModalOpen} />
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <LinearProgress variant="determinate" value={steps[activeStep].progress} sx={{ marginTop: 2 }} />
                                                    <Box display='flex'>
                                                        <Typography sx={{ marginTop: '3px' }}>Step</Typography>
                                                        <MobileStepper
                                                            variant="text" // "text" for both text and progress
                                                            steps={4}
                                                            position="static"
                                                            activeStep={activeStep}
                                                            size='small'
                                                            sx={{
                                                                padding: '0px 5px', '& .MuiMobileStepper-dotActive, & .MuiMobileStepper-dot': {
                                                                    width: '5px',
                                                                    height: '5px',
                                                                },
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </DialogTitle>
                                        <DialogContent >
                                            {steps[activeStep].content}
                                        </DialogContent>
                                        <DialogActions sx={{ marginBottom: '10px' }}>
                                            <Box sx={{ justifyContent: 'space-between', alignItems: 'center', display: 'flex', textAlign: 'left', flexGrow: '1' }}>

                                                <Box sx={{ marginLeft: '10px' }}>
                                                    {activeStep > 2 &&
                                                        <Button variant="outlined" sx={{ borderRadius: '200px', margin: '5px' }} size="small" onClick={handleBack} >
                                                            <ArrowBackIcon />  Back
                                                        </Button>
                                                    }
                                                </Box>

                                                <Box sx={{ marginRight: '10px' }}>
                                                    <Button onClick={closeConfirmationModalOpen} variant="outlined" size="small" sx={{ borderRadius: '200px', margin: '5px' }}>
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        sx={{ borderRadius: '200px', margin: '5px' }}
                                                        onClick={handleNext}
                                                        backgroundColor={buttonLoading && 'grey'}
                                                        disabled={buttonLoading || (!selectedDepartment && activeStep == 0) || (!uploadComplete && activeStep == 1)}
                                                    >
                                                        {buttonLoading && <CircularProgress size={16} sx={{ marginRight: '10px' }} />}{(activeStep === steps.length - 1 ? 'Confirm' : 'Next')}
                                                    </Button>

                                                </Box>
                                            </Box>
                                        </DialogActions>
                                    </Dialog>
                                    <Dialog open={confirmClose}
                                        onClose={closeConfirmationModalClose}
                                        sx={{
                                            "& .MuiDialog-paper": {
                                                borderRadius: "20px",
                                                // width: '550px',
                                            },
                                        }}
                                        className={{ paper: 'custom-dialog-paper' }}
                                    >

                                        <DialogContent >
                                            Do you want to discard the unsave items?
                                        </DialogContent>
                                        <DialogActions sx={{ marginBottom: '10px' }}>
                                            <Box >


                                                <Box sx={{ marginRight: '10px' }}>
                                                    <Button onClick={closeConfirmationModalClose} variant="outlined" size="small" sx={{ borderRadius: '200px', margin: '5px' }}>
                                                        Cancel
                                                    </Button>
                                                    <Button onClick={closeAddDocumentModal} variant="contained" size="small" sx={{ borderRadius: '200px', margin: '5px' }}>
                                                        Okay
                                                    </Button>

                                                </Box>
                                            </Box>
                                        </DialogActions>
                                    </Dialog>
                                </ThemeProvider>
                            </Box>
                        </Box>

                        <Box sx={{
                            fontSize: '13px', textAlign: 'left', marginTop: '15px', color: 'gray'
                        }}>
                            Total {totalDoc ? totalDoc : '0'} documents
                        </Box>
                    </Box>
                    <Box>
                        <Paper sx={{ width: '100%', overflow: 'hidden', }}>


                            <TableContainer ref={tableContainerRef}
                                sx={{ height: '62.8vh', overflow: 'auto' }}
                            >
                                <Table stickyHeader size="small" aria-label="a dense table">
                                    <TableHead sx={{ backgroundColor: '#eff1f3', color: 'pink' }}>
                                        <TableRow >

                                            {/* <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white', borderLeft: '4px solid white' }}>
                                                    ID
                                                </TableCell> */}
                                            <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white', borderLeft: '4px solid white' }}>
                                                Name
                                            </TableCell>
                                            <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white' }}>
                                                Size
                                            </TableCell>
                                            <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white' }}>
                                                Upload Date
                                            </TableCell>
                                            <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white' }}>
                                            Department / Area
                                            </TableCell>
                                            <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white' }}>
                                                Shared With
                                            </TableCell>
                                            <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white', }}>
                                                Indexed
                                            </TableCell>
                                            <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white', }}>
                                                OCR
                                            </TableCell>
                                            <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white', }}>
                                                Action
                                            </TableCell>
                                            <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white', borderRight: '4px solid white' }}>
                                                
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {isLoading ? (
                                        // Render loading skeletons while data is being fetched
                                        Array.from({ length: 10 }).map((_, index) => (
                                            <LoadingSkeleton key={index} />
                                        ))
                                    ) : (
                                        <TableBody>
                                            {documentResults.map((item, index) => (
                                                <React.Fragment>
                                                <TableRow sx={{ padding: '0px', height: '20px' }}>
                                                    <TableCell sx={{ color: 'grey', maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                        <div style={{ display: 'flex', padding: '0px', margin: '0px', maxWidth: '180px' }}>
                                                            <PictureAsPdfIcon sx={{ color: "red", marginTop: '10px', marginRight: '5px' }} />
                                                            <Link
                                                                to="#"
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                <p
                                                                    title={item.doc_name}
                                                                    onClick={() =>
                                                                        handlePDfdata(
                                                                            item.doc_name,
                                                                            item.doc_id,
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    {item.doc_name}
                                                                </p>
                                                            </Link>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell sx={{ color: 'grey' }}>
                                                        {item.size}
                                                    </TableCell>
                                                    <TableCell sx={{ color: 'grey' }}>
                                                        {item.upload_date}
                                                    </TableCell>
                                                    <TableCell sx={{ color: 'grey' }}>
                                                        <div style={{ display: 'flex', padding: '0px', margin: '0px' }}>
                                                            {/* <ChatBubbleOutlineIcon sx={{ marginTop: '10px', marginRight: '5px',color: 'grey'  }} /> */}
                                                            {item.department}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell sx={{ color: 'grey' }}>
                                                        <Box sx={{ display: 'flex' }}>
                                                            {item.shared_with.slice(0, 3).map((photo, index) => (
                                                                 <Tooltip title={photo.name} key={index}>
                                                                <Box
                                                                    sx={{ marginRight: '5px' }}
                                                                    key={index}
                                                                >
                                                                    <img
                                                                        src={photo.photo}
                                                                        style={{
                                                                            height: '30px',
                                                                            width: '30px',
                                                                            borderRadius: '50px',
                                                                            marginRight: '2px',
                                                                            border: photo.owner === 'yes' ? '1px solid #2F80ED' : 'transparent'
                                                                        }}
                                                                    />

                                                                </Box>
                                                                </Tooltip>
                                                            ))}
                                                            {item.shared_with.length > 3 && (
                                                                <span style={{ marginLeft: '0px', padding: '5px', fontWeight: 'bold', borderRadius: '200px', border: '1px solid #2F80ED', color: '#2F80ED', backgroundColor: '#2F80ED1F', fontSize: '11px', height: '35px', width: '35px' }}><Typography sx={{ fontSize: '11px', cursor: 'pointer', margin: '3px', textAlign: 'center' }} onClick={(e) => handleMoreShare(e, item)}>+{item.shared_with.length - 3}</Typography> </span>
                                                            )}
                                                        </Box>

                                                    </TableCell>
                                                    <TableCell sx={{ color: 'grey' }}>
                                                        {item.index_status == 'indexed' &&
                                                            <Button variant="outlined" style={{ borderColor: '#27AE603D', backgroundColor: '#27AE6029', color: '#27AE60', borderRadius: '10px', fontSize: '10px', width: '100px' }} disabled color="primary"> {item.index_status}</Button>
                                                        }
                                                        {item.index_status == 'new' &&
                                                            <Button variant="outlined" style={{ borderColor: '#2F80ED3D', backgroundColor: '#2F80ED1F', color: '#2F80ED', borderRadius: '10px', fontSize: '10px', width: '100px' }} disabled color="primary"> {item.index_status}</Button>
                                                        }
                                                        {item.index_status == 'processing' &&
                                                            <Button variant="outlined" style={{ borderColor: '#F2994A3D', backgroundColor: '#F2994A29', color: '#F2994A', borderRadius: '10px', fontSize: '10px', width: '100px' }} disabled color="primary"> {item.index_status}</Button>
                                                        }
                                                        {item.index_status == 'failed' &&
                                                            <Button variant="outlined" style={{ borderColor: '#EB57573D', backgroundColor: '#EB57571F', color: '#EB5757', borderRadius: '10px', fontSize: '10px', width: '100px' }} disabled color="primary"> {item.index_status}</Button>
                                                        }
                                                        {item.index_status == 'no' &&
                                                            <Typography>N/A</Typography>
                                                        }
                                                    </TableCell>
                                                    <TableCell sx={{ color: 'grey' }}>
                                                        {item.ocr_status == 'not_req' &&
                                                            <Typography>N/A</Typography>
                                                        }
                                                        {item.ocr_status == 'success' &&
                                                            <CheckIcon />
                                                        }
                                                        {item.ocr_status == 'failed' &&
                                                            <CloseIcon />
                                                        }
                                                    </TableCell>
                                                    <TableCell sx={{ color: 'grey' }}>
                                                        {superAdmin == 'yes' ?
                                                            <ActionMenu user={item} handleFetchDocuments={handleFetchDocuments} />
                                                            : (item.created_by == user_id ?
                                                                <ActionMenu user={item} handleFetchDocuments={handleFetchDocuments} />

                                                                : 'N/A')
                                                        }


                                                    </TableCell>
                                                    <TableCell>
                                                                <IconButton
                                                                    aria-label="expand row"
                                                                    size="small"
                                                                    onClick={() => handleRowClick(index)}
                                                                >
                                                                    {openAction[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                                </IconButton>
                                                            </TableCell>
                                                </TableRow>
                                                <TableRow key={index}>
                                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                                                                <Collapse in={openAction[index]} timeout="auto" unmountOnExit>
                                                                    <Box>
                                                                    <Typography gutterBottom component="div" sx={{fontWeight:'bold',fontSize:'16px'}}>
                                                                        Description:
                                                                        </Typography>
                                                                    <Typography gutterBottom component="div"sx={{fontSize:'14px'}}>
                                                                    {item.doc_desc} 
                                                                        </Typography>
                                                                        </Box>
                                                                </Collapse>
                                                            </TableCell>
                                                        </TableRow>
                                                </React.Fragment>
                                            ))}
                                        </TableBody>
                                    )}
                                </Table>
                                <InfiniteScroll
                                    dataLength={documentResults.length}
                                    next={handleLoadMore}
                                    hasMore={hasMore}
                                    loader={
                                        <CircularProgress disableShrink style={{ margin: "20px" }} />
                                    }
                                    endMessage={
                                        <div style={{ textAlign: "center", marginTop: "10px" }}>
                                            {documentResults.length == 0 ? (
                                                <div
                                                    style={{
                                                        color: "grey",
                                                        fontSize: "13px",
                                                        marginTop: "20px",
                                                    }}
                                                >
                                                    No record found
                                                </div>
                                            ) : (
                                                <>
                                                    <div>No More Records</div>

                                                </>
                                            )}
                                        </div>
                                    }
                                ></InfiniteScroll>
                            </TableContainer>


                            <Dialog open={isDeleteModal} onClose={closeDeleteModal} >
                                <DialogTitle sx={{ paddingBottom: '0px', paddingTop: '32px', marginBottom: '0px' }}>Delete Confirmation</DialogTitle>
                                <DialogContent sx={{ width: '500px' }}>
                                    Are you sure you want to delete this item?
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={closeDeleteModal} color="primary">
                                        Cancel
                                    </Button>
                                    <Button color="primary" onClick={handleClickDelete}>
                                        Apply
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Snackbar
                                mode="outlined"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center', // Center bottom
                                }}
                                open={snackbarOpen}
                                autoHideDuration={6000}
                                onClose={handleSnackbarClose}
                            >
                                <SnackbarContent
                                    style={{
                                        backgroundColor: 'black',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                    message={
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                            {toastmessage}
                                        </span>
                                    }
                                    action={[
                                        <IconButton key="close" color="inherit" onClick={handleSnackbarClose}>
                                            <CloseIcon />
                                        </IconButton>,
                                    ]}
                                />
                            </Snackbar>
                            <Popover
                                id={moreShareId}
                                open={openMoreShare}
                                anchorEl={moreShared_with}
                                onClose={handleMoreShareClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <Typography sx={{ p: 2 }}>
                                    <List sx={{ width: '100%', maxWidth: 250, bgcolor: 'background.paper', height: '250px' }}>
                                        <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>Shared With</Typography>
                                        {share_data.shared_with?.map((item) => (
                                            <ListItem key={item.user_id}> {/* Make sure to include a unique key for each item */}
                                                <ListItemAvatar>
                                                    <img src={item.photo} style={{ width: '30px', height: '30px', borderRadius: '50px' }} />
                                                </ListItemAvatar>
                                                <ListItemText primary={item.name} secondary={item.email} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Typography>
                            </Popover>
                        </Paper>

                    </Box>

                </div>
            </Box>
        </Box>
    );
}

export default DocumentManager;
