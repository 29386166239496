import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
// import { getAutocompleteResults, getDepartment, fetchMoreData, downloadPDF, searchDocuments, } from '../ApiHelper/DocumentSearchapi';
import {
  IconButton,
  Paper,
  TextField,
  MenuList,
  Checkbox,
  ListItemText,
  MenuItem,
  Autocomplete,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
} from '@mui/material';

const CmdDepartmentFilter = forwardRef((props, ref) => {
  const { data, onSelectionChange, onApplyButtonClick, onResetClick } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [checkedItems, setCheckedItems] = useState({});
  const [checked, setChecked] = useState(() => {
    const storedState = JSON.parse(localStorage.getItem('checkboxState')) || [];
    return storedState;
  });

  const [filterclicked, setFilterClicked] = useState(false);


  // const handleCheckboxChange = (name) => {
  //   setCheckedItems((prevCheckedItems) => ({
  //     ...prevCheckedItems,
  //     [name]: !prevCheckedItems[name],
  //   }));
  // };

  const [personName, setPersonName] = React.useState([]);
  const open = Boolean(anchorEl);
  const id = open ? 'dropdown-popover' : undefined;
  const paperStyle = {
    width: '320px',
    height: '400px',
  };


  // const data = props.data;
  const [filterData, setFilterData] = useState(data);
  const [searchdepartment, setsearchdepartment] = useState('');
  const [deptSearch, setDeptSearch] = useState([])
  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
    setFilterData(data);
  };
  const handleSearch = (query) => {
    setsearchdepartment(query);

    // Filter the data based on the search query
    const filteredData = data.filter((department) =>
      department.department_name.toLowerCase().includes(query.toLowerCase())
    );
    setFilterData(filteredData);
  };
  const handleCheckboxChange = (value) => () => {
    onApplyButtonClick(value);
    setFilterClicked(true);
    setAnchorEl(null);
    // const currentIndex = checked.indexOf(value);
    // const newChecked = [...checked];

    // if (currentIndex === -1) {
    //   newChecked.push(value);
    // } else {
    //   newChecked.splice(currentIndex, 1);
    // }

    // setChecked(newChecked);
    // onSelectionChange(newChecked);
  };
  const handleApplyClick = () => {
    if (checked.length > 0) {
      setFilterClicked(true);
      onApplyButtonClick(checked);
      setAnchorEl(null);
    } else {
      setFilterClicked(false);
    }
  };

  // const resetClick = () => {
  //   setChecked([]); // Reset the selected checkboxes
  //   setAnchorEl(null); // Close the popover
  //   setFilterClicked(false);
  // };
  const resetClick = () => {
    setChecked([]);
    onSelectionChange(''); // Notify the parent about the reset
    setAnchorEl(null);
    setFilterClicked(false);
    // Call the onResetClick function if needed
    //  props.onResetClick();

  };
  React.useImperativeHandle(ref, () => ({
    handleResetClick: resetClick,
  }));
  return (
    <div>
      <Button
        onClick={handleButtonClick}
        sx={filterclicked  ? {
            '&:hover': {
                backgroundColor: '#DBE2F9', // Set the hover color
            },
            fontWeight:'bold',
            color: '#2B74E2',
            backgroundColor: '#DBE2F9',
            textTransform: 'none',
            borderRadius: '8px',
            border: '1px solid #DBE2F9',
            // marginRight: '15px',
            // padding:'7.5px 20px',
            height:'40px',
            minWidth:'100px'
          
        } : {
            '&:hover': {
                backgroundColor: '', // Set the hover color
                border:'1px solod black'
            },
            fontWeight:'bold',
            backgroundColor: '',
            color: '#344054',
            textTransform: 'none',
            borderRadius: '8px',
            border: '1px solid #D0D5DD',
            // marginRight: '15px',
            // padding:'7.5px 20px',
            height:'40px',
            minWidth:'100px'
           
        }}
      >
        Department
        {filterclicked ? (
          <IconButton style={{padding:'0px 0px',}}><CheckIcon sx={{ height: '15px', width: '15px', color: '#2B74E2',marginLeft:'8px' }} /></IconButton>  
        
          ) : (
          <ArrowDropDownIcon sx={{ height: '23px', width: '23px', marginLeft: '2px', marginTop: '1px' }} />
        )}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        sx={{ height: '350px', width: '320px' }}
        style={paperStyle}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: '260px' }}>
          <Box sx={{ position: 'sticky', top: 0, backgroundColor: 'white', padding: '2px' }}>

            <TextField
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="Search Department"
              size='small'
              style={{
                paddingTop: '0px',
                margin: '10px',
                width: '92%',
                color: '#828282',
                backgroundColor: '#e0e0e0',
                border: 'none',
              }}
              InputProps={{

                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />


          </Box>
          <Box
            sx={{
              overflow: 'auto',
              maxHeight: '230px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
              textAlign:'left',
              padding: '0px',
            }}
          >
            <MenuList>
              <List sx={{ '&fieldset': { padding: '0px' } }}>
              {filterData && Array.isArray(filterData) && filterData.map((department) => (
                  <ListItem key={department.department_id} disablePadding>
                    <ListItemButton
                      role={undefined}
                      onClick={handleCheckboxChange(department.department_id)}
                      dense
                      margin="0"
                      paddingTop="0px"
                    >
                      {/* <ListItemIcon> */}
                        {/* <Checkbox
                          edge="start"
                          sx={{ paddingTop: '0px' }}
                          checked={checked.indexOf(department.dept_id) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            'aria-labelledby': `checkbox-list-label-${department.dept_id}`,
                          }}
                        /> */}
                      {/* </ListItemIcon> */}
                      <ListItemText
                        id={`checkbox-list-label-${department.department_id}`}
                        primary={department.department_name}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </MenuList>
          </Box>
          <Box>
            <Divider orientation="horizontal" />
          </Box>
          {/* <Box
            sx={{
              position: 'sticky',
              bottom: '0',
              backgroundColor: 'white',
              padding: '8px',
              display: 'flex',
              justifyContent: 'end',
              width: '100%',
              marginBottom: '0px',
            }}
          >
            <Button onClick={resetClick}>Reset</Button>
            <Button onClick={handleApplyClick}>Apply</Button>
          </Box> */}
        </Box>
      </Popover>
    </div>
  );
});

export default CmdDepartmentFilter;
