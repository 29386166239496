import React, { useState, useEffect, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InputAdornment from '@mui/material/InputAdornment';
import MenuIcon from "@mui/icons-material/Menu";
import modalBackground1 from '../../../../Background pattern dark.svg'
import modalBackground2 from '../../../../Background pattern light.svg'
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import AddIcon from '@mui/icons-material/Add';
import DescriptionIcon from '@mui/icons-material/Description';
import { NavLink } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import EditIcon from '@mui/icons-material/Edit';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import WorkIcon from '@mui/icons-material/Work';
import NotificationsIcon from '@mui/icons-material/Notifications';
import TaskIcon from '@mui/icons-material/Task';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ShortTextIcon from '@mui/icons-material/ShortText';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ListIcon from '@mui/icons-material/List';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Sidebar from "../../Include/Sidebar";
import {
    Button, Select, TextField, DialogContentText, Autocomplete, FormControl,
    ListItem, InputLabel, ListItemIcon, List, ListItemAvatar, RadioGroup, FormControlLabel, Radio
} from "@mui/material";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InfiniteScroll from "react-infinite-scroll-component";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CloseIcon from '@mui/icons-material/Close';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import CheckIcon from '@mui/icons-material/Check';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import NotesIcon from '@mui/icons-material/Notes';
import ArticleIcon from '@mui/icons-material/Article';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Calendar, momentLocalizer } from "react-big-calendar";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
// import 'react-big-calendar/lib/sass/styles';
// import 'react-big-calendar/lib/addons/dragAndDrop/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
    getCalenderMeeting,
    getAllAttendeesWithGuestUser,
    getMeetingRooms,
    AddMeetingEvent,
    getAllRedList,
    getMeetingDetails,
    deleteMeetingById,
    EditMeetingEvent,
    RedListCreatetemPath,
    RedListDocument,
    RedListDocumentDeleteById,
    AddGuestUser,
    AddMeetingRoom,
    GuestUserAutocomplete,
    InstantDocumentDelete,
} from "../../CmdApiHelper";
import { getAllRedListbyid } from "../RedList/RedListApi";
import FilePresentIcon from '@mui/icons-material/FilePresent';
// import { getAllDepartment, deleteDepartment, addDepartment, fetchMoreDepartment } from "../AdminApiHelper";
import Skeleton from "@mui/material/Skeleton";
import { getAllUser } from "../../../AdminConsole/AdminApiHelper";
import { CircularProgress, MenuItem } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
// import AddDepartment from "./addDepartment";
// import ViewDepartment from "./viewDepartment";
import Appbar from "../../Include/Header";
import Footer from "../../Include/footer";
import './CalenderReviewMeeting.css'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { borderRadius, display, width } from "@mui/system";
const drawerWidth = 200;
const theme = createTheme();
const localizer = momentLocalizer(moment);
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
function MyCalendar(props) {
    // const [events, setEvents] = useState([
    //     {
    //         start: moment().add(2, "days").toDate(),
    //         end: moment().add(2, "days").toDate(),
    //         title: "php",
    //         meeting_id: 1,
    //         color: 'red'
    //     },

    // ]);
    const handleEventDrop = (event) => {
        // Handle event drop
        // console.log("eventtime", event)
    };

    const handleEventResize = (event) => {
        // Handle event resize
    };
    const [view, setView] = useState('month');
    const handleViewChange = (selectedView) => {
        // onView(selectedView);
        setView(selectedView);
    }
    const handleDateChange = (newDate) => {
        setcalenderDate(newDate); // Update the selected date
        // Navigate the Calendar component to the selected date (you need to implement this logic)
    };
    const [currentDate, setCurrentDate] = useState(new Date());
    const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth() + 1); // Get the month (0-indexed)
    const [currentYear, setCurrentYear] = useState(currentDate.getFullYear()); // Get the year

    // console.log('currentDate', currentDate)
    // console.log('currentMonth', currentMonth)
    // console.log('currentYear', currentYear)
    useEffect(() => {
        setCurrentMonth(currentDate.getMonth());
        setCurrentYear(currentDate.getFullYear());
    }, [currentDate]);
    const CustomToolbar = ({ onView, onNavigate, date }) => {

        const handleNavigate = (action, event, date) => {
            // console.log('event', event)
            // console.log('date', date)
            let newDate = new Date(date); // Create a new Date object based on the current date prop

            switch (action) {
                case 'TODAY':
                    newDate = new Date(); // Set newDate to today's date
                    break;
                case 'PREV':
                    if (view === 'month') {
                        // If in month view, subtract one month from the current date
                        newDate.setMonth(date.getMonth() - 1);
                    } else if (view === 'week') {
                        // If in week view, subtract seven days from the current date
                        newDate.setDate(date.getDate() - 7);
                    } else {
                        // If in day view, subtract one day from the current date
                        newDate.setDate(date.getDate() - 1);
                    }
                    break;
                case 'NEXT':
                    if (view === 'month') {
                        // If in month view, add one month to the current date
                        newDate.setMonth(date.getMonth() + 1);
                    } else if (view === 'week') {
                        // If in week view, add seven days to the current date
                        newDate.setDate(date.getDate() + 7);
                    } else {
                        // If in day view, add one day to the current date
                        newDate.setDate(date.getDate() + 1);
                    }
                    break;
                default:
                    break;
            }

            onNavigate(action);
            setCurrentDate(newDate); // Update the current date state
            setCurrentMonth(newDate.getMonth() + 1); // Update the current month state
            setCurrentYear(newDate.getFullYear()); // Update the current year state
            fetchAllMeetings(newDate.getMonth() + 1, newDate.getFullYear())
        };


        return (
            <div>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Button onClick={(event) => handleNavigate('TODAY', event, date)} sx={{ textTransform: 'none', fontWeight: 'bold', color: 'grey', marginRight: "20px" }}>Today</Button>
                        <IconButton onClick={(event) => handleNavigate('PREV', event, date)} sx={{ marginRight: '20px' }}><ArrowBackIosIcon size='samll' /></IconButton>
                        <IconButton onClick={(event) => handleNavigate('NEXT', event, date)} sx={{ marginRight: '20px' }}><ArrowForwardIosIcon /></IconButton>
                        {/* <Typography>{date} </Typography> */}
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <ButtonDatePicker
                                date={moment(date).format('MMMM D, YYYY')}
                                onDateChange={handleDateChange} // Pass the handleDateChange function
                            />
                        </LocalizationProvider> */}
                        <Typography sx={{ fontWeight: 'bold' }} variant="h6">{moment(date).format('MMMM D, YYYY')}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-arround' }}>
                        <Select
                            value={view}
                            onChange={(e) => handleViewChange(e.target.value, onView(e.target.value))}
                            size="small"
                            onView={(e) => handleViewChange(e.target.value)} // handle view change separately
                        >
                            <MenuItem value="month">Month</MenuItem>
                            <MenuItem value="week">Week</MenuItem>
                            <MenuItem value="day">Day</MenuItem>
                        </Select>
                        {/* Add event button */}
                        <Button variant="contained" onClick={addEventModalOpen} sx={{ textTransform: 'none', fontWeight: 'bold', marginLeft: '20px', borderRadius: '10px' }}>Add Event</Button>
                    </Box>
                </Box>


            </div>
        );
    };

    const handleDateSelect = (date, endTime) => {
        setSelectedDate(date);
        addEventModalOpen(date); // Pass the selected date to toggleAddEventModal
        // console.log('Meeting time:', dayjs(endTime));
        setStartTime(dayjs(date))
        setEndTime(dayjs(endTime))
        // console.log('Meeting Date:', dayjs(date));
        setMeetingDate(dayjs(date));
    };
    const handleEventClick = (event) => {
        // console.log('event#####', event)
        viewEventModalOpen(event);
        // console.log('event', event)
        setTempMeetingId(event.meeting_id)
        fetchMeetingDetails(event.meeting_id)
        // Call openEditEventModal when an event is clicked
    };
    const CalenderSkeleton = () => {
        // You can customize the skeleton based on your design
        return (

            <Skeleton sx={{ marginRight: '10px' }} variant="rectangular" width='81.5vw' height='77vh' />

        );
    };

    const LoadingSkeleton = () => {
        // You can customize the skeleton based on your design
        return (
            <Stack spacing={2} sx={{ padding: '30px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box display='flex'>
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                        <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={80} height={30} />
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    </Box>
                    <Box display='flex'>
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    </Box>
                </Box>
                <Box display='flex'>
                    <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={500} height={30} />
                </Box>
                <Box display='flex'>
                    <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={500} height={90} />
                </Box>
                <Box display='flex'>
                    <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={500} height={30} />
                </Box>
                <Box display='flex'>
                    <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={500} height={30} />
                </Box>
                <Box display='flex'>
                    <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={500} height={30} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box display='flex'>
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                        <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                        <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    </Box>
                    <Box display='flex'>
                        <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                        <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                        <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    </Box>
                </Box>
                <Box display='flex'>
                    <Skeleton sx={{ marginRight: '10px' }} variant="circular" width={30} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={100} height={30} />
                    <Skeleton sx={{ marginRight: '10px', borderRadius: '8px' }} variant="rectangular" width={500} height={30} />
                </Box>


            </Stack>
        );
    };
    const [deleteloading, setdeleteloading] = useState(false);
    const [calenderDate, setcalenderDate] = useState('');
    const [tempMeetingId, setTempMeetingId] = useState('');
    const [tempAgenda, setTempAgenda] = useState('');
    const [tempVCRoom, setTempVCRoom] = useState('');
    const [tempEventType, setTempEventType] = useState('');
    const [tempVCRoomLink, setTempVCRoomLink] = useState('');
    const [tempAtendees, setTempAttendees] = useState([]);
    const [tempWpNotifyAttendees, setTempWpNotifyAttendees] = useState([]);
    const [tempMeetingColor, setTempMeetingColor] = useState('');
    const [tempMeetingDesc, setTempMeetingDesc] = useState('');
    const [tempMeetingRoom, setTempMeetingRoom] = useState('');
    const [tempMeetingDate, setTempMeetingDate] = useState('');
    const [tempMeetingStartTime, setTempMeetingStartTime] = useState('');
    const [tempMeetingEndTime, setTempMeetingEndTime] = useState('');
    const [tempReferenceRedList, setTempReferenceRedList] = useState('');
    const [meetingDetails, setMeetingDetails] = useState([]);
    const [meetingResults, setMeetingResults] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [guestButtonLoading, setguestButtonLoading] = useState(false);
    const [usertoken, setusertoken] = useState("");
    const [index, setIndex] = useState(0);
    const [user_id, setuser_id] = useState("");
    const [userorg_id, setuserorg_id] = useState("");
    const [attendeesSearchResults, setAttendeesSearchResults] = useState([]);
    const [meetingRooms, setMeetingRooms] = useState([]);
    const [addMeetingRoom, setaddMeetingRoom] = useState([]);

    const [allRedList, setAllRedList] = useState([]);
    const [showStaticContent, setShowStaticContent] = useState(false);
    const [userconversation_id, setuserconversation_id] = useState("");
    const [dept_id, setDept_id] = useState([]);
    const [dept_name, setDept_name] = useState('');
    const [dept_desc, setDept_desc] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [toastmessage, settoastmessage] = useState('');
    const [hasMore, setHasMore] = useState(true);
    const [isCalenderLoading, setIsCalenderLoading] = useState(false);
    const [totalrec, setTotalrec] = useState('');
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [meetingDate, setMeetingDate] = useState(null);
    const [agenda, setAgenda] = useState('');
    const [meetingRoomLink, setMeetingRoomLink] = useState('');
    const [meetingDesc, setMeetingDesc] = useState('');
    const [selectedRedListIds, setSelectedRedListIds] = useState(null);
    const [uploadFiles, setUploadFiles] = useState([])
    const [guestUserName, setGuestUserName] = useState('');
    const [guestUserEmail, setGuestUserEmail] = useState('');
    const [guestUserPhone, setGuestUserPhone] = useState('');
    const [guestUserResult, setGuestUserResult] = useState([]);
    const [guestUserAutocompleteResult, setGuestUserAutocompleteResult] = useState([]);
    const [closeConfirmation, setCloseConfirmation] = useState(false);
    const [isModified, setIsModified] = useState(false);
    const [openDialogRoom, setOpenDialogRoom] = useState(false);
    const [isNewDesignation, setIsNewDesignation] = useState('');
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [fileIndexToRemove, setFileIndexToRemove] = useState(null);
    const [fileDocNameToRemove, setFileDocNameToRemove] = useState(null);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const openCloseConfirm = () => {
        setCloseConfirmation(true)
    }
    const closeCloseConfirm = () => {
        setCloseConfirmation(false)
    }
    const handleCloseConfirm = () => {
        setEditEventModal(false);
        setAddEventModal(false)
        setMeetingDate(null);
        setStartTime(null);
        setEndTime(null);
        setCloseConfirmation(false)
        setIsModified(false)
    }

    function ButtonField(props, { date }) {
        const { setOpen, label, id, disabled, InputProps: { ref } = {}, inputProps: { 'aria-label': ariaLabel } = {} } = props;

        return (
            <Button
                id={id}
                disabled={disabled}
                ref={ref}
                aria-label={ariaLabel}
                onClick={() => setOpen?.((prev) => !prev)} variant='none' style={{ borderColor: 'grey', color: 'grey', borderRadius: '8px', fontSize: '10px', textTransform: 'none', padding: '8px', fontWeight: 'bold' }} >
                <Typography sx={{ fontWeight: 'bold' }} variant="h6">{moment(date).format('MMMM D, YYYY')}</Typography>
            </Button>
        );
    }
    function ButtonDatePicker(props) {
        const { date, onDateChange } = props;

        const handleDateClick = (newDate) => {
            onDateChange(newDate);
        };

        const [open, setOpen] = React.useState(false);

        return (
            <DatePicker
                date={date}
                slots={{ field: ButtonField, ...props.slots }}
                slotProps={{ field: { setOpen } }}
                {...props}
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                onSelect={handleDateClick} // Call handleDateClick when a date is selected
            />
        );
    }
    const fetchGuestUser = async () => {
        // console.log('guestUserName',guestUserName)
        // console.log('guestUserEmail',guestUserEmail)
        // console.log('guestUserPhone',guestUserPhone)
        setguestButtonLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            // console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
            } = userDataObj;
            // setSuperAdmin(super_admin)
            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            setIndex(0);
            const requestData = await AddGuestUser(
                deepuser_id,
                deeporg_id,
                guestUserName, guestUserEmail, guestUserPhone,
                userToken,
                index
            );
            if (requestData.code == "200") {
                setuserconversation_id(requestData.conversation_id);

                const newResults = requestData.data[0];
                console.log('newResults', newResults)
                const updatedAttendees = [...inputValue, newResults];
                const updatedAttendees2 = [...tempAtendees, newResults];
                console.log('updatedAttendees', updatedAttendees)
                // setSelectedAttendees(updatedAttendees);
                // setInputValue(updatedAttendees)
                // setSelectedAttendeesWpList(updatedAttendees)
                handleInputChange(null, updatedAttendees);
                // setTempAttendees(updatedAttendees2)
                // setTempWpNotifyAttendees(updatedAttendees2)
                handleTempInputChange(null, updatedAttendees2)
                // setSnackbarOpen(true);
                // settoastmessage(requestData.message)
                setguestButtonLoading(false)
                handleCloseDialog()
            } else {
                setguestButtonLoading(false)
                setSnackbarOpen(true);
                settoastmessage(requestData.message)
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            setguestButtonLoading(false)
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            setButtonLoading(false);
        }
    }
    const getOptionSelected = (option, value) => {
        return option === value;
    };
    //   console.log('guestUserName',guestUserName)
    const [isOptionSelected, setIsOptionSelected] = useState(false);
    const fetchAutocompleteGuestUser = async () => {
        // console.log('guestUserName1',guestUserName)
        setGuestUserName(guestUserName)
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            // console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
            } = userDataObj;
            // setSuperAdmin(super_admin)
            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            setIndex(0);
            const requestData = await GuestUserAutocomplete(
                deepuser_id,
                deeporg_id,
                guestUserName,
                userToken,
                index
            );
            if (requestData.code == "200") {
                setuserconversation_id(requestData.conversation_id);

                const newResults = requestData.data;
                setGuestUserAutocompleteResult(requestData.data)
                // console.log('autocomplete', newResults)
            } else {

                // setSnackbarOpen(true);
                // settoastmessage(requestData.message)
            }
        } catch (error) {
            console.error("Error fetching user data:", error);

        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not

        }
    }
    useEffect(() => {
        let newDate = new Date();
        fetchAllMeetings(newDate.getMonth() + 1, newDate.getFullYear());
        return () => { };
    }, []);
    const fetchAllMeetings = async (month, year) => {
        setIsCalenderLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            // console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
                super_admin: super_admin,
            } = userDataObj;
            // setSuperAdmin(super_admin)
            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            setIndex(0);

            const requestData = await getCalenderMeeting(
                deepuser_id,
                deeporg_id,
                month,
                year,
                userToken,
                index
            );
            if (requestData.code == "200") {
                setuserconversation_id(requestData.conversation_id);

                const newResults = requestData.data;
                setMeetingResults(requestData.data);
                // console.log(newResults)

                setIsCalenderLoading(false)
                setShowStaticContent(false);
            } else {
                setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            setIsLoading(false);
        }
    };
    const fetchAllUserData = async () => {
        setIsLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            // console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
                super_admin: super_admin,
            } = userDataObj;

            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            // setSuperAdmin(super_admin)
            setIndex(0);
            const requestData = await getAllAttendeesWithGuestUser(deepuser_id, deeporg_id, 'all', '', userToken);
            if (requestData.code == "200") {
                setuserconversation_id(requestData.conversation_id);
                // const newResults = requestData.data;
                setAttendeesSearchResults(requestData.data);
                setIsLoading(false)
                setShowStaticContent(false);
            } else {
                setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            setIsLoading(false);
        }
    };
    const fetchAllMeetingRoom = async () => {
        setIsLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            // console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
                super_admin: super_admin,
            } = userDataObj;

            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            // setSuperAdmin(super_admin)
            setIndex(0);
            const requestData = await getMeetingRooms(deepuser_id, deeporg_id, userToken);
            if (requestData.code == "200") {
                setuserconversation_id(requestData.conversation_id);
                // const newResults = requestData.data;
                setMeetingRooms(requestData.data);
                setIsLoading(false)
                setShowStaticContent(false);
            } else {
                setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            setIsLoading(false);
        }
    };

    const fetchAllRedList = async () => {
        setIsLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            // console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
                // super_admin: super_admin,
            } = userDataObj;
            // setSuperAdmin(super_admin)
            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            setIndex(0);
            const requestData = await getAllRedList(
                deepuser_id,
                deeporg_id,

                userToken,
            );
            if (requestData.code == "200") {
                setuserconversation_id(requestData.conversation_id);

                const newResults = requestData.data;
                setAllRedList(requestData.data);
                // console.log('newResults', requestData.data)
                // console.log('requestData.total_rec', requestData.total_rec)
                // console.log('requestData.length', requestData.data.length)
                // if (requestData.data.length >= 0 && requestData.data.length < 15) {
                //     setHasMore(false)
                // }
                // else {
                //     setHasMore(true);

                // }
                // setHasMore(requestData.data.length > 0);

                setIsLoading(false)
                setShowStaticContent(false);
            } else {
                setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            setIsLoading(false);
        }
    };
    const [document, setDocument] = useState([]);
    const [DocumentPath, setDocumentPath] = useState('');
    const fetchMeetingDetails = async (mId) => {
        setIsLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            // console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
                // super_admin: super_admin,
            } = userDataObj;
            // setSuperAdmin(super_admin)
            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            setIndex(0);
            const requestData = await getMeetingDetails(
                deepuser_id,
                deeporg_id,
                mId,
                userToken,
            );
            if (requestData.code == "200") {
                setuserconversation_id(requestData.conversation_id);
                setIsLoading(false)
                const newResults = requestData.data;
                setMeetingDetails(requestData.data);
                setmeetingids(requestData.data[0].meeting_id)
                setTempAgenda(requestData.data[0].agenda)
                setTempEventType(requestData.data[0].meeting_type)
                setTempVCRoom(requestData.data[0].virtual_meeting_status)
                setTempVCRoomLink(requestData.data[0].virtual_meeting_link)
                setTempAttendees(requestData.data[0].attendees.map(attendee => attendee));
                setTempWpNotifyAttendees(requestData.data[0].whatsapp_notification_to.map(attendee => attendee));
                setTempMeetingColor(requestData.data[0].colour)
                setTempMeetingDesc(requestData.data[0].description)
                setTempMeetingRoom(requestData.data[0].meeting_room_id)
                setTempMeetingDate(dayjs(requestData.data[0].meeting_date, 'DD MMM,YYYY'));
                setTempReferenceRedList(requestData.data[0].redlist_id)
                setDocument(requestData.data[0].document)
                setDocumentPath(requestData.data[0].doc_path)
                setTempMeetingStartTime(dayjs(requestData.data[0].start_time, 'h:mm A'));
                setTempMeetingEndTime(dayjs(requestData.data[0].end_time, 'h:mm A'));
                // console.log('requestData.total_rec', requestData.total_rec)
                // console.log('requestData.length', requestData.data.length)
                // if (requestData.data.length >= 0 && requestData.data.length < 15) {
                //     setHasMore(false)
                // }
                // else {
                //     setHasMore(true);

                // }
                // setHasMore(requestData.data.length > 0);

                setIsLoading(false)
                setShowStaticContent(false);
            } else {
                setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            setIsLoading(false);
        }
    };
    const deleteMeetingDetails = async () => {
        setIsLoading(true);
        setButtonLoading(true)
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            // console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
                // super_admin: super_admin,
            } = userDataObj;
            // setSuperAdmin(super_admin)
            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            setIndex(0);
            const requestData = await deleteMeetingById(
                deepuser_id,
                deeporg_id,
                tempMeetingId,
                userToken,
            );
            if (requestData.code == "200") {
                setuserconversation_id(requestData.conversation_id);
                closeDeleteModal()
                let newDate = new Date();
                fetchAllMeetings(newDate.getMonth() + 1, newDate.getFullYear());
                setButtonLoading(false)
                // console.log('requestData.total_rec', requestData.total_rec)
                // console.log('requestData.length', requestData.data.length)
                // if (requestData.data.length >= 0 && requestData.data.length < 15) {
                //     setHasMore(false)
                // }
                // else {
                //     setHasMore(true);

                // }
                // setHasMore(requestData.data.length > 0);

                setIsLoading(false)
                setShowStaticContent(false);
            } else {
                setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            setIsLoading(false);
        }
    };
    const fetchEditMeetingEvent = async (newAttendeeIds, newWpNotifyAttendeeIds) => {
        setButtonLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            // console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
                // super_admin: super_admin,
            } = userDataObj;

            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            // setSuperAdmin(super_admin)
            setIndex(0);
            const requestData = await EditMeetingEvent(
                deepuser_id,
                deeporg_id,
                tempMeetingId,
                tempAgenda,
                tempMeetingDesc,
                newAttendeeIds,
                newWpNotifyAttendeeIds,
                tempMeetingRoom,
                tempVCRoom,
                tempVCRoomLink,
                tempReferenceRedList,
                formatDateTime(tempMeetingDate),
                formatDateTime(tempMeetingStartTime),
                formatDateTime(tempMeetingEndTime),
                tempMeetingColor,
                uploadFiles,
                tempEventType,
                userToken);
            if (requestData.code == "200") {
                setIsModified(false)
                setuserconversation_id(requestData.conversation_id);
                // const newResults = requestData.data;
                setUploadFiles([])
                setMeetingRooms(requestData.data);
                setIsLoading(false)
                setShowStaticContent(false);
                setButtonLoading(false)
                setSnackbarOpen(true);
                settoastmessage(requestData.message)
                setEditEventModal(false);
                let newDate = new Date(formatDateTime(tempMeetingDate));
                fetchAllMeetings(newDate.getMonth() + 1, newDate.getFullYear());
            } else {
                setShowStaticContent(true);
                setButtonLoading(false)
                setSnackbarOpen(true);
                settoastmessage(requestData.message)
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            setButtonLoading(false)
            setSnackbarOpen(true);
            settoastmessage(error.message)
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            setIsLoading(false);
            setButtonLoading(false)
            setSnackbarOpen(true);
        }
    };
    const confirmcreateDelete = () => {
        if (fileIndexToRemove !== null && fileDocNameToRemove !== null) {
            handleRemoveFile(fileIndexToRemove, fileDocNameToRemove);
        }
    };
    const handleRemoveFile = async (indexToRemove, doc_name) => {

        setIsDeleteLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
            } = userDataObj;


            const data = await InstantDocumentDelete(deepuser_id, deeporg_id, Docpath, doc_name, userToken);
            if (data.code == "200") {
                setIsDeleteLoading(false);
                setUploadFiles(prevFiles => prevFiles.filter((file, index) => index !== indexToRemove));
                closeConfirmDialog();
            } else {

            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            //setIsLoading(false);
        }
    };
    const [attendeeIds, setAttendeeIds] = useState([]);
    const fetchEditEvent = () => {

        const newAttendeeIds = tempAtendees.map(attendee => attendee.attendees_id);
        const newWpNotifyAttendeeIds = tempWpNotifyAttendees.map(attendee => attendee.attendees_id);
        setAttendeeIds(newAttendeeIds);

        fetchEditMeetingEvent(tempAtendees, tempWpNotifyAttendees)
    }
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };
    const handleAgendaChange = (event) => {
        setAgenda(event.target.value)
        setIsModified(true)
        // console.log('agenda', event.target.value)
    }
    const handleVirtualMeetingRoomChange = (event) => {
        setMeetingRoomLink(event.target.value)
        setIsModified(true)
        // console.log('agenda', event.target.value)
    }
    const handleDescriptionChange = (event) => {
        setMeetingDesc(event.target.value)
        setIsModified(true)
        // console.log('desc', event.target.value)
    }
    function convertTimeToISO(timeString) {
        // Create a new Date object with the given time string
        const date = new Date(`2000-01-01T${timeString}`);

        // Convert the date to ISO string format
        const isoString = date.toISOString().slice(0, -1);

        return isoString;
    }
    const formatDateTime = (dateTimeString) => {
        if (!dateTimeString) {
            return null; // or any other default message
        }

        const timeZone = "India Standard Time"; // Add your desired time zone here

        // Create a new Date object from the input string
        const dateTime = new Date(dateTimeString);

        // Check if the date is valid
        if (isNaN(dateTime.getTime())) {
            return "Invalid date and time";
        }

        // Format the date and time strings manually in the required format
        const formattedDate = dateTime.toDateString(); // Sat Nov 25 2023
        const formattedTime = dateTime.toTimeString().substring(0, 8); // 00:00:00
        const gmtOffset = dateTime.getTimezoneOffset(); // Offset in minutes
        const offsetHours = Math.floor(Math.abs(gmtOffset) / 60);
        const offsetMinutes = Math.abs(gmtOffset) % 60;
        const offsetSign = gmtOffset >= 0 ? '+' : '-';

        const formattedTimeZone = `GMT${offsetSign}${offsetHours}${offsetMinutes > 0 ? `:${offsetMinutes}` : ''}`; // GMT+0530

        return `${formattedDate} ${formattedTime} ${formattedTimeZone} (${timeZone})`;
    };

    const handleMeetingDateChange = (date) => {
        const formattedDateTime = formatDateTime(date);
        // console.log('Meeting Date:', formattedDateTime);
        setMeetingDate(date);
        setIsModified(true)
        setErrorDate(false)
    };

    const handleStartTimeChange = (time) => {
        const formattedDateTime = formatDateTime(time);
        // console.log('Start Time:', formattedDateTime);
        setStartTime(time);
        setIsModified(true)
        setErrorSTime(false)
    };

    const handleEndTimeChange = (time) => {
        const formattedDateTime = formatDateTime(time);
        // console.log('End Time:', formattedDateTime);
        setEndTime(time);
        setIsModified(true)
        setErrorETime(false)
    };

    const [addEventModal, setAddEventModal] = useState(false)
    const addEventModalOpen = () => {
        setAddEventModal(true);
        fetchAllUserData();
        fetchAllMeetingRoom();
        fetchAllRedList();
        setUploadFiles([]);
        RedListSubmitTemp();
        setInputValue([])
        setSelectedAttendees([])
        setSelectedAttendeesWpList([])
        setFileCount(0)
        setMeetingRoomLink('')
        setMeetingRoomType('no')
        setTempMeetingRoom('')

    }
    const addEventModalClose = () => {
        // setAddEventModal(false)
        // setMeetingDate(null);
        // setStartTime(null);
        // setEndTime(null);
        if (isModified) {
            if (
                agenda == ''
                && meetingDesc == ""
                && inputValue.length == 0
                && selectedAttendees.length == 0
                && meetingRoomType == 'no'
                && meetingRoomLink == ''
                && selectedRedListIds == null
                && uploadFiles.length == 0
                && event_type == 'meeting'
            ) {
                console.log('close modal')
                setAddEventModal(false);
            }
            else {

                openCloseConfirm()
            }

        }
        else {
            console.log('confirm close modal')
            setAddEventModal(false);

        }
    }
    const [viewEventModal, setViewEventModal] = useState(false)
    const viewEventModalOpen = () => {
        setViewEventModal(true);
    }
    const viewEventModalClose = () => {
        setViewEventModal(false)
    }
    const [editEventModal, setEditEventModal] = useState(false);
    const [editEventData, setEditEventData] = useState(null); // State to hold the event data for editing
    const [meetingids, setmeetingids] = useState('');
    const openEditEventModal = (event) => {
        setUploadFiles([]);
        // setmeetingids(meetingids);
        setEditEventData(event); // Set the event data to state
        setEditEventModal(true); // Open the edit modal
        fetchAllUserData();
        fetchAllMeetingRoom();
        fetchAllRedList();
        setViewEventModal(false)
        setFileCount(meetingDetails[0].document.length)
    };

    const editEventModalClose = () => {
        setEditEventData(null); // Clear the event data from state when closing the modal
        // setEditEventModal(false);
        if (isModified) {
            const ids1 = meetingDetails[0].attendees.map(obj => obj.attendees_id.toString());
            const ids2 = tempAtendees.map(obj => obj.attendees_id.toString());
            ids1.sort();
            ids2.sort();
            const string1 = JSON.stringify(ids1);
            const string2 = JSON.stringify(ids2);
            const ids3 = meetingDetails[0].whatsapp_notification_to.map(obj => obj.attendees_id.toString());
            const ids4 = tempWpNotifyAttendees.map(obj => obj.attendees_id.toString());
            ids3.sort();
            ids4.sort();
            const string3 = JSON.stringify(ids3);
            const string4 = JSON.stringify(ids4);
            console.log('meetingDetails.attendees', meetingDetails[0].document);
            console.log('tempAtendees', uploadFiles);
        
            if (
                meetingDetails[0].meeting_type === tempEventType &&
                meetingDetails[0].agenda === tempAgenda &&
                meetingDetails[0].description === tempMeetingDesc &&
                string1 == string2 &&
                string3 == string4 &&
                meetingDetails[0].meeting_room_id === tempMeetingRoom &&
                meetingDetails[0].virtual_meeting_status === tempVCRoom &&
                meetingDetails[0].virtual_meeting_link === tempVCRoomLink &&
                meetingDetails[0].redlist_id == tempReferenceRedList &&
                uploadFiles.length == 0
            ) {
                setEditEventModal(false);
            } else {
                openCloseConfirm();
            }
        }
        
        else {
            setEditEventModal(false);

        }
    };
    const [isDeleteModal, setIsDeleteModal] = useState(false)

    const openDeleteModal = (index) => {
        setIsDeleteModal(true);
        setViewEventModal(false)
    }
    const closeDeleteModal = () => {
        setIsDeleteModal(false);

    }
    // Function to handle saving edited event
    const handleSaveEditedEvent = () => {
        // Logic to save edited event
        editEventModalClose(); // Close the edit modal after saving
    };




    const [selectedDate, setSelectedDate] = useState(null);


    const getOptionLabel = (option) => option.name;

    const filterOptions = (options, { inputValue }) => {
        // Provide a default empty string value if inputValue is null or undefined
        const lowercaseInputValue = inputValue ? inputValue.toLowerCase() : '';

        return options.filter((option) => {
            // Ensure option.name, option.department, and option.designation are not null or undefined
            const name = option.name ? option.name.toLowerCase() : '';
            const dept_name = option.dept_name ? option.dept_name.toLowerCase() : '';
            const mobile_no = option.mobile_no ? option.mobile_no : '';
            // Filter based on name, department, and designation
            const matchName = name.includes(lowercaseInputValue);
            const matchDepartment = dept_name.includes(lowercaseInputValue);
            const matchPhone = mobile_no.includes(lowercaseInputValue);
            return matchName || matchDepartment || matchPhone;
        });
    };
    const [openDialog, setOpenDialog] = useState(false);

    // Handler for opening the dialog
    const handleOpenDialog = (e) => {
        // console.log("add btn")
        e.stopPropagation();
        setOpenDialog(true);
    };

    // Handler for closing the dialog
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setGuestUserName('')
        setGuestUserEmail('')
        setGuestUserPhone('')
    };
    const renderOption = (props, option, { selected }) => {
        // Check if the option's name is 'A C Choudhary'
        const isSpecialOption = option.tag === 'Add Guest User';

        // Render the special button only if it's the special option
        if (isSpecialOption) {
            return (
                <Box
                    style={{
                        display: 'flex',
                        position: 'sticky',
                        top: 0,
                        width: '100%',
                        background: 'white',
                        padding: '12px',
                        zIndex: 1, // Ensure it's above other elements
                    }}
                >
                    <AddIcon sx={{ marginRight: '5px', color: 'grey', cursor: 'pointer' }} />
                    <Typography onClick={handleOpenDialog} sx={{ fontWeight: 'bold', color: 'grey', cursor: 'pointer', fontSize: "0.8em" }}> Add Guest User</Typography>
                </Box>
            )
            // Don't render anything for the special option
        }

        // Return the normal rendering for other options
        return (
            // <Box style={{ overflowY: 'auto', maxHeight: '300px' }}> {/* Adjust maxHeight as needed */}
            <li {...props} style={{ display: "block" }}>
                <Box display="flex" style={{ fontSize: "0.8em" }}>
                    <Box>
                        <img src={option.photo} style={{ height: '35px', width: '35px', borderRadius: '50px', marginRight: '5px' }} />
                    </Box>
                    <Box fontWeight="bold" sx={{ marginTop: "5px" }}>
                        {option.name}
                        <Box display="flex" style={{ fontSize: "0.8em", color: "gray" }}>
                            <Box>
                                {option.email}{" "}
                            </Box>
                            <Box sx={{ margin: "0px 5px" }}> | </Box>
                            <Box>
                                {option.dept_name}
                            </Box>
                            <Box sx={{ margin: "0px 5px" }}> | </Box>
                            <Box>
                                {option.mobile_no}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </li>
            // </Box>
        );
    };
    const autocompleteRenderOption = (props, option,) => {


        // Return the normal rendering for other options
        return (
            // <Box style={{ overflowY: 'auto', maxHeight: '300px' }}> {/* Adjust maxHeight as needed */}
            <li {...props} style={{ display: "block" }}>

                <Box fontWeight="bold" sx={{ marginTop: "5px" }}>
                    {option.full_name}
                    <Box display="flex" style={{ fontSize: "0.8em", color: "gray" }}>
                        <Box>
                            {option.email}{" "}
                        </Box>

                        <Box sx={{ margin: "0px 5px" }}> | </Box>
                        <Box>
                            {option.mobile_no}
                        </Box>
                    </Box>
                </Box>

            </li>
            // </Box>
        );
    };



    const [selectedAttendees, setSelectedAttendees] = useState([]);
    const [selectedAttendeesWpList, setSelectedAttendeesWpList] = useState([]);
    const [inputValue, setInputValue] = useState([]);
    // const handleInputChange = (event, newInputValue) => {
    //     console.log('newInputValue', newInputValue)
    //     const id = newInputValue.map(item => item.attendees_id);
    //     setSelectedAttendeesWpList(newInputValue)
    //     setSelectedAttendees(newInputValue)
    //     setInputValue(newInputValue);
    //     setIsModified(true)
    // };
    const handleInputChange = (event, newInputValue) => {
        // Find the last inserted attendee
        const lastInserted = newInputValue.find(item => !selectedAttendeesWpList.some(wpItem => wpItem.attendees_id === item.attendees_id));

        // Find the last removed attendee
        const lastRemoved = selectedAttendeesWpList.find(wpItem => !newInputValue.some(item => item.attendees_id === wpItem.attendees_id));

        console.log('newInputValue:', newInputValue);
        console.log('Last Inserted:', lastInserted);
        console.log('Last Removed:', lastRemoved);

        // Update the selected attendees list
        let updatedAttendees = [...selectedAttendees];
        let updatedAttendees2 = [...inputValue];

        // If an attendee was inserted, append it to the selected attendees list
        if (lastInserted) {
            updatedAttendees.push(lastInserted);
            updatedAttendees2.push(lastInserted);
        }

        // If an attendee was removed, remove it from the selected attendees list
        if (lastRemoved) {
            updatedAttendees = updatedAttendees.filter(item => item.attendees_id !== lastRemoved.attendees_id);
            updatedAttendees2 = updatedAttendees2.filter(item => item.attendees_id !== lastRemoved.attendees_id);
        }

        // Update states
        setSelectedAttendeesWpList(updatedAttendees2);
        setSelectedAttendees(updatedAttendees);
        setInputValue(updatedAttendees2);
        setIsModified(true);
    };



    // console.log('inputValue', inputValue)
    // console.log('selectedAttendees', selectedAttendees)
    const handleTempInputChange = (event, newInputValue) => {

        // Find the last inserted attendee
        const lastInserted = newInputValue.find(item => !tempAtendees.some(wpItem => wpItem.attendees_id === item.attendees_id));

        // Find the last removed attendee
        const lastRemoved = tempAtendees.find(wpItem => !newInputValue.some(item => item.attendees_id === wpItem.attendees_id));

        console.log('newInputValue:', newInputValue);
        console.log('Last Inserted:', lastInserted);
        console.log('Last Removed:', lastRemoved);

        // Update the selected attendees list
        let updatedAttendees = [...tempWpNotifyAttendees];
        let updatedAttendees2 = [...tempAtendees];

        // If an attendee was inserted, append it to the selected attendees list
        if (lastInserted) {
            updatedAttendees.push(lastInserted);
            updatedAttendees2.push(lastInserted);
        }

        // If an attendee was removed, remove it from the selected attendees list
        if (lastRemoved) {
            updatedAttendees = updatedAttendees.filter(item => item.attendees_id !== lastRemoved.attendees_id);
            updatedAttendees2 = updatedAttendees2.filter(item => item.attendees_id !== lastRemoved.attendees_id);
        }


        setTempWpNotifyAttendees(updatedAttendees)
        setTempAttendees(updatedAttendees2)

    };
    const [meetingRoom, setMeetingRoom] = useState('');
    const handleMeetingRoomChange = (event, newInputValue) => {
        setMeetingRoom(newInputValue.room_id);
        setIsModified(true)
    };
    const [Docpath, SetDocpath] = useState("");
    const [addRedList, setAddRedList] = useState('');
    const handleAddRedList = (event, newInputValue) => {
        if (!newInputValue) {
            // Handle the case where newValue is null (i.e., when clearing the redlist)
            // For example, you might reset the redlist state to null or perform any other necessary action.
            setSelectedRedListIds(null);
        } else {
            setSelectedRedListIds(newInputValue.redlist_id);
        }
        setIsModified(true)
    };
    const [colorClicked, setColorClicked] = useState('#ee82ee');
    const handleColorClick = (color) => {
        // console.log("color", color)
        setColorClicked(color)
    }
    const [fileCount, setFileCount] = useState(0)
    const handleFileChange = async (event) => {
        if (fileCount <= 100) {
            const uploadedFiles = Array.from(event.target.files);
            setFileCount(fileCount + uploadedFiles.length)
            const newFiles = uploadedFiles.map(file => ({
                doc_type: getFileType(file.name),
                doc_name: file.name,
                doc_size: file.size.toString(),
                doc_path: Docpath,
                uploadstatus: 'new', // Initially set upload status to 'loading'
                percentage: '0', // Initial value for percentage
                message: ''
            }));

            setUploadFiles(prevFiles => [...prevFiles, ...newFiles]);

            await Promise.all(uploadedFiles.map(async (file) => {
                const fileType = getFileType(file.name);

                // Make a request to your backend to get the presigned URL for S3 upload
                const userData = await localStorage.getItem("userdata");
                const userDataObj = JSON.parse(userData);
                if (!userDataObj || !userDataObj.token) {
                    console.error("User token not found in AsyncStorage.");
                    return;
                }
                const userToken = userDataObj.token;
                const deepuser_id = userDataObj.user_id;
                const deeporg_id = userDataObj.org_id;

                // get presigned url
                const presignedUrlResponse = await fetch(`${window.constants.api_url}/cmdadmin/initiate-redlist-upload`, {
                    method: 'POST',
                    body: JSON.stringify({
                        "user_id": deepuser_id,
                        "org_id": deeporg_id,
                        "activity_id": "0",
                        "activity_type": "meeting",
                        "doc_path": Docpath,
                        "docs": [file.name]
                    }
                    ),
                    headers: {
                        'Content-Type': 'application/json',
                        'token': userToken
                    },
                });

                if (!presignedUrlResponse.ok) {
                    throw new Error('Failed to get presigned URL from the server');
                }

                const presignedUrlData = await presignedUrlResponse.json();

                if (!presignedUrlData.data[0].presignedUrl) {
                    return {
                        doc_type: fileType,
                        doc_name: file.name,
                        doc_size: file.size.toString(),
                        doc_path: Docpath,
                        uploadstatus: 'failed', // Update upload status to 'error'
                        percentage: '0', // Initial value for percentage
                        message: presignedUrlData.data[0].message,
                    };
                }

                // Upload the file to S3 using the presigned URL
                const xhr = new XMLHttpRequest();
                xhr.open('PUT', presignedUrlData.data[0].presignedUrl);
                xhr.upload.onprogress = (event) => {
                    const percentage = (event.loaded / event.total) * 100;
                    // Update the percentage and upload status for this file
                    setUploadFiles(prevFiles => {
                        const updatedFiles = prevFiles.map(prevFile => {
                            if (prevFile.doc_name === file.name) {
                                return {
                                    ...prevFile,
                                    percentage: percentage.toFixed(2),
                                    uploadstatus: percentage === 100 ? 'complete' : 'processing',
                                };
                            }
                            return prevFile;
                        });
                        return updatedFiles;
                    });
                };

                xhr.send(file);
            }));
            setIsModified(true)
        }
    };

    // get file size
    const formatBytes = (bytes) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };


    // get file type
    function getFileType(fileName) {
        const extension = fileName.split('.').pop().toLowerCase();
        if (extension === 'pdf') {
            return 'pdf';
        } else if (extension === 'doc' || extension === 'docx') {
            return 'doc';
        } else if (extension === 'xls' || extension === 'xlsx') {
            return 'excel';
        } else {
            return 'other';
        }
    }

    const RedListSubmitTemp = async () => {
        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            // Use the API helper function to fetch query history data
            const data = await RedListCreatetemPath(
                deepuser_id,
                deeporg_id,
                userToken
            );

            if (data.code === "200") {
                // setUserData(data.data);
                SetDocpath(data.doc_path);
                // console.log(data.data);
            } else {
                // console.log(data.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error, show a message or log it
            // seterrorSnackbar(true);
        }
    };
    const [errorDate, setErrorDate] = useState(false)
    const [errorSTime, setErrorSTime] = useState(false)
    const [errorETime, setErrorETime] = useState(false)
    const fetchAddMeetingEvent = async () => {
        setButtonLoading(true);
        if (formatDateTime(meetingDate) == null) {
            setErrorDate(true)
        }
        if (formatDateTime(startTime) == null) {
            setErrorSTime(true)
        }
        if (formatDateTime(endTime) == null) {
            setErrorETime(true)
        }
        if (errorDate == true || errorETime == true || errorSTime == true) {
            setButtonLoading(false)
        }
        else {
            try {
                const storedUserData = localStorage.getItem("userdata");

                if (!storedUserData) {
                    console.error("User data not found in localStorage.");
                    return;
                }

                const userDataObj = JSON.parse(storedUserData);
                console.log(userDataObj);
                if (!userDataObj || !userDataObj.token) {
                    console.error(
                        "User token not found in localStorage or component unmounted."
                    );
                    return;
                }

                const {
                    token: userToken,
                    user_id: deepuser_id,
                    org_id: deeporg_id,
                    // super_admin: super_admin,
                } = userDataObj;

                setusertoken(userToken);
                setuser_id(deepuser_id);
                setuserorg_id(deeporg_id);
                // setSuperAdmin(super_admin)
                setIndex(0);
                const requestData = await AddMeetingEvent(deepuser_id, deeporg_id, agenda, meetingDesc, inputValue, selectedAttendees, tempMeetingRoom, meetingRoomType, meetingRoomLink, selectedRedListIds, formatDateTime(meetingDate), formatDateTime(startTime), formatDateTime(endTime), colorClicked, Docpath, uploadFiles, event_type, userToken);
                if (requestData.code == "200") {
                    setIsModified(false)
                    setuserconversation_id(requestData.conversation_id);
                    setUploadFiles([])
                    setMeetingRooms(requestData.data);
                    setIsLoading(false)
                    setShowStaticContent(false);
                    setButtonLoading(false)
                    setSnackbarOpen(true);
                    setInputValue([])
                    setSelectedAttendees([])
                    setSelectedAttendeesWpList([])
                    settoastmessage(requestData.message)
                    setAddEventModal(false);
                    let newDate = new Date(formatDateTime(meetingDate));
                    fetchAllMeetings(newDate.getMonth() + 1, newDate.getFullYear());
                    setCurrentDate(newDate); // Update the current date state
                    setCurrentMonth(newDate.getMonth() + 1); // Update the current month state
                    setCurrentYear(newDate.getFullYear()); // Update the current year state
                } else {
                    setShowStaticContent(true);
                    setButtonLoading(false)
                    setSnackbarOpen(true);
                    settoastmessage(requestData.message)
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
                setButtonLoading(false)
                setSnackbarOpen(true);
                settoastmessage(error.message)
            } finally {
                // Make sure to set isLoading to false whether the fetch was successful or not
                setIsLoading(false);
                setButtonLoading(false)
                setSnackbarOpen(true);
            }
        }
    };
    const [pdfDialogOpen, setPdfDialogOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState("");
    const [LoadingviewStates, setLoadingviewStates] = useState([]);
    const handleClosePdfDialog = () => {
        setPdfDialogOpen(false);
        setPdfUrl("");
    };
    const handleLoading = (index) => {
        setLoadingviewStates((prevStates) => {
            const newState = [...prevStates];
            newState[index] = true;
            return newState;
        });
    };
    const RedListDocviewPreview = async (
        index,
        docid,
        docname,
        docpath,
        redlistid
    ) => {
        handleLoading(index);

        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            // Use the API helper function to fetch query history data
            const data = await RedListDocument(
                deepuser_id,
                deeporg_id,
                redlistid,
                docname,
                docid,
                docpath,
                userToken
            );

            if (data.code === "200") {
                setLoadingviewStates((prevStates) => {
                    const newState = [...prevStates];
                    newState[index] = false; // Set loading state to false after successful fetch
                    return newState;
                });

                const doclink = data.data[0].doclink;
                // console.log(doclink);
                if (docname.endsWith('.pdf')) {
                    setPdfDialogOpen(true); // Open the PDF dialog
                }
                else {
                    // console.log('doclink', doclink);
                    window.open(doclink, '_blank');
                }
                setPdfUrl(doclink); // Set the PDF URL
            } else {
                // console.log(data.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error, show a message or log it
            // seterrorSnackbar(true);
        }
    };
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [docToDelete, setDocToDelete] = useState(null);
    const handleDocumentDelete = async (docid, redlistid, docname, docpath) => {
        // Call this function to open the confirm modal
        openConfirmModal(docid, redlistid, docname, docpath);
    };
    const openConfirmModal = (docid, redlistid, docname, docpath) => {
        setDocToDelete({ docid, redlistid, docname, docpath });
        setConfirmOpen(true);
    };

    const closeConfirmModal = () => {
        setConfirmOpen(false);
        setDocToDelete(null);
    };
    const confirmDelete = async () => {
        setdeleteloading(true);
        const { docid, redlistid, docname, docpath } = docToDelete;
        console.log('docid', docid)
        console.log('redlistid', redlistid)
        console.log('docname', docname)
        console.log('docpath', docpath)
        if (docid == undefined) {

            setIsLoading(false);
            try {
                const storedUserData = localStorage.getItem("userdata");

                if (!storedUserData) {
                    console.error("User data not found in localStorage.");
                    return;
                }

                const userDataObj = JSON.parse(storedUserData);
                if (!userDataObj || !userDataObj.token) {
                    console.error(
                        "User token not found in localStorage or component unmounted."
                    );
                    return;
                }

                const {
                    token: userToken,
                    user_id: deepuser_id,
                    org_id: deeporg_id,
                } = userDataObj;


                const data = await InstantDocumentDelete(deepuser_id, deeporg_id, docpath, docname, userToken);
                if (data.code == "200") {
                    // console.log('document',document)
                    //         setDocument(prevUserData => ({
                    //         ...prevUserData,
                    //     document: prevUserData.document.filter(doc => doc.doc_name !== docname) // Filter out the deleted document by doc_name
                    // }));
                    setDocument(prevFiles => prevFiles.filter(file => file.doc_name !== docname));
                    setUploadFiles(prevFiles => prevFiles.filter(file => file.doc_name !== docname)); // Filter out the deleted document from upload files
                    closeConfirmModal();
                    setdeleteloading(false);
                    return;
                } else {
                    console.error("Error fetching user data:", error);
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            } finally {
                // Make sure to set isLoading to false whether the fetch was successful or not
                //setIsLoading(false);
            }
            // setSelectedUserData(prevUserData => ({
            //     ...prevUserData,
            //     document: prevUserData.document.filter(doc => doc.doc_name !== docname) // Filter out the deleted document by doc_name
            // }));
            // setUploadFiles(prevFiles => prevFiles.filter(file => file.doc_name !== docname)); // Filter out the deleted document from upload files
            // closeConfirmModal();
            // setdeleteloading(false);
            // return; // Exit the function
        }

        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            // Use the API helper function to fetch query history data
            const data = await RedListDocumentDeleteById(deepuser_id, deeporg_id, redlistid, docname, docid, docpath, userToken);

            if (data.code === "200") {
                setdeleteloading(false);
                setDocument(prevFiles => prevFiles.filter(file => file.doc_id !== docid));
                setUploadFiles(prevFiles => prevFiles.filter(file => file.doc_id !== docid)); // Filter out the deleted document from upload files

                // Call openEditModal with the updated redlistid
                //   openEditModal(redlistid);
                console.log(data.data);

            } else {
                console.log(data.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error, show a message or log it
            // seterrorSnackbar(true);
        }
        closeConfirmModal();
    };
    const handleUpdateFileChange = async (meeting_id, docpath) => {
        try {
            if (fileCount <= 100) {
                const uploadedFiles = Array.from(event.target.files);
                setFileCount(fileCount + uploadedFiles.length)
                // Create an array of new files with initial upload status
                const newFiles = uploadedFiles.map((file) => ({
                    doc_type: getFileType(file.name),
                    doc_name: file.name,
                    doc_size: file.size.toString(),
                    doc_path: docpath,
                    uploadstatus: "new", // Initially set upload status to 'loading'
                    percentage: "0", // Initial value for percentage
                    message: "",
                }));

                setUploadFiles((prevFiles) => [...prevFiles, ...newFiles]);
                setDocument((prevFiles) => [...prevFiles, ...newFiles]);

                // console.log(electedUserData.document)
                await Promise.all(
                    uploadedFiles.map(async (file) => {
                        const fileType = getFileType(file.name);

                        // Make a request to your backend to get the presigned URL for S3 upload
                        const userData = await localStorage.getItem("userdata");
                        const userDataObj = JSON.parse(userData);
                        if (!userDataObj || !userDataObj.token) {
                            console.error("User token not found in AsyncStorage.");
                            return;
                        }
                        const userToken = userDataObj.token;
                        const deepuser_id = userDataObj.user_id;
                        const deeporg_id = userDataObj.org_id;

                        // get presigned url
                        const presignedUrlResponse = await fetch(
                            `${window.constants.api_url}/cmdadmin/initiate-redlist-upload`,
                            {
                                method: "POST",
                                body: JSON.stringify({
                                    user_id: deepuser_id,
                                    org_id: deeporg_id,
                                    activity_id: meeting_id,
                                    activity_type: "meeting",
                                    doc_path: docpath,
                                    docs: [file.name],
                                }),
                                headers: {
                                    "Content-Type": "application/json",
                                    token: userToken,
                                },
                            }
                        );

                        if (!presignedUrlResponse.ok) {
                            throw new Error("Failed to get presigned URL from the server");
                        }

                        const presignedUrlData = await presignedUrlResponse.json();

                        if (!presignedUrlData.data[0].presignedUrl) {
                            // console.log(presignedUrlData.data[0].presignedUrl);
                            const failedFile = {
                                doc_type: fileType,
                                doc_name: file.name,
                                doc_size: file.size.toString(),
                                doc_path: docpath,
                                uploadstatus: "failed", // Update upload status to 'failed'
                                percentage: "0", // Initial value for percentage
                                message: presignedUrlData.data[0].message,
                            };
                            // Update selectedUserData.document with the failed file
                            setDocument((prevUserData) => ({
                                ...prevUserData,
                                document: [...prevUserData.document, failedFile],
                            }));
                            return; // Exit the function execution for this file
                        }

                        // Upload the file to S3 using the presigned URL
                        const xhr = new XMLHttpRequest();
                        xhr.open("PUT", presignedUrlData.data[0].presignedUrl);
                        xhr.upload.onprogress = (event) => {
                            const percentage = (event.loaded / event.total) * 100;
                            // Update the percentage and upload status for this file

                            setDocument(prevFiles => {
                                const updatedFiles = prevFiles.map(prevFile => {
                                    if (prevFile.doc_name === file.name) {
                                        return {
                                            ...prevFile,
                                            percentage: percentage.toFixed(2),
                                            uploadstatus: percentage === 100 ? 'complete' : 'processing',
                                        };
                                    }
                                    return prevFile;
                                });
                                return updatedFiles;
                            });
                        };

                        xhr.send(file);
                    })
                );
            }
        } catch (error) {
            console.error("Error handling file upload:", error);
            // Handle error, show a message or log it
        }
        setIsModified(true)
    };
    useEffect(() => {
        setCurrentMonth(currentDate.getMonth());
        setCurrentYear(currentDate.getFullYear());
    }, [currentDate]);
    const currentDateTime = moment(currentDate);
    // Set the desired date for the view based on currentMonth and currentYear
    const defaultViewDate = moment().set({ 'month': currentMonth, 'year': currentYear }).toDate();
    const startOfCurrentHour = currentDateTime.startOf('hour'); // Set the start of the current hour

    // Calculate the start time for the day view (from the current hour)
    const minTimeForDayView = startOfCurrentHour.toDate();

    const [userData, setUserData] = useState([]);
    const [viewRedList, setViewRedList] = useState(false);
    const [Tempassign, setTempassign] = useState([]);
    const closeViewRedListModal = () => {
        setViewRedList(false);
    }
    const openViewAddRedListModal = async (redlistid) => {

        setIsLoading(true);
        setViewRedList(true);
        try {
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;

            const data = await getAllRedListbyid(deepuser_id, deeporg_id, redlistid, userToken);

            if (data.code === "200") {
                setIsLoading(false)
                setUserData(data.data);
                // SetDocpath(data.doc_path);
                // if (data.data[0]?.notificationto) { // Check if notificationto is defined
                //     setTempassign(data.data[0].notificationto.map(attendee => attendee));
                // }
                // console.log(data.data);
            } else {
                // console.log(data.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }



    };
    const openConfirmDialog = (index, docName) => {
        setFileIndexToRemove(index);
        setFileDocNameToRemove(docName);
        setIsConfirmDialogOpen(true);
    };

    const closeConfirmDialog = () => {
        setIsConfirmDialogOpen(false);
        setFileIndexToRemove(null);
        setFileDocNameToRemove(null);
    };
    const [event_type, setEvent_type] = React.useState('meeting');
    const [meetingRoomType, setMeetingRoomType] = React.useState('no');

    const handleTypeEventChange = (event) => {
        setEvent_type(event.target.value);
        setIsModified(true)
    };
    const handleMeetingTypeChange = (event) => {
        setMeetingRoomType(event.target.value);
        setIsModified(true)
    };
    const slotClickDate = new Date();
    slotClickDate.setDate(slotClickDate.getDate() - 1);
    const calendarRef = useRef(null);
    useEffect(() => {
        // Scroll to 9 AM when the day or week view is shown
        if (calendarRef.current) {
            const timeColumn = calendarRef.current.querySelector('.rbc-time-content');
            const nineAMSlot = calendarRef.current.querySelector('.rbc-time-header-content').childNodes[1]; // Assuming 9 AM slot is the second child
            if (timeColumn && nineAMSlot) {
                const nineAMSlotTop = nineAMSlot.offsetTop;
                timeColumn.scrollTop = nineAMSlotTop;
            }
        }
    }, [view]);



    const handleOpenDialogRoom = () => {
        setOpenDialogRoom(true);
    };

    const handleCloseDialogRoom = () => {
        setOpenDialogRoom(false);
        setIsNewDesignation('');
    };
    const handleCreateNewDesignation = async () => {
        try {

            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            // console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
            } = userDataObj;
            const response = await AddMeetingRoom(deepuser_id, deeporg_id, isNewDesignation, userToken);
            if (response.code === '200') {
                const createdRoom = {
                    room_id: response.data.room_id,
                    name: response.data.room_name
                };
                const updatedMeetingRooms = [createdRoom, ...meetingRooms];
                setMeetingRooms(updatedMeetingRooms);
                setTempMeetingRoom(createdRoom.room_id); // Select the newly created room
                setaddMeetingRoom(createdRoom.room_id);
                setIsModified(true);
                handleCloseDialogRoom();
            } else {
                console.error('Error creating room:', response.message);
                settoastmessage(response.message);
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Error creating room:', error);
        }
    };

    return (
        <Box>
            {isCalenderLoading ?
                <CalenderSkeleton />
                :
                <Calendar
                    selectable
                    localizer={localizer}
                    onSelectSlot={(slotInfo) => {
                        if (slotInfo.action === "click" || slotInfo.action === "select") {
                            const currentDate = new Date(slotClickDate);
                            const currentD = new Date();
                            const currentTime = currentD.getTime();
                            const slotStartTime = new Date(slotInfo.start).getTime();

                            // Check if the selected slot's start date is after or equal to the current date and time
                            if (slotStartTime < currentTime) {
                                // If the selected slot is before the current date and time, do nothing or provide feedback to the user
                                console.log("Please select a slot from the current date and time onwards.");
                            } else {
                                handleDateSelect(slotInfo.start, slotInfo.end);
                            }
                        }
                    }}
                    events={meetingResults}
                    defaultDate={currentDate}
                    onView={handleViewChange}
                    defaultView={view}
                    views={['month', 'week', 'day']}
                    defaultViewDate={defaultViewDate}
                    onEventDrop={handleEventDrop}
                    onEventResize={handleEventResize}
                    onSelectEvent={handleEventClick} // Use onSelectEvent to handle event clicks
                    startAccessor={(event) => { return new Date(event.start) }}
                    endAccessor={(event) => { return new Date(event.end) }}
                    timeslots={6} // Enable time slots and set the number of slots per hour
                    step={10} // Set the interval between time slots in minutes
                    style={{ height: '77vh' }}

                    components={{
                        toolbar: CustomToolbar,

                    }}
                    eventPropGetter={(events) => ({
                        style: {
                            width: '100%',
                            fontSize: '12px',
                            backgroundColor: events.colour, // Set the background color based on the event's color property
                            color: events.colour == "#DCDCDC" && '#3B3B3B'
                        },
                    })}

                    dayPropGetter={(date) => {
                        const today = new Date().setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0 for proper comparison
                        const startOfMonth = new Date(currentYear, currentMonth, 1); // First day of the current month
                        const endOfMonth = new Date(currentYear, currentMonth + 1, 0); // Last day of the current month
                        const isCurrentMonth = date >= startOfMonth && date <= endOfMonth; // Check if date is within the current month
                        const isPastDate = isCurrentMonth && date < today; // Check if date is in the past and within the current month
                        return {
                            className: isPastDate ? 'rbc-past-date' : '',
                        };
                    }}
                />
            }
            <Dialog open={addEventModal} onClose={addEventModalClose}
                sx={{
                    "& .MuiDialog-paper": {
                        borderRadius: "10px",
                        minWidth: '600px',
                        backgroundImage: { modalBackground2 },
                    },
                    // position: 'relative',
                    '@media (max-width: 500px)': {
                        minWidth: '100%', // Set minimum width to 100% for full-screen modal on small screens
                        minHeight: '100%',
                        margin: 0, // Remove default margin
                    },
                    borderRadius: '10px',
                }}
            >
                <DialogTitle sx={{ padding: '0px 5px ' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', margin: '10px 0px 0px 10px' }}>
                            <Box>
                                <AddCardOutlinedIcon sx={{}} />
                            </Box>
                            <Typography sx={{ fontWeight: 'bold', marginLeft: '10px' }}>Create New Event</Typography>
                        </Box>
                        <Box>
                            <IconButton onClick={addEventModalClose}> <CloseIcon /></IconButton>

                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{ '@media (min-width: 500px)': {
                                maxHeight: 'calc(100vh - 200px)', overflowY: 'auto'
                            },}}>
                    <Box >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>

                            <Box fontWeight='bold'>
                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', marginTop: '8px' }}> Event Type</Typography>
                            </Box>
                            <Box >
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"

                                    name="radio-buttons-group"
                                    value={event_type}
                                    onChange={handleTypeEventChange}
                                >
                                    <Box sx={{ display: 'flex' }}>
                                        <FormControlLabel value="meeting" control={<Radio />} label="Meeting" sx={{ fontSize: '13px', fontWeight: 'bold', color: 'grey', }} />
                                        <FormControlLabel value="other" control={<Radio />} label="Others" sx={{ fontSize: '13px', fontWeight: 'bold', color: 'grey', }} />
                                    </Box>


                                </RadioGroup>
                            </Box>

                        </Box>
                        <Box sx={{ marginTop: '20px' }}>
                            {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }} >Agenda <span style={{color:'red'}}>*</span> </Typography></Box> */}
                            <Box><TextField label='Event Agenda' required placeholder="Event Agenda" sx={{ width: '100%' }} size="small" id="outlined-required" onChange={handleAgendaChange} inputProps={{ maxLength: 128 }} /></Box>
                        </Box>
                        <Box sx={{ marginTop: '20px' }}>
                            {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Description <span style={{color:'red'}}>*</span> </Typography></Box> */}
                            <Box><TextField label='Description' required multiline rows={3} placeholder="Add event description" sx={{ width: '100%' }} size="small" onChange={handleDescriptionChange} inputProps={{ maxLength: 256 }} /></Box>
                        </Box>
                        <Box>
                            <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Color </Typography></Box>
                            <Typography sx={{ display: 'flex', }}>
                                <IconButton sx={{ color: colorClicked === '#ee82ee' ? 'white' : '#ee82ee', backgroundColor: '#ee82ee', ":hover": { backgroundColor: '#ee82ee' }, marginRight: '10px' }} onClick={() => { handleColorClick('#ee82ee'); setIsModified(true); }} ><CheckIcon sx={{ height: '10px', width: '10px' }} /></IconButton>
                                <IconButton sx={{ color: colorClicked === '#ffa500' ? 'white' : '#ffa500', backgroundColor: '#ffa500', ":hover": { backgroundColor: '#ffa500' }, marginRight: '10px' }} onClick={() => { handleColorClick('#ffa500'); setIsModified(true); }}><CheckIcon sx={{ height: '10px', width: '10px' }} /></IconButton>
                                <IconButton sx={{ color: colorClicked === '#ff0000' ? 'white' : '#ff0000', backgroundColor: '#ff0000', ":hover": { backgroundColor: '#ff0000' }, marginRight: '10px' }} onClick={() => { handleColorClick('#ff0000'); setIsModified(true); }}><CheckIcon sx={{ height: '10px', width: '10px' }} /></IconButton>
                                <IconButton sx={{ color: colorClicked === '#aaaa04' ? 'white' : '#aaaa04', backgroundColor: '#aaaa04', ":hover": { backgroundColor: '#aaaa04' }, marginRight: '10px' }} onClick={() => { handleColorClick('#aaaa04'); setIsModified(true); }}><CheckIcon sx={{ height: '10px', width: '10px' }} /></IconButton>
                                <IconButton sx={{ color: colorClicked === '#9933F5' ? 'white' : '#9933F5', backgroundColor: '#9933F5', ":hover": { backgroundColor: '#9933F5' }, marginRight: '10px' }} onClick={() => { handleColorClick('#9933F5'); setIsModified(true); }}><CheckIcon sx={{ height: '10px', width: '10px' }} /></IconButton>
                                <IconButton sx={{ color: colorClicked === '#00e4ff' ? 'white' : '#00e4ff', backgroundColor: '#00e4ff', ":hover": { backgroundColor: '#00e4ff' }, marginRight: '10px' }} onClick={() => { handleColorClick('#00e4ff'); setIsModified(true); }}><CheckIcon sx={{ height: '10px', width: '10px' }} /></IconButton>
                                <IconButton sx={{ color: colorClicked === '#99e4ff' ? 'white' : '#99e4ff', backgroundColor: '#99e4ff', ":hover": { backgroundColor: '#99e4ff' }, marginRight: '10px' }} onClick={() => { handleColorClick('#99e4ff'); setIsModified(true); }}><CheckIcon sx={{ height: '10px', width: '10px' }} /></IconButton>
                                <IconButton sx={{ color: colorClicked === '#0B24F7' ? 'white' : '#0B24F7', backgroundColor: '#0B24F7', ":hover": { backgroundColor: '#0B24F7' }, marginRight: '10px' }} onClick={() => { handleColorClick('#0B24F7'); setIsModified(true); }}><CheckIcon sx={{ height: '10px', width: '10px' }} /></IconButton>

                            </Typography>
                        </Box>
                        <Box sx={{ marginTop: '20px' }}>
                            {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Attendees<span style={{color:'red'}}>*</span> </Typography></Box> */}

                            <Box>
                                {Array.isArray(attendeesSearchResults) &&
                                    <Autocomplete
                                        limitTags={2}
                                        multiple
                                        id="tags-outlined"
                                        // options={userSearchResults.filter(option => !accessUser.includes(option))}
                                        // options={userSearchResults.filter(option => !accessUser.some(user => user.user_id === option.user_id))}
                                        options={attendeesSearchResults}
                                        getOptionLabel={getOptionLabel}
                                        renderOption={renderOption}
                                        filterOptions={filterOptions}
                                        value={selectedAttendeesWpList}
                                        onChange={(event, newValue) => handleInputChange(event, newValue)}
                                        filterSelectedOptions
                                        renderTags={(value, getTagProps) =>
                                            value
                                                .filter((option, index, self) =>
                                                    index === self.findIndex((t) => (
                                                        t.attendees_id === option.attendees_id
                                                    ))
                                                )
                                                .map((option, index) => (
                                                    <Chip
                                                        key={index}
                                                        avatar={<img src={option.photo} alt={option.name} style={{ width: '24px', height: '24px', borderRadius: '50%' }} />}
                                                        label={option.name}
                                                        {...getTagProps({ index })}
                                                        color={option.within_org === 'no' ? 'primary' : "default"}
                                                        variant={option.within_org === 'no' ? 'outlined' : 'filled'}
                                                    />
                                                ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label='Attendees' required
                                                placeholder="Select Attendees"
                                                size="small"
                                            />
                                        )}


                                    />}
                            </Box>
                        </Box>
                        <Box sx={{ marginTop: '20px' }}>
                            {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Attendees<span style={{color:'red'}}>*</span> </Typography></Box> */}

                            <Box>
                                {Array.isArray(selectedAttendeesWpList) &&
                                    <Autocomplete
                                        limitTags={2}
                                        multiple
                                        id="tags-outlined"
                                        options={selectedAttendeesWpList}
                                        getOptionLabel={getOptionLabel}
                                        renderOption={renderOption}
                                        filterOptions={filterOptions}
                                        value={selectedAttendees}
                                        onChange={(event, newValue) => { setSelectedAttendees(newValue); setIsModified(true) }}
                                        filterSelectedOptions
                                        renderTags={(value, getTagProps) =>
                                            value
                                                .filter((option, index, self) =>
                                                    index === self.findIndex((t) => (
                                                        t.attendees_id === option.attendees_id
                                                    ))
                                                )
                                                .map((option, index) => (
                                                    <Chip
                                                        key={index}
                                                        avatar={<img src={option.photo} alt={option.name} style={{ width: '24px', height: '24px', borderRadius: '50%' }} />}
                                                        label={option.name}
                                                        onDelete={() => {
                                                            // Remove the selected attendee from the list
                                                            setSelectedAttendees(prev => prev.filter((item, i) => i !== index));
                                                        }}
                                                        {...getTagProps({ index })}
                                                        color={option.within_org === 'no' ? 'primary' : "default"}
                                                        variant={option.within_org === 'no' ? 'outlined' : 'filled'}
                                                    />
                                                ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={
                                                    <Box sx={{ display: 'flex', width: '100%' }}>
                                                        <WhatsAppIcon style={{ marginRight: '8px', color: 'white', backgroundColor: 'green', borderRadius: '50px', padding: '2px' }} />
                                                        <Typography>Send whatsapp notification to</Typography>
                                                    </Box>
                                                }
                                                placeholder="Select Attendees"
                                                size="small"
                                            />
                                        )}
                                    />}
                            </Box>
                        </Box>
                        <Box sx={{ marginTop: '20px' }}>
                            {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Meeting Room <span style={{color:'red'}}>*</span> </Typography></Box> */}


                            <FormControl size="small" sx={{ width: '100%' }} required>
                                <InputLabel>Meeting Room</InputLabel>
                                <Select
                                    label="Meeting Room"
                                    id="demo-simple-select1"
                                    value={tempMeetingRoom}
                                    onChange={(e) => { setTempMeetingRoom(e.target.value); setIsModified(true) }}
                                >
                                    <MenuItem onClick={handleOpenDialogRoom}>
                                        <ListItemIcon>
                                            <AddIcon sx={{ fontWeight: "bold" }} /> {/* Render the MUI icon */}
                                        </ListItemIcon>
                                        <b>Create New Room</b>
                                    </MenuItem>
                                    {meetingRooms.map((item) => (
                                        <MenuItem key={item.room_id} value={item.room_id}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {/* <Autocomplete

                                    // multiple
                                    id="tags-outlined"
                                         options={meetingRooms}
                                    getOptionLabel={getOptionLabel}
                                   
                                    onChange={(event, newValue) => handleMeetingRoomChange(event, newValue)}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label='Meeting Room' required
                                            placeholder="Select Room"
                                            size="small"
                                        />
                                    )}


                                /> */}

                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>

                            <Box fontWeight='bold'>
                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', marginTop: '8px' }}>Virtual Conference</Typography>
                            </Box>
                            <Box >
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"

                                    name="radio-buttons-group"
                                    value={meetingRoomType}
                                    onChange={handleMeetingTypeChange}
                                >
                                    <Box sx={{ display: 'flex' }}>
                                        <FormControlLabel value="no" control={<Radio />} label="No" sx={{ fontSize: '13px', fontWeight: 'bold', color: 'grey', }} />
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" sx={{ fontSize: '13px', fontWeight: 'bold', color: 'grey', }} />
                                    </Box>


                                </RadioGroup>
                            </Box>

                        </Box>
                        {meetingRoomType == 'yes' && <Box sx={{ marginTop: '20px' }}>
                            {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }} >Agenda <span style={{color:'red'}}>*</span> </Typography></Box> */}
                            <Box><TextField label='Conference Link' placeholder="Event Conference Link" sx={{ width: '100%' }} size="small" id="outlined-required" onChange={handleVirtualMeetingRoomChange} inputProps={{ maxLength: 128 }} /></Box>
                        </Box>}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
                            <Box>
                                <Grid container spacing={3}>
                                    <Grid item xs={4}>

                                        <Box sx={{ marginRight: '5px' }}>
                                            {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Meeting Date <span style={{color:'red'}}>*</span> </Typography></Box> */}
                                            <Box sx={{ minWidth: '100px' }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <Box sx={{ minWidth: '100px' }}>
                                                            <DatePicker placeholder="Meeting Date"
                                                                sx={{ marginTop: '20px' }}
                                                                label='Meeting Date *' required
                                                                value={meetingDate}
                                                                slotProps={{
                                                                    textField: { size: 'small', format: 'DD/MM/YYYY', readOnly: true },
                                                                    field: { error: errorDate ? true : false, },
                                                                }}
                                                                onChange={handleMeetingDateChange}
                                                                minDate={dayjs(new Date())}
                                                            />
                                                        </Box>
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </Box>
                                        </Box>

                                    </Grid>
                                    <Grid item xs={4}>

                                        <Box sx={{ marginLeft: '5px' }}>
                                            {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Start Time <span style={{color:'red'}}>*</span> </Typography></Box> */}
                                            <Box>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['TimePicker']}>
                                                        <Box sx={{ minWidth: '100px' }}>
                                                            <TimePicker
                                                                placeholder="Start Time"
                                                                label='Start Time *' required
                                                                value={startTime}
                                                                sx={{ marginTop: '20px' }}
                                                                onChange={handleStartTimeChange}
                                                                slotProps={{
                                                                    textField: { size: 'small', readOnly: true },
                                                                    field: { error: errorSTime ? true : false, },
                                                                }}
                                                                viewRenderers={{
                                                                    hours: renderTimeViewClock,
                                                                    minutes: renderTimeViewClock,
                                                                    seconds: renderTimeViewClock,
                                                                }}
                                                                minTime={dayjs(meetingDate).isSame(dayjs(), 'day') ? dayjs() : undefined}
                                                            />
                                                        </Box>
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </Box>
                                        </Box>

                                    </Grid>
                                    <Grid item xs={4}>

                                        <Box sx={{ marginLeft: '5px' }}>
                                            {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>End Time <span style={{color:'red'}}>*</span> </Typography></Box> */}
                                            <Box>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['TimePicker']}>
                                                        <Box sx={{ minWidth: '100px' }}>
                                                            <TimePicker
                                                                placeholder="End Time"
                                                                label='End Time *' required
                                                                value={endTime}
                                                                sx={{ marginTop: '20px' }}
                                                                onChange={handleEndTimeChange}
                                                                slotProps={{
                                                                    textField: { size: 'small', readOnly: true },
                                                                    field: { error: errorETime ? true : false, },
                                                                }}
                                                                css={{ padding: '0px' }}
                                                                viewRenderers={{
                                                                    hours: renderTimeViewClock,
                                                                    minutes: renderTimeViewClock,
                                                                    seconds: renderTimeViewClock,
                                                                }}
                                                                minTime={dayjs(startTime)}
                                                            />
                                                        </Box>
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </Box>
                                        </Box>

                                    </Grid>
                                </Grid>
                            </Box>

                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <InfoRoundedIcon sx={{ color: '#2F80ED', height: '20px', width: '20px' }} /><Typography sx={{ fontSize: '14px', marginLeft: '5px' }}> {'select (AM/PM) hour minute for time respectively'}</Typography>
                        </Box>
                        <Box sx={{ marginTop: '20px' }}>
                            {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Reference to Red List Activity</Typography></Box> */}

                            <Box>

                                <Autocomplete

                                    // multiple
                                    id="tags-outlined"
                                    // options={userSearchResults.filter(option => !accessUser.includes(option))}
                                    // options={userSearchResults.filter(option => !accessUser.some(user => user.user_id === option.user_id))}
                                    options={allRedList}
                                    getOptionLabel={(option) => option.agenda}
                                    // renderOption={renderOption}
                                    // filterOptions={filterOptions}
                                    onChange={(event, newValue) => handleAddRedList(event, newValue)}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Reference to Red List Activity"
                                            placeholder="Select Red List"
                                            size="small"
                                        />
                                    )} />
                            </Box>
                        </Box>
                        <Box>
                            <Box sx={{ border: '1px solid var(--Colors-Border-border-secondary, #EAECF0)', background: ' var(--Colors-Background-bg-primary, #FFF)', borderRadius: '12px', textAlign: 'center', fontSize: '14px', color: 'grey', margin: '10px 0px' }}>
                                <Box>
                                    <label htmlFor="contained-button-file">
                                        <CloudUploadOutlinedIcon style={{ cursor: 'pointer', margin: '10px 0px 0px 0px ', }} />
                                    </label>
                                </Box>
                                <Box >
                                    <Typography>
                                        <label htmlFor="contained-button-file">
                                            <span style={{ color: '#005AC1', fontWeight: 'bold', cursor: 'pointer' }}>Click to upload </span>
                                        </label>
                                        {" "}or drag and drop
                                        <input
                                            accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
                                            id="contained-button-file"
                                            multiple
                                            type="file"
                                            onChange={handleFileChange}
                                            style={{ display: 'none' }} // Hide the input field
                                        />

                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography sx={{ fontSize: '12px', marginBottom: '5px' }}>PDF, DOC, PPT and XLSX  files (Max 5 gb or 100 files)</Typography>
                                </Box>
                            </Box>
                        </Box>
                        {uploadFiles.map((file, index) => (
                            <Box key={index} sx={{ backgroundColor: '', border: '1px solid var(--_components-input-outlined-enabledBorder, rgba(0, 0, 0, 0.23))', borderRadius: '4px', margin: '10px 0px ', overflow: 'auto' }}>

                                <Box display='flex' justifyContent='space-between' alignItems='center' padding='10px'>
                                    <Box display='flex' alignItems='center'>
                                        <Box>
                                            <Box>
                                                {file.doc_name.endsWith('.pdf') && <DescriptionIcon sx={{ color: 'red', margin: '0 10px 0 0' }} />}
                                                {file.doc_name.endsWith('.xls') && <DescriptionIcon sx={{ color: 'green', margin: '0 10px 0 0' }} />}
                                                {file.doc_name.endsWith('.xlsx') && <DescriptionIcon sx={{ color: 'green', margin: '0 10px 0 0' }} />}
                                                {file.doc_name.endsWith('.doc') && <DescriptionIcon sx={{ color: 'blue', margin: '0 10px 0 0' }} />}
                                                {file.doc_name.endsWith('.docx') && <DescriptionIcon sx={{ color: 'blue', margin: '0 10px 0 0' }} />}
                                                {file.doc_name.endsWith('.ppt') && <DescriptionIcon sx={{ color: '#FFA500', margin: '0 10px 0 0' }} />}
                                                {file.doc_name.endsWith('.pptx') && <DescriptionIcon sx={{ color: '#FFA500', margin: '0 10px 0 0' }} />}
                                            </Box>
                                        </Box>
                                        <Box >

                                            <Box sx={{ maxWidth: '120px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px', }}> {file.doc_name} </Box>
                                            <Box sx={{ color: 'grey', fontSize: '12px', }}>{file.doc_size} </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex' }}>
                                        <Box>
                                            {
                                                file.uploadstatus === 'new' ? (
                                                    <CircularProgress size={20} sx={{ marginTop: '8px' }} />
                                                ) : file.uploadstatus === 'processing' ? (
                                                    <CircularProgress variant="determinate" value={file.percentage} size={20} sx={{ marginTop: '8px' }} />
                                                ) : file.uploadstatus === 'complete' ? (
                                                    <CheckIcon sx={{ color: '#000000', marginTop: '8px' }} />
                                                ) : file.uploadstatus === 'failed' ? (
                                                    <SmsFailedIcon />
                                                ) : null // Ensure to have a default value or null at the end if necessary
                                            }
                                        </Box>
                                        {file.uploadstatus === 'complete' && <IconButton onClick={() => openConfirmDialog(index, file.doc_name)}><CloseIcon sx={{ padding: '0px' }} /></IconButton>}
                                    </Box>
                                </Box>

                            </Box>
                        ))}
                    </Box>
                </DialogContent>
                <DialogActions sx={{ padding: '10px 23px 15px 23px' }}>
                    <Box sx={{ display: 'flex', width: '100%' }}>
                        <Box sx={{ width: '50%', marginRight: '5px' }}>
                            <Button variant="outlined" sx={{ textTransform: 'none', width: '100%', fontWeight: 'bold', }} onClick={addEventModalClose}>Cancel</Button>
                        </Box>
                        <Box sx={{ width: '50%', marginLeft: '5px' }}>
                            <Button variant="contained" sx={{ textTransform: 'none', width: '100%', fontWeight: 'bold' }} onClick={fetchAddMeetingEvent} backgroundColor={buttonLoading && '#616161'} disabled={buttonLoading}>
                                {buttonLoading && <CircularProgress size={16} sx={{ marginRight: '10px' }} />}Add Event</Button>
                        </Box>
                    </Box>
                </DialogActions>
            </Dialog>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle sx={{ paddingBottom: '0px', width: '350px' }}>Add Guest User</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Autocomplete
                        size="small"
                        id="free-solo-demo"
                        freeSolo
                        // clearOnBlur={false}
                        // style={{
                        //     backgroundColor: "white",
                        //     borderRadius: "5px",
                        //     borderColor: "#eff1f3",
                        //     borderBlockColor: "#eff1f3",
                        //     height: "50px",
                        //     marginBottom: "40px",
                        //     marginTop: "20px",
                        // }}
                        options={guestUserAutocompleteResult}
                        getOptionLabel={(option) => option.full_name}
                        renderOption={autocompleteRenderOption}
                        inputValue={guestUserName}
                        onInputChange={(event) => {
                            const value = event.target.value;
                            if (!isOptionSelected) {
                                fetchAutocompleteGuestUser(value);
                            }
                            setIsOptionSelected(false); // Reset isOptionSelected state
                        }}
                        onChange={(event, value) => {
                            if (value && value.full_name) {
                                setGuestUserName(value.full_name);
                                setGuestUserEmail(value.email);
                                setGuestUserPhone(value.mobile_no);
                                setIsOptionSelected(true); // Set isOptionSelected to true when an option is selected
                            }
                        }}
                        getOptionSelected={getOptionSelected}
                        renderInput={(params) => (
                            <TextField size="small"  {...params} label='Guest Name' required placeholder="Enter Name" value={guestUserName} onChange={(e) => setGuestUserName(e.target.value)} sx={{ marginTop: '20px' }} />
                        )}
                    />
                    {/* <TextField size="small" label='Guest Name' required placeholder="Enter Name" onChange={(e) => setGuestUserName(e.target.value)} sx={{ marginTop: '20px' }} /> */}

                    <TextField size="small" label='Email Address' required placeholder="email@email.com" value={guestUserEmail} onChange={(e) => setGuestUserEmail(e.target.value)} sx={{ marginTop: '20px' }} />
                    <TextField size="small" label='Phone Number' required placeholder="000-000-0000" value={guestUserPhone} onChange={(e) => setGuestUserPhone(e.target.value)} sx={{ marginTop: '20px' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start"> +91</InputAdornment>
                            ),
                        }}
                    />
                </DialogContent>

                <DialogActions sx={{ padding: '10px 23px 15px 23px' }}>
                    <Box sx={{ display: 'flex', width: '100%' }}>
                        <Box sx={{ width: '50%', marginRight: '5px' }}>
                            <Button variant='outlined' sx={{ fontWeight: 'bold', textTransform: 'none', width: '100%' }} onClick={handleCloseDialog}>Cancel</Button>
                        </Box>
                        <Box sx={{ width: '50%', marginLeft: '5px' }}>
                            <Button color='primary' variant='contained' onClick={fetchGuestUser}
                                sx={{ fontWeight: 'bold', textTransform: 'none', width: '100%' }}
                                disabled={guestButtonLoading}
                            >
                                {guestButtonLoading && <CircularProgress size={16} sx={{ marginRight: '10px' }} />}
                                Submit
                            </Button>
                        </Box>
                    </Box>


                </DialogActions>
            </Dialog>
            <Dialog open={editEventModal} onClose={editEventModalClose}
                sx={{
                    "& .MuiDialog-paper": {
                        borderRadius: "10px",
                        minWidth: '600px'
                    },'@media (max-width: 500px)': {
                        minWidth: '100%', // Set minimum width to 100% for full-screen modal on small screens
                        minHeight: '100%',
                        margin: 0, // Remove default margin
                    }
                }}
            >
                <DialogTitle sx={{ padding: '0px 5px ' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', margin: '10px 0px 0px 10px' }}>
                            <Box>
                                <AddCardOutlinedIcon sx={{}} />
                            </Box>
                            <Typography sx={{ fontWeight: 'bold', marginLeft: '10px' }}>Edit Event</Typography>
                        </Box>
                        <Box>
                            <IconButton onClick={editEventModalClose}> <CloseIcon /></IconButton>

                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{ '@media (min-width: 500px)': {
                                maxHeight: 'calc(100vh - 200px)', overflowY: 'auto'
                            }, }}>
                    <Box >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>

                            <Box fontWeight='bold'>
                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', marginTop: '8px' }}> Event Type</Typography>
                            </Box>
                            <Box >
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"

                                    name="radio-buttons-group"
                                    value={tempEventType} onChange={(e) => { setTempEventType(e.target.value); setIsModified(true) }}
                                >
                                    <Box sx={{ display: 'flex' }}>
                                        <FormControlLabel value="meeting" control={<Radio />} label="Meeting" sx={{ fontSize: '13px', fontWeight: 'bold', color: 'grey', }} />
                                        <FormControlLabel value="other" control={<Radio />} label="Others" sx={{ fontSize: '13px', fontWeight: 'bold', color: 'grey', }} />
                                    </Box>


                                </RadioGroup>
                            </Box>

                        </Box>
                        <Box sx={{ marginTop: '20px' }}>
                            {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Agenda <span style={{ color: 'red' }}>*</span> </Typography></Box> */}
                            <Box><TextField placeholder="Event Agenda" label='Event Agenda' required sx={{ width: '100%' }} size="small" value={tempAgenda} onChange={(e) => { setTempAgenda(e.target.value); setIsModified(true) }} inputProps={{ maxLength: 128 }} /></Box>
                        </Box>
                        <Box sx={{ marginTop: '20px' }}>
                            {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Description <span style={{ color: 'red' }}>*</span> </Typography></Box> */}
                            <Box><TextField multiline label='Description' required rows={3} placeholder="Add event description" sx={{ width: '100%' }} size="small" value={tempMeetingDesc} onChange={(e) => { setTempMeetingDesc(e.target.value); setIsModified(true) }} inputProps={{ maxLength: 256 }} /></Box>
                        </Box>
                        <Box>
                            <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Color </Typography></Box>
                            <Typography sx={{ display: 'flex', }}>
                                <IconButton sx={{ color: '#ee82ee' === tempMeetingColor ? 'white' : '#ee82ee', backgroundColor: '#ee82ee', ":hover": { backgroundColor: '#ee82ee' }, marginRight: '10px' }} onClick={() => { setTempMeetingColor('#ee82ee'); setIsModified(true) }} ><CheckIcon sx={{ height: '10px', width: '10px' }} /></IconButton>
                                <IconButton sx={{ color: '#ffa500' === tempMeetingColor ? 'white' : '#ffa500', backgroundColor: '#ffa500', ":hover": { backgroundColor: '#ffa500' }, marginRight: '10px' }} onClick={() => { setTempMeetingColor('#ffa500'); setIsModified(true) }}><CheckIcon sx={{ height: '10px', width: '10px' }} /></IconButton>
                                <IconButton sx={{ color: '#ff0000' === tempMeetingColor ? 'white' : '#ff0000', backgroundColor: '#ff0000', ":hover": { backgroundColor: '#ff0000' }, marginRight: '10px' }} onClick={() => { setTempMeetingColor('#ff0000'); setIsModified(true) }}><CheckIcon sx={{ height: '10px', width: '10px' }} /></IconButton>
                                <IconButton sx={{ color: '#aaaa04' === tempMeetingColor ? 'white' : '#aaaa04', backgroundColor: '#aaaa04', ":hover": { backgroundColor: '#aaaa04' }, marginRight: '10px' }} onClick={() => { setTempMeetingColor('#aaaa04'); setIsModified(true) }}><CheckIcon sx={{ height: '10px', width: '10px' }} /></IconButton>
                                <IconButton sx={{ color: '#9933F5' === tempMeetingColor ? 'white' : '#9933F5', backgroundColor: '#9933F5', ":hover": { backgroundColor: '#9933F5' }, marginRight: '10px' }} onClick={() => { setTempMeetingColor('#9933F5'); setIsModified(true) }}><CheckIcon sx={{ height: '10px', width: '10px' }} /></IconButton>
                                <IconButton sx={{ color: '#00e4ff' === tempMeetingColor ? 'white' : '#00e4ff', backgroundColor: '#00e4ff', ":hover": { backgroundColor: '#00e4ff' }, marginRight: '10px' }} onClick={() => { setTempMeetingColor('#00e4ff'); setIsModified(true) }}><CheckIcon sx={{ height: '10px', width: '10px' }} /></IconButton>
                                <IconButton sx={{ color: '#99e4ff' === tempMeetingColor ? 'white' : '#99e4ff', backgroundColor: '#99e4ff', ":hover": { backgroundColor: '#99e4ff' }, marginRight: '10px' }} onClick={() => { setTempMeetingColor('#99e4ff'); setIsModified(true) }}><CheckIcon sx={{ height: '10px', width: '10px' }} /></IconButton>
                                <IconButton sx={{ color: '#0B24F7' === tempMeetingColor ? 'white' : '#0B24F7', backgroundColor: '#0B24F7', ":hover": { backgroundColor: '#0B24F7' }, marginRight: '10px' }} onClick={() => { setTempMeetingColor('#0B24F7'); setIsModified(true) }}><CheckIcon sx={{ height: '10px', width: '10px' }} /></IconButton>

                            </Typography>
                        </Box>
                        <Box sx={{ marginTop: '20px' }}>
                            {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Attendees<span style={{ color: 'red' }}>*</span> </Typography></Box> */}

                            <Box>
                                {Array.isArray(attendeesSearchResults) && (
                                    <Autocomplete
                                        limitTags={2}
                                        size="small"
                                        multiple
                                        id="tags-outlined"
                                        options={attendeesSearchResults.filter(option => !tempAtendees.some(user => user.attendees_id == option.attendees_id))}
                                        getOptionLabel={getOptionLabel}
                                        renderOption={renderOption}
                                        filterOptions={filterOptions}
                                        value={tempAtendees}
                                        // renderTags={(value, getTagProps) =>
                                        //     value.map((option, index) => (
                                        //         <Chip
                                        //             key={index}
                                        //             avatar={<img src={option.photo} alt={option.name} style={{ width: '24px', height: '24px', borderRadius: '50%' }} />}
                                        //             label={option.name}
                                        //             {...getTagProps({ index })}
                                        //         />
                                        //     ))
                                        // }
                                        renderTags={(value, getTagProps) =>
                                            value
                                                .filter((option, index, self) =>
                                                    index === self.findIndex((t) => (
                                                        t.attendees_id === option.attendees_id
                                                    ))
                                                )
                                                .map((option, index) => (
                                                    <Chip
                                                        key={index}
                                                        avatar={<img src={option.photo} alt={option.name} style={{ width: '24px', height: '24px', borderRadius: '50%' }} />}
                                                        label={option.name}
                                                        {...getTagProps({ index })}
                                                        color={option.within_org === 'no' ? 'primary' : "default"}
                                                        variant={option.within_org === 'no' ? 'outlined' : 'filled'}
                                                    />
                                                ))
                                        }
                                        onChange={(event, newValue) => { handleTempInputChange(event, newValue); setIsModified(true) }}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label='Attendees' required
                                                placeholder="Select Attendees"
                                                size="small"
                                            />
                                        )}
                                    />
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ marginTop: '20px' }}>
                            {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Attendees<span style={{ color: 'red' }}>*</span> </Typography></Box> */}

                            <Box>
                                {Array.isArray(tempAtendees) && (
                                    <Autocomplete
                                        limitTags={2}
                                        size="small"
                                        multiple
                                        id="tags-outlined"
                                        // options={tempAtendees}
                                        options={tempAtendees.filter(option => !tempWpNotifyAttendees.some(user => user.attendees_id == option.attendees_id))}
                                        getOptionLabel={getOptionLabel}
                                        renderOption={renderOption}
                                        filterOptions={filterOptions}
                                        value={tempWpNotifyAttendees}
                                        // renderTags={(value, getTagProps) =>
                                        //     value.map((option, index) => (
                                        //         <Chip
                                        //             key={index}
                                        //             avatar={<img src={option.photo} alt={option.name} style={{ width: '24px', height: '24px', borderRadius: '50%' }} />}
                                        //             label={option.name}
                                        //             {...getTagProps({ index })}
                                        //         />
                                        //     ))
                                        // }
                                        renderTags={(value, getTagProps) =>
                                            value
                                                .filter((option, index, self) =>
                                                    index === self.findIndex((t) => (
                                                        t.attendees_id === option.attendees_id
                                                    ))
                                                )
                                                .map((option, index) => (
                                                    <Chip
                                                        key={index}
                                                        avatar={<img src={option.photo} alt={option.name} style={{ width: '24px', height: '24px', borderRadius: '50%' }} />}
                                                        label={option.name}
                                                        {...getTagProps({ index })}
                                                        color={option.within_org === 'no' ? 'primary' : "default"}
                                                        variant={option.within_org === 'no' ? 'outlined' : 'filled'}
                                                    />
                                                ))
                                        }
                                        onChange={(event, newValue) => { setTempWpNotifyAttendees(newValue); setIsModified(true) }}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={
                                                    <Box sx={{ display: 'flex', width: '100%' }}>
                                                        <WhatsAppIcon style={{ marginRight: '8px', color: 'white', backgroundColor: 'green', borderRadius: '50px', padding: '2px' }} />
                                                        <Typography>Send whatsapp notification to</Typography>
                                                    </Box>
                                                }
                                                placeholder="Select Attendees"
                                                size="small"
                                            />
                                        )}
                                    />
                                )}
                            </Box>
                        </Box>
                        <Box>
                            {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Meeting Room<span style={{ color: 'red' }}>*</span> </Typography></Box> */}

                            <Box sx={{ marginTop: '20px' }}>
                                <FormControl size="small" sx={{ width: '100%', }} label='Meeting Room' required>
                                    <InputLabel id="demo-simple-select-label">Meeting Room</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        label='Meeting Room' required
                                        id="demo-simple-select"
                                        value={tempMeetingRoom}
                                        onChange={(e) => { setTempMeetingRoom(e.target.value); setIsModified(true) }}

                                    >
                                        <MenuItem onClick={handleOpenDialogRoom}>
                                            <ListItemIcon>
                                                <AddIcon sx={{ fontWeight: "bold" }} /> {/* Render the MUI icon */}
                                            </ListItemIcon>
                                            <b>Create New Room</b>
                                        </MenuItem>
                                        {meetingRooms.map((item, index) => (
                                            <MenuItem
                                                value={item.room_id}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>

                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>

                            <Box fontWeight='bold'>
                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', marginTop: '8px' }}> Virtual Conference</Typography>
                            </Box>
                            <Box >
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"

                                    name="radio-buttons-group"
                                    value={tempVCRoom} onChange={(e) => { setTempVCRoom(e.target.value); setIsModified(true) }}
                                >
                                    <Box sx={{ display: 'flex' }}>
                                        <FormControlLabel value="no" control={<Radio />} label="No" sx={{ fontSize: '13px', fontWeight: 'bold', color: 'grey', }} />
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" sx={{ fontSize: '13px', fontWeight: 'bold', color: 'grey', }} />
                                    </Box>


                                </RadioGroup>
                            </Box>

                        </Box>
                        {tempVCRoom == 'yes' && <Box sx={{ marginTop: '20px' }}>
                            {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Agenda <span style={{ color: 'red' }}>*</span> </Typography></Box> */}
                            <Box><TextField placeholder="Enter Conference Room" label='Event Conference Room' sx={{ width: '100%' }} size="small" value={tempVCRoomLink} onChange={(e) => { setTempVCRoomLink(e.target.value); setIsModified(true) }} inputProps={{ maxLength: 128 }} /></Box>
                        </Box>}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
                            <Box>
                                <Grid container spacing={3}>
                                    <Grid item xs={4}>

                                        <Box sx={{ marginRight: '5px' }}>
                                            {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Meeting Date<span style={{ color: 'red' }}>*</span> </Typography></Box> */}
                                            <Box sx={{ minWidth: '100px' }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <Box sx={{ minWidth: '100px' }}>
                                                            <DatePicker placeholder="Meeting Date"
                                                                sx={{ marginTop: '20px' }}
                                                                label='Meeting Date' required
                                                                slotProps={{ textField: { size: 'small', format: 'DD/MM/YYYY', readOnly: true } }}
                                                                value={tempMeetingDate}
                                                                onChange={(newValue) => { setTempMeetingDate(newValue); setIsModified(true) }}
                                                                minDate={dayjs(new Date())}
                                                            />
                                                        </Box>
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </Box>
                                        </Box>

                                    </Grid>
                                    <Grid item xs={4}>

                                        <Box sx={{ marginLeft: '5px' }}>
                                            {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Start Time<span style={{ color: 'red' }}>*</span> </Typography></Box> */}
                                            <Box>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['TimePicker']}>
                                                        <Box sx={{ minWidth: '100px' }}>
                                                            <TimePicker
                                                                placeholder="Start Time"
                                                                label='Start Time' required
                                                                value={tempMeetingStartTime}
                                                                sx={{ marginTop: '20px' }}
                                                                onChange={(newValue) => { setTempMeetingStartTime(newValue); setIsModified(true) }}
                                                                slotProps={{ textField: { size: 'small', readOnly: true } }}
                                                                viewRenderers={{
                                                                    hours: renderTimeViewClock,
                                                                    minutes: renderTimeViewClock,
                                                                    seconds: renderTimeViewClock,
                                                                }}
                                                                minTime={dayjs(tempMeetingDate).isSame(dayjs(), 'day') ? dayjs() : undefined}
                                                            />
                                                        </Box>
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </Box>
                                        </Box>

                                    </Grid>
                                    <Grid item xs={4}>

                                        <Box sx={{ marginLeft: '5px' }}>
                                            {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>End Time<span style={{ color: 'red' }}>*</span> </Typography></Box> */}
                                            <Box>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['TimePicker']}>
                                                        <Box sx={{ minWidth: '100px', marginTop: '0px' }}>
                                                            <TimePicker
                                                                placeholder="End Time"
                                                                label='End Time' required
                                                                value={tempMeetingEndTime}
                                                                sx={{ marginTop: '20px' }}
                                                                onChange={(newValue) => { setTempMeetingEndTime(newValue); setIsModified(true) }}
                                                                slotProps={{ textField: { size: 'small', readOnly: true } }}
                                                                css={{ padding: '0px' }}
                                                                viewRenderers={{
                                                                    hours: renderTimeViewClock,
                                                                    minutes: renderTimeViewClock,
                                                                    seconds: renderTimeViewClock,
                                                                }}
                                                                minTime={dayjs(tempMeetingStartTime)}
                                                            />
                                                        </Box>
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </Box>
                                        </Box>

                                    </Grid>
                                </Grid>
                            </Box>

                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <InfoRoundedIcon sx={{ color: '#2F80ED', height: '20px', width: '20px' }} /><Typography sx={{ fontSize: '14px', marginLeft: '5px' }}> {'select (AM/PM) hour minute for time respectively'}</Typography>
                        </Box>
                        <Box>
                            {/* <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey', margin: '20px 0px 0px 0px' }}>Reference to Red List Activity</Typography></Box> */}

                            <Box sx={{ marginTop: '20px' }}>

                                <FormControl size="small" sx={{ width: '100%', }} label="Reference to Red List Activity">
                                    <InputLabel id="demo-simple-select-label">Reference to Red List Activity</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={tempReferenceRedList}
                                        onChange={(e) => { setTempReferenceRedList(e.target.value); setIsModified(true) }}
                                        label="Reference to Red List Activity"
                                    >
                                        {allRedList.map((item, index) => (
                                            <MenuItem
                                                value={item.redlist_id}>{item.agenda}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>

                        <Box>
                            <Box sx={{ border: '1px solid var(--Colors-Border-border-secondary, #EAECF0)', background: ' var(--Colors-Background-bg-primary, #FFF)', borderRadius: '12px', textAlign: 'center', fontSize: '14px', color: 'grey', margin: '10px 0px' }}>
                                <Box>
                                    <label htmlFor="contained-button-file">
                                        <CloudUploadOutlinedIcon style={{ cursor: 'pointer', margin: '10px 0px 0px 0px ', }} />
                                    </label>
                                </Box>
                                <Box>
                                    <Typography>
                                        <label htmlFor="contained-button-file">
                                            <span style={{ color: '#005AC1', fontWeight: 'bold', cursor: 'pointer' }}>Click to upload </span>
                                        </label>
                                        {" "}or drag and drop
                                        <input
                                            accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
                                            id="contained-button-file"
                                            multiple
                                            type="file"

                                            onChange={() => handleUpdateFileChange(meetingids,
                                                DocumentPath,)}
                                            style={{ display: 'none' }} // Hide the input field
                                        />

                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography sx={{ fontSize: '12px', marginBottom: '5px' }}>PDF, DOC, PPT and XLSX  files (Max 5 gb or 100 files)</Typography>
                                </Box>
                            </Box>
                        </Box>
                        {/* <Box sx={{ backgroundColor: '#f5f3f7', border: '1px solid #74777f', borderRadius: '10px', margin: '0px 0px ', maxHeight: '60px', overflow: 'auto' }}>
    {selectedUserData.document && selectedUserData.document.map((document, index) => (
        <Box key={index} display='flex' justifyContent='space-between' alignItems='center' padding='10px'>
            <Box display='flex' alignItems='center'>
                <Box><PictureAsPdfIcon sx={{ color: 'red', margin: '0 10px 0 0' }} /></Box>
                <Box sx={{ maxWidth: '120px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px', fontWeight: 'bold' }}>{document.doc_name}</Box>
            </Box>
            <IconButton onClick={() => handleDocumentDelete(document.doc_id,selectedUserData.redlist_id,document.doc_name,document.doc_path,)}><CloseIcon /></IconButton>
        </Box>
    ))}
</Box> */}

                        {document &&
                            document.map((file, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        backgroundColor: "",
                                        border:
                                            "1px solid var(--_components-input-outlined-enabledBorder, rgba(0, 0, 0, 0.23))",
                                        borderRadius: "4px",
                                        margin: "10px 0px ",
                                        overflow: "auto",
                                    }}
                                >
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        padding="10px"
                                    >
                                        <Box display="flex" alignItems="center">
                                            <Box>
                                                <Box> {file.doc_name.endsWith('.pdf') && <DescriptionIcon sx={{ color: 'red', margin: '0 10px 0 0' }} />}
                                                    {file.doc_name.endsWith('.xls') && <DescriptionIcon sx={{ color: 'green', margin: '0 10px 0 0' }} />}
                                                    {file.doc_name.endsWith('.xlsx') && <DescriptionIcon sx={{ color: 'green', margin: '0 10px 0 0' }} />}
                                                    {file.doc_name.endsWith('.doc') && <DescriptionIcon sx={{ color: 'blue', margin: '0 10px 0 0' }} />}
                                                    {file.doc_name.endsWith('.docx') && <DescriptionIcon sx={{ color: 'blue', margin: '0 10px 0 0' }} />}
                                                    {file.doc_name.endsWith('.ppt') && <DescriptionIcon sx={{ color: '#FFA500', margin: '0 10px 0 0' }} />}
                                                    {file.doc_name.endsWith('.pptx') && <DescriptionIcon sx={{ color: '#FFA500', margin: '0 10px 0 0' }} />}
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Box
                                                    sx={{
                                                        maxWidth: "120px",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    {" "}
                                                    {file.doc_name}{" "}
                                                </Box>
                                                <Box sx={{ color: "grey", fontSize: "12px" }}>
                                                    {file.doc_size}{" "}
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box display="flex">
                                            <Box>
                                                {
                                                    file.uploadstatus === "new" ? (
                                                        <CircularProgress
                                                            size={16}
                                                            sx={{ marginTop: "8px" }}
                                                        />
                                                    ) : file.uploadstatus === "processing" ? (
                                                        <CircularProgress
                                                            variant="determinate"
                                                            value={file.percentage}
                                                            size={16}
                                                            sx={{ marginTop: "8px" }}
                                                        />
                                                    ) : file.uploadstatus === "complete" ? (
                                                        <CheckIcon sx={{ marginTop: "8px" }} />
                                                    ) : file.uploadstatus === "failed" ? (
                                                        <SmsFailedIcon />
                                                    ) : null // Ensure to have a default value or null at the end if necessary
                                                }
                                            </Box>
                                            <IconButton
                                                onClick={() =>
                                                    handleDocumentDelete(
                                                        file.doc_id,
                                                        meetingids,
                                                        file.doc_name,
                                                        file.doc_path
                                                    )
                                                }
                                            >
                                                <CloseIcon sx={{ padding: "0px" }} />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                    </Box>
                </DialogContent>
                <DialogActions sx={{ padding: '10px 23px 15px 23px' }}>
                    <Box sx={{ display: 'flex', width: '100%' }}>
                        <Box sx={{ width: '50%', marginRight: '5px' }}>
                            <Button variant="outlined" sx={{ textTransform: 'none', width: '100%', fontWeight: 'bold', }} onClick={editEventModalClose}>Cancel</Button>
                        </Box>
                        <Box sx={{ width: '50%', marginLeft: '5px' }}>
                            <Button variant="contained" sx={{ textTransform: 'none', width: '100%', fontWeight: 'bold' }} onClick={fetchEditEvent}
                                backgroundColor={buttonLoading && '#616161'} disabled={buttonLoading}>
                                {buttonLoading && <CircularProgress size={16} sx={{ marginRight: '10px' }} />} Edit Event</Button>
                        </Box>
                    </Box>
                </DialogActions>
            </Dialog>

            <Dialog open={viewEventModal} onClose={viewEventModalClose}
                sx={{
                    "& .MuiDialog-paper": {
                        borderRadius: "10px",
                        minWidth: '600px',
                        // Add media query for smaller screens
                        '@media (max-width: 500px)': {
                            minWidth: '100%', // Set minimum width to 100% for full-screen modal on small screens
                            minHeight: '100%',
                            margin: 0, // Remove default margin
                        }
                    },
                }}
            >
                {isLoading ? (
                    <LoadingSkeleton key={index} />
                )
                    : (
                        <Box>

                            <DialogTitle sx={{ padding: '0px 5px ' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '15px 15px 0px 15px' }}>
                                    <Box sx={{ display: 'flex', margin: '10px ' }}>
                                        <Box>
                                            <AddCardOutlinedIcon sx={{ marginTop: '6px' }} />
                                        </Box>
                                        <Typography sx={{ fontWeight: 'bold', marginLeft: '10px', marginTop: '6px' }}>View Event</Typography>
                                        {meetingDetails.map((item, index) => (
                                            <Box>{item.colour &&
                                                <IconButton
                                                    sx={{
                                                        color: item.colour,
                                                        backgroundColor: item.colour,
                                                        "&:hover": { backgroundColor: item.colour },
                                                        marginLeft: '10px'
                                                    }}
                                                ></IconButton>

                                            }

                                            </Box>
                                        ))}
                                    </Box>

                                    <Box sx={{ marginTop: '5px', display: 'flex' }}>
                                        {meetingDetails.map((item, index) => (
                                            <Box key={index}>
                                                {item.allow_edit === 'yes' && (
                                                    <Box>
                                                        {/* {new Date(item.meeting_date) > new Date() &&  */}
                                                        <IconButton onClick={openEditEventModal}> <EditOutlinedIcon /></IconButton>
                                                        {/* } */}
                                                        <IconButton onClick={openDeleteModal}> <DeleteOutlinedIcon /></IconButton>
                                                    </Box>
                                                )}
                                            </Box>
                                        ))}
                                        <Box>
                                            <IconButton onClick={viewEventModalClose}> <CloseIcon /></IconButton>
                                        </Box>

                                    </Box>
                                </Box>
                            </DialogTitle>


                            <DialogContent sx={{ 
                            '@media (min-width: 500px)': {
                                maxHeight: 'calc(100vh - 200px)', overflowY: 'auto'
                            },
                            }}>
                                {meetingDetails.map((item, index) => (
                                    <Box >
                                        <Box display='flex' sx={{ marginBottom: "20px" }}>
                                            <EventNoteOutlinedIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Event Type:</Typography>
                                            <Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.meeting_type}</Typography>
                                        </Box>
                                        <Box display='flex' sx={{ marginBottom: "20px" }}>
                                            <TaskIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Agenda:</Typography>
                                            <Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.agenda}</Typography>
                                        </Box>
                                        <Box display='flex' sx={{ marginBottom: "20px" }}>
                                            <ShortTextIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                            <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Description: </Typography></Box>
                                            <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.description}</Typography></Box>
                                        </Box>

                                        <Box display='flex' sx={{ marginBottom: "20px" }}>
                                            <PersonAddIcon sx={{ color: '#0000008A', marginRight: '20px', marginTop: '5px' }} />
                                            <Box sx={{ marginBottom: "20px" }}>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', marginTop: '5px' }}>Attendees:</Typography>
                                            </Box>
                                            <Box>
                                                <List sx={{ marginLeft: '10px', padding: '0px' }}>
                                                    {item.attendees?.map((item) => (
                                                        <ListItem key={item.attendees_id} sx={{ padding: '0px' }}> {/* Make sure to include a unique key for each item */}
                                                            <ListItemAvatar sx={{ minWidth: '30px', paddingTop: '5px' }}>
                                                                <img src={item.photo} style={{ width: '20px', height: '20px', borderRadius: '50px' }} />
                                                            </ListItemAvatar>
                                                            <Typography sx={{ fontSize: '14px' }}>
                                                                {item.name}{item.within_org == 'no' && " * "}
                                                            </Typography>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Box>
                                        </Box>

                                        <Box display='flex' sx={{ marginBottom: "20px" }}>
                                            <WhatsAppIcon sx={{ color: 'white', backgroundColor: 'green', borderRadius: '50px', marginRight: '20px', marginTop: '5px' }} />
                                            <Box sx={{ marginBottom: "20px" }}>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', marginTop: '5px' }}>Notification To:</Typography>
                                            </Box>
                                            <Box>
                                                <List sx={{ marginLeft: '10px', padding: '0px' }}>
                                                    {item.whatsapp_notification_to?.map((item) => (
                                                        <ListItem key={item.attendees_id} sx={{ padding: '0px' }}> {/* Make sure to include a unique key for each item */}
                                                            <ListItemAvatar sx={{ minWidth: '30px', paddingTop: '5px' }}>
                                                                <img src={item.photo} style={{ width: '20px', height: '20px', borderRadius: '50px' }} />
                                                            </ListItemAvatar>
                                                            <Typography sx={{ fontSize: '14px' }}>
                                                                {item.name}{item.within_org == 'no' && " * "}
                                                            </Typography>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Box>
                                        </Box>
                                        <Box display='flex' sx={{ marginBottom: "20px" }}>
                                            <MeetingRoomIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Meeting Room:</Typography>
                                            <Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.meeting_room} </Typography>
                                        </Box>

                                        <Box display='flex' sx={{ marginBottom: "20px" }}>
                                            <CalendarViewDayIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Virtual Conference Link :</Typography>
                                            <Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>
                                                {item.virtual_meeting_status === 'no' ?
                                                    'No' :
                                                    item.virtual_meeting_link
                                                }
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                            <CalendarTodayIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                            <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Meeting Date:</Typography></Box>
                                            <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}> {item.meeting_date} </Typography></Box>

                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: "20px" }}>
                                            <Box sx={{ width: '100%' }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>

                                                        <Box sx={{ display: 'flex' }}>
                                                            <AccessTimeIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                                            <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Start Time:</Typography></Box>
                                                            <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}> {item.start_time} </Typography></Box>

                                                        </Box>

                                                    </Grid>
                                                    <Grid item xs={6}>

                                                        <Box sx={{ display: 'flex' }}>
                                                            <AccessTimeIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                                            <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>End Time:</Typography></Box>
                                                            <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.end_time} </Typography></Box>

                                                        </Box>

                                                    </Grid>
                                                </Grid>
                                            </Box>

                                        </Box>
                                        <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                            <ListIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                            <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Reference to Red List Activity: </Typography></Box>
                                            <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px', }}  > {item.redlist_agenda ? <Typography onClick={(e) => openViewAddRedListModal(item.redlist_id)} sx={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '14px' }}> {item.redlist_agenda} </Typography> : 'N/A'} </Typography></Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                            <FilePresentIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                            <Box>
                                                <Box>
                                                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>Document: </Typography>

                                                </Box>
                                                <List>
                                                    {item.document.map((file, docindex) => (
                                                        <Box key={docindex} sx={{
                                                            backgroundColor: '', cursor: 'pointer', '&:hover': {
                                                                textDecoration: 'underline',// Set the hover color
                                                                color: '#2196F3'
                                                            }, margin: '0px 0px 10px 0px ', overflow: 'auto'
                                                        }}>

                                                            <Box display='flex' justifyContent='space-between' alignItems='center' padding='10px'>
                                                                <Box display='flex' alignItems='center'>
                                                                    <Box> {file.doc_name.endsWith('.pdf') && <DescriptionIcon sx={{ color: 'red', margin: '0 10px 0 0' }} />}
                                                                        {file.doc_name.endsWith('.xls') && <DescriptionIcon sx={{ color: 'green', margin: '0 10px 0 0' }} />}
                                                                        {file.doc_name.endsWith('.xlsx') && <DescriptionIcon sx={{ color: 'green', margin: '0 10px 0 0' }} />}
                                                                        {file.doc_name.endsWith('.doc') && <DescriptionIcon sx={{ color: 'blue', margin: '0 10px 0 0' }} />}
                                                                        {file.doc_name.endsWith('.docx') && <DescriptionIcon sx={{ color: 'blue', margin: '0 10px 0 0' }} />}
                                                                        {file.doc_name.endsWith('.ppt') && <DescriptionIcon sx={{ color: '#FFA500', margin: '0 10px 0 0' }} />}
                                                                        {file.doc_name.endsWith('.pptx') && <DescriptionIcon sx={{ color: '#FFA500', margin: '0 10px 0 0' }} />}
                                                                    </Box>
                                                                    <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px', fontWeight: 'bold' }} onClick={() => RedListDocviewPreview(docindex, file.doc_id, file.doc_name, file.doc_path, item.meeting_id)}>{file.doc_name} {LoadingviewStates[docindex] && <CircularProgress size={18} sx={{ marginRight: '12px' }} />}</Box>
                                                                </Box>

                                                                <Box sx={{ fontSize: '12px' }}>
                                                                    <Typography sx={{ fontSize: '13px', marginLeft: '10px' }}>{file.doc_size}</Typography>
                                                                </Box>
                                                            </Box>

                                                        </Box>
                                                    ))}
                                                </List>
                                            </Box>
                                        </Box>
                                    </Box>
                                ))}
                            </DialogContent>
                        </Box>
                    )}
            </Dialog>
            <Dialog fullWidth maxWidth="sm" open={viewRedList} onClose={closeViewRedListModal}>

                {isLoading ? (

                    <DialogContent >
                        <LoadingSkeleton />
                    </DialogContent>
                ) : (
                    userData.map((item, index) => (
                        <div key={index}>
                            <DialogTitle sx={{ padding: '0px 5px ' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '15px 15px 0px 15px' }}>
                                    <Box sx={{ display: 'flex', margin: '10px ' }}>
                                        <Box>
                                            <AddCardOutlinedIcon sx={{}} />
                                        </Box>
                                        <Typography sx={{ fontWeight: 'bold', marginLeft: '10px' }}>View Red List</Typography>
                                    </Box>
                                    <Box display='flex'>
                                        {/* {item.allow_edit === 'yes' && (
                            <Box>
                                <IconButton onClick={() => handleEditClick(item)}> <EditIcon /></IconButton>
                                <IconButton onClick={() => handleOpen(item.redlist_id)} > <DeleteIcon /></IconButton>
                            </Box>
                        )} */}
                                        <Box>
                                            <IconButton onClick={closeViewRedListModal}> <CloseIcon /></IconButton>
                                        </Box>
                                    </Box>
                                </Box>
                            </DialogTitle>

                            <DialogContent sx={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto', }}>

                                <Box >
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <WorkIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Project Name:</Typography></Box>
                                        <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.project_name}</Typography></Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <TaskIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Agenda:</Typography></Box>
                                        <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.agenda}</Typography></Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <ShortTextIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Description: </Typography></Box>
                                        <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.description}</Typography></Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <PersonAddIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Assign To:</Typography></Box>

                                        <Box><Typography sx={{ fontSize: '14px', marginLeft: '10px' }}> {item.assignto_name}</Typography>

                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <NotificationsIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>{"Notification To:"}</Typography></Box>
                                        <List sx={{ marginLeft: '10px', padding: '0px' }}>
                                            {item.notificationto.map((item, index) => (
                                                <ListItem key={item.attendees_id} sx={{ padding: '0px' }}> {/* Make sure to include a unique key for each item */}
                                                    <ListItemAvatar sx={{ minWidth: '30px', paddingTop: '5px' }}>
                                                        <img src={item.photo} style={{ width: '20px', height: '20px', borderRadius: '50px' }} />
                                                    </ListItemAvatar>
                                                    <Typography sx={{ fontSize: '14px' }}>
                                                        {item.name}
                                                    </Typography>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Box>

                                    <Box sx={{ marginBottom: "20px", display: 'flex' }}>
                                        <CalendarTodayIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>Activity Start:</Typography></Box>
                                        <Box>
                                            <Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.start_datetime}</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ marginBottom: "20px", display: 'flex' }}>
                                        <CalendarTodayIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box><Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Activity End:</Typography></Box>
                                        <Box>
                                            <Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{item.end_datetime}</Typography>
                                        </Box>
                                    </Box>


                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <HourglassTopIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box>
                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Status: </Typography>

                                        </Box>
                                        <Box sx={{ display: 'flex', marginLeft: '10px' }}>
                                            {item.status == 'new' &&
                                                <Chip label='New' size="small" color="primary" />
                                            }
                                            {item.status == 'inprogress' &&
                                                <Chip label='In Progress' size="small" color="warning" />
                                            }
                                            {item.status == 'complete' &&
                                                <Chip label='Complete' size="small" color="success" />
                                            }
                                            {item.status == 'hold' &&
                                                <Chip label='Hold' size="small" color="error" />
                                            }
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <ShowChartIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box>
                                            <Box>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', }}>Milestones: </Typography>

                                            </Box>
                                            <Box sx={{ display: 'flex', fontSize: '14px', marginTop: '10px' }}>
                                                <Box sx={{ display: 'flex' }}>
                                                    {item.milestone && item.milestone.length > 0 ? (

                                                        <TableContainer >

                                                            <Table size="small" aria-label="a dense table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell sx={{ backgroundColor: 'rgba(0, 0, 0, 0.00)', fontWeight: 'bold' }} > Description</TableCell>
                                                                        <TableCell sx={{ backgroundColor: 'rgba(0, 0, 0, 0.00)', fontWeight: 'bold' }}>Due Date</TableCell>
                                                                        <TableCell sx={{ backgroundColor: 'rgba(0, 0, 0, 0.00)', fontWeight: 'bold' }} >Assign To</TableCell>
                                                                        {/* <th style={{ border: '1px solid #dddddd', padding: '8px' }}>photo</th> */}
                                                                        <TableCell sx={{ backgroundColor: 'rgba(0, 0, 0, 0.00)', fontWeight: 'bold' }}>Status</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {item.milestone.map((milestone, index) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell >{milestone.milestone_description}</TableCell>
                                                                            <TableCell >{milestone.due_date}</TableCell>

                                                                            <TableCell >
                                                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>

                                                                                    <Avatar src={milestone.assign_to_photo} alt={milestone.assign_to_name} sx={{ height: 25, width: 25, right: 5 }} />
                                                                                    <Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{milestone.assign_to_name}</Typography>
                                                                                </Box>
                                                                            </TableCell>
                                                                            {/* <td style={{ border: '1px solid #dddddd', padding: '8px' }}>  </td> */}
                                                                            <TableCell >{milestone.status}</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>

                                                    ) : (

                                                        <Typography sx={{ textAlign: 'center' }} variant="body1">N/A</Typography>

                                                    )}
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginBottom: "20px" }}>
                                        <FilePresentIcon sx={{ color: '#0000008A', marginRight: '20px' }} />
                                        <Box>
                                            <Box>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>Document: </Typography>

                                            </Box>
                                            <List>
                                                {item.document.map((file, docindex) => (
                                                    <Box key={docindex} sx={{ backgroundColor: '', margin: '0px 0px 10px 0px ', overflow: 'auto' }}>

                                                        <Box display='flex' justifyContent='space-between' alignItems='center' padding='10px'>
                                                            <Box display='flex' alignItems='center'>
                                                                <Box>
                                                                    {file.doc_name.endsWith('.pdf') && <DescriptionIcon sx={{ color: 'red', margin: '0 10px 0 0' }} />}
                                                                    {file.doc_name.endsWith('.xls') && <DescriptionIcon sx={{ color: 'green', margin: '0 10px 0 0' }} />}
                                                                    {file.doc_name.endsWith('.xlsx') && <DescriptionIcon sx={{ color: 'green', margin: '0 10px 0 0' }} />}
                                                                    {file.doc_name.endsWith('.doc') && <DescriptionIcon sx={{ color: 'blue', margin: '0 10px 0 0' }} />}
                                                                    {file.doc_name.endsWith('.docx') && <DescriptionIcon sx={{ color: 'blue', margin: '0 10px 0 0' }} />}
                                                                    {file.doc_name.endsWith('.ppt') && <DescriptionIcon sx={{ color: '#FFA500', margin: '0 10px 0 0' }} />}
                                                                    {file.doc_name.endsWith('.pptx') && <DescriptionIcon sx={{ color: '#FFA500', margin: '0 10px 0 0' }} />}
                                                                </Box>
                                                                <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px', fontWeight: 'bold' }}
                                                                //  onClick={() => RedListDocviewPreview(docindex, file.doc_id, file.doc_name, file.doc_path, item.redlist_id)}
                                                                >{file.doc_name} {LoadingviewStates[docindex] && <CircularProgress size={18} sx={{ marginRight: '12px' }} />}</Box>
                                                            </Box>

                                                            <Box sx={{ fontSize: '12px' }}>
                                                                <Typography sx={{ fontSize: '13px', marginLeft: '10px' }}>{file.doc_size}</Typography>
                                                            </Box>
                                                        </Box>

                                                    </Box>
                                                ))}
                                            </List>
                                        </Box>
                                    </Box>
                                    {/* <Box>
            <Box>
                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'grey' }}>Document : </Typography>

                </Box> 

             <Box>
{item.document.includes(',') ? (
// If item.document contains a comma, split it and map over the array
item.document.split(',').map((document, index) => (
<Box key={index} sx={{ backgroundColor: '#f5f3f7', border: '1px solid #74777f', borderRadius: '10px', margin: '0px 0px ', maxHeight: '60px' }}>
<Box display='flex' flexGrow={1} sx={{ justifyContent: 'space-between' }}>
    <Box display='flex'>
        <Box><PictureAsPdfIcon sx={{ color: "red", margin: '10px', height: '35px', width: '35px' }} /></Box>
        <Box sx={{ marginTop: '10px' }}>
            <Box sx={{ fontSize: '14px', fontWeight: 'bold', maxWidth: '120px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={document}>{document}</Box>
            
            <Box sx={{ fontSize: '12px' }}>
                <Typography sx={{ fontSize: '13px' }}>{'100'}kb</Typography>
            </Box>
        </Box>
    </Box>
    
    <Box sx={{ margin: '18px 10px 10px 10px' }}>
    </Box>
</Box>
</Box>
))
) : (
// If item.document does not contain a comma, render a single Box
<Box sx={{ backgroundColor: '#f5f3f7', border: '1px solid #74777f', borderRadius: '10px', margin: '0px 0px ', maxHeight: '60px' }}>
<Box display='flex' flexGrow={1} sx={{ justifyContent: 'space-between' }}>
<Box display='flex'>
    <Box><PictureAsPdfIcon sx={{ color: "red", margin: '10px', height: '35px', width: '35px' }} /></Box>
    <Box sx={{ marginTop: '10px' }}>
        <Box sx={{ fontSize: '14px', fontWeight: 'bold', maxWidth: '120px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={item.document}>{item.document}</Box>
        
        <Box sx={{ fontSize: '12px' }}>
            <Typography sx={{ fontSize: '13px' }}>{'100'}kb</Typography>
        </Box>
    </Box>
</Box>

<Box sx={{ margin: '18px 10px 10px 10px' }}>
</Box>
</Box>
</Box>
)}
</Box> 



            </Box> */}
                                </Box>

                            </DialogContent>
                            {/* <DialogActions>
    <Box sx={{ display: 'flex', width: '100%' }}>
        <Box sx={{ width: '50%', marginRight: '5px' }}>
            <Button variant="outlined" sx={{ textTransform: 'none', width: '100%',fontWeight:'bold' }} onClick={closeViewRedListModal}>Cancel</Button>
        </Box>
        <Box sx={{ width: '50%', marginLeft: '5px' }}>
            <Button variant="contained" sx={{ textTransform: 'none', width: '100%',fontWeight:'bold' }}>Update Red List</Button>
        </Box>
    </Box>
</DialogActions> */}
                        </div>
                    ))
                )}
            </Dialog>
            <Dialog open={pdfDialogOpen} onClose={handleClosePdfDialog} fullScreen>
                <DialogTitle>
                    <Button variant="none" sx={{ textTransform: "none", fontWeight: "bold", marginLeft: '30px' }} onClick={handleClosePdfDialog} size="small">
                        <ArrowBackIcon /> Go back
                    </Button>
                </DialogTitle>
                <DialogContent>
                    <iframe
                        src={pdfUrl}
                        title="PDF Preview"
                        width="100%"
                        height="100%"
                    ></iframe>
                </DialogContent>
                {/* <DialogActions>
                    <Button variant="outlined" sx={{ textTransform: "none", fontWeight: "bold", marginRight: '30px' }} onClick={handleClosePdfDialog} size="small">
                        Close
                    </Button>

                </DialogActions> */}
            </Dialog>
            <Dialog open={isDeleteModal} onClose={closeDeleteModal} >
                <DialogTitle sx={{ paddingBottom: '0px', paddingTop: '32px', marginBottom: '0px' }}>Delete Confirmation</DialogTitle>
                <DialogContent sx={{ width: '500px' }}>
                    Are you sure you want to delete this meeting event?
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteModal} color="primary">
                        Cancel
                    </Button>
                    {/* <Button color="primary" onClick={handleDelete}>
                                        Apply
                                    </Button> */}
                    <Button onClick={deleteMeetingDetails} backgroundColor={buttonLoading && '#616161'} disabled={buttonLoading}>
                        {buttonLoading && <CircularProgress size={16} sx={{ marginRight: '10px' }} />} Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={confirmOpen}
                onClose={closeConfirmModal}
                aria-labelledby="confirm-modal"
                aria-describedby="confirm-modal-description"
            >
                <DialogTitle id="confirm-modal">Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-modal-description">
                        Are you sure you want to delete this item? This action cannot be
                        undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>

                    <Button

                        sx={{ textTransform: "none", fontWeight: "bold" }}
                        onClick={closeConfirmModal}
                    >
                        Cancel
                    </Button>
                    <Button

                        sx={{ textTransform: "none", fontWeight: "bold" }}
                        disabled={deleteloading}
                        onClick={confirmDelete}
                    >
                        {" "}
                        {deleteloading && (
                            <CircularProgress size={18} sx={{ marginRight: "12px" }} />
                        )}
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={closeConfirmation}
                onClose={closeCloseConfirm}
                aria-labelledby="confirm-modal"
                aria-describedby="confirm-modal-description"
            >
                <DialogTitle id="confirm-modal">Close Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-modal-description">
                        Are you sure you want to close this modal? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={closeCloseConfirm} color="primary">
                        Cancel
                    </Button>

                    <Button onClick={handleCloseConfirm} >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isConfirmDialogOpen}
                onClose={closeConfirmDialog}
                aria-labelledby="confirm-dialog"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="confirm-dialog">Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        Are you sure you want to delete this item? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeConfirmDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmcreateDelete} color="primary" disabled={isDeleteLoading}>
                        {isDeleteLoading && <CircularProgress size={16} sx={{ marginRight: '10px' }} />} Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                mode="outlined"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center', // Center bottom
                }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <SnackbarContent
                    style={{
                        backgroundColor: 'black',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                    message={
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            {toastmessage}
                        </span>
                    }
                    action={[
                        <IconButton key="close" color="inherit" onClick={handleSnackbarClose}>
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </Snackbar>

            <Dialog
                open={openDialogRoom}
                onClose={handleCloseDialogRoom}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Create New Meeting Room</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="New Meeting Room"
                        value={isNewDesignation}
                        onChange={(e) => setIsNewDesignation(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialogRoom} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleCreateNewDesignation} disabled={!isNewDesignation.trim()} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

function CalenderReviewMeeting(props) {
    const { windows } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <Sidebar />
        </div>
    );

    const container =
        windows !== undefined ? () => window().document.body : undefined;

    const [departmentbutton, setDepartmentbutton] = React.useState(false);
    const handlebutton = () => {
        setDepartmentbutton(!departmentbutton);
    };



    // Function to handle saving edited event





    //   const tableContainerRef = useRef(null);
    //   useEffect(() => {
    //     const tableContainer = tableContainerRef.current;

    //     // Check if tableContainerRef.current is not null before adding event listener
    //     if (tableContainer) {
    //       const handleScroll = () => {
    //         // Check if the user has scrolled to the bottom of the table container
    //         if (
    //           tableContainer.scrollTop + tableContainer.clientHeight >=
    //           tableContainer.scrollHeight
    //         ) {
    //           // Trigger the load more function when scrolled to the bottom
    //           handleLoadMore();
    //         }
    //       };

    //       // Add scroll event listener to the table container
    //       tableContainer.addEventListener('scroll', handleScroll);

    //       return () => {
    //         // Remove scroll event listener on component unmount
    //         tableContainer.removeEventListener('scroll', handleScroll);
    //       };
    //     }
    //   }, [handleLoadMore, tableContainerRef]);

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    backgroundColor: "white",
                }}
            >
                <Appbar />
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                            borderRight: '0px',
                        },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: "none", sm: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                            borderRight: '0px',
                        },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,

                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    background: "#EFF1F3",
                    minHeight: "100vh",
                }}
            >


                <div className="cmd_main">
                    <MyCalendar className="cmd_calender" />
                </div>
            </Box>

            {/* <AppBar
                position="fixed"
                sx={{
                    backgroundColor: 'white',
                    top: "auto",
                    bottom: 0,
                    width: `calc(100% - ${drawerWidth}px)`,
                    ml: `${drawerWidth}px`,
                }}
            >
                <Typography component="div"
                    sx={{ flexGrow: 1, textAlign: 'right', color: '#575E71', padding: '10px 20px', fontSize: '12px' }}
                    className="cmd_footer"
                >
                    Powered by <span style={{ fontWeight: 'bold', fontSize: '13px' }}>ZeonAI Labs</span>
                </Typography>

            </AppBar> */}
            <Footer />
        </Box>
    );
}

export default CalenderReviewMeeting;
