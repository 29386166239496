import React,{ useState } from 'react'; 
import Box from '@mui/material/Box';
import {CssBaseline , AppBar , Drawer} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Sidebar from '../../Includes/Menu';
import Appbar from '../../Includes/Appbar';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
//changes
//changes
const CapabilitiesAndLimitations = (props) =>{
  const [show, setShow] = useState(false);
  const handleNewQueryClick = () => {
    console.log('New Query Clicked');
   
  };
  const { windows } = props;
  const drawerWidth = 322;
  const drawer = (
    <div>
     <Sidebar onNewQueryClick={handleNewQueryClick} />
    </div>
  );
  const container =
    windows !== undefined ? () => window().document.body : undefined;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    return(
        <>
        <Box sx={{ display: 'flex' }} >
        <CssBaseline />
        <AppBar
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            backgroundColor: "white",
          }}
        >
          <Appbar />
        </AppBar>

        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            // onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
        
          sx={{ flexGrow: 10, bgcolor: '#eff1f3', p: 3 ,minHeight:'100vh'}}
        >
          <Toolbar />
          <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: '20px 0px', textAlign: 'left' }}>
          <Typography variant='h5' sx={{margin:'5px',padding:'5px',marginLeft:'0px'}}>
          Capabilities
            </Typography>
          
          <div className="rectangle-2 " variant='h6' sx={{ color: 'rgba(0, 0, 0, 0.6);' }}style={{padding:'20px'}} >
          <div style={{display:'flex'}}>
          <div> 
            <IconButton><ArrowRightIcon /> </IconButton></div>
            <div style={{marginTop:'10px'}}>Ability to context of user query and generate appropriate answer.</div>
          </div>
          <div style={{display:'flex'}}>
          <div> <IconButton><ArrowRightIcon /> </IconButton> </div>
          <div style={{marginTop:'10px'}}>Provisions for document types like pdf,word,scanned doc,excel to be uploaded.</div>
          </div>
          <div style={{display:'flex'}}>
          <div> <IconButton><ArrowRightIcon /> </IconButton> </div>
          <div style={{marginTop:'10px'}}>Mitigates hallucination in generated answers through advanced NLP techniques.</div>
          </div>
          <div style={{display:'flex'}}>
          <div> <IconButton><ArrowRightIcon /> </IconButton> </div>
          <div style={{marginTop:'10px'}}>Allows user feedback on generated answers to further enhance search accuracy.</div>
          </div>
          </div>
          <Typography variant='h5'sx={{margin:'5px',padding:'5px',marginLeft:'0px'}}>
            Limitations
            </Typography>
          <div className="rectangle-2" sx={{ color: 'rgba(0, 0, 0, 0.6);' }} style={{padding:'20px'}} >
          <div style={{display:'flex'}}>
          <div> <IconButton><ArrowRightIcon  />  </IconButton> </div>
          <div style={{marginTop:'10px'}}>Limited memory of previous interactions. To be enhanced in future upgrades.</div>
          </div>
          <div style={{display:'flex'}}>
          <div> <IconButton><ArrowRightIcon  />  </IconButton> </div>
          <div style={{marginTop:'10px'}}>Further scope for improvement in reasoning capabilities.</div>
          </div>
          <div style={{display:'flex'}}>
          <div><IconButton><ArrowRightIcon  />  </IconButton></div>
          <div style={{marginTop:'10px'}}>Generative ability based on NLP currently restricted to text based answers.</div>
          </div>
          <div style={{display:'flex'}}> 
          <div> <IconButton><ArrowRightIcon  />  </IconButton> </div>
          <div style={{marginTop:'10px'}}> Image generation is out of scope on this platform.</div> 
          </div>
         
          </div>
        </Box>
        </Box>
      </Box>
        </>
    )
};
export default CapabilitiesAndLimitations;