
export const getAllMeeting = async (user_id, userorg_id,searchstring,dateList,fromDate,toDate ,token) => {
  try {
    const apiEndpoint = "cmdadmin/getallmeetings";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      start_index: 0,
      limit: 15,
      meeting_type:'meeting',
      searchstring: searchstring,
      date: {
        type: "1",
        value: dateList,
      },
    };
    if (fromDate !== "" && toDate !== "") {
      requestData.date = {
        type: "2",
        value: `${fromDate},${toDate}`,
      };
    }
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const getCalenderMeeting = async (user_id, userorg_id,month,year ,token) => {
  try {
    const apiEndpoint = "cmdadmin/getcalendermeetings";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      start_index: 0,
      limit: 1000,
      month: month,
      year: year,
      
    };
  
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const getAllAttendees = async (user_id, userorg_id,type,deptId,token) => {
  try {
    const apiEndpoint = "cmdadmin/getallattendees";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      start_index: 0,
      limit: 1000,
      type: type,
      dept_id: deptId,
     
    };
   
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const getAllAttendeesWithGuestUser = async (user_id, userorg_id,type,deptId,token) => {
  try {
    const apiEndpoint = "cmdadmin/getallattendeeswithguest";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      start_index: 0,
      limit: 1000,
      type: type,
      dept_id: deptId,
     
    };
   
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const getMeetingRooms = async (user_id, userorg_id,token) => {
  try {
    const apiEndpoint = "cmdadmin/getallmeetingroom";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      start_index: 0,
      limit: 1000,
      
    };
  
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const AddMeetingEvent = async (
  user_id, userorg_id,agenda,description,attendees,whatsapp_notification_to,meeting_room,virtual_meeting_status,virtual_meeting_link,redlist_id,meeting_date,start_time,end_time,meeting_color,Docpath,uploadFiles,meeting_type,token
  ) => {
  try {
    const apiEndpoint = "cmdadmin/schedulemeeting";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      start_index: 0,
      limit: 1000,
      agenda: agenda,
      description: description,
      attendees: attendees,
      whatsapp_notification_to:whatsapp_notification_to,
      meeting_room: meeting_room,
      virtual_meeting_status:virtual_meeting_status,
      virtual_meeting_link:virtual_meeting_link,
      redlist_id:redlist_id,
      meeting_date: meeting_date,
      start_time: start_time,
      end_time: end_time,
      meeting_color: meeting_color,
      doc_path:Docpath,
        docs:uploadFiles,
        meeting_type:meeting_type,
    };
   
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const AddGuestUser = async (
  user_id, userorg_id,name,email,phone,token
  ) => {
  try {
    const apiEndpoint = "cmdadmin/addguestuser";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      start_index: 0,
      limit: 1000,
      name: name,
      email: email,
      phone: phone,
     
    };
   
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const GuestUserAutocomplete = async (
  user_id, userorg_id,searchstring,token
  ) => {
  try {
    const apiEndpoint = "cmdadmin/guestuserautocomplete";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      start_index: 0,
      limit: 1000,
      searchstring: searchstring,
      
     
    };
   
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const getAllRedList = async (user_id, userorg_id,token) => {
  try {
    const apiEndpoint = "cmdadmin/getallredlist";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      serach_text:"",
      date: {
        type: "",
        value: ""
    },
 
      start_index: 0,
      limit: 1000,
      
    };
  
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const getMeetingDetails = async (user_id, userorg_id,meeting_id,token) => {
  try {
    const apiEndpoint = "cmdadmin/getmeetingbyid";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      meeting_id:meeting_id,
      start_index: 0,
      limit: 1000,
      
    };
  
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const deleteMeetingById = async (user_id, userorg_id,meeting_id,token) => {
  try {
    const apiEndpoint = "cmdadmin/deletemeeting";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      meeting_id:meeting_id,
      start_index: 0,
      limit: 1000,
      
    };
  
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const EditMeetingEvent = async (
  user_id, userorg_id,meeting_id,agenda,description,attendees,whatsapp_notification_to,meeting_room,virtual_meeting_status,virtual_meeting_link,redlist_id,meeting_date,start_time,end_time,meeting_color,uploadFiles,meeting_type,token
  ) => {
  try {
    const apiEndpoint = "cmdadmin/editmeeting";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      start_index: 0,
      limit: 1000,
      meeting_id:meeting_id,
      agenda: agenda,
      description: description,
      attendees: attendees,
      whatsapp_notification_to:whatsapp_notification_to,
      meeting_room: meeting_room,
      virtual_meeting_status:virtual_meeting_status,
      virtual_meeting_link:virtual_meeting_link,
      redlist_id:redlist_id,
      meeting_date: meeting_date,
      start_time: start_time,
      end_time: end_time,
      meeting_color: meeting_color,
        docs:uploadFiles,
        meeting_type:meeting_type,
    };
   
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const RedListCreatetemPath = async (user_id, userorg_id,token) => {
  try {
    const apiEndpoint = "cmdadmin/createtempdocpath";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
    

    };
  
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const RedListDocument = async (user_id, userorg_id,redlistid,docname,docid,docpath,token) => {
  try {
    const apiEndpoint = "cmdadmin/downloadactivitydoucment";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      activity_id:redlistid,
      doc_name:docname,
      document_id:docid,
       doc_path:docpath,
       activity_type: "meeting",
    

    };
  
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const RedListDocumentDeleteById = async (user_id, userorg_id,redlistid,docname,docid,docpath,token) => {
  try {
    const apiEndpoint = "cmdadmin/deleteactivitydocument";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      activity_id:redlistid,
      doc_name:docname,
      document_id:docid,
       doc_path:docpath,
       activity_type: "meeting",
    

    };
  
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const getGeneralInfo = async (user_id, userorg_id,dept_id,token) => {
  try {
    const apiEndpoint = "cmdadmin/getgeneralinfo";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      dept_id:dept_id,
      start_index: 0,
      limit: 1000,
      
    };
  
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const commentToNodalOfficer = async (user_id, userorg_id,dept_id,nodal_officer,description,token) => {
  try {
    const apiEndpoint = "cmdadmin/sendnotificationtonodalofficer";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      dept_id:dept_id,
      nodal_officer:nodal_officer,
      description:description,
      start_index: 0,
      limit: 1000,
      
    };
  
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const getAllCMDDeptAndArea = async (user_id, userorg_id,token) => {
  try {
    const apiEndpoint = "admin/getalldepartmentandarea";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      
    };
  
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const AddMeetingRoom = async (
  user_id, userorg_id,name,token
  ) => {
  try {
    const apiEndpoint = "cmdadmin/addmeetingroom";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
    
      room_name: name,
     
    };
   
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const InstantDocumentDelete = async (deepuser_id, userorg_id,docpath,docname,token) => {
  try {
    const apiEndpoint = "cmdadmin/delete_instant_upload_file";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id:deepuser_id,
      doc_name:docname,
       doc_path:docpath,
       activity_type: "critical",
    

    };
  
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const getMoreMeeting = async (user_id, userorg_id,searchstring,dateList,fromDate,toDate ,index,token,) => {
  try {
    const apiEndpoint = "cmdadmin/getallmeetings";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      start_index: index,
      limit: 15,
      meeting_type:'meeting',
      searchstring: searchstring,
      date: {
        type: "1",
        value: dateList,
      },
    };
    if (fromDate !== "" && toDate !== "") {
      requestData.date = {
        type: "2",
        value: `${fromDate},${toDate}`,
      };
    }
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const validateShortLinkToken = async (token) => {
  try {
      const apiEndpoint = "validateshortlinktoken";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}?token=${token}`;

      const response = await fetch(API_URL, {
          method: "GET",
          headers: {
              "Content-Type": "application/json",
          },
      });

      const data = await response.json();
      return data;
  } catch (error) {
      console.error("API request error:", error);
      throw error;
  }
};
export const getShortLinkDoc = async (docname,docid,docpath,token) => {
  try {
    const apiEndpoint = "shortlink-doucment-previewurl";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      doc_name:docname,
      document_id:docid,
       doc_path:docpath,
       token:token
    };
    
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        // token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
