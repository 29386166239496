import React, { useState, useEffect, useRef } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer } from 'recharts';
import { Skeleton } from "@mui/material";
const data = [
  { name: 'Group 1', 'Series 1': 400, 'Series 2': 300,  },
  { name: 'Group 2', 'Series 1': 500, 'Series 2': 100,  },
  { name: 'Group 3', 'Series 1': 300, 'Series 2': 600, },
  { name: 'Group 1', 'Series 1': 400, 'Series 2': 300,  },
  { name: 'Group 2', 'Series 1': 500, 'Series 2': 100,  },
  { name: 'Group 3', 'Series 1': 300, 'Series 2': 600, },
  { name: 'Group 1', 'Series 1': 400, 'Series 2': 300,  },
  { name: 'Group 2', 'Series 1': 500, 'Series 2': 100,  },
  { name: 'Group 3', 'Series 1': 300, 'Series 2': 600, },
];

const CustomXAxisTick = ({ x, y, payload }) => {
  return (
    <text x={x} y={y} dy={16} textAnchor="middle" fill="#666">
      {payload.value}
    </text>
  );
};
const style = {
  top: '0',
  right: 0,
  transform: 'translate(0, -50%)',
  lineHeight: '24px',
};

const VerticalLineSkeleton = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'flex-end', height: '320px' }}>
    {/* Vertical Line Skeletons */}
    <Skeleton variant="rectangular" width={20} height="60%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="70%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={10} height="80%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="50%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="100%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
   
    <Skeleton variant="rectangular" width={20} height="60%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="70%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="80%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="50%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="100%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="90%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="50%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="80%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="70%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="60%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="20%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="40%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="85%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="50%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
    <Skeleton variant="rectangular" width={20} height="25%" style={{ marginRight: '8px', backgroundColor: '#e0e0e0' }} />
   
  </div>
  );
};
const GroupedBarChart = () => {
  const [loading, setLoading] = useState(false);
  return (
    <ResponsiveContainer  height={350}>
       {loading ? (
        <VerticalLineSkeleton />
      ) : (
    <BarChart
      width={500}
      height={350}
      data={data}
      margin={{ top: 10, right: 20,left:0, bottom: 30 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <YAxis />
      <Tooltip />
      <Legend verticalAlign="top"  wrapperStyle={style}/>
      <Bar dataKey="Series 1" fill="#D500F9" />
      <Bar dataKey="Series 2" fill="#2196F3" />
     
      <XAxis 
       
        dataKey="name"
        tick={<CustomXAxisTick />}
      />
    </BarChart>
      )}
</ResponsiveContainer>
  );
};

export default GroupedBarChart;
