import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import mcl_logo from '../../../mcl-logo.png';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PlaceIcon from '@mui/icons-material/Place';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Typography, Link } from "@mui/material";
import logo from '../../../image 1.png';
import favicon from '../../../soochna kosh-black.png';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import './Include.css'
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import Logout from '@mui/icons-material/Logout';
import LockIcon from '@mui/icons-material/Lock';
import PeopleIcon from '@mui/icons-material/People';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import BusinessIcon from '@mui/icons-material/Business';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
const drawerWidth = 220;
import { getAllCmdDepartment } from "../../AdminConsole/AdminApiHelper";
import { getAllCMDDeptAndArea } from "../CmdApiHelper";
export default function Sidebar() {

    const pathName = useLocation().pathname;
    const location = useLocation()
    const [isHovering, setIsHovering] = useState(false);
    const StyledListMiddleDrawer1 = styled(List)`
    padding: 0;
    height: 100%;
    overflow: hidden;

    /* Define custom scrollbar styles */
    ::-webkit-scrollbar {
        width: 1px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 5px;
    }

    /* Hide scrollbar by default */
    ::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, 0.5);
    }
`;

    const StyledListMiddleDrawer2 = styled(List)`
padding: 0;
height: 100%;
overflow: hidden;

/* Define custom scrollbar styles */
::-webkit-scrollbar {
    width: 1px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

/* Hide scrollbar by default */
::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.5);
}
`;

    const StyledList = styled(List)({
        // "&& .Mui-selected, && .Mui-selected:hover": {
        //   backgroundColor: "#30344A",
        //   "&, & .MuiListItemIcon-root": {
        //     color: "#4361EE",
        //   },
        // },
        "& .MuiListItemButton-root:hover": {
            color: "#2F80ED",
            width: '100%',

            //   borderLeft:"2px solid #2F80ED",
            "&, & .MuiListItemIcon-root": {
                color: "#2F80ED",
            },
            "&, & .MuiListItemText-root": {
                color: "#2F80ED",
            },
        },
        "& .MuiListItemText-root": {
            color: "#606060",
        },
        // "li.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding.css-1p823my-MuiListItem-root": {
        //     padding: '0px 0px 0px 16px',
        // },
        // '& .MuiListItemIcon-root.css-cveggr-MuiListItemIcon-root': {
        //     minWidth: '35px'
        // },
        // '& .MuiPaper-root-MuiDrawer-paper': {
        //     border: '0px solid grey'
        // }
    });
    const handleMouseEnterMiddleDrawer1 = () => {
        // Show scrollbar on mouse enter
        const list = document.querySelector('.middle_drawer1');
        list.style.overflowY = 'auto';
    };
    const handleMouseEnterMiddleDrawer2 = () => {
        // Show scrollbar on mouse enter
        const list = document.querySelector('.middle_drawer2');
        list.style.overflowY = 'auto';
    };
    const handleMouseLeaveMiddleDrawer1 = () => {
        // Hide scrollbar on mouse leave
        const list = document.querySelector('.middle_drawer1');
        list.style.overflowY = 'hidden';
    };
    const handleMouseLeaveMiddleDrawer2 = () => {
        // Hide scrollbar on mouse leave
        const list = document.querySelector('.middle_drawer2');
        list.style.overflowY = 'hidden';
    };
    const handleMouseLeave = () => {
        setIsHovering(false);
    };
    const handleMouseEnter = () => {
        setIsHovering(true);
    };
    const isActive = (route) => {

        return pathName.substring(1) === "cmd-dashboard/" + route;
    };
    const [usertoken, setusertoken] = useState('');
    const [user_id, setuser_id] = useState('');
    const [userorg_id, setuserorg_id] = useState('');
    const [userType, setUserType] = useState('');
    const updateUserDataFromLocalStorage = () => {
        const storedUserData = localStorage.getItem('userdata');
        if (storedUserData) {
            const userDataObj = JSON.parse(storedUserData);
            const { token: userToken, user_id: deepuser_id, org_id: deeporg_id, user_priviledge: user_priviledge } = userDataObj;

            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            setUserType(user_priviledge)
        }
    };
    useEffect(() => {
        updateUserDataFromLocalStorage();
    }, []);

    const [deptSearchResults, setDeptSearchResults] = useState([]);
    const [areaSearchResults, setAreaSearchResults] = useState([]);
    const fetchData = async () => {

        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
            } = userDataObj;

            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            const data = await getAllCMDDeptAndArea(deepuser_id, deeporg_id, userToken);
            if (data.code == "200") {
                setDeptSearchResults(data.department);
                setAreaSearchResults(data.area);
                console.log('data1sidebar', data.data)
            } else {

            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            // setIsLoading(false);
        }
    };

    const [deptClicked, setDeptClicked] = useState(false)
    const handleDeptClick = () => {

        setDeptClicked(true)
        setAreaClicked(false)
        console.log('dept', deptClicked)
        fetchData()
    }
    const handleDeptRevert = () => {

        setDeptClicked(false)
        console.log('dept', deptClicked)
    }
    const [areaClicked, setAreaClicked] = useState(false)
    const handleAreaClick = () => {

        setAreaClicked(true)
        setDeptClicked(false)
        console.log('dept', areaClicked)
        fetchData()
    }
    const handleAreaRevert = () => {

        setAreaClicked(false)
        console.log('dept', areaClicked)
    }
    return (
        <div className="full_drawer">
            <div className="top_drawer">
                <div>
                    <img alt="" src={favicon} style={{ width: '130px', height: '6vh', marginTop: '15px' }} />
                </div>

                <StyledList sx={{ paddingLeft: '10px', paddingBottom: '0px' }}>
                    <List style={{
                        width: '100%',
                        margin: '0px 0px 0px 0px',
                        padding: '0px'
                    }}>
                        <Box sx={{ backgroundColor: '#eff1f3', height: '10px' }}>
                            <Box sx={{ height: '10px', borderRadius: location.pathname === '/cmd-dashboard/calendar' ? "0px 0px 20px 0px" : '', backgroundColor: '#D8E2FF' }} />
                        </Box>

                        <NavLink
                            to={`/cmd-dashboard/calendar`}
                            style={{ textDecoration: "none", outline: "2px solid black", outlineOffset: "-2px" }}
                        >
                            <ListItem sx={{ padding: '0px' }}>
                                <ListItemButton
                                    className={location.pathname === '/cmd-dashboard/calendar' ? "active_menu" : ""}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    style={{
                                        // borderRadius: "0px 8px 8px 0px",
                                        borderRadius: location.pathname === '/cmd-dashboard/calendar' ? "10px 0px 0px 10px" : "",
                                        background: location.pathname === '/cmd-dashboard/calendar' ? "#eff1f3" : "",
                                        borderRight: location.pathname === '/cmd-dashboard/calendar' ? '10px solid #eff1f3' : "",
                                        width: '100%'
                                    }}
                                >
                                    <ListItemIcon style={{ color: location.pathname === '/cmd-dashboard/calendar' ? "#005AC1" : "#3F4759", minWidth: '30px' }}>
                                        <CalendarTodayIcon />
                                    </ListItemIcon>

                                    <ListItemText
                                        style={{ color: location.pathname === '/cmd-dashboard/calendar' ? "#005AC1" : "#3F4759", fontSize: '12px' }}
                                        primary={<Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>Calendar</Typography>}
                                        sx={{ fontSize: '12px' }}
                                    />

                                </ListItemButton>
                            </ListItem>
                        </NavLink>
                        <Box sx={{ backgroundColor: '#eff1f3', height: '10px' }}>
                            <Box
                                sx={{
                                    height: '10px',
                                    borderRadius: location.pathname === '/cmd-dashboard/calendar' ? '0px 20px 0px 0px' : location.pathname === '/cmd-dashboard/review-meetings' ? '0px 0px 20px 0px' : '',
                                    backgroundColor: '#D8E2FF'
                                }}
                            />
                        </Box>

                        <NavLink
                            to={`/cmd-dashboard/review-meetings`}
                            style={{ textDecoration: "none" }}
                        >
                            <ListItem sx={{ padding: '0px' }}>
                                <ListItemButton
                                    className={location.pathname === '/cmd-dashboard/review-meetings' ? "active_menu" : ""}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    style={{
                                        // borderRadius: "0px 8px 8px 0px",
                                        borderRadius: location.pathname === '/cmd-dashboard/review-meetings' ? "10px 0px 0px 10px" : "",
                                        background: location.pathname === '/cmd-dashboard/review-meetings' ? "#eff1f3" : "",
                                        borderRight: location.pathname === '/cmd-dashboard/review-meetings' ? '10px solid #eff1f3' : "",
                                        width: '100%'
                                    }}
                                >
                                    <ListItemIcon style={{ color: location.pathname === '/cmd-dashboard/review-meetings' ? "#005AC1" : "#3F4759", minWidth: '30px' }}>
                                        <PlaylistAddCheckIcon />
                                    </ListItemIcon>

                                    <ListItemText
                                        style={{ color: location.pathname === '/cmd-dashboard/review-meetings' ? "#005AC1" : "#3F4759", fontSize: '12px' }}
                                        primary={<Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>Review Meeting </Typography>}
                                        sx={{ fontSize: '12px' }}
                                    />

                                </ListItemButton>
                            </ListItem>
                        </NavLink>
                        <Box sx={{ backgroundColor: '#eff1f3', height: '10px' }}>
                            <Box
                                sx={{
                                    height: '10px',
                                    borderRadius: location.pathname === '/cmd-dashboard/review-meetings' ? '0px 20px 0px 0px' : location.pathname === '/cmd-dashboard/red-list' ? '0px 0px 20px 0px' : '',
                                    backgroundColor: '#D8E2FF'
                                }}
                            />
                        </Box>
                        <NavLink
                            to={`/cmd-dashboard/red-list`}
                            style={{ textDecoration: "none" }}
                        >
                            <ListItem sx={{ padding: '0px' }}>
                                <ListItemButton
                                    className={location.pathname === '/cmd-dashboard/red-list' ? "active_menu" : ""}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    style={{
                                        // borderRadius: "0px 8px 8px 0px",
                                        borderRadius: location.pathname === '/cmd-dashboard/red-list' ? "10px 0px 0px 10px" : "",
                                        background: location.pathname === '/cmd-dashboard/red-list' ? "#eff1f3" : "",
                                        borderRight: location.pathname === '/cmd-dashboard/red-list' ? '10px solid #eff1f3' : "",
                                        width: '100%',
                                        zIndex: location.pathname === '/cmd-dashboard/red-list' ? 1 : "auto", // Add zIndex property
                                    }}
                                >
                                    <ListItemIcon style={{ color: location.pathname === '/cmd-dashboard/red-list' ? "#005AC1" : "#3F4759", minWidth: '30px' }}>
                                        <WarningAmberIcon />
                                    </ListItemIcon>

                                    <ListItemText
                                        style={{ color: location.pathname === '/cmd-dashboard/red-list' ? "#005AC1" : "#3F4759", fontSize: '12px' }}
                                        primary={<Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>Red Lists</Typography>}
                                        sx={{ fontSize: '12px' }}
                                    />

                                </ListItemButton>
                            </ListItem>
                        </NavLink>
                        <Box sx={{ backgroundColor: '#eff1f3', height: '10px' }}>
                            <Box
                                sx={{
                                    height: '10px',
                                    borderRadius: location.pathname === '/cmd-dashboard/red-list' ? '0px 20px 0px 0px' : location.pathname === '/cmd-dashboard/critical-activity' ? '0px 0px 20px 0px' : '',
                                    backgroundColor: '#D8E2FF'
                                }}
                            />
                        </Box>
                        <NavLink
                            to={`/cmd-dashboard/critical-activity`}
                            style={{ textDecoration: "none" }}
                        >
                            <ListItem sx={{ padding: '0px' }}>
                                <ListItemButton
                                    className={location.pathname === '/cmd-dashboard/critical-activity' ? "active_menu" : ""}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    style={{
                                        // borderRadius: "0px 8px 8px 0px",
                                        borderRadius: location.pathname === '/cmd-dashboard/critical-activity' ? "10px 0px 0px 10px" : "",
                                        background: location.pathname === '/cmd-dashboard/critical-activity' ? "#eff1f3" : "",
                                        borderRight: location.pathname === '/cmd-dashboard/critical-activity' ? '10px solid #eff1f3' : "",
                                        width: '100%',
                                        zIndex: location.pathname === '/cmd-dashboard/critical-activity' ? 1 : "auto", // Add zIndex property
                                    }}
                                >
                                    <ListItemIcon style={{ color: location.pathname === '/cmd-dashboard/critical-activity' ? "#005AC1" : "#3F4759", minWidth: '30px' }}>
                                        <DirectionsRunIcon />
                                    </ListItemIcon>

                                    <ListItemText
                                        style={{ color: location.pathname === '/cmd-dashboard/critical-activity' ? "#005AC1" : "#3F4759", fontSize: '12px' }}
                                        primary={<Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>Critical Activities</Typography>}
                                        sx={{ fontSize: '12px' }}
                                    />

                                </ListItemButton>
                            </ListItem>
                        </NavLink>
                        <Box sx={{ backgroundColor: '#eff1f3', height: '10px' }}>
                            <Box
                                sx={{
                                    height: '10px',
                                    borderRadius: location.pathname === '/cmd-dashboard/critical-activity' ? '0px 20px 0px 0px' : location.pathname === '/cmd-dashboard/departments' ? '0px 0px 20px 0px' : '',
                                    backgroundColor: '#D8E2FF'
                                }}
                            />
                        </Box>
                        

                    </List>
                </StyledList>


            </div>
            <div className="middle_drawer0">
                <ListItem sx={{ padding: '0px' }}>
                    <ListItemButton
                    className={location.pathname === '/cmd-dashboard/departments' ? "active_menu" : ""}
                        style={{
                            // borderRadius: "0px 8px 8px 0px",
                            borderRadius: location.pathname === '/cmd-dashboard/departments' ? "10px 0px 0px 10px" : "",
                            background: location.pathname === '/cmd-dashboard/departments' ? "#eff1f3" : "",
                            borderRight: location.pathname === '/cmd-dashboard/departments' ? '10px solid #eff1f3' : "",
                            width: '100%',
                            zIndex: location.pathname === '/cmd-dashboard/departments' ? 1 : "auto", // Add zIndex property
                        }}
                    >
                        <Box sx={{display:'flex',justifyContent:'space-between'}}>
                        <Box sx={{display:'flex',}}>
                        <ListItemIcon style={{ color: location.pathname === '/cmd-dashboard/departments' ? "#005AC1" : "#3F4759", minWidth: '30px' }}>
                            <BusinessIcon sx={{ color: '#3F4759' }} />
                        </ListItemIcon>

                        {/* Wrap only the ListItemText with NavLink */}
                        <NavLink to={`/cmd-dashboard/departments`} style={{ textDecoration: "none", backgroundColor: location.pathname === '/cmd-dashboard/departments' && "#eff1f3"  }}>
                            <ListItemText
                            style={{ color: location.pathname === '/cmd-dashboard/departments' ? "#005AC1" : "#3F4759", fontSize: '12px' }}
                                primary={
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography sx={{ fontSize: '12px', color: '#3F4759', fontWeight: 'bold' }}>
                                            Departments
                                        </Typography>
                                    </Box>
                                }
                                sx={{ fontSize: '12px' }}
                            />
                        </NavLink>
                        </Box>
                        <Box sx={{marginLeft:'10px'}}>
                        {deptClicked ?
                            <IconButton onClick={handleDeptRevert} sx={{ padding: '0px' }}>
                                <KeyboardArrowDownIcon />
                            </IconButton>
                            :
                            <IconButton onClick={handleDeptClick} sx={{ padding: '0px' }}>
                                <KeyboardArrowRightIcon />
                            </IconButton>
                        }
                        </Box>
                        </Box>
                        
                        
                        
                    </ListItemButton>
                </ListItem>
                <Box sx={{ backgroundColor: '#eff1f3', height: '10px' }}>
                            <Box
                                sx={{
                                    height: '10px',
                                    borderRadius: location.pathname === '/cmd-dashboard/departments' ? '0px 20px 0px 0px' : location.pathname === '/cmd-dashboard/area' ? '0px 0px 20px 0px' : '',
                                    backgroundColor: '#D8E2FF'
                                }}
                            />
                        </Box>
                
                {deptClicked &&
                    <div className="middle_drawer1">
                        <StyledListMiddleDrawer1 sx={{ padding: '0px 0px', }}>
                            <List sx={{ padding: '0px 0px 0px 30px', height: '100%', overFlow: 'hidden' }}>
                                {deptSearchResults.map((item, index) => {


                                    return (

                                        <NavLink
                                            to={`/cmd-dashboard/department/${item.department_id}`}
                                            style={{
                                                textDecoration: "none",
                                            }}
                                            state={item.department_name}
                                        >
                                            {location.pathname === `/cmd-dashboard/department/${item.department_id}` && <Box sx={{ backgroundColor: '#eff1f3', height: '10px' }}>
                                                <Box
                                                    sx={{
                                                        height: '10px',
                                                        borderRadius: location.pathname === `/cmd-dashboard/department/${item.department_id}` ? '0px 0px 20px 0px' : '',
                                                        backgroundColor: '#D8E2FF'
                                                    }}
                                                />
                                            </Box>}
                                            <ListItem sx={{ padding: '0px 0px 0px 10px' }}>
                                                <ListItemButton
                                                    className={location.pathname === `/cmd-dashboard/department/${item.department_id}` ? "active_menu" : ""}
                                                    onMouseEnter={handleMouseEnterMiddleDrawer1}
                                                    onMouseLeave={handleMouseLeaveMiddleDrawer1}
                                                    style={{
                                                        // borderRadius: "0px 8px 8px 0px",
                                                        borderRadius: location.pathname === `/cmd-dashboard/department/${item.department_id}` ? "10px 0px 0px 10px" : "",
                                                        background: location.pathname === `/cmd-dashboard/department/${item.department_id}` ? "#eff1f3" : "",
                                                        borderRight: location.pathname === `/cmd-dashboard/department/${item.department_id}` ? '10px solid #eff1f3' : "",
                                                        width: '100%'
                                                    }}
                                                >


                                                    <ListItemText
                                                        style={{ color: location.pathname === `/cmd-dashboard/department/${item.department_id}` ? "#005AC1" : "#3F4759", fontSize: '12px' }}
                                                        primary={<Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>{item.department_name}</Typography>}
                                                        sx={{ fontSize: '12px' }}
                                                    />

                                                </ListItemButton>
                                            </ListItem>

                                            {location.pathname === `/cmd-dashboard/department/${item.department_id}` && <Box sx={{ backgroundColor: '#eff1f3', height: '10px' }}>
                                                <Box
                                                    sx={{
                                                        height: '10px',
                                                        borderRadius: location.pathname === `/cmd-dashboard/department/${item.department_id}` ? '0px 20px 0px 0px' : '',
                                                        backgroundColor: '#D8E2FF'
                                                    }}
                                                />
                                            </Box>}
                                        </NavLink>

                                    )
                                })}
                            </List>
                        </StyledListMiddleDrawer1>
                    </div>}
                <ListItem sx={{ padding: '0px' }}>

                <ListItemButton
                        style={{
                            // borderRadius: "0px 8px 8px 0px",
                            borderRadius: location.pathname === '/cmd-dashboard/area' ? "10px 0px 0px 10px" : "",
                            background: location.pathname === '/cmd-dashboard/area' ? "#eff1f3" : "",
                            borderRight: location.pathname === '/cmd-dashboard/area' ? '10px solid #eff1f3' : "",
                            width: '100%',
                            zIndex: location.pathname === '/cmd-dashboard/area' ? 1 : "auto", // Add zIndex property
                        }}
                    >
                        <Box sx={{display:'flex',justifyContent:'space-between'}}>
                        <Box sx={{display:'flex',}}>
                        <ListItemIcon style={{ color: location.pathname === '/cmd-dashboard/area' ? "#005AC1" : "#3F4759", minWidth: '30px' }}>
                            <PlaceOutlinedIcon sx={{ color: '#3F4759' }} />
                        </ListItemIcon>

                        <NavLink to={`/cmd-dashboard/area`} style={{ textDecoration: "none", backgroundColor: location.pathname === '/cmd-dashboard/area' && "#eff1f3"  }}>
                            <ListItemText
                            style={{ color: location.pathname === '/cmd-dashboard/area' ? "#005AC1" : "#3F4759", fontSize: '12px' }}
                                primary={
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography sx={{ fontSize: '12px', color: '#3F4759', fontWeight: 'bold' }}>
                                            Areas
                                        </Typography>
                                    </Box>
                                }
                                sx={{ fontSize: '12px' }}
                            />
                        </NavLink>
                        </Box>
                        <Box sx={{marginLeft:'50px'}}>
                        {areaClicked ?
                            <IconButton onClick={handleAreaRevert} sx={{ padding: '0px' }}>
                                <KeyboardArrowDownIcon />
                            </IconButton>
                            :
                            <IconButton onClick={handleAreaClick} sx={{ padding: '0px' }}>
                                <KeyboardArrowRightIcon />
                            </IconButton>
                        }
                        </Box>
                        </Box>
                    </ListItemButton>

                </ListItem>
                <Box sx={{ backgroundColor: '#eff1f3', height: '10px' }}>
                            <Box sx={{ height: '10px', borderRadius: location.pathname === '/cmd-dashboard/area' ? "0px 20px 0px 0px" : '', backgroundColor: '#D8E2FF' }} />
                        </Box>
                {areaClicked &&
                    <div className="middle_drawer2">
                        <StyledListMiddleDrawer2 sx={{ padding: '0px 0px', }}>
                            <List sx={{ padding: '0px 0px 0px 30px', height: '100%', overFlow: 'hidden' }}>
                                {areaSearchResults.map((item, index) => {


                                    return (

                                        <NavLink
                                            to={`/cmd-dashboard/department/${item.department_id}`}
                                            style={{
                                                textDecoration: "none",
                                            }}
                                            state={item.department_name}
                                        >
                                            {location.pathname === `/cmd-dashboard/department/${item.department_id}` && <Box sx={{ backgroundColor: '#eff1f3', height: '10px' }}>
                                                <Box
                                                    sx={{
                                                        height: '10px',
                                                        borderRadius: location.pathname === `/cmd-dashboard/department/${item.department_id}` ? '0px 0px 20px 0px' : '',
                                                        backgroundColor: '#D8E2FF'
                                                    }}
                                                />
                                            </Box>}
                                            <ListItem sx={{ padding: '0px 0px 0px 10px' }}>
                                                <ListItemButton
                                                    className={location.pathname === `/cmd-dashboard/department/${item.department_id}` ? "active_menu" : ""}
                                                    onMouseEnter={handleMouseEnterMiddleDrawer2}
                                                    onMouseLeave={handleMouseLeaveMiddleDrawer2}
                                                    style={{
                                                        // borderRadius: "0px 8px 8px 0px",
                                                        borderRadius: location.pathname === `/cmd-dashboard/department/${item.department_id}` ? "10px 0px 0px 10px" : "",
                                                        background: location.pathname === `/cmd-dashboard/department/${item.department_id}` ? "#eff1f3" : "",
                                                        borderRight: location.pathname === `/cmd-dashboard/department/${item.department_id}` ? '10px solid #eff1f3' : "",
                                                        width: '100%'
                                                    }}
                                                >


                                                    <ListItemText
                                                        style={{ color: location.pathname === `/cmd-dashboard/department/${item.department_id}` ? "#005AC1" : "#3F4759", fontSize: '12px' }}
                                                        primary={<Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>{item.department_name}</Typography>}
                                                        sx={{ fontSize: '12px' }}
                                                    />

                                                </ListItemButton>
                                            </ListItem>

                                            {location.pathname === `/cmd-dashboard/department/${item.department_id}` && <Box sx={{ backgroundColor: '#eff1f3', height: '10px' }}>
                                                <Box
                                                    sx={{
                                                        height: '10px',
                                                        borderRadius: location.pathname === `/cmd-dashboard/department/${item.department_id}` ? '0px 20px 0px 0px' : '',
                                                        backgroundColor: '#D8E2FF'
                                                    }}
                                                />
                                            </Box>}
                                        </NavLink>

                                    )
                                })}
                            </List>
                        </StyledListMiddleDrawer2>
                    </div>}
            </div>
            <div className="bottom_drawer">
                <div>
                    <img alt="" src={mcl_logo} style={{ width: '60%', left: 0, marginTop: '15px' }} />
                </div>
            </div>
        </div>
    );
}
