export const GetActivityforDashboard = async (user_id, userorg_id,department_type,label,token,  startIndex, rowsPerPage) => {
    try {
      const apiEndpoint = "cmdadmin/getactivityfordashboard";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        "org_id":userorg_id,
         "user_id":user_id,
         "department_type" : department_type,
        "activity_type":"critical",
        "status": label || "upcoming",
         "start_index":startIndex,
          "limit":rowsPerPage

        
      };
    
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };

  export const UserperDepartment = async (user_id, userorg_id,department_type,token) => {
    try {
      const apiEndpoint = "cmdadmin/getuserscountperdepartment";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        "org_id":userorg_id,
         "user_id":user_id,
         "department_type" : department_type,
      };
    
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };
  export const DocumentsperDepartment = async (user_id, userorg_id,department_type,token) => {
    try {
      const apiEndpoint = "cmdadmin/getdocumentscountperdepartment";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        "org_id":userorg_id,
         "user_id":user_id,
         "department_type" : department_type,
      };
    
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };

  