import React, { useState, useEffect, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Footer from "../../Include/footer";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import Sidebar from "../../Include/Sidebar";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Appbar from "../../Include/Header";
import { Typography, IconButton, Grid } from "@mui/material";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
const drawerWidth = 200;
const theme = createTheme();
const localizer = momentLocalizer(moment);

function createData(name, calories, fat, carbs, protein, price) {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
        price,
        history: [
            {
                date: '2020-01-05',
                customerId: '11091700',
                amount: 3,
            },
            {
                date: '2020-01-02',
                customerId: 'Anonymous',
                amount: 1,
            },
        ],
    };
}



function Support(props) {
    const { windows } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <Sidebar />
        </div>
    );

    const container =
        windows !== undefined ? () => window().document.body : undefined;



    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    backgroundColor: "white",
                }}
            >
                <Appbar />
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                            borderRight: '0px',
                        },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: "none", sm: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                            borderRight: '0px',
                        },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,

                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    background: "#EFF1F3",
                    minHeight: "100vh",
                }}
            >
                <Box sx={{ marginTop: '25vh' }}>
                    <Typography sx={{ margin: '20px' }} variant="h6">
                        Feel free to contact us anytime for assistance or inquiries. Our dedicated support team is here to help you.
                    </Typography>
                    <Grid container rowSpacing={1}  >
                        <Grid item xs={12} md={6}
                            sx={{ padding: '10px 20px 0px 20px' }}
                        >
                            <Card sx={{ padding: '10px' }}>
                                <CardContent sx={{ padding: '10px' }}>
                                    <Typography variant="h5" sx={{ textAlign: 'left', marginLeft: '10px' }}>
                                        Mahanadi Coalfield Limited
                                    </Typography>
                                    <Typography variant="h6" sx={{ textAlign: 'left', marginLeft: '10px' }}>
                                        Y K Singha
                                    </Typography>
                                    <Box sx={{ display: 'flex' }}>
                                        <IconButton><MailOutlineIcon /></IconButton>
                                        <Typography sx={{ marginTop: '7px' }}>yksingha@coalindia.com</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex' }}>
                                        <IconButton><PhoneAndroidOutlinedIcon /></IconButton>
                                        <Typography sx={{ marginTop: '7px' }}>+91 - 9438879723</Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}
                            sx={{ padding: '10px 0px 0px 20px' }}
                        >
                            <Card sx={{ padding: '10px' }}>

                                <CardContent sx={{ padding: '10px' }}>
                                    <Typography variant="h5" sx={{ textAlign: 'left', marginLeft: '10px' }}>
                                        ZeonAI Labs Pvt. Ltd.
                                    </Typography>
                                    <Typography variant="h6" sx={{ textAlign: 'left', marginLeft: '10px' }}>
                                        Sukanta Kumar Mishra
                                    </Typography>
                                    <Box sx={{ display: 'flex' }}>
                                        <IconButton><MailOutlineIcon /></IconButton>
                                        <Typography sx={{ marginTop: '7px' }}>sukanta.mishra@zeonai.com</Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex' }}>
                                        <IconButton><PhoneAndroidOutlinedIcon /></IconButton>
                                        <Typography sx={{ marginTop: '7px' }}>+91 - 9439540050</Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>

                </Box>


            </Box>


            <Footer />
        </Box>
    );
}

export default Support;