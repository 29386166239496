import React, { useEffect, useState, useRef } from 'react';
import Toolbar from '@mui/material/Toolbar';
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import Typography from '@mui/material/Typography';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InputAdornment from '@mui/material/InputAdornment';
import AppBar from '@mui/material/AppBar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Collapse from '@mui/material/Collapse';
import Logout from '@mui/icons-material/Logout';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NotificationsIcon from '@mui/icons-material/Notifications';
import mcl_logo from '../../../mcl-small.png';
// import './SignIn.css';
import { useAppContext } from '../../../Includes/AppContext';
import List from '@mui/material/List';
import { usePath } from '../../../Includes/PathContext';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { NavLink } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Button from '@mui/material/Button';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import changePass from '../../../wrong-password.png';
import userUpdate from '../../../settings.png'
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/Close';
import { updateProfile, changePassword, updateAccount } from '../../ApiHelper/Profile';
import { CircularProgress, Drawer } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Alert from '@mui/material/Alert';
import DrawerComponent from './Sidebar';
import { getAllRedListDownload } from '../../AdminConsole/CmdLink/CmdLinkApi';
// import { useNavigate } from 'react-router-dom'
const Appbar = () => {

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <DrawerComponent />
    </div>
  );

  const drawerWidth = 200;
  const location = useLocation();
  const navigate = useNavigate();
  // const history = useHistory();
  // const searchText = new URLSearchParams(location.search).get('text');
  const { searchText } = useAppContext();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const { currentPath, setCurrentPath } = usePath(window.location.pathname);
  const handleKeyDown = (e) => {
    e.stopPropagation();
    // ... your onKeyDown logic here ...
  };
  // useEffect(() => {
  //   // Set the current path when the component mounts

  // }, []);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const currentPath = window.location.pathname;
  const reloadText = (currentPath) => {

    // Your logic for the reload text based on currentPath
    console.log('currentPath', windowWidth)
    if (currentPath === '/cmd-dashboard/calendar')
      return ("CMD's Dashboard");
    else if (currentPath == '/cmd-dashboard/review-meetings')
      return ("CMD's Dashboard")
    else if (currentPath == '/cmd-dashboard/red-list')
      return ("CMD's Dashboard")
    else if (currentPath == '/cmd-dashboard/critical-activity')
      return ("CMD's Dashboard")
    else
      return ("CMD's Dashboard")
  }
  console.log(currentPath)
  const [updatePassword, setUpdatePassword] = useState(false)
  const changePasswordModalOpen = () => {
    setUpdatePassword(true)
  }
  const changePasswordModalClose = () => {
    setUpdatePassword(false)
    setAnchorEl(null);
  }
  const [updateUser, setUpdateUser] = useState(false)
  const updateUserModalOpen = () => {
    setUpdateUser(true)
  }
  const updateUserModalClose = () => {
    setUpdateUser(false)
    setAnchorEl(null);
  }
  const fileInputRef = useRef(null);
  const [uploadImg, setUploadImg] = useState('')
  const [imageSrc, setImageSrc] = useState(null);
  const handleButtonClick = (e) => {
    // Trigger the file input click event
    fileInputRef.current.click();
    setUploadImg(fileInputRef.current.value)
  };


  const [toastmessage, settoastmessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [downloadBtnLoading, setDownloadBtnLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [usertoken, setusertoken] = useState('');
  const [user_id, setuser_id] = useState('');
  const [userorg_id, setuserorg_id] = useState('');
  const [updateProfileData, setUpdateProfileData] = useState('');
  const [fullName, setFullName] = useState('')
  const [userEmail_id, setUserEmail_id] = useState('')
  const [userMobile_no, setUserMobile_no] = useState('')
  const [userdesg, setUserdesg] = useState('')
  // const [updatePassword,setUpdatePassword] = useState('');
  const [oldPass, setOldPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [confirmNewPass, setConfirmNewPass] = useState('');
  const [passwordCheck, setPasswordCheck] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [pic, setPic] = useState('')
  const [fileSelect, setFileSelect] = useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [userType, setUserType] = useState('');
  const [RedListData, setRedListData] = useState([]);
  const updateUserDataFromLocalStorage = () => {
    const storedUserData = localStorage.getItem('userdata');
    if (storedUserData) {
      const userDataObj = JSON.parse(storedUserData);
      const { token: userToken,
        user_id: deepuser_id,
        org_id: deeporg_id,
        full_name: user_full_name,
        picture: picture,
        role_name: role_name,
        user_priviledge: user_priviledge,
        email_id: userEmail,
        mobile_no: userMobile
      } = userDataObj;

      // setPic(picture)
      setusertoken(userToken);
      setuser_id(deepuser_id);
      setuserorg_id(deeporg_id);
      setFullName(user_full_name)
      setUserEmail_id(userEmail)
      setUserMobile_no(userMobile)
      setUserdesg(role_name)
      setImageSrc(picture)
      setUserType(user_priviledge)
    }
  };
  useEffect(() => {
    updateUserDataFromLocalStorage();
  }, []);
  const fetchUserName = (event) => {
    setFullName(event.target.value)
  }
  const fetchUserEmail = (event) => {
    setUserEmail_id(event.target.value)
  }
  const fetchUserMobile = (event) => {
    setUserMobile_no(event.target.value)
  }
  // const handleUpdateUser = () => {
  //   setIsLoading(true)
  //   settoastmessage("Loading...")
  //   const storedUserData = localStorage.getItem('userdata');
  //     const userDataObj = JSON.parse(storedUserData);
  //     const { token: userToken, user_id: deepuser_id,  org_id: deeporg_id, } = userDataObj;
  //     setuserorg_id(deeporg_id);
  //     setusertoken(userToken);
  //     setuser_id(deepuser_id);
  //   console.log('him',deeporg_id)
  //   updateAccount({user_id,deeporg_id,fullName,profilePhoto,userToken}).then(res=>{
  //     if(res.code==="200"){
  //       settoastmessage(res.message);
  //       setFileSelect(false)
  //       setIsLoading(false);
  //       setAnchorEl(null);
  //       setUpdateUser(false)
  //     }
  //     else{
  //       setIsLoading(false);
  //       setAnchorEl(null);
  //       settoastmessage(res.message);
  //       setUpdateUser(false)
  //     }
  //   })

  // }; 
  const updateChangePassword = async () => {
    // setIsLoadingMore(true);
    // setfullLoading(true)
    setIsLoading(true)

    try {
      const storedUserData = localStorage.getItem('userdata');
      const userDataObj = JSON.parse(storedUserData);
      const { token: userToken, user_id: deepuser_id, } = userDataObj;

      setusertoken(userToken);
      setuser_id(deepuser_id);

      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }


      const data = await changePassword(
        deepuser_id,
        oldPass,
        newPass,
        userToken,
      );
      if (data.code == "200") {
        console.log(' ++inside')
        setUpdatePassword(false)
        setSnackbarOpen(true);
        settoastmessage(data.message)
        setIsLoading(false);
        setAnchorEl(null);
      }
      else {
        setSnackbarOpen(true);
        settoastmessage(data.message)
        setIsLoading(false);

      }
    } catch (error) {
      console.error("Error:", error);
      settoastmessage(data.message)
    }


  };
  const changeOldPassword = (e) => {
    setOldPass(e.target.value)
  }
  const changeNewPassword = (e) => {
    setNewPass(e.target.value)
  }
  const changeConfirmNewPassword = (e) => {
    setConfirmNewPass(e.target.value)
  }
  // useEffect(() => {
  //   // Retrieve image data from local storage
  //   const storedImageData = localStorage.getItem('userdata.picture');

  //   if (storedImageData) {
  //     setImageSrc(storedImageData);
  //   }
  // }, []);
  const handleFileChange = (event) => {
    // Handle the selected file
    setFileSelect(true);
    const imgFile = event.target.files[0];
    setPic(event.target.files[0]);
    console.log('imgFile', pic)
  };
  const updateUserData = async () => {
    // setIsLoadingMore(true);
    // setfullLoading(true)
    setIsLoadingData(true)
    console.log(isLoadingData)
    try {
      const storedUserData = localStorage.getItem('userdata');
      const userDataObj = JSON.parse(storedUserData);
      const { token: userToken, user_id: deepuser_id, org_id: deeporg_id, } = userDataObj;

      setusertoken(userToken);
      setuser_id(deepuser_id);
      setuserorg_id(deeporg_id);
      //setFullName(fullName)
      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }

      console.log('Updated_pic1:', pic);
      const result = await updateProfile(
        deepuser_id,
        deeporg_id,
        fullName,
        userEmail_id,
        userMobile_no,
        userToken,
        pic,

      );
      console.log(result)
      if (result.code === "200") {
        console.log(' ++inside')
        setPic('')
        setImageSrc(result.data.picture)
        console.log('Updated_pic2:', result.data.picture)
        const userDataJSON = localStorage.getItem('userdata');
        if (userDataJSON) {
          const userDataObj = JSON.parse(userDataJSON);
          userDataObj.full_name = fullName; // Update the full name
          userDataObj.picture = result.data.picture;
          userDataObj.email_id = userEmail_id;
          userDataObj.mobile_no = userMobile_no;

          // Update the profile image data

          // Update other properties as needed

          try {
            localStorage.setItem('userdata', JSON.stringify(userDataObj));
            console.log('Data set successfully in localStorage');
          } catch (error) {
            console.error('Error setting data in localStorage:', error);
          }
        }
        setSnackbarOpen(true);
        settoastmessage(result.message);
        setFileSelect(false)
        setIsLoadingData(false);
        setAnchorEl(null);
        updateUserModalClose();
        console.log(isLoadingData)
      }
      else {
        setSnackbarOpen(true);
        settoastmessage(result.message);
        setFileSelect(false)
        setIsLoadingData(false);
        console.log(isLoadingData)
      }

    } catch (error) {
      console.error("Error:", error);
      setIsLoadingData(false);
      ;
    }
  };
  const fetchAllRedList = async () => {
    setDownloadBtnLoading(true);
    try {
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }
      const userToken = userDataObj.token;
      const deepuser_id = userDataObj.user_id;
      const deeporg_id = userDataObj.org_id;

      setusertoken(userToken);
      setuser_id(deepuser_id);
      setuserorg_id(deeporg_id);
      // setIndex(0);

      // Use the API helper function to fetch query history data
      const data = await getAllRedListDownload(deepuser_id, deeporg_id, '', '', '', '', '', '', userToken);

      if (data.code === "200") {
        setRedListData(data.data);
        setDownloadBtnLoading(false);
        
        if (data.data.length === 0) {
          console.error('RedListData is empty. Unable to generate PDF.');
          return; // Exit the function if RedListData is empty
        }
      
        const styles = `
<style type="text/css">
  /* Define custom styles for the PDF content */
  @page {
    margin: 20px; /* Define page margin */
    border: 1px solid #000; /* Define page border */
  }
  .critical { color: orange; font-weight: bold; }
  .daytoday { color: blue; font-weight: bold; }
  .redlist { color: red; font-weight: bold; }
  .new { background-color: #1976D2; border-radius: 50px;color:white;padding:3px 10px 6px 10px;margin-left:5px; }
  .complete { background-color: #2E7D32; border-radius: 50px; color:white;padding:3px 10px 6px 10px;margin-left:5px;}
  .inprogress { background-color: #ED6C02; border-radius: 50px; color:white;padding:3px 10px 6px 10px;margin-left:5px;}
  .hold { background-color: #D32F2F; border-radius: 50px; color:white;padding:3px 10px 6px 10px;margin-left:5px;}
  .bold { font-weight: bold; }
  .heading { text-align: center;justify-content:center; }
  .activities{margin-left:20px;margin-right:20px;font-size:12px;margin-top: 15px;page-break-before: auto;page-break-after: auto;page-break-inside: avoid;position: relative;display:block; }
  #activity { border-top: 0.01px solid #e6e6e6;margin-top: 15px; }
  div {
        page-break-before: auto;
        page-break-after: auto; 
        page-break-inside: avoid;
        position: relative;
    }
</style>
`;

const content = `
<html>
<head>
  ${styles}
</head>
        <body>
          <h1 class="heading">ALL ACTIVITIES</h1>
          <div class="activities">
            ${data.data.map(result => `
            <div id="activity">
                <p><span class="${result.activity_type} bold" >${result.activity_type.charAt(0).toUpperCase() + result.activity_type.slice(1)}</span>: ${result.agenda}</p>
                <p><span class="bold">Description:</span> ${result.description}</p>
                <p><span class="bold">Project Name:</span> ${result.project_name || 'N/A'}</p>
                <p><span class="bold">Assign to:</span> ${result.assignto || 'N/A'},<span class="bold">Due date: </span>${result.end_datetime || 'N/A'},<span class="bold"> Department:</span>${result.dept_name || 'N/A'},<span class="bold"> Status:</span><span class=${result.status}>${result.status || 'N/A'}</span></p>
              </div>
            `).join('')}
          </div>
        </body>
        </html>
        `;
      
        // Create PDF with html2pdf
        const element = document.createElement('div');
        element.innerHTML = content;
        html2pdf().from(element).save('All_Activities.pdf');
      }
       else {
        setDownloadBtnLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error, show a message or log it
      // seterrorSnackbar(true);
    }
  };

  const generatePDF = () => {
    if (RedListData.length === 0) {
      console.error('RedListData is empty. Unable to generate PDF.');
      return; // Exit the function if RedListData is empty
    }

    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.setTextColor(255, 0, 0); // Set text color to red
    const text = `ALL ACTIVITIES\n\n`;
    // Add heading with center alignment, yellow background, and red text color
    doc.setTextColor(255, 0, 0); // Set text color to red
    doc.setFillColor(255, 255, 0); // Set background color to yellow
    doc.text(text, doc.internal.pageSize.width / 2, 15, { align: 'center', fill: true }); // Center align heading
    doc.setTextColor(0); // Reset text color

    // Define margin
    const margin = {
      top: 20,
      left: 0,
      right: 0,
      bottom: 20,
    };

    // Initial position
    let yPos = margin.top;

    // Map each element of RedListData to a paragraph
    RedListData.forEach((result, index) => {
      let formattedText = `
      ${result.activity_type}:  ${result.agenda}
      Description:  ${result.description}
      Project Name:  ${result.project_name || 'N/A'}
      Assign to:  ${result.assignto || 'N/A'}
      Due date:  ${result.end_datetime || 'N/A'}, Department:  ${result.dept_name || 'N/A'},  Status: ${result.status || 'N/A'}`;

      // Split text into lines to fit within available width
      const splitText = doc.splitTextToSize(formattedText, doc.internal.pageSize.width - margin.left - margin.right);

      // Check if there's enough space for the current text, if not, move to the next page
      const spaceLeft = doc.internal.pageSize.height - yPos - margin.bottom;
      if (spaceLeft < splitText.length * 12) {
        doc.addPage();
        yPos = margin.top;
      }

      // Add the text to the document
      doc.text(splitText, margin.left + 5, yPos + 5);

      // Update yPos for the next paragraph
      yPos += (splitText.length * 12) + 5; // Considering font size and line spacing
    });

    doc.save('All_Activities.pdf');
  };


  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };
  const handleLogout = () => {
    localStorage.setItem('logoutmessage', 'Logout Successful ! Please sign in.');
    localStorage.removeItem('userdata');
    window.location.replace('/');
  }
  const closeLogoutModal = () => {
    setAlertOpen(false);
    setAnchorEl(null)
  }
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - 200px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "white",
          color: '#003776',

        }}
      >

        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" }, color: "black" }}
          >
            <MenuIcon />
          </IconButton>
          <Box display='flex' flexGrow={1}>
            <Box
              // noWrap component="div"
              //  sx={{ fontSize: '30px' }} 
              className='reload_text_class'
            >
              {reloadText(currentPath)}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            <Tooltip title="Activity Download">
              <Button onClick={fetchAllRedList} endIcon={downloadBtnLoading ? <CircularProgress size={15} color='inherit' /> : <FileDownloadOutlinedIcon />} size='small' variant='contained' sx={{ borderRadius: '50px', textTransform: 'none', fontWeight: 'bold' }}> Activities</Button>
            </Tooltip>
            <Tooltip title="My Profile">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2, color: '#2F80ED', border: '4px solid #eff1f3' }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <img style={{ height: '30px', width: '30px', justifyContent: 'center', borderRadius: '50px', }} src={imageSrc} alt="updateUser" />
              </IconButton>
            </Tooltip>
          </Box>

        </Toolbar>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}


          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <Box sx={{ marginLeft: '20px' }}>
            <List>
              {fullName}
            </List>
            <List>
              {userdesg}
            </List>
          </Box>
          <Divider />
          <Box >


            <MenuItem >
              <NavLink to={`/query`} style={{ textDecoration: 'none', color: 'black', display: 'flex' }}>
                <Box>
                  <ListItemIcon >
                    <ToggleOnIcon fontSize="medium" />
                  </ListItemIcon>
                </Box>
                <Box>
                  Switch to Deep Probe
                </Box>
              </NavLink>
            </MenuItem>

            <MenuItem onClick={updateUserModalOpen}>
              <ListItemIcon>
                <PersonAdd fontSize="small" />
              </ListItemIcon>
              Update Profile
            </MenuItem>
            <Dialog open={updateUser} onClose={updateUserModalClose}
              sx={{
                "& .MuiDialog-paper": {
                  borderRadius: "20px",
                  width: '700px',
                  maxWidth: '900px'
                },
              }} >
              <DialogTitle >


              </DialogTitle>
              <DialogContent >
                <Box display='flex' className='mobile_view_updateuser'>
                  <Box sx={{ textAlign: 'center', margin: '0px 15px', width: '950px' }}>
                    <Box sx={{ margin: '15px 0px', textAlign: 'left', fontWeight: 'bold' }}> UPDATE PROFILE</Box>
                    <Box sx={{ margin: '15px 0px' }}>
                      <TextField label="Enter Name" variant="outlined" placeholder='Enter here'
                        value={fullName}
                        onKeyDown={handleKeyDown}
                        onChange={fetchUserName}
                        InputProps={{
                          startAdornment: (
                            <IconButton  >
                              <PersonOutlineOutlinedIcon />
                            </IconButton>
                          ),
                        }}
                      />
                    </Box>
                    <Box sx={{ margin: '15px 0px' }}>
                      <TextField label="Enter Email" variant="outlined" placeholder='Enter here'
                        value={userEmail_id}
                        onKeyDown={handleKeyDown}
                        onChange={fetchUserEmail}
                        InputProps={{
                          startAdornment: (
                            <IconButton  >
                              <MailOutlineOutlinedIcon />
                            </IconButton>
                          ),
                        }}
                      />
                    </Box>
                    <Box sx={{ margin: '15px 0px' }}>
                      <TextField label="Enter Mobile" variant="outlined" placeholder='Enter here'
                        value={userMobile_no}
                        onKeyDown={handleKeyDown}
                        onChange={fetchUserMobile}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton>
                                <LocalPhoneOutlinedIcon />
                              </IconButton>
                              +91
                            </InputAdornment>
                          ),
                        }}

                      />
                    </Box>
                    <Box sx={{ margin: '15px 0px' }}>
                      <IconButton component="label">
                        <AddAPhotoIcon />
                        <input
                          styles={{ display: "none" }}
                          type="file"
                          accept="image/*"
                          hidden
                          name="[licenseFile]"
                          // value={profilePhoto}
                          onChange={handleFileChange}
                        />
                      </IconButton>
                      {/* <IconButton onClick={handleButtonClick}>
                          <AddAPhotoIcon />
                        </IconButton>
                        <input
                          type="file"
                          accept="image/*"
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                        /> */}
                    </Box>
                    <Box > {fileSelect ?
                      <Box color='green' display='flex' textAlign='center' justifyContent='center'>
                        <Box sx={{ marginRight: '5px' }}><CheckCircleOutlineIcon /></Box>

                        <Box>file selected</Box>
                      </Box> : "Upload photo"} </Box>
                    <Box sx={{ margin: '15px 0px' }}><Button variant="contained" onClick={updateUserData} backgroundColor={isLoadingData && '#616161'} disabled={isLoadingData}>
                      {isLoadingData && <CircularProgress size={16} sx={{ marginRight: '10px' }} />}Submit</Button></Box>
                  </Box>
                  <Box> <Divider orientation='vertical' /></Box>
                  <Box  >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <IconButton onClick={updateUserModalClose}>
                        <CloseIcon />
                      </IconButton>
                    </Box >
                    <Box sx={{ margin: '35px 15px', textAlign: 'center' }}>

                      <Box sx={{ height: '100px', width: '100px', marginLeft: '32%', marginBottom: '10px' }}>
                        <img style={{ height: '100px', width: '100px', justifyContent: 'center', borderRadius: '50px' }} src={imageSrc} alt="updateUser" />
                      </Box>

                      <Box>Strong password required. Enter 8-256 characters. Do not include common words or names. Combine uppercase letters, lowercase letters, numbers, and symbols.</Box>
                    </Box>
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions >

              </DialogActions>
            </Dialog>
            <MenuItem onClick={changePasswordModalOpen}>
              <ListItemIcon>
                <LockIcon fontSize="small" />
              </ListItemIcon>
              Change Password
            </MenuItem>
            <Dialog open={updatePassword} onClose={changePasswordModalClose}
              sx={{
                "& .MuiDialog-paper": {
                  borderRadius: "20px",
                  width: '650px',
                  maxWidth: '800px'
                },
              }} >
              <DialogTitle >

              </DialogTitle>
              <DialogContent >
                <Box display='flex' >
                  <Box sx={{ textAlign: 'center', margin: '0px 35px', width: '950px' }}>
                    <Box sx={{ margin: '15px 0px', textAlign: 'left', fontWeight: 'bold' }}> CHANGE PASSWORD</Box>
                    <Box sx={{ margin: '15px 0px' }}>
                      <TextField
                        id="outlined-password-input"
                        value={oldPass}
                        onChange={changeOldPassword}
                        placeholder='Old Password'
                        type="password"
                        autoComplete="current-password"
                        InputProps={{
                          startAdornment: (
                            <IconButton  >
                              <LockOpenIcon />
                            </IconButton>
                          ),
                        }}
                      />

                    </Box>
                    <Box sx={{ margin: '15px 0px' }}>
                      <TextField
                        id="outlined-password-input"
                        value={newPass}
                        onChange={changeNewPassword}
                        placeholder='New Password'
                        type="password"

                        InputProps={{
                          startAdornment: (
                            <IconButton  >
                              <LockOpenIcon />
                            </IconButton>
                          ),
                        }}
                      />

                    </Box>
                    <Box sx={{ margin: '15px 0px' }}>
                      <TextField
                        id="outlined-password-input"
                        value={confirmNewPass}
                        onChange={changeConfirmNewPassword}
                        placeholder='Confirm Password'
                        type="password"
                        autoComplete="current-password"
                        InputProps={{
                          startAdornment: (
                            <IconButton  >
                              <LockOpenIcon />
                            </IconButton>
                          ),
                        }}
                      />

                    </Box>

                    <Box sx={{ margin: '15px 0px' }}>
                      <Button variant="contained" onClick={updateChangePassword} backgroundColor={isLoading && '#616161'} disabled={isLoading}>
                        {isLoading && <CircularProgress size={16} sx={{ marginRight: '10px' }} />}Submit
                      </Button>
                    </Box>
                  </Box>
                  <Box> <Divider orientation='vertical' /></Box>
                  <Box  >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <IconButton onClick={changePasswordModalClose}>
                        <CloseIcon />
                      </IconButton>
                    </Box >
                    <Box sx={{ margin: '35px 15px', textAlign: 'center' }} >
                      <Box>
                        <img src={changePass} alt="changePassword" />
                      </Box>

                      <Box>Strong password required. Enter 8-256 characters. Do not include common words or names. Combine uppercase letters, lowercase letters, numbers, and symbols.</Box>
                    </Box>
                  </Box> </Box >

              </DialogContent>
              <DialogActions >

              </DialogActions>
            </Dialog>
            <MenuItem onClick={() => {
              setAlertOpen(true);
            }}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>

          </Box>
        </Menu>
        <Snackbar
          mode="outlined"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center', // Center bottom
          }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <SnackbarContent
            style={{
              backgroundColor: 'black',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            message={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                {toastmessage}
              </span>
            }
            action={[
              <IconButton key="close" color="inherit" onClick={handleSnackbarClose}>
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </Snackbar>
        <Dialog open={alertOpen} onClose={closeLogoutModal}
        // sx={{
        //   "& .MuiDialog-paper": {
        //     borderRadius: "20px",
        //     width: '650px',
        //     maxWidth: '800px'
        //   },
        // }}
        >

          <DialogContent >
            Are yot sure you want to Logout ?

          </DialogContent>
          <DialogActions >
            <Button onClick={closeLogoutModal}>Cancel</Button>
            <Button onClick={handleLogout}>Yes</Button>
          </DialogActions>
        </Dialog>
      </AppBar>
      <Drawer

        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>

    </>
  )

}
export default Appbar;