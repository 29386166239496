import React, { useState, useEffect,useCallback } from 'react';
import ShareIcon from '@mui/icons-material/Share';
import MessageIcon from '@mui/icons-material/Message';
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import AddIcon from '@mui/icons-material/Add';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { NavLink } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import InputAdornment from '@mui/material/InputAdornment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { TextField, Typography } from '@mui/material';
import image1 from '../soochna kosh-black.png';
import image2 from '../Pages/SignIn/footer.svg';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Toolbar ,Badge} from '@mui/material';
import { useAppContext } from './AppContext';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useParams, useLocation } from 'react-router-dom';
import './SignIn.css'
import { GetNotificationCount } from "../Pages/AdminConsole/CmdLink/CmdLinkApi";
export default function Sidebar({ conversationId, onNewQueryClick ,updateNotifications}) {
  const [linkCopied, setLinkCopied] = useState(false);
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const [sharelink, setsharelink] = useState(
    `${window.constants.share_url}/query-share?`
  );
  const [loadingShareLink, setLoadingShareLink] = useState(false);
  const active = {
    // backgroundColor: '#D0E2FB',
    // color:'#2F80ED'
  }

  const handleShareClick = async () => {
    setsharelink("");
    setLoadingShareLink(true);
    const STATIC_URL = `${window.constants.share_url}/query-share?`;
    setShareModalOpen(true);

    try {

      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }
      const userToken = userDataObj.token;
      const deepuser_id = userDataObj.user_id;
      const deeporg_id = userDataObj.org_id;
      const apiEndpoint = "shareconversation"; // Replace with your API endpoint
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
      const requestData = {
        user_id: deepuser_id,
        org_id: deeporg_id,
        conversation_id: conversationId,
      };
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: userToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      const responseData = await response.json();
      if (responseData.code === "200") {
        const referenceNo = responseData.data[0].referenceno;
        const dynamicPart = `${conversationId}=${referenceNo}`;
        const finalURL = `${STATIC_URL}${dynamicPart}`;
        setLoadingShareLink(false);
        setsharelink(finalURL);
        console
      } else {
        setLoadingShareLink(false);
        console.error("Error:", responseData.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleShareModalClose = () => {
    setShareModalOpen(false);
  };
  const handleCopyToClipboard = () => {
    // Copy sharelink value to clipboard (you can use a library like clipboard.js)
    // For simplicity, I'm using the basic document.execCommand('copy')
    const textField = document.getElementById('shareLinkTextField');
    textField.select();
    document.execCommand('copy');
    setLinkCopied(true);
  };
  const drawerWidth = 240;
  const icon1 = [<AddIcon />, <PlagiarismOutlinedIcon />, <HistoryOutlinedIcon />, <LocalActivityOutlinedIcon />, <NotificationsOutlinedIcon />]
  const icon2 = [<HelpRoundedIcon />, <SettingsApplicationsIcon />]
  const text1 = ['New Query', 'Document Search', 'Query History', 'Activities', 'Notifications']
  const text2 = ['FAQs', 'Capabilities And Limitations']
  const page1 = ['query', 'document-search', 'query-history', 'activities', 'notifications']
  const page2 = ['faq', 'capabilities-limitations']
  const { setSearchText } = useAppContext();
  const handleLinkClick = (item) => {
    setSearchText(item);

  };
  const [notificationCounts, setNotificationCounts] = useState({});
  const memoizedFetchNotificationCounts = useCallback(async () => {
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }
      const userToken = userDataObj.token;
      const deepuser_id = userDataObj.user_id;
      const deeporg_id = userDataObj.org_id;
      const dept_id = userDataObj.dept_id;

      const data = await GetNotificationCount(deepuser_id, deeporg_id, userToken);

      if (data.code === "200") {
        setNotificationCounts(data.notification_count);
      } else {
        console.log("Error fetching notification counts:", data);
      }

    } catch (error) {
      console.error("Error fetching notification counts:", error);

    }
  }, [updateNotifications]); // Empty dependency array indicates that there are no dependencies

  useEffect(() => {
    memoizedFetchNotificationCounts();
  }, []);
  const location = useLocation();
  return (

    <>
      <Drawer xs={1} sm={2} md={3}
        sx={{
          width: '240px',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',

          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box sx={{ marginTop: '10px' }}>
          <img src={image1} style={{ height: '50px' }} />
        </Box>
        <Divider sx={{ margin: '10px' }} />
        <List className='mobile_view_menu_btn'>
          {text1.map((item, index) => (
            <ListItem
              key={item}
              className={location.pathname === `/${page1[index]}?text=${item}` ? 'active' : ''}
              style={{ textDecoration: 'none', color: '#606060', padding: '2px 16px' }}
            >
              <NavLink
                to={`/${page1[index]}`}
                style={{ textDecoration: 'none', color: '#606060', width: '100%' }}
              >
                <ListItemButton
                  className={location.pathname === `/${page1[index]}?text=${item}` ? 'active' : ''}
                >
                  {/* <ListItemIcon onClick={() => (index === 0 ? onNewQueryClick() : null)}
                    sx={location.pathname == `/${page1[index]}` ? { color: '#2F80ED' } : { color: '#606060' }} >
                    {icon1[index]}
                  </ListItemIcon> */}
                  {index === 4 ? (
                    <Badge badgeContent={typeof notificationCounts === 'number' ? notificationCounts : 0} color="primary">
                     <ListItemIcon onClick={() => (index === 0 ? onNewQueryClick() : null)}
                     style={{minWidth:'35px'}}
                    sx={location.pathname == `/${page1[index]}` ? { color: '#2F80ED' } : { color: '#606060' }} >
                    {icon1[index]}
                  </ListItemIcon>
                    </Badge>
                  ) : (
                    <ListItemIcon onClick={() => (index === 0 ? onNewQueryClick() : null)}
                    sx={location.pathname == `/${page1[index]}` ? { color: '#2F80ED' } : { color: '#606060' }} style={{minWidth:'35px'}}>
                    {icon1[index]}
                  </ListItemIcon>
                  )}
                  <ListItemText
                    primary={item}
                    onClick={() => (index === 0 ? onNewQueryClick() : handleLinkClick(item))}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>


          ))}
          {conversationId && (
            <>
              <ListItemButton onClick={() => handleShareClick()} style={active} sx={{ margin: '15px' }}>
                <ListItemIcon onClick={() => handleShareClick()}>
                  <ShareIcon />
                </ListItemIcon>
                <ListItemText sx={{ color: 'grey' }} primary="Share" onClick={() => handleShareClick()} />
              </ListItemButton>
            </>
          )}
        </List>


        {/* <List>
          {text2.map((item, index) => (
            <ListItem key={item} className={location.pathname === `/${page2[index]}?text=${item}` ? 'active' : ''}>
              <NavLink
                to={`/${page2[index]}`}
                style={{ textDecoration: 'none', color: '#606060', width: '100%' }}
                className={location.pathname === `/${page2[index]}?text=${item}` ? 'active' : ''}

              >
                <ListItemButton
                  className={location.pathname === `/${page2[index]}?text=${item}` ? 'active' : ''}
                >
                  <ListItemIcon sx={location.pathname == `/${page2[index]}` ? { color: '#2F80ED' } : { color: '#606060' }}>{icon2[index]}</ListItemIcon>


                  <ListItemText onClick={() => handleLinkClick(item)}>{text2[index]}</ListItemText>
                </ListItemButton>
              </NavLink>
            </ListItem>
          ))}
        </List> */}
        <Divider sx={{ margin: '10px' }} />
        {/* <img className='image-3' src={image2} /> */}
        <Typography component="div"
          sx={{ flexGrow: 1, textAlign: 'center', color: '#575E71', padding: '10px 20px', fontSize: '12px' }}
        // className="cmd_footer"
        >
          Powered by <span style={{ fontWeight: 'bold', fontSize: '13px' }}>ZeonAI Labs</span>
        </Typography>
      </Drawer>
      <Dialog open={isShareModalOpen} onClose={handleShareModalClose}>
        <DialogTitle sx={{ paddingBottom: '0px', paddingTop: '32px', marginBottom: '0px' }}>Share Link</DialogTitle>
        <DialogContent >
          <Box>
            <p>Click this to copy a particular link that you wish to store/share with others</p>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {loadingShareLink ? (
                <CircularProgress />
              ) : (
                <>
                  <TextField label="Link" id="shareLinkTextField" value={sharelink}
                    onChangeText={(text) => setsharelink(text)} sx={{ width: '100%' }} InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {linkCopied ? (
                            <IconButton color="success">
                              <CheckCircleIcon />
                            </IconButton>
                          ) : (
                            <IconButton onClick={handleCopyToClipboard} color="primary">
                              <ContentCopyIcon />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }} />
                </>

              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleShareModalClose} color="primary">
            close
          </Button>

        </DialogActions>
      </Dialog>
    </>
  );

}