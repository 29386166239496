export const getAllRedList = async (user_id, userorg_id,searchText,catagoryType,catagoryStatus ,dateList,fromDate,toDate,token) => {
    try {
      const apiEndpoint = "cmdadmin/getallredlist";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
        serach_text:searchText,
        date: {
          type: "1",
          value: dateList,
        },
        activity_type:catagoryType,
        status:catagoryStatus,
        start_index: 0,
        limit: 15,
        
      };
      if (fromDate !== "" && toDate !== "") {
        requestData.date = {
          type: "2",
          value: `${fromDate},${toDate}`,
        };
      }
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };
  export const getAllRedListDownload = async (user_id, userorg_id,searchText,catagoryType,catagoryStatus ,dateList,fromDate,toDate,token) => {
    try {
      const apiEndpoint = "cmdadmin/getallredlist";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
        serach_text:searchText,
        date: {
          type: "1",
          value: dateList,
        },
        activity_type:catagoryType,
        status:catagoryStatus,
        start_index: 0,
        // limit: 15,
        
      };
      if (fromDate !== "" && toDate !== "") {
        requestData.date = {
          type: "2",
          value: `${fromDate},${toDate}`,
        };
      }
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };
  export const getMoreRedList = async (user_id, userorg_id,searchText,catagoryType,catagoryStatus ,dateList,fromDate,toDate,token,index) => {
    try {
      const apiEndpoint = "cmdadmin/getallredlist";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
        serach_text:searchText,
        date: {
          type: "1",
          value: dateList,
        },
        activity_type:catagoryType,
        status:catagoryStatus,
        start_index: index,
        limit: 15,
        
      };
      if (fromDate !== "" && toDate !== "") {
        requestData.date = {
          type: "2",
          value: `${fromDate},${toDate}`,
        };
      }
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };

  export const getAllRedListbyid = async (user_id, userorg_id,redlist_id,token) => {
    try {
      const apiEndpoint = "cmdadmin/getredlistbyid";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
        redlist_id:redlist_id,

      };
    
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };

  export const RedListbydeleteid = async (user_id, userorg_id,redlist_id,token) => {
    try {
      const apiEndpoint = "cmdadmin/deleteredlist";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
        redlist_id:redlist_id,

      };
    
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };
  export const getAllAttendees = async (user_id, userorg_id,type,deptId,token) => {
    try {
      const apiEndpoint = "cmdadmin/getallattendees";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
        start_index: 0,
        limit: 15,
        type: type,
        department_id: deptId,
       
      };
     
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };

  export const RedListCreatetemPath = async (user_id, userorg_id,token) => {
    try {
      const apiEndpoint = "cmdadmin/createtempdocpath";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
      

      };
    
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };

  export const RedListDocument = async (user_id, userorg_id,redlistid,docname,docid,docpath,activitytype,token) => {
    try {
      const apiEndpoint = "cmdadmin/downloadactivitydoucment";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
        activity_id:redlistid,
        doc_name:docname,
        document_id:docid,
         doc_path:docpath,
         activity_type: 12,
      

      };
    
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };
  export const RedListDocumentDeleteById = async (user_id, userorg_id,redlistid,docname,docid,docpath,activityType,token) => {
    try {
      const apiEndpoint = "cmdadmin/deleteactivitydocument";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
        activity_id:redlistid,
        doc_name:docname,
        document_id:docid,
         doc_path:docpath,
         activity_type: activityType,
      

      };
    
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };
  export const GetallnotificationCmdLink = async (user_id, userorg_id,searchText ,dateList,fromDate,toDate,token) => {
    try {
      const apiEndpoint = "cmdadmin/getallnotification";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
      
        serach_text:searchText,      
        date: {
          type: "1",
          value: dateList,
        },
        "start_index":"0",
        "limit":"20"
     

      };
      if (fromDate !== "" && toDate !== "") {
        requestData.date = {
          type: "2",
          value: `${fromDate},${toDate}`,
        };
      }
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };
  export const getGeneralInfoTab = async (user_id, userorg_id,dept_id,token) => {
    try {
      const apiEndpoint = "/cmdadmin/getgeneralinfo";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
        dept_id,

      };
    
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };
  export const AddGeneralInfo = async (user_id, userorg_id,dept_id,editorContent,token) => {
    try {
      const apiEndpoint = "cmdadmin/addgeneralinfo";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
        dept_id,
        content:editorContent,

      };
    
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };

  export const GetNotificationCount = async (user_id, userorg_id,token) => {
    try {
      const apiEndpoint = "cmdadmin/getnotificationcount";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
      
      };
    
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };
  export const UpdateNotification = async (user_id, userorg_id,token) => {
    try {
      const apiEndpoint = "cmdadmin/updatenotificationstatus";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
      
      };
    
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };
  